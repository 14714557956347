import {useEffect} from "react";
import {batch, useDispatch, useSelector} from "react-redux";

import PageLayout from "../../../components/layouts/PageLayout";
import SphereLoader from "../../../components/loaders/SphereLoader";
import Body from "./Body";
import {getBankAccounts, getUserWallet, getUserWalletEntries} from "../../../store/actions/wallet.action";
import {Loading, menuType} from "../../../utils/helpers/constants";
import { getProfile } from "../../../store/actions/profile.action";

const Wallet = () => {
  const dispatch = useDispatch();
  const {wallet, profile} = useSelector(({walletReducer, profileReducer}) => ({
    wallet: walletReducer,
    profile: profileReducer
  }));
  const loadState = wallet.loading !== Loading.SUCCESS || wallet.loadingEntries !== Loading.SUCCESS
    || wallet.loadingBankAccounts !== Loading.SUCCESS || profile.loading !== Loading.SUCCESS;

  useEffect(() => {
    batch(() => {
      if (profile.loading !== Loading.SUCCESS) dispatch(getProfile());
      if (wallet.loadingEntries !== Loading.SUCCESS) dispatch(getUserWalletEntries(""));
      if (wallet.loading !== Loading.SUCCESS) dispatch(getUserWallet());
      if (wallet.loadingBankAccounts !== Loading.SUCCESS) dispatch(getBankAccounts());
    });
  }, []);


  return (
    <PageLayout active={menuType.WALLET}>
      {loadState ? <SphereLoader/> :
        <Body wallet={wallet.wallet} accounts={wallet.bankAccounts} entries={wallet.walletEntries}
              count={wallet.entriesCount} orgainsation={profile.organisation}/>
      }
    </PageLayout>
  );
}

export default Wallet;
