import { Fragment, useCallback, useMemo, useState } from "react";
import { NavLink } from "react-bootstrap";
import Documents from "./Documents";

const TripDocuments = ({ trip }) => {
    const [key, setKey] = useState('WAYBILL');
  
    const changeManifestType = (event, type) => {
      event.preventDefault();
      setKey(type);
    }
  
    const DocumentList = (type) => {
      const filterDocuments = useCallback((userJourney) => {
        return userJourney.documents?.filter(data => (data && data.type && data.type.toUpperCase() === type.toUpperCase()));
      }, [type]);
  
      const filteredDocuments = useMemo(() => {
        return trip["user_journey"].map(filterDocuments);
      }, [filterDocuments, trip]);
  
      return (
        <div className="d-flex flex-wrap">
          {filteredDocuments.map((documents, index) =>  <Documents key={index} documents={documents ?? []} />)}
        </div>
      );
    };
  
  
    return (
      <Fragment>
        {((trip && trip["user_journey"]) && trip["user_journey"].some(item => (item.documents != null && (!Array.isArray(item.documents) || item.documents.length > 0)))) &&
          <div className="row row-sm">
            <div className="col-sm-12 col-md-12 col-xl-12 my-2">
              <div className="card custom-card">
                <div className="card-body">
                  <div className="d-flex justify-content-center">
                    <ul className="item1-links nav nav-tabs mt-4 mb-5" style={{ width: "900px", maxWidth: "100%" }}>
                      <li className="nav-item bg-light d-flex justify-content-center text-center">
                        <NavLink className={`nav-link ${key === 'WAYBILL' && 'active'} w-50`}
                          onClick={event => changeManifestType(event, 'WAYBILL')}>Waybill
                        </NavLink>
                        <NavLink className={`nav-link ${key === 'PROOF_OF_DELIVERY' && 'active'} w-50`}
                          onClick={event => changeManifestType(event, 'PROOF_OF_DELIVERY')}>Proof of Delivery
                        </NavLink>
                      </li>
                    </ul>
                  </div>
  
                  <div className="tab-content" id="myTabContent">
                    <div className={`tab-pane fade ${key === 'WAYBILL' && 'active show'}`}>
                      {DocumentList('WAYBILL')}
                    </div>
                    <div className={`tab-pane fade ${key === 'PROOF_OF_DELIVERY' && 'active show'}`}>
                      {DocumentList('PROOF_OF_DELIVERY')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
      </Fragment>
    );
  }
  
  export default TripDocuments;