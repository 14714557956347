import * as WalletConstants from "../constants/wallet.constants";
import {Loading} from "../../utils/helpers/constants";

const initialState = {
  loading: "",
  loadingEntries: "",
  loadingBanks: "",
  loadingBankAccounts: "",
  entriesCount: "",
  wallet: {},
  walletEntries: [],
  banks: [],
  bankAccounts: []
};

const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case WalletConstants.FETCH_WALLET_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case WalletConstants.FETCH_WALLET_SUCCESS:
      return {...state, loading: Loading.SUCCESS, wallet: action.payload};

    case WalletConstants.FETCH_WALLET_FAILURE:
      return {...state, loading: Loading.ERROR}

    case WalletConstants.FETCH_WALLET_ENTRIES_REQUEST:
      return {...state, loadingEntries: Loading.FETCHING};

    case WalletConstants.FETCH_WALLET_ENTRIES_SUCCESS:
      return {
        ...state,
        loadingEntries: Loading.SUCCESS,
        walletEntries: action.payload.data,
        entriesCount: action.payload.count
      };

    case WalletConstants.FETCH_WALLET_ENTRIES_FAILURE:
      return {...state, loadingEntries: Loading.ERROR}

    case WalletConstants.FETCH_BANKS_REQUEST:
      return {...state, loadingBanks: Loading.FETCHING};

    case WalletConstants.FETCH_BANKS_SUCCESS:
      return {...state, loadingBanks: Loading.SUCCESS, banks: action.payload};

    case WalletConstants.FETCH_BANKS_FAILURE:
      return {...state, loadingBanks: Loading.ERROR}

    case WalletConstants.FETCH_BANK_ACCOUNT_REQUEST:
      return {...state, loadingBankAccounts: Loading.FETCHING};

    case WalletConstants.FETCH_BANK_ACCOUNT_SUCCESS:
      return {...state, loadingBankAccounts: Loading.SUCCESS, bankAccounts: action.payload};

    case WalletConstants.FETCH_BANK_ACCOUNT_FAILURE:
      return {...state, loadingBankAccounts: Loading.ERROR}

    default:
      return state;
  }
};

export default walletReducer;
