import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

import SiennaSvg from "../../../../assets/images/svg/sienna.svg";
import ArrowBackSvg from "../../../../assets/images/svg/arrow-back.svg";
import BusinessConfirmation from "./BusinessConfirmation";
import DatePickerHook from "../../../../components/hooks/DatePickerHook";
import {validateIfInteger} from "../../../../utils/services/validator.service";
import {Loading} from "../../../../utils/helpers/constants";
import {BookBusinessTripFlowMap} from "../../../../utils/helpers/mappers";
import BookingService from "../../../../utils/services/booking.service";
import {generateString} from "../../../../utils/helpers/logicHelper";
import MapsSelectHook from "../../../../components/maps/MapsSelectHook";


const BookBusinessTrip = ({domState, wallet, orgWalletConfig, categories}) => {
	const navigate = useNavigate();
	const [step, setStep] = useState(1);
	const [stops, setStop] = useState("");
	const [date, setDate] = useState("");
	const [coupon, setCoupon] = useState("");
	const [category, setCategory] = useState({});
	const [waybill, setWaybill] = useState("");
	const [sourceObject, setSourceObject] = useState({});
	const [confirmationData, setConfirmationData] = useState({});
	const [destinationObject, setDestinationObject] = useState({});
	const [confirmedVehicleData, setConfirmedVehicleData] = useState({});
	const [loading, setLoading] = useState(false);
	const [confirmationState, setConfirmationState] = useState(false);


	const tripContext = BookBusinessTripFlowMap[step];

	const goToDashboard = () => navigate(-1);

	const updateLocation = (placeData, type) => {
		let placeObj = {
			city: placeData.city,
			state: placeData.state,
			address: placeData.address,
			latitude: placeData['latitude'],
			longitude: placeData['longitude']
		};
		if (type === "SOURCE") setSourceObject(prevState => ({ ...prevState, ...placeObj }))
			if (type === "DESTINATION") setDestinationObject(prevState => ({ ...prevState, ...placeObj }))
	}

	const isFormValid = () => {
		if(step === 1 && Object.entries(sourceObject).length > 0) return true;
		else if(step === 2 && Object.entries(destinationObject).length > 0) return true;
		else if(step === 3 && date) return true;
		else if(step === 4 && Object.entries(category).length > 0) return true;
		else if(step === 5) return true;
		return false;
	}

	const canPrevious = () => step !== 1;

	const decreaseStep = () => {
		if(step === 1 || step === 2);
		if(canPrevious()) setStep(step - 1);
	}

	const bookHandler = async() => {
		setLoading(true);
		let destination = {...destinationObject, stops: {stopovers: [], number: stops}};

		if(step < 5) setStep(step + 1);
		else {
			let trips = [{
				coupon, destination, source: sourceObject, scheduled_date: moment(date).format(),
				trip_type: domState.business.config, category_size: category.id,
				waybill_number: waybill ? waybill : generateString(8)
			}];
			let payload = {business: domState.business.id, trips};
			const {data: responseData} = await BookingService.bookBusinessTrip(payload);

			if(responseData.status !== Loading.SUCCESS) {
				const message = (responseData && responseData.message) ? responseData.message : "You cannot book trip at the moment, please try again later";
				toast.error(message);
			}
			else {
				setConfirmationState(true);
				setConfirmationData(responseData.data);
				setConfirmedVehicleData(category);
			}
		}
		setLoading(false);
	}


	return (
		<Fragment>
			<p className="mt-4 booking-direct fs-18 pointer-event" onClick={goToDashboard}>
				<img src={ArrowBackSvg} alt="Arrow-back"/> &nbsp; Dashboard
			</p>
			<div className="py-md-5 pt-5 pb-2 ms-2 ms-md-5">
				{confirmationState ?
					<BusinessConfirmation domState={domState} wallet={wallet} orgWalletConfig={orgWalletConfig}
																booking={confirmationData} vehicle={confirmedVehicleData}/> :
					<div className="booking-main d-flex flex-column">
						<h3 className="booking-title">Book a trip</h3>
						<form className="booking-form d-flex mt-4 flex-column align-items-start">
							<label>{tripContext.title}</label>
							<div className="position-relative w-100 mg-t-15">
								{step === 1 &&
									<MapsSelectHook
									setInitialValue={ sourceObject.address}
									setLocation={value => updateLocation(value, "SOURCE")} />
								}
								{step === 2 &&
									<MapsSelectHook
									setInitialValue={ destinationObject.address}
									setLocation={value => updateLocation(value, "DESTINATION")} />
								}
								{step === 3 ?
									<DatePickerHook placeholder="Select scheduled date" dateFormat="MMMM d, yyyy h:mm aa"
																	selected={date} getDate={setDate} timeIntervals={30} filterTime={true}
																	showYearDropdown selectDropdownMode withPortal showTimeSelect
																	setMinDate={new Date()}/>
									: ""
								}
								{step === 4 ?
									<>
										<div className="grid-group">
											{categories.map((item, index) =>
												<div key={index}
														 className={`d-flex flex-column raise-transition text-center ${item['tonnage'] === category['tonnage'] && 'selected-option'}`}
														 onClick={() => setCategory(item)}>
													<img className="mb-0 d-block mx-auto" src={item.image ?? SiennaSvg} alt="" width={75}
															 height={75}/>
													<span className="mt-0">{item.category} - {item['tonnage']} tonne</span>
												</div>
											)}
										</div>
										{Object.entries(category).length > 0 &&
											<div
												className="description-box mt-5 text-muted fw-semibold">Description: {category.description ?? ""}</div>
										}
									</> : ""
								}
								{step === 5 &&
									<>
										<div className="form-group mt-3">
											{stops && <label className="d-block tx-18-f">Stops</label>}
											<input type="number" className="mt-1" placeholder="Enter the number of stops" value={stops}
														 onKeyPress={validateIfInteger}
														 onChange={e => setStop(e.target.value)}/>
										</div>
										<div className="form-group mt-4">
											{waybill && <label className="d-block tx-18-f">Waybill Number</label>}
											<input type="text" className="mt-1" placeholder="Enter your waybill number (If Applicable)"
														 value={waybill} onChange={e => setWaybill(e.target.value)}/>
										</div>
										{domState.business["is_merchant"] &&
											<div className="form-group mt-4 mb-3">
												{coupon && <label className="d-block tx-18-f">Coupon Code</label>}
												<input type="text" className="mt-1" placeholder="Enter your coupon code (If Applicable)"
															 value={coupon}
															 onChange={e => setCoupon(e.target.value.toUpperCase())}/>
											</div>
										}
									</>
								}
							</div>
							<div className="booking-btn-group wd-md-85p-f wd-lg-70p-f">
								{step !== 1 ?
									<button className="btn main-btn bg-danger text-white mt-4 me-4" onClick={decreaseStep} type="button">
										{tripContext.prev_button}
									</button>
									: ''}
								<button className="btn main-btn mt-4" disabled={!isFormValid()} onClick={bookHandler} type="button">
									{tripContext.next_button}
									{loading ? <i className="zmdi zmdi-spinner mg-l-5 zmdi-hc-spin"/> : ""}
								</button>
							</div>
						</form>
						<div className="mt-auto">
							<div className="d-flex justify-content-between align-items-center me-5">
								<div className="booking-step flex-grow-1">
									<p className="mb-1">{step} of 5</p>
									<div className="booking-progress">
										<div className={`booking-progress-bar ${tripContext.progress}`}></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</Fragment>
	)
}

export default BookBusinessTrip;
