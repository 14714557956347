import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {batch, useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import PageLayout from "../../../../components/layouts/PageLayout";
import { getProfile } from "../../../../store/actions/profile.action";
import {getUserWallet} from "../../../../store/actions/wallet.action";
import {Loading, menuType} from "../../../../utils/helpers/constants";
import {getPickList, getTripInfo} from "../../../../store/actions/booking.action";

const TripInfo = () => {

  const dispatch = useDispatch(), {ref} = useParams();
  const {booking, profile, finance} = useSelector(({bookingReducer, profileReducer, walletReducer}) => ({
    booking: bookingReducer,
    profile: profileReducer,
    finance: walletReducer
  }));

  const loadState = booking.loadingInfo !== Loading.SUCCESS || booking.loadingPicklist !== Loading.SUCCESS
    || finance.loading !== Loading.SUCCESS || profile.loading !== Loading.SUCCESS;

  useEffect(() => {
    batch( () => {
      dispatch(getTripInfo({ reference: ref }, profile.domState.type));

      if (profile.domState.type === "BUSINESS") dispatch(getPickList(ref, profile.domState.business.reference));
      else dispatch(getPickList(ref, ''));

      if (finance.loading !== Loading.SUCCESS) dispatch(getUserWallet());
      if(profile.loading !== Loading.SUCCESS) dispatch(getProfile());
    });
  }, []);

  return (
    <PageLayout active={menuType.TRIP}>
      {loadState ? <SphereLoader/> :
        <Body domState={profile.domState} wallet={finance.wallet} organisation={profile.organisation}
              trip={booking.trip} picklist={booking.picklist} />}
    </PageLayout>
  );
}

export default TripInfo;
