import {useDispatch, useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

import HomeSvg from "../../../assets/images/svg/home.svg";
import WalletSvg from "../../../assets/images/svg/wallet.svg";
import OrderSvg from "../../../assets/images/svg/order.svg";
import ProfileSvg from "../../../assets/images/svg/profile.svg";
import SettingsSvg from "../../../assets/images/svg/settings.svg";
import HomeSelectedSvg from "../../../assets/images/svg/home-selected.svg";
import OrderSelectedSvg from "../../../assets/images/svg/order-selected.svg";
import WalletSelectedSvg from "../../../assets/images/svg/wallet-selected.svg";
import ProfileSelectedSvg from "../../../assets/images/svg/profile-selected.svg";
import SettingsSelectedSvg from "../../../assets/images/svg/settings-selected.svg";
import {menuType} from "../../../utils/helpers/constants";
import {toggleNavMenu} from "../../../store/actions/component.action";


const SideMenu = ({active, navStatus}) => {
	const dispatch = useDispatch();
	const toggleMobileNav = () => dispatch(toggleNavMenu(false));

	const profile = useSelector(({profileReducer}) => profileReducer);
	const organisation = profile.organisation
	const logo = organisation['logo'];


	return (
		<div id="sidebar" className={`sidebar d-flex flex-column offcanvas-lg offcanvas-end ${navStatus ? 'show' : ''}`}>
			<div className="sidebar-logo d-lg-none d-flex align-items-center justify-content-between">
				{logo && <NavLink to="/">
					<img src={logo} alt="Organisation Logo" width={90} height={90} className="d-block mx-auto"/>
				</NavLink>
				}
				<span className="close-menu pt-4" onClick={toggleMobileNav} aria-label="Close">x</span>
			</div>
			<div className="sidebar-menu d-flex flex-column pt-4">
				<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.HOME ? 'active' : ''}`}>
					<NavLink className="d-flex align-items-center" to="/">
						<img src={active === menuType.HOME ? HomeSelectedSvg : HomeSvg} alt="HOME"/>
						<span className="ms-2">Home</span>
					</NavLink>
				</div>
				<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.WALLET ? 'active' : ''}`}>
					<NavLink className="d-flex align-items-center" to="/wallet">
						<img src={active === menuType.WALLET ? WalletSelectedSvg : WalletSvg} alt="WALLET"/>
						<span className="ms-2">Wallet</span>
					</NavLink>
				</div>
				<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.TRIP ? 'active' : ''}`}>
					<NavLink className="d-flex align-items-center" to="/trips">
						<img src={active === menuType.TRIP ? OrderSelectedSvg : OrderSvg} alt="TRIP"/>
						<span className="ms-2">Trips</span>
					</NavLink>
				</div>
				<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.PROFILE ? 'active' : ''}`}>
					<NavLink className="d-flex align-items-center" to="/profile/edit-profile">
						<img src={active === menuType.PROFILE ? ProfileSelectedSvg : ProfileSvg} alt="PROFILE"/>
						<span className="ms-2">Profile</span>
					</NavLink>
				</div>
				<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.SETTINGS ? 'active' : ''}`}>
					<NavLink className="d-flex align-items-center" to="/settings/wallet-pin">
						<img src={active === menuType.SETTINGS ? SettingsSelectedSvg : SettingsSvg} alt="SETTINGS"/>
						<span className="ms-2">Settings</span>
					</NavLink>
				</div>
			</div>
		</div>

	);
}

export default SideMenu;
