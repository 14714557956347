import axios from 'axios';
import cacheHelper from "../helpers/cacheHelper";
import TokenHelpers from "../helpers/tokenHelper";
import cookieHelper from "../helpers/cookieHelper";
import {Configs, GeneralAppInfo, UserType} from "../helpers/constants";

export const LOCALHOST = "localhost";
export const BETA_BASEURL = "dodge-super-app-beta.vercel.app";


export const getBaseUrl = () => {
	if(window.location.hostname === LOCALHOST || window.location.hostname === BETA_BASEURL) {
		return GeneralAppInfo.DODGE_STAGING_BASE_URL;
	} else return GeneralAppInfo.DODGE_BASE_URL;
};

const dodgeInstance = axios.create({baseURL: getBaseUrl()});

dodgeInstance.interceptors.request.use(config => {
	let token = TokenHelpers.getToken(), organisation = TokenHelpers.getSelectedOrg();
	let headers = {organisation, 'Content-Type': 'application/json', Authorization: `Bearer ${token}`, ...config.headers};
	if(organisation) headers.organisation = organisation;
	if(TokenHelpers.checkIfLoggedIn()) config.headers = headers;
	else config.headers = {organisation, 'Content-Type': 'application/json', ...config.headers};
	return config;
}, error => Promise.reject(error));

dodgeInstance.interceptors.response.use(response => response, error => {
	let token = TokenHelpers.getToken();
	let loginRoute = TokenHelpers.getUserType() === UserType.ADMIN ? '/auth/admin/login' : '/auth/login';
	if(error && error.response && error.response.status === 403) window.location.href = '/403';
	else if(error && error.response && error.response.status === 401 && token) {
		cookieHelper.remove(Configs.KEY);
		cacheHelper.clear();
		window.location.href = loginRoute;
	} else return error.response;
});

export {dodgeInstance};
