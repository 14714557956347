import {useState} from "react";
import {useDispatch} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import moment from "moment";

import UpArrowSvg from "../../../assets/images/svg/up-arrow.svg";
import DownArrowSvg from "../../../assets/images/svg/down-arrow.svg";
import Gravatar from "../../../assets/images/pngs/gravatar.png";
import NotificationSvg from "../../../assets/images/svg/notifications.svg";
import NotificationUnreadSvg from "../../../assets/images/svg/notifications_unread.svg";
import * as ProfileConstants from "../../../store/constants/profile.constants";
import ActionCreator from "../../../utils/helpers/actionCreator";
import {removeLoggedInUser} from "../../../store/actions/auth.action";
import {toggleNavMenu} from "../../../store/actions/component.action";
import TokenHelper from "../../../utils/helpers/tokenHelper";
import {DomStateType} from "../../../utils/helpers/constants";


const HeaderNav = ({domState, businesses, profile, driverProfile, organisation, notifications, notificationCount}) => {

	const dispatch = useDispatch(), navigate = useNavigate();
	const logo = organisation['logo'];
	const [showMenu, setShowMenu] = useState(false);
	const [notificationDropdown, setNotificationDropdown] = useState(false);


	const dropdownMenu = () => setShowMenu(!showMenu);
	const logoutUser = () => dispatch(removeLoggedInUser());
	const toggleMobileNav = () => dispatch(toggleNavMenu(true));
	const toggleNotificationDropdown = () => setNotificationDropdown(!notificationDropdown);
	const goToProfile = () => navigate('/profile/edit-profile');
	const goToSettings = () => navigate('/settings/wallet-pin');

	const renderDomType = () => {
		if(!domState || Object.entries(domState).length === 0) return 'Customer Profile';
		else {
			if(domState.type === DomStateType.BUSINESS) return domState.business.name;
			else if(domState.type === DomStateType.DRIVER) return 'Driver Profile';
			else if(domState.type === DomStateType.CUSTOMER) return 'Customer Profile';
		}
	}

	const setCustomerProfile = () => {
		let newState = {type: DomStateType.CUSTOMER, id: profile.id};
		dispatch(ActionCreator(ProfileConstants.SET_DOM_PROFILE, newState));
	}

	const setBusinessProfile = business => {
		let newState = {type: DomStateType.BUSINESS, id: business.id, business};
		TokenHelper.saveSelectedBusiness(business.reference);
		dispatch(ActionCreator(ProfileConstants.SET_DOM_PROFILE, newState));
	}

	const setDriverProfile = () => {
		let newState = {type: DomStateType.DRIVER, id: driverProfile.id, driverProfile};
		dispatch(ActionCreator(ProfileConstants.SET_DOM_PROFILE, newState));
	}

	

	return (
		<div className="header navbar justify-content-between navbar-expand-lg">
			{logo && <div className="header-logo d-none d-lg-block mb-4">
				<NavLink to="/" className="header-logo-link py-4">
					<img src={logo} alt="Super App Logo" width={90} height={90} className="header-logo d-block mx-auto"/>
				</NavLink>
			</div>}
			<div className="header-end d-flex justify-content-end">
				<div className="dropdown header-end-notif notifications px-sm-4 px-2" onClick={toggleNotificationDropdown}>
					{(!notificationCount || notificationCount === 0)
						? <img src={NotificationSvg} alt="notifications" width={25} height={25}/>
						: <img src={NotificationUnreadSvg} alt="notifications" width={25} height={25}/>
					}

					<div className={`dropdown-menu mb-3 mt-4 ${notificationDropdown ? 'show' : ''}`}>
						<div className="dropdown-item border-bottom py-2">
							You have {(!notificationCount || notificationCount === 0) ? "0" : notificationCount} unread notifications.
						</div>
						{(notifications && notifications.length > 0) && notifications.slice(0, 3).map((item, index) => (
							<div key={index} className="dropdown-item border-bottom py-2">
								<div className="media-body">
									<p>{item.title ? item.title : ""}</p>
									<span
										className="float-end">{item['delivery_time'] ? moment(item['delivery_time']).format('ll') : ""}</span>
								</div>
							</div>
						))}
						<div className="dropdown-footer border-top text-center py-2">
							<NavLink to="/settings/notifications">View All Notifications</NavLink>
						</div>
					</div>
				</div>
				<div className="header-end-profile d-flex align-items-center px-md-5 px-0 pointer-event">
					<img onClick={goToProfile} src={profile.picture ?? Gravatar} alt="Profile"
							 className="rounded-circle bg-light" width={50} height={50}/>
					<div onClick={dropdownMenu} className="ms-sm-3 ms-1">
						<p className="mb-0 d-lg-none d-block">Welcome Back,</p>
						<p className="mb-0 role-text">{profile.first_name} {profile.last_name}</p>
						<p className="mb-0 role-text type">{renderDomType()}</p>
					</div>
					<div onClick={dropdownMenu} className="dropdown header-end-notif px-sm-4 px-2 pointer-event">
						{showMenu ? <img onClick={dropdownMenu} src={DownArrowSvg} alt="Exit"/>
							: <img onClick={dropdownMenu} src={UpArrowSvg} alt="Exit"/>
						}
						<div className={`dropdown-menu mb-3 ${showMenu ? 'show' : ''}`}>
							<div onClick={goToProfile} className="dropdown-item py-2">Profile</div>
							<div onClick={setCustomerProfile} className="dropdown-item py-2">Customer Profile</div>
							{businesses.map((item, index) =>
								<div key={index} onClick={() => setBusinessProfile(item)}
										 className="dropdown-item py-2">{item.name} Business</div>
							)}
							{profile['is_driver'] ?
								<div onClick={setDriverProfile} className="dropdown-item py-2">Driver Profile</div> : ""
							}
							<div onClick={goToSettings} className="dropdown-item py-2">Settings</div>
							<div onClick={logoutUser} className="dropdown-item text-danger py-2">Log out</div>
						</div>
					</div>
				</div>

				<button className="navbar-toggler" onClick={toggleMobileNav} type="button">
					<span className="mg-l-5"><i className="zmdi zmdi-menu"/></span>
				</button>
			</div>
		</div>
	);
}

export default HeaderNav;
