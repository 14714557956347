import {BrowserRouter as Router, Route, Routes, Outlet, Navigate} from "react-router-dom";
import {Toaster} from "react-hot-toast";

import Login from "./pages/auth/login/Login";
import Register from "./pages/auth/register/Register";
import ResetPassword from "./pages/auth/resetPassword/ResetPassword";
import ForgotPassword from "./pages/auth/forgotPassword/ForgotPassword";
import Home from "./pages/dashboard/home/Home";
import Wallet from "./pages/dashboard/wallet/Wallet";
import Trips from "./pages/dashboard/trips/list/Trips";
import BookTrip from "./pages/dashboard/book-trip/BookTrip";
import TripInfo from "./pages/dashboard/trips/info/TripInfo";
import Warehouse from "./pages/dashboard/profile/warehouse/Warehouse";
import BookManifest from "./pages/dashboard/book-manifest/BookManifest";
import DriverInfo from "./pages/dashboard/profile/driver-info/DriverInfo";
import OnboardDriver from "./pages/dashboard/onboard-driver/OnboardDriver";
import EditProfile from "./pages/dashboard/profile/edit-profile/EditProfile";
import OnboardVehicle from "./pages/dashboard/onboard-vehicle/OnboardVehicle";
import BankAccount from "./pages/dashboard/settings/bank-account/BankAccount";
import OnboardBusiness from "./pages/dashboard/onboard-business/OnboardBusiness";
import WarehouseInfo from "./pages/dashboard/profile/warehouse/atom/WarehouseInfo";
import WithdrawalPin from "./pages/dashboard/settings/withdrawal-pin/WithdrawalPin";
import DeleteAccount from "./pages/dashboard/settings/delete-account/DeleteAccount";
import OnboardWarehouse from "./pages/dashboard/onboard-warehouse/OnboardWarehouse";
import DisableAccount from "./pages/dashboard/profile/disable-account/DisableAccount";
import ChangePassword from "./pages/dashboard/settings/change-password/ChangePassword";
import VehicleInfo from "./pages/dashboard/profile/registered-vehicles/atom/VehicleInfo";
import RegisteredVehicles from "./pages/dashboard/profile/registered-vehicles/RegisteredVehicles";
import NotificationPreference from "./pages/dashboard/settings/notification-preference/NotificationPreference";
import Error404 from "./pages/misc/error/Error404";

import TokenHelpers from "./utils/helpers/tokenHelper";
import Notifications from "./pages/dashboard/settings/notifications/Notifications";
import ApiIntegration from "./pages/dashboard/settings/api-Integration/ApiIntegration";




const App = () => {

	const AuthenticatedRoutes = () => {
		if(TokenHelpers.checkIfLoggedIn()) return <Outlet/>
		else return <Navigate to="/auth/login" replace/>;
	};

	return (
		<Router>
			<Toaster containerStyle={{zIndex: '99999999'}}/>
			<Routes>
				<Route path="/auth/login" element={<Login/>}/>
				<Route path="/auth/register" element={<Register/>}/>
				<Route path="/auth/reset-password" element={<ResetPassword/>}/>
				<Route path="/auth/forgot-password" element={<ForgotPassword/>}/>
				<Route element={<AuthenticatedRoutes/>}>
					{['/', 'home', 'dashboard'].map(path => <Route path={path} key={path} element={<Home/>}/>)}
					<Route path="book-trip" element={<BookTrip/>}/>
					<Route path="book-manifest" element={<BookManifest/>}/>
					<Route path="wallet" element={<Wallet/>}/>

					<Route path="trips">
						<Route index element={<Trips/>}/>
						<Route path=":ref" element={<TripInfo/>}/>
					</Route>

					<Route path="profile">
						<Route path="edit-profile" element={<EditProfile/>}/>
						<Route path="warehouse">
							<Route index element={<Warehouse/>}/>
							<Route path=":ref" element={<WarehouseInfo/>}/>
						</Route>
						<Route path="disable-account" element={<DisableAccount/>}/>
						<Route path="driver-information" element={<DriverInfo/>}/>
						<Route path="registered-vehicle">
							<Route index element={<RegisteredVehicles/>}/>
							<Route path=":reg" element={<VehicleInfo/>}/>
						</Route>
					</Route>

					<Route path="settings">
						<Route path="wallet-pin" element={<WithdrawalPin/>}/>
						<Route path="change-password" element={<ChangePassword/>}/>
						<Route path="api-integration" element={<ApiIntegration/>}/>
						<Route path="bank-account" element={<BankAccount/>}/>
						<Route path="notifications" element={<Notifications/>}/>
						<Route path="notification-preference" element={<NotificationPreference/>}/>
						<Route path="delete-account" element={<DeleteAccount/>}/>
					</Route>

					<Route path="onboard-driver" element={<OnboardDriver/>}/>
					<Route path="onboard-business" element={<OnboardBusiness/>}/>
					<Route path="register-vehicle" element={<OnboardVehicle/>}/>
					<Route path="register-warehouse" element={<OnboardWarehouse/>}/>
				</Route>
				<Route path="*" element={<Error404/>}/>
			</Routes>
		</Router>
	);
}

export default App;
