import { Fragment, useState} from "react";
import { useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import moment from "moment";

import MapHook from "../../../../components/maps/MapHook";
import CostSvg from "../../../../assets/images/svg/cost.svg";
import TruckSvg from "../../../../assets/images/svg/truck.svg";
import TransitSvg from "../../../../assets/images/svg/transit.svg";
import ProfileSvg from "../../../../assets/images/svg/profile.svg";
import { TripStatusMap } from "../../../../utils/helpers/mappers";
import BookingService from "../../../../utils/services/booking.service";
import { getPickList, getTripInfo } from "../../../../store/actions/booking.action";
import { formatAmountToString, toTitleCase } from "../../../../utils/helpers/logicHelper";
import { ActionPromptOption, DomStateType, Loading, TripStatus } from "../../../../utils/helpers/constants";
import ActionPromptModal from "../../../../components/modals/ActionPromptModal";
import AdvancePaymentModal from "../../../../components/modals/AdvancePaymentModal";
import DriverConfirmationCodeModal from "../../../../components/modals/DriverConfirmationCodeModal";
import TripDocuments from "../../../../components/trips/TripDocuments";
import TripReconciliation from "./atom/TripReconciliation";


const Body = ({ domState, organisation, wallet, trip, picklist }) => {

  const dispatch = useDispatch(), orgWalletConfig = organisation && organisation["wallet_configs"];
  const [loading, setLoading] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  const [cancelTripRender, setCancelTripRender] = useState(false);
  const [stopNumber, setStopNumber] = useState(null);
  const [advancePaymentRender, setAdvancePaymentRender] = useState(false);
  const [driverConfirmationRender, setDriverConfirmationRender] = useState(false);
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);
  const [imageUploadRender, setImageUploadRender] = useState(false);


  let isBusiness = (trip['business_tripset'] && trip['business_tripset']["business"]) ? trip['business_tripset']["business"]
    : (trip['interoperability_details'] && trip['interoperability_details']['is_business']) ? trip['interoperability_details']['is_business'] : null;

    const isPickUpReconciled = picklist?.some(eachStop => (eachStop.status === "TRIP-ASSIGNED" && (eachStop?.type === "PICKUP" || eachStop?.label.toUpperCase().startsWith('PICKUP'))));

  const handleSuccessResponse =  () => {
     dispatch(getTripInfo({ reference: trip.reference }, domState.type));
    if (domState.type === DomStateType.BUSINESS) dispatch(getPickList(trip.reference, domState.business.reference));
    else dispatch(getPickList(trip.reference, ''));
  }

  const getCheckoutUrl = async () => {
    setLoadingPayment(true);
    const callbackUrl = `${window.location.origin}/trips/${trip.reference}/`;
    if (isBusiness) {
      if (trip.quote['checkout_url']) window.open(trip.quote['checkout_url'], '_self')?.focus();
      else {
        const { data: responseData } = await BookingService.checkoutBusiness(trip['business_tripset']['tripset_id'], trip['business_tripset']['business'], callbackUrl);
        if (responseData.status !== Loading.SUCCESS) {
          const message = (responseData && responseData.message) ? responseData.message : "You cannot pay for this trip at the moment, please try again later";
          toast.error(message);
        }
        else window.open(responseData.data['checkout_url'], '_self')?.focus();
      }
    } else {
      if (trip.quote['checkout_url']) window.open(trip.quote['checkout_url'], '_self')?.focus();
      else {
        const { data: responseData } = await BookingService.checkout(trip.id, callbackUrl);
        if (responseData.status !== Loading.SUCCESS) {
          const message = (responseData && responseData.message) ? responseData.message : "You cannot pay for this trip at the moment, please try again later";
          toast.error(message);
        }
        else window.open(responseData.data['checkout_url'], '_self')?.focus();
      }
    }
    setLoadingPayment(false);
  }

  const payWithWallet = async () => {
    let data;
    setLoadingPayment(true);
    if (isBusiness) {
      data = await BookingService.walletBusinessCheckout(trip['business_tripset']['tripset_id'], trip['business_tripset']['business']);
    } else data = await BookingService.walletCheckout(trip.id);
    if (data.data.status !== Loading.SUCCESS) {
      const message = (data && data.message && data.data.message) ? data.data.message.message : "You cannot pay for this trip at the moment, please try again later";
      toast.error(message);
    }
    else {
      toast.success(data.data.message);
      setLoadingPayment(false);
      await handleSuccessResponse();
    }
  }

  const handleDriverUpdate = async () => {
    let data, payload = { driver: domState.id, trip: trip.reference };
    if ((isBusiness && trip['system_status'] === "TRIP-STARTED") || trip['system_status'] === "DROP-OFF") {
      setLoading(true);
      if ('geolocation' in navigator) {
        setLoading(true);
        navigator.geolocation.getCurrentPosition(async position => {
          setLoading(true);
          let payload = { latitude: position.coords.latitude, longitude: position.coords.longitude }
          const { data: responseData } = await BookingService.dropOffDelivery(trip.id, payload);
          if (responseData.status !== Loading.SUCCESS) {
            const message = (responseData && responseData.message) ? responseData.message : "You cannot confirm drop off for this trip at the moment, please try again later";
            toast.error(message);
          }
          else await handleSuccessResponse();
          setLoading(false);
        });
      }
    } else if (trip['system_status'] === "DRIVER-AWAITING-CONFIRMATION") openDriverCodeConfirmationRender();
    else {
      setLoading(true);
      if (trip['system_status'] === "ORDER-CONFIRMED") data = await BookingService.acceptTripRequest(payload);
      else if (trip['system_status'] === "ASSIGNED-TO-DRIVER") data = await BookingService.validateTripArrival(trip.id);
      else if (trip['system_status'] === "LOADING") data = await BookingService.startTrip(trip.id);
      else if (trip['system_status'] === "DRIVER-ARRIVED") data = await BookingService.startTrip(trip.id);
      else if (trip['system_status'] === "TRIP-STARTED") data = await BookingService.validateDelivery(trip.id);
      else if (trip['system_status'] === "COMPLETED") data = await BookingService.reconcileDelivery(trip.id);

      if (data.data.status !== Loading.SUCCESS) {
        const message = (data && data.message && data.data.message) ? data.data.message : "You cannot update trip status at the moment, please try again later";
        toast.error(message);
      }
      else await handleSuccessResponse();
      setLoading(false);
    }
  }

  const handleDriverLabel = () => {
    let status;
    if (isBusiness && trip['system_status'] === 'TRIP-STARTED') status = 'TRIP-STARTED-WITH-STOPS';
    else {
      if (!trip['system_status'] || trip['system_status'] === 'None') status = 'OTHER';
      else if (trip['system_status'] === 'TRIP-STARTED') status = 'DROP-OFF';
      // else if (trip['system_status'] === 'DRIVER-AWAITING-CONFIRMATION') status = 'AWAITING-DELIVERY-CONFIRMATION';
      else status = trip['system_status']
    }
    return TripStatusMap[status].nextButton;
  }

  const changeCancelRenderStatus = () => setCancelTripRender(false);
  const changeAdvancePaymentRender = () => setAdvancePaymentRender(false);
  const openDriverCodeConfirmationRender = () => setDriverConfirmationRender(true);
  const changeDriverConfirmationRenderStatus = () => setDriverConfirmationRender(false);


  const advancePaymentModal = advancePaymentRender ?
    <AdvancePaymentModal onchange={changeAdvancePaymentRender} props={{ trip, wallet }}
      onSuccessResponse={handleSuccessResponse} /> : null;

  const cancelTripModal = cancelTripRender ?
    <ActionPromptModal onchange={changeCancelRenderStatus} type={ActionPromptOption.CANCEL_TRIP} props={trip}
      initValue={trip['reference']} setDeleteSuccess={handleSuccessResponse} /> : null;

  const driverConfirmationModal = driverConfirmationRender ?
    <DriverConfirmationCodeModal trip={trip} onchange={changeDriverConfirmationRenderStatus}
      onSuccessResponse={handleSuccessResponse} /> : null;


  return (
    <div className="main p-sm-5 p-3">
      {cancelTripModal}
      {advancePaymentModal}
      {driverConfirmationModal}
      <div className="d-flex flex flex-lg-row flex-column">
        <p className="table-title my-3 flex-1">Trip Information - {trip.status}</p>
        {domState.type === DomStateType.DRIVER && ((!trip['business_tripset'] && trip['system_status'] !== "TRIP-STARTED" && trip['system_status'] !== "DROP-OFF" && trip["system_status"] !== TripStatus.COMPLETED)
							|| (trip['business_tripset'] && trip["system_status"] !== TripStatus.RECONCILED)) &&
          <button onClick={handleDriverUpdate} className="btn main-btn trip-btn text-decoration-none mg-r-2 mb-2">
            {handleDriverLabel()}
            {loading ? <i className="zmdi zmdi-spinner zmdi-hc-spin ms-3" /> : ""}
          </button>
        }
        {domState.type === DomStateType.BUSINESS && trip["is_paid"] && trip['system_status'] === "COMPLETED" &&
          <button onClick={handleDriverUpdate} className="btn main-btn trip-btn text-decoration-none mg-r-2 mb-2">
            Reconcile Trip
            {loading ? <i className="zmdi zmdi-spinner zmdi-hc-spin ms-3" /> : ""}
          </button>
        }
        {domState.type === DomStateType.DRIVER && !trip['is_ims'] && trip["system_status"] === TripStatus.RECONCILED &&
          <button onClick={() => {setImageUploadRender(true); trip?.destination?.stops ? setStopNumber(trip?.destination?.stops.number) : setStopNumber(1)}} className="btn main-btn secondary-btn text-decoration-none mg-r-2 mb-2">
            Upload document
            {loadingImageUpload ? <i className="zmdi zmdi-spinner zmdi-hc-spin ms-3" /> : ""}
          </button>
        }

        {!trip['is_paid'] &&
          <div>
            <Dropdown className="d-inline" drop="down-centered">
              <Dropdown.Toggle variant="primary" className="trip-btn text-decoration-none mg-r-4 mb-3">
                {loadingPayment ? <i className="zmdi zmdi-spinner zmdi-hc-spin text-white me-2" /> :
                  <i className="zmdi zmdi-caret-down-circle me-2" />} Payment Options
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item className="fs-6" onClick={getCheckoutUrl}>
                  Pay with Card
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item className="fs-6" onClick={payWithWallet}>
                  Pay with Wallet
                </Dropdown.Item>
                {((isBusiness && Object.entries(trip['quote']).length > 0)
                  && (wallet && wallet.extras && wallet.extras["advanced_payment_status"])
                  && (orgWalletConfig && orgWalletConfig["advance_payment_status"])) &&
                  <Fragment>
                    <Dropdown.Divider />
                    <Dropdown.Item className="fs-6" onClick={() => setAdvancePaymentRender(true)}>
                      Part Payment
                    </Dropdown.Item>
                  </Fragment>
                }
              </Dropdown.Menu>
            </Dropdown>
            <button onClick={() => setCancelTripRender(true)} className="btn bg-danger trip-btn text-white mb-3">
              Cancel
            </button>
          </div>
        }
      </div>
      <div className="main-table py-4 px-md-2 px-0 d-flex flex-column">
        <div className="container-fluid order-md-2 order-1">
          <div className="row">
            <div className="col-sm-8 h-100 py-2">
              <div className="text-center" style={{ height: '60vh', width: '100%' }}>
                <MapHook source={trip.source} destination={trip.destination} />
              </div>
            </div>
            <div className="col-sm-4 h-100 py-2">
              <div className="d-flex flex-column main-card p-3 pb-4">
                <div className="main-card-icon d-flex justify-content-center cost-bg"><img src={TruckSvg} alt="" /></div>
                <p className="mt-2 mb-1">Date: {moment(trip["scheduled_date"]).format('lll')}</p>
                <p className="mt-2 mb-1">
                  Driver: {!trip["driver"] ? "Not Assigned" : `${trip.driver.user.first_name} ${trip.driver.user.last_name}`}
                </p>
                <p className="mt-2 mb-1">Phone: {!trip["driver"] ? "Not Assigned" : `${trip.driver.user.phone}`}</p>
                <p className="mt-2 mb-1">
                  Vehicle Size: {!trip["category_size"] || typeof (trip["category_size"]) !== 'object' ? "Not Assigned"
                    : `${trip.category_size['tonnage']} tonnes`}
                </p>
                <p className="mt-2 mb-1">Reference: {trip.reference}</p>
                {(domState.type === DomStateType.BUSINESS || domState.type === DomStateType.CUSTOMER) &&
                  <Fragment>
                    {(trip["confirmation"] && (trip["confirmation"]['arrival'] || trip["confirmation"]['delivery'])) &&
                      <p className="mt-2 mb-1">Confirmation Code:  <span className="fw-bold">
                        {trip["confirmation"]['delivery'] ? trip["confirmation"]['delivery']
                          : trip["confirmation"]['arrival'] ? trip["confirmation"]['arrival'] : '*****'}
                      </span>
                      </p>
                    }
                  </Fragment>
                }
              </div>
              {typeof trip.user === 'object' &&
                <div className="d-flex flex-column main-card p-3 pb-4 mt-2">
                  <div className="main-card-icon d-flex justify-content-center cost-bg">
                    <img src={ProfileSvg} alt="" />
                  </div>
                  <p className="mt-2 mb-1">
                    Customer: {trip.user && Object.entries(trip.user).length > 0 ? `${toTitleCase(trip.user["first_name"])} ${toTitleCase(trip.user["last_name"])}` : ''}
                  </p>
                  <p className="mt-2 mb-1">
                    Phone: {trip.user && Object.entries(trip.user).length > 0 ? `${trip.user.phone}` : "Not Assigned"}
                  </p>
                </div>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="main-table py-4 px-md-2 px-0 d-flex flex-column mt-4">
        <div className="container-fluid order-md-2 order-1">
          <div className="row">
            <div className="col-sm-6 py-2">
              <div className="d-flex flex-column main-card p-3 pb-4 h-100">
                <div className="main-card-icon d-flex justify-content-center cost-bg"><img src={TransitSvg} alt="" />
                </div>
                <p className="mt-2 mb-1">Pickup address: {trip['source']['address']}</p>
                <p className="mt-2 mb-1">Dropoff address: {trip['destination']['address']}</p>
                <p className="mt-2 mb-1">Distance: {trip['distance']} kms</p>
                <p className="mt-2 mb-1">Trip type: {trip['trip_type']}</p>
                <p className="mt-2 mb-1">
                  Vehicle category: {!trip["category_size"] || typeof (trip["category_size"]) !== 'object' ?
                    "Not Assigned" : `${trip.category_size['tonnage']} tonnes (${trip.category_size['category']})`}
                </p>
                {domState.type === DomStateType.CUSTOMER &&
                  <p className="mt-2 mb-1">
                    Estimated Worth of Item: {(trip?.quote && trip.quote["estimated_value"]) ? `${formatAmountToString(trip.quote["estimated_value"])}` : trip.estimated_value ? formatAmountToString(trip["estimated_value"]) : '-'} {trip.quote.currency}
                  </p>
                }
                {(domState.type === DomStateType.CUSTOMER && (Object.keys(trip.extras).length > 0) && Object.keys(trip.extras).map((key, index) =>
                  <p key={index} className="mt-2 mb-1">
                    Number of {toTitleCase(key)}: {trip.extras[key] ? trip.extras[key] : 0}
                  </p>
                ))}
              </div>
            </div>
            <div className="col-sm-6 py-2">
              <div className="d-flex flex-column main-card p-3 pb-4 h-100">
                <div className="main-card-icon d-flex justify-content-center cost-bg"><img src={CostSvg} alt="" /></div>
                {domState.type === DomStateType.DRIVER ?
                  <p className="mt-2 mb-1">
                    Base Fare:
                    {(trip && trip['vehicle_payment'] && trip['vehicle_payment']["vehicle_payment"]) ? formatAmountToString(trip['vehicle_payment']["vehicle_payment"]) : 0.00}
                    {(trip && trip['vehicle_payment'] && trip['vehicle_payment']['currency']) ? trip['vehicle_payment']['currency'] : 0.00}

                  </p> : domState.type === DomStateType.BUSINESS ?
                    <p className="mt-2 mb-1">
                      Base Fare: {formatAmountToString(trip.quote["charge"])} {trip.quote.currency}
                    </p> :
                    <p className="mt-2 mb-1">
                      Base Fare: {formatAmountToString(trip.quote["base_fare"])} {trip.quote.currency}
                    </p>
                }
                {domState.type === DomStateType.CUSTOMER &&
                  <p className="mt-2 mb-1">
                    Platform Fare: {formatAmountToString(trip.quote["platform_fee"])} {trip.quote.currency}
                  </p>
                }
                {domState.type === DomStateType.CUSTOMER &&
                  <p className="mt-2 mb-1">
                    Cost Per Kilometre: {formatAmountToString(trip.quote["cost_per_kilometer"])} {trip.quote.currency}
                  </p>
                }
                {domState.type === DomStateType.CUSTOMER &&
                  <p className="mt-2 mb-1">
                    Estimated Insurance: {formatAmountToString(trip.quote["estimated_insurance"])} {trip.quote.currency}
                  </p>
                }
                {(domState.type === DomStateType.CUSTOMER && (Object.keys(trip.extras).length > 0) && Object.keys(trip.extras).map((key, index) =>
                  <Fragment key={index}>
                    {key in trip.quote &&
                      <p className="mt-2 mb-1">
                        {toTitleCase(key)} Charge: {trip.quote[key] ? formatAmountToString(trip.quote[key]) : 0} {trip.quote.currency}
                      </p>
                    }
                  </Fragment>
                ))}
                {(domState.type === DomStateType.CUSTOMER || domState.type === DomStateType.BUSINESS) &&
                  <p className="mt-2 mb-1">
                    VAT: {(trip && trip.quote && trip.quote["vat_value"]) ? formatAmountToString(trip.quote["vat_value"]) : "0.00"} {trip.quote.currency}
                  </p>
                }
                {domState.type === DomStateType.CUSTOMER && trip.quote['discount_amount'] &&
                  <p className="mt-2 mb-1">
                    Discount: {formatAmountToString(trip.quote["discount_amount"])} {trip.quote.currency}
                  </p>
                }
                {domState.type === DomStateType.DRIVER ?
                  <p className="mt-2 mb-1 fw-semibold">
                    Total: {(trip && trip['vehicle_payment'] && trip['vehicle_payment']["vehicle_payment"]) ? formatAmountToString(trip['vehicle_payment']["vehicle_payment"]) : "0.00"}
                    {" "}{(trip && trip['vehicle_payment'] && trip['vehicle_payment']["currency"]) ? trip['vehicle_payment']['currency'] : ""}
                  </p> : domState.type === DomStateType.BUSINESS ?
                    <p className="mt-2 mb-1 fw-semibold">
                      Total: {formatAmountToString(trip.quote["charge"])} {trip.quote.currency}
                    </p> :
                    <p className="mt-2 mb-1 fw-semibold">
                      Total: {formatAmountToString(trip.quote["charge"])} {trip.quote.currency}
                    </p>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      <TripReconciliation trip={trip} picklist={picklist} isPickUpReconciled={isPickUpReconciled} domState={domState}
                          handleSuccessResponse={() => handleSuccessResponse()} loadingImageUpload={loadingImageUpload} setLoadingImageUpload={setLoadingImageUpload} imageUploadRender={imageUploadRender} setImageUploadRender={setImageUploadRender} stopNumber={stopNumber} setStopNumber={setStopNumber}/>
      <TripDocuments trip={trip} />
    </div>
  );
}

export default Body;
