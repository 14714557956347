import {useState} from "react";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import MapsSelectHook from "../maps/MapsSelectHook";
import {Loading} from "../../utils/helpers/constants";
import ConfigsService from "../../utils/services/configs.service";

const EditWarehouseModal = ({onchange, warehouse}) => {
	const navigate = useNavigate();
	const [show, setShow] = useState(true);
	const [name, setName] = useState(warehouse.name ?? "");
	const [loading, setLoading] = useState(false);
	const [warehouseLocation, setWarehouseLocation] = useState(warehouse.location ?? {});
	const isValidated = !name || Object.entries(warehouseLocation).length === 0;

	const handleClose = () => {
		setShow(false);
		onchange();
	};

	const mapsError = (error) => console.error(error);

	const handleWarehouseLocation = data => {
		const location = {
			state: data.state,
			address: data.address,
			latitude: data.latitude,
			longitude: data.longitude
		}
		setWarehouseLocation(location)
	}

	const editWarehouse = async() => {
		setLoading(true);
		const {data: responseData} = await ConfigsService.updateWarehouse(warehouse.reference, {name, location: warehouseLocation});
		if(responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
		else {
			const {data: response} = await ConfigsService.getWarehouse(warehouse.reference);
			if(response.status !== Loading.SUCCESS) navigate('/profile/warehouse');
			else navigate(`/profile/warehouse/${response.data['reference']}`, {state: response.data})
			toast.success(responseData.message);
			handleClose();
		}
		setLoading(false);
	}

	return (
		<>
			<Modal show={show} backdrop="static" keyboard={false} centered>
				<Modal.Body>
					<span id="close-modal-btn1" onClick={handleClose} className="close-modal mt-2">&times;</span>
					<div className="proceed">
						<div className="mt-4">
							<div className="position-relative w-100 mg-t-15">
								<label>Warehouse Name</label>
								<input type="text" value={name} className="form-control" onChange={e => setName(e.target.value)}/>
							</div>
							<div className="position-relative w-100 mg-t-25-f">
								<MapsSelectHook label="Warehouse Address" placeholder="Type to search for warehouse location"
																setLocation={handleWarehouseLocation} setError={mapsError}/>
							</div>
						</div>
						<div className="d-flex justify-content-end mt-5">
							<button onClick={editWarehouse} disabled={isValidated} className="btn main-btn">
								Update Warehouse
								{loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
							</button>
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default EditWarehouseModal;
