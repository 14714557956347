import { useRef, useState } from "react";
import toast from "react-hot-toast";
import Button from "react-bootstrap/Button";
import  Modal  from "react-bootstrap/Modal";
import  Cropper  from "react-cropper";

import { fileToBase64 } from "../../utils/helpers/logicHelper";
import { imageUploadPromptMap } from "../../utils/helpers/mappers";


const ImageUploadModal = ({ onchange, type, props, name, message, onSuccessfulUpload}) => {
	const actionProps = imageUploadPromptMap[type];

	const hiddenFileInput = useRef()
	const [show, setShow] = useState(true);
	const [image, setImage] = useState("");
	const [cropper, setCropper] = useState("");
	const [fileName, setFileName] = useState("");
	const [mimeType, setMimeType] = useState("");
	const [loading, setLoading] = useState(false);

	const isInvalid = !image || !mimeType

	const handleClose = () => {
		setShow(false);
		onchange();
	};

	const completeAction = response => {
		handleClose();
		onSuccessfulUpload(response);
	}

	const onChange = async event => {
		event.preventDefault();
		setFileName(event.target.files[0].name);
		const base64Data = await fileToBase64(event);
		const updateMimeType = base64Data.split(",")[0].split(":")[1].split(";")[0];

		if (updateMimeType === 'application/pdf') return completeAction(base64Data.split(',')[1]);
		setImage(base64Data);
		setMimeType(updateMimeType);
	};

	const getCroppedData = () => {
		setLoading(true);
		if(typeof cropper === "undefined") toast.error(`Error occurred while trying to crop image`);
		else {
			const base64File = cropper.getCroppedCanvas().toDataURL().split(',')[1];
			completeAction(base64File)
		}
		setLoading(false);
	};

	return (
		<>
			<Modal show={show} backdrop="static" size="lg" keyboard={false} centered>
				<div className="modal-header">
					<h4 className="modal-title">{actionProps.title}</h4>
					<button aria-label="Close" onClick={handleClose} className="btn-close" data-bs-dismiss="modal"/>
				</div>

				<Modal.Body>
					<div className="row row-sm">

						{message  && 
						<div className="text-center mb-3">
								{message} {name && <span className="fw-bold">{name}</span>}.
						</div>
						}
						<div className="text-center text-warning mb-3">{actionProps.message}</div>

						<div className="col-md-12 col-lg-10 offset-lg-1 col-xl-8 col-xl-2 col-xxl-6 offset-xxl-3">
							<input type="file" accept="image/*,application/pdf" ref={hiddenFileInput} onChange={event => onChange(event)}
										 style={{display: 'none'}}/>
							<div className="d-flex flex-row">
								<input type="text" className="form-control border-end-0 py-2" value={fileName}
											 placeholder={`choose an to image...`} readOnly/>
								<label>
									<span className="btn btn-primary py-2 px-3" onClick={() => hiddenFileInput.current.click()}>Browse</span>
								</label>
							</div>
						</div>
					</div>
					{(image && (mimeType && mimeType !== 'application/pdf') ) &&
						<div className="row">
							<div className="col-md-12 col-lg-6">
								<div className="box" style={{width: "100%"}}>
									<Cropper
										style={{height: 400, width: "100%"}}
										initialAspectRatio={props.aspectRatio}
										aspectRatio={props.aspectRatio}
										src={image}
										alt={type}
										preview=".img-preview"
										viewMode={1}
										autoCropArea={1}
										minCropBoxHeight={10}
										minCropBoxWidth={10}
										guides={true}
										responsive={true}
										background={false}
										checkOrientation={false}
										onInitialized={instance => setCropper(instance)}
									/>
								</div>
							</div>

							<div className="col-md-12 col-lg-6">
								<div className="box" style={{width: "100%", height: "400px"}}>
									<h5>Preview</h5>
									<div className="img-preview" style={{width: "100%", height: "100%"}}/>
								</div>
							</div>
						</div>
					}
				</Modal.Body>
				<Modal.Footer>
					<div className="d-flex flex-row">
						<Button className="ms-lg-4" variant="danger" onClick={handleClose}>Close</Button>&nbsp;
						<Button className="mr-2" variant="primary" onClick={getCroppedData} disabled={isInvalid}>
							{actionProps.button} {loading && <>&nbsp;<i className="fa fa-spin fa-spinner"/></>}
						</Button>
					</div>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default ImageUploadModal;