import { useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toTitleCase } from "../../../utils/helpers/logicHelper";

const FILE = ({ divClass, inputClass, formControl, formHook }) => {
  const [validatorSchema, setValidatorSchema] = useState({});
  const errors = formHook.formState.errors || formHook.formState.errors[formControl["key"]];

  useMemo(() => {
    for (const [key, value] of Object.entries(formControl)) {
      switch (key) {
        case 'required':
          if (value) setValidatorSchema(prevState => ({ ...prevState, required: `${toTitleCase(formControl["label"])} is required.` }));
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <div className={divClass}>
      <label htmlFor={formControl["key"]}>
        {toTitleCase(formControl["label"])} {formControl["required"] && <span className="text-danger">*</span>}
      </label>
      <Controller
        name={formControl["key"]}
        control={formHook.control}
        isInvalid={errors}
        rules={{ ...validatorSchema }}
        render={({ field }) => (
          <>
            <div className={inputClass}>
              <input type="text" 
                className={`form-control border-end-0 mb-0 `}
                readOnly
                placeholder={formControl.placeholder}
                value={field?.value?.name} />

              <label className="input-group-btn mb-0">
                <span className="btn btn-primary">Browse
                  <input type="file" style={{ display: "none" }}
                    inputRef={field.ref}
                    onBlur={field.onBlur}
                    onChange={(event) => {
                      field.onChange(event.target.files[0]);
                    }}
                  />
                </span>
              </label>
            </div>
            <ErrorMessage
              errors={errors}
              name={formControl["key"]}
              render={(error) =>
                error &&
                Object.entries(error).map(([type, message]) => (
                  <small key={type} className="text-danger mt-1">{message}</small>
                ))}
            />
          </>
        )} />
    </div>
  );
}
export default FILE;