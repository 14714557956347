import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../components/loaders/SphereLoader";
import PageLayout from "../../../components/layouts/PageLayout";
import {getOverview} from "../../../store/actions/profile.action";
import {getDriverTripRequests, getTrips} from "../../../store/actions/booking.action";
import {DomStateType, Loading, menuType} from "../../../utils/helpers/constants";
import {getUserWallet} from "../../../store/actions/wallet.action";

const Home = () => {

  const dispatch = useDispatch();
  const {booking, profile, wallet} = useSelector(({bookingReducer, profileReducer, walletReducer}) => ({
    booking: bookingReducer,
    profile: profileReducer,
    wallet: walletReducer
  }));

  let driverLoader = !!(profile.domState && profile.domState.type === DomStateType.DRIVER && booking.loadingRequests !== Loading.SUCCESS);
  let loadState = (driverLoader && booking.loading !== Loading.SUCCESS) || profile.loadingOverview !== Loading.SUCCESS || wallet.loading !== Loading.SUCCESS;

  useEffect(() => {
    if (profile.loadingOverview !== Loading.SUCCESS) dispatch(getOverview());
    if (wallet.loading !== Loading.SUCCESS) dispatch(getUserWallet());
  }, []);


  useEffect(() => {
    let entity = profile.domState && profile.domState.type ? profile.domState.type : DomStateType.CUSTOMER;
    if (profile.domState && profile.domState.type === DomStateType.DRIVER) dispatch(getDriverTripRequests());
    else if (profile.domState && booking.loading !== Loading.SUCCESS) dispatch(getTrips("", entity));
  }, [profile.domState]);

  return (
    <PageLayout active={menuType.HOME}>
      {loadState ? <SphereLoader/> :
        <Body domState={profile.domState} insights={profile.overview} trips={booking.trips}
              tripRequests={booking.tripRequests} wallet={wallet.wallet}/>
      }
    </PageLayout>
  );
}

export default Home;
