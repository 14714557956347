import { ImageOptions } from "./constants";

export const DynamicFileUploadMap = {
  DRIVER: {
    id: 1,
    title: "Upload Driver Document",
    successMessage: "Driver Document uploaded successfully",
    button: "Upload Driver Document"
  },
  VEHICLE: {
    id: 2,
    title: "Upload Vehicle Document",
    successMessage: "Vehicle Document uploaded successfully",
    button: "Upload Vehicle Document"
  },
};

export const WalletStatusMap = {
  "PROCESSED": {
    id: 0,
    label: "PROCESSED",
    fill: "text-info",
  },
  "AWAITING_VERIFICATION": {
    id: 1,
    label: "AWAITING VERIFICATION",
    fill: "text-warning",
  },
  "SUCCESS": {
    id: 2,
    label: "SUCCESS",
    fill: "text-success",
  },
  "COMPLETED": {
    id: 3,
    label: "SUCCESS",
    fill: "text-success",
  },
  "FAILED": {
    id: 4,
    label: "FAILED",
    fill: "text-danger",
  },
  "REFUNDED": {
    id: 5,
    label: "REFUNDED",
    fill: "text-warning",
  },
};

export const BookBusinessManifestFlowMap = {
  1: {
    id: 1,
    title: '1. Upload a trip booking manifest',
    progress: 'bar-2',
    next_button: 'Next'
  },
  2: {
    id: 2,
    title: '2. Include a pickup location?',
    progress: 'bar-5',
    next_button: 'Next',
    prev_button: 'Previous'
  },
  3: {
    id: 3,
    title: '3. When are you expected to move?',
    progress: 'bar-7',
    next_button: 'Book Trip',
    prev_button: 'Previous'
  }
}

export const BookBusinessTripFlowMap = {
  1: {
    id: 1,
    title: '1. Pickup Location',
    progress: 'bar-1',
    next_button: 'Next'
  },
  2: {
    id: 2,
    title: '2. Drop-off Location',
    progress: 'bar-3',
    next_button: 'Next',
    prev_button: 'Previous'
  },
  3: {
    id: 3,
    title: '3. When are you expected to move?',
    progress: 'bar-5',
    next_button: 'Next',
    prev_button: 'Previous'
  },
  4: {
    id: 4,
    title: '4. Choose Vehicle Category',
    progress: 'bar-6',
    next_button: 'Next',
    prev_button: 'Previous',
  },
  5: {
    id: 5,
    title: '5. Additional services (Number of Stops, Waybill Number)',
    progress: 'bar-7',
    next_button: 'Book Trip',
    prev_button: 'Previous'
  },
}

export const BookTripFlowMap = {
  1: {
    id: 1,
    title: '1. Select your trip type',
    progress: 'bar-1',
    next_button: 'Next'
  },
  2: {
    id: 2,
    title: '2. Where are you picking up?',
    progress: 'bar-2',
    next_button: 'Next',
    prev_button: 'Previous'
  },
  3: {
    id: 3,
    title: '3. Where are you moving to?',
    progress: 'bar-3',
    next_button: 'Next',
    prev_button: 'Previous'
  },
  4: {
    id: 4,
    title: '4. What is the estimated worth of your goods?',
    progress: 'bar-4',
    next_button: 'Next',
    prev_button: 'Previous'
  },
  5: {
    id: 5,
    title: '5. What day do you want to move?',
    progress: 'bar-5',
    next_button: 'Next',
    prev_button: 'Previous'
  },
  6: {
    id: 6,
    title: '6. Select a vehicle category or vehicle size',
    progress: 'bar-6',
    next_button: 'Next',
    prev_button: 'Previous'
  },
  7: {
    id: 7,
    title: '7. Enter your coupon code (If Applicable)',
    progress: 'bar-7',
    next_button: 'Next',
    prev_button: 'Previous'
  },
  8: {
    id: 8,
    title: '8. Select additional services needed (Loaders, Packaging materials)',
    progress: 'bar-8',
    next_button: 'Book Trip',
    prev_button: 'Previous'
  },
}


export const TripStatusMap = {
  "INITIATED": {
    id: 0,
    label: "Initiated",
    fill: "text-primary",
    nextButton: "Order Process Ongoing"
  },
  "AWAITING-PAYMENT": {
    id: 1,
    label: "Awaiting Payment",
    fill: "text-warning",
    nextButton: "Order Process Ongoing"
  },
  "ORDER-CONFIRMED": {
    id: 2,
    label: "Order Confirmed",
    fill: "text-secondary",
    nextButton: "Accept Trip"
  },
  "PAYMENT-CONFIRMED": {
    id: 3,
    label: "Payment Confirmed",
    fill: "text-warning",
    nextButton: "Accept Trip"
  },
  "ASSIGNED-TO-DRIVER": {
    id: 4,
    label: "Assigned to Driver",
    fill: "text-info",
    nextButton: "I have Arrived"
  },
  "DRIVER-AWAITING-CONFIRMATION": {
    id: 5,
    label: "Driver Awaiting Confirmation",
    fill: "text-info",
    nextButton: "Confirm Arrival"
  },
  "DRIVER-ARRIVED": {
    id: 6,
    label: "Driver Arrived",
    fill: "text-primary",
    nextButton: "Start Trip"
  },
  "LOADING": {
    id: 7,
    label: "Loading",
    fill: "text-info",
    nextButton: "Start Trip"
  },
  "TRIP-STARTED": {
    id: 8,
    label: "Trip Started",
    fill: "text-info",
    nextButton: "End Trip"
  },
  "AWAITING-DELIVERY-CONFIRMATION": {
    id: 9,
    label: "Awaiting Delivery Confirmation",
    fill: "text-warning",
    nextButton: "End Trip"
  },
  "TRIP-STARTED-WITH-STOPS": {
    id: 10,
    label: "Trip Started",
    fill: "text-primary",
    nextButton: "Confirm Drop Off"
  },
  "DROP-OFF": {
    id: 11,
    label: "Drop Off",
    fill: "text-info",
    nextButton: "Confirm Drop Off"
  },
  "COMPLETED": {
    id: 12,
    label: "Completed",
    fill: "text-success",
    nextButton: "Reconcile Trip"
  },
  "RECONCILED": {
    id: 13,
    label: "Reconciled Trip",
    fill: "text-success",
    nextButton: "Reconciled"
  },
  "OTHER": {
    id: 14,
    label: "Invalid Trip",
    fill: "text-danger",
    nextButton: "Cancel Trip"
  },
  CANCELLED: {
    id: 15,
    label: "Trip Cancelled",
    fill: "text-danger",
    nextButton: "Cancelled",
  },
};

export const ActionPromptMap = {
  DELETE_WAREHOUSE: {
    id: 1,
    title: "Delete a Warehouse from your Business",
    message: "You are about to remove a selected warehouse/branch from the your business",
    placeholder: "Type in your password to validate this action",
    successMessage: "Warehouse deleted successfully",
    button: "Remove Warehouse"
  },
  DELETE_VEHICLE: {
    id: 2,
    title: "Delete your vehicle info",
    message: "You are about to delete a vehicle information and resources from your account",
    placeholder: "Type in your password to validate this action",
    successMessage: "Vehicle deleted successfully",
    button: "Remove Vehicle"
  },
  TRANSFER_BUSINESS: {
    id: 3,
    title: "Transfer your business(s) asset",
    message: "You are about to transfer all business information and resources from your account",
    placeholder: "Type in your password to validate this action",
    successMessage: "Business(s) transferred successfully",
    button: "Transfer Business(s)"
  },
  DELETE_BUSINESS: {
    id: 4,
    title: "Delete your business asset",
    message: "You are about to delete a business information and resources from your account",
    placeholder: "Type in your password to validate this action",
    successMessage: "Business Deleted successfully",
    button: "Remove Business"
  },
  DELETE_DRIVER: {
    id: 5,
    title: "Delete your driver asset",
    message: "You are about to delete your driver information and resources from your account",
    placeholder: "Type in your password to validate this action",
    successMessage: "Driver Deleted successfully",
    button: "Delete Driver Asset"
  },
  TRANSFER_VEHICLE: {
    id: 6,
    title: "Transfer your fleet asset",
    message: "You are about to transfer fleet information and resources from your account",
    placeholder: "Type in your password to validate this action",
    successMessage: "Fleet transferred successfully",
    button: "Transfer Fleet Asset"
  },
  CANCEL_TRIP: {
    id: 7,
    title: "Cancel a trip information",
    message: "You are about to cancel a trip from the platform",
    placeholder: "Type in your password to validate this action",
    successMessage: "Trip has been cancelled successfully",
    button: "Cancel Trip"
  },
};

export const imageUploadPromptMap = {
	[ImageOptions.PROFILE]: {
		id: 1,
		title: "Upload your Profile picture",
		message: "Accepted file type .png, .jpng, .gif. Less than 2MB",
		button: "Upload Profile Picture"
	},
	[ImageOptions.WAYBILL]: {
		id: 2,
		title: "Upload Waybill",
		message: "Accepted file type .png, .jpng, .gif., .pdf Less than 2MB",
		button: "Upload Waybill"
	},
	[ImageOptions.PROOF_OF_DELIVERY]: {
		id: 3,
		title: "Upload Proof of Delivery",
		message: "Accepted file type .png, .jpng, .gif., .pdf Less than 2MB",
		button: "Upload Proof Of Delivery"
	},
}
