import Meta from "./header/Meta";


const AuthLayout = ({favicon, logo, children}) => {
	return (
		<div className="page main-signin-wrapper">
			<Meta logo={favicon}/>
			<div className="row text-center">
				<div className="col-md-12 d-flex flex-column align-items-center">
          {logo && <div className="w-50 mb-3">
            <img src={logo} alt="Logo" className="img-fluid" width="200" height='100'/>
          </div>}
          <div className="card card-width">
						<div className="row row-sm">
							<div className="col wd-md-95p-f wd-lg-75p-f wd-xl-50p-f px-4">
								<div className="container-fluid">
									<div className="row row-sm">
										<div className="card-body mt-2">{children}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default AuthLayout;
