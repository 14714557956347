import {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import {read, utils} from 'xlsx';
import moment from "moment";

import MapsSelectHook from "../../../../components/maps/MapsSelectHook";
import DatePickerHook from "../../../../components/hooks/DatePickerHook";
import {getTrips} from "../../../../store/actions/booking.action";
import BookingService from "../../../../utils/services/booking.service";
import {Loading} from "../../../../utils/helpers/constants";
import {BookBusinessManifestFlowMap} from "../../../../utils/helpers/mappers";


const BookBusinessTrip = ({domState, wallet}) => {

  const dispatch = useDispatch(), navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [date, setDate] = useState("");
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState([]);
  const [sourceObject, setSourceObject] = useState({});
  const [confirmationData, setConfirmationData] = useState({});
  const [formattedManifest, setFormattedManifest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmationState, setConfirmationState] = useState(false);

  const tripContext = BookBusinessManifestFlowMap[step];
  const description = "The uploaded document may provide a pickup location. If the pickup location is not provided, a " +
    "new pickup location can be filtered, or choose from the provided branches from the list of locations.."


  const setLocation = (placeData) => {
    if (step === 2) setSourceObject(placeData);
  }

  const isFormValid = () => {
    if (step === 1 && fileData && fileData.length > 0) return true;
    else if (step === 2 && Object.entries(sourceObject).length > 0) return true;
    else if (step === 3 && date) return true;
    return false;
  }

  const canPrevious = () => step !== 1;

  const decreaseStep = () => {
    if (canPrevious()) setStep(step - 1);
  }

  const mapsError = (error) => console.error(error);

  const onChangeFile = async event => {
    const allowedExtensions = ["xls", "XLS", "xlsx", "XLSX"];
    const sizeLimit = 2097152;
    const selectedFileSize = event.target.files[0].size || "";
    const selectedFileName = event.target.files[0].name || null;
    const fileExtension = selectedFileName.split(".").pop();
    if (typeof selectedFileSize === 'undefined') return false
    else if (event.target.files[0] && !allowedExtensions.includes(fileExtension)) toast.error("File must be an excel document");
    else if (selectedFileSize > sizeLimit) toast.error("File size too large");
    else {
      processSheet(event.target.files[0]);
      setFileName(event.target.files[0]['name']);
    }
  }

  const processSheet = file => {
    const reader = new FileReader();
    reader.onload = event => {
      const wb = read(event.target.result);
      const sheets = wb.SheetNames;
      if (sheets.length) setFileData(utils.sheet_to_json(wb.Sheets[sheets[0]]))
    }
    reader.readAsArrayBuffer(file);
  }

  const processFileData = () => {
    let processedManifest = [];
    for (let order of fileData) {
      let alternative_contact = {name: order['Agent Name'], phone: order['Agent Phone']};
      let customer_address = {city: order['City'], address: order['Customer Address'], state: order['State']}
      let product = {name: order['Product'], quantity: order['Ordered Quantity'], price: order['Price']}
      let data = {
        source: sourceObject, product, alternative_contact,
        customer_store_name: order['Customer Name'], customer_phone: order['Customer Phone'],
        customer_address, created_by: {}, business: domState.business.reference,
      }
      processedManifest.push(data);
    }
    return processedManifest;
  }

  const bookHandler = async () => {
    if (step === 2) {
      let formattedManifest = processFileData();
      setFormattedManifest(formattedManifest);
      setStep(step + 1);
    } else if (step === 1) setStep(step + 1);
    else if (step === 3) {
      setLoading(true);
      let payload = {
        business: domState.business.id, scheduled_date: moment(date).format(), processed_manifest: formattedManifest
      };
      const {data: responseData} = await BookingService.bookIMSTrip(payload);
      if (responseData.status !== Loading.SUCCESS) {
        const message = (responseData && responseData.message) ? responseData.message : "You cannot book this trip at the moment, please try again later";
        toast.error(message);
      }
      else {
        navigate('/trips');
        toast.success("Trip booked successfully");
        setConfirmationState(true);
        setConfirmationData({count: responseData.data['trip_count'], created_by: responseData.data['created_by']});
      }
      setLoading(false);
    }
  }

  const makePayment = async () => {
    setLoading(true);
    const {data: responseData} = await BookingService.walletBusinessCheckout(confirmationData.id);
    if (responseData.status !== Loading.SUCCESS) {
      const message = (responseData && responseData.message) ? responseData.message : "You cannot pay for this trip at the moment, please try again later";
      toast.error(message);
    }
    else {
      await dispatch(getTrips("", domState.type));
      toast.success("Trip booked successfully");
      navigate('/trips')
    }
    setLoading(false);
  }


  return (
    <div className="py-md-5 pt-5 pb-2 ms-5">
      {confirmationState ?
        <div className="booking-main d-flex flex-column">
          <h3 className="booking-title">Manifest Booking Confirmation</h3>
          <div className="info-group">
            <div className="mg-y-15-f">
              <span className="mg-r-3">Number of orders:</span>
              <span>{confirmationData['count']}</span>
            </div>
            <div className="mg-y-15-f">
              <span className="mg-r-3">Scheduled Date:</span>
              <span>{moment(confirmationData.scheduled_date).format('lll')}</span>
            </div>
          </div>
          <button className="btn main-btn w-50 mt-4" onClick={makePayment} type="button">
            Pay with Wallet
            {loading && <i className="zmdi zmdi-spinner ms-2 zmdi-hc-spin"/>}
          </button>
        </div> :
        <div className="booking-main d-flex flex-column">
          <h3 className="booking-title">Book a trip</h3>
          <form className="booking-form d-flex mt-4 flex-column align-items-start">
            <label>{tripContext.title}</label>
            <div className="position-relative browse-file w-100 mg-t-15">
              {step === 1 &&
                <input id={fileName} name={fileName} value={fileName} type="text" placeholder="Clik here to select a file.."
                       readOnly/>}
              {step === 1 &&
                <input type="file" onChange={event => onChangeFile(event)} readOnly/>}
              {step === 2 &&
                <MapsSelectHook setLocation={setLocation} placeholder="Type to search for pick-up location"
                                setError={mapsError}/>
              }
              {step === 3 && <DatePickerHook placeholder="Select scheduled date" getDate={setDate} showYearDropdown
                                             timeIntervals={30} dateFormat="MMMM d, yyyy h:mm aa" filterTime={true}
                                             selectDropdownMode withPortal showTimeSelect setMinDate={new Date()}/>
              }
              {step === 2 &&
                <div className="description-box mt-5 text-muted fw-semibold">Description: {description ?? ""}</div>}
            </div>
            <div className="booking-btn-group wd-md-85p-f wd-lg-70p-f">
              {step !== 1 ? <button className="btn bg-danger text-white mt-4 me-4" onClick={decreaseStep} type="button">
                {tripContext.prev_button}
              </button> : ''}
              <button className="btn main-btn mt-4" disabled={!isFormValid()} onClick={bookHandler} type="button">
                {tripContext.next_button}
                {loading ? <i className="zmdi zmdi-spinner mg-l-5 zmdi-hc-spin"/> : ""}
              </button>
            </div>
          </form>
          <div className="mt-auto">
            <div className="d-flex justify-content-between align-items-center">
              <div className="booking-step flex-grow-1">
                <p className="mb-1">{step} of 3</p>
                <div className="booking-progress">
                  <div className={`booking-progress-bar ${tripContext.progress}`}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </div>
  );
}

export default BookBusinessTrip;
