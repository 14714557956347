import { useState } from "react";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Loading } from "../../utils/helpers/constants";
import BookingService from "../../utils/services/booking.service";

const DriverConfirmationCodeModal = ({ onchange, trip, onSuccessResponse }) => {
  const [show, setShow] = useState(true);
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  let isBusiness = (trip['business_tripset'] && trip['business_tripset']["business"]) ? trip['business_tripset']["business"]
    : (trip['interoperability_details'] && trip['interoperability_details']['is_business']) ? trip['interoperability_details']['is_business'] : null;

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const handleConfirmation = async () => {
    let responseData;
    setLoading(true);
    if (isBusiness) responseData = await BookingService.confirmB2BArrival(trip.id, { code });
    else responseData = await BookingService.confirmB2CDelivery(trip.id, { code });
    
    if (responseData.data.status !== Loading.SUCCESS) {
      const message = (responseData && responseData.data && responseData.data.message) ? responseData.data.message : "You cannot confirm this code at the moment. Please try again later.";
      toast.error(message);
    }
    else {
      toast.success(responseData.data.message);
      onSuccessResponse('SUCCESSFUL')
      handleClose();
    }
    setLoading(false);
  }

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <span id="close-modal-btn1" onClick={handleClose} className="close-modal mt-2">&times;</span>
          <div className="proceed">
            <div className="mt-5">
              <div className="position-relative w-100 mg-t-15">
                <label>Provide Confirmation Code</label>
                <input type="text" className="form-control" value={code} onChange={e => setCode(e.target.value)} />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <button onClick={handleConfirmation} disabled={!code || code.length < 5} className="btn main-btn">
                Confirm
                {loading && <>&nbsp;<i className="zmdi zmdi-spinner mg-l-1 ms-2 zmdi-hc-spin" /></>}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DriverConfirmationCodeModal;