import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";

import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import DynamicFields from "../dynamicFields/DynamicFields";
import FleetService from "../../utils/services/fleet.service";
import {InputType, Loading} from "../../utils/helpers/constants";

const EditVehicleModal = ({onchange, props, dynamicFields}) => {
  const navigate = useNavigate();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const useFormHook = useForm({
    defaultValues: (props && props['dynamic_fields']) ? props['dynamic_fields'] : {},
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
  });

  const handleUpdateVehicle = async () => {
    setLoading(true);
    const payload = { dynamic_fields: { ...props['dynamic_fields'], ...useFormHook.getValues()}};
    const {data: responseData} = await FleetService.updateVehicle(props.id, payload);
    if (responseData.status !== Loading.SUCCESS) {
      const message = (responseData && responseData.message) ? responseData.message : "You cannot update this vehicle information at the moment. Please try again later.";
      toast.error(message);
    }
    else {
      const {data: response} = await FleetService.getVehicle(props.id);
      if (response.status !== Loading.SUCCESS) navigate('/profile/registered-vehicle');
      else navigate(`/profile/registered-vehicle/${response.data['dynamic_fields']['registration_number']}`, {state: response.data});
      toast.success(responseData.message);
      handleClose();
    }
    setLoading(false);
  }

  const renderDynamicFields = dynamicFields.map((eachField, index) => {
    return (
      <Fragment key={index}>
        {(eachField.type !== InputType.FILE && !eachField['hidden_field'])&&
          <DynamicFields divClass="col-lg-6 form-group mb-4" inputClass="form-control"
          formControl={eachField} formHook={useFormHook} initialValue={props['dynamic_fields']} />
        }
      </Fragment>
    )
  });

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} size="lg" centered scrollable>
        <Modal.Body>
          <span id="close-modal-btn1" onClick={handleClose} className="close-modal mt-2 fs-1 text-danger">&times;
          </span>
          <div className="proceed">
            <div className="mt-2 mb-5">
              <h5>Edit Vehicle Information</h5>
            </div>
            <div className="mt-4">
              <div className="row">{renderDynamicFields}</div>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <button onClick={useFormHook.handleSubmit(handleUpdateVehicle)} className="btn main-btn w-auto">
                Update Vehicle Info
                {loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default EditVehicleModal;