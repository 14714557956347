import {Fragment, useEffect, useState} from "react";
import OpacityLoader from "../loaders/OpacityLoader";

const Pagination = ({data, filterData, size, index, count, loading, setPage,setPageSize, setGlobalFilter}) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const [canPreviousPage, setCanPreviousPage] = useState(false);
  const [canPageOne, setCanPageOne] = useState(false);
  const [canNextPage, setCanNextPage] = useState(true);
  const [renderStartPoint, setRenderStartPoint] = useState(0);
  const [renderBreakPoint, setRenderBreakPoint] = useState(11);
  let renderPageCountButton = [];

  useEffect(() => {
    if(setGlobalFilter) setGlobalFilter(filterData);
    if(setPageSize) setPageSize(size);
    if (Math.ceil(count / size) !== currentIndex) setCanNextPage(true);
    else setCanNextPage(false);
  }, [filterData, size]);

  useEffect(() => {
    if (currentIndex <= 1) setCanPreviousPage(false);
    else setCanPreviousPage(true);
    if (currentIndex > 12) setCanPageOne(true);
    else setCanPageOne(false);
    if (currentIndex < Math.ceil(count / size)) setCanNextPage(true);
    else setCanNextPage(false);
  }, [currentIndex, size]);

  const paginationSize = () => {
    if (count < size * currentIndex) return count;
    else return size * currentIndex;
  }

  const setIndex = index => {
    setCurrentIndex(index)
    setPage(index);
  }

  const goToPreviousPage = () => {
    if (canPageOne && currentIndex - 1 === renderStartPoint) {
      setRenderStartPoint(renderStartPoint - 12);
      setRenderBreakPoint(renderBreakPoint - 12);
      for (let i = renderStartPoint - 12; i < Math.ceil(count / size); i++) {
        if (i > renderBreakPoint - 12) break;
        renderPageCountButton.push(
          <li key={i} className={`paginate_button page-item ${i === index ? "active" : ""}`}>
            <button onClick={() => setIndex(i + 1)} className="page-link">{i + 1}
            </button>
          </li>
        );
      }
    }
    setIndex(currentIndex - 1);
  }

  const goToPageOne = () => {
    setRenderStartPoint(0);
    setRenderBreakPoint(11);
    setCanPageOne(false);
    for (let i = 0; i < Math.ceil(count / size); i++) {
      if (i > 11) break;
      renderPageCountButton.push(
        <li key={i} className={`paginate_button page-item ${i === index ? "active" : ""}`}>
          <button onClick={() => setIndex(i + 1)} className="page-link">{i + 1}
          </button>
        </li>
      );
    }
    setIndex(1);
  }

  const goToNextPage = () => {
    if (currentIndex - 1 === renderBreakPoint) {
      if (Math.ceil(count / size) >= 12) {
        setRenderStartPoint(renderStartPoint + 12);
        setRenderBreakPoint(renderBreakPoint + 12);

        for (let i = renderStartPoint + 12; i < Math.ceil(count / size); i++) {
          if (i > renderBreakPoint + 12) break;
          renderPageCountButton.push(
            <li key={i} className={`paginate_button page-item ${i === index ? "active" : ""}`}>
              <button onClick={() => setIndex(i + 1)} className="page-link">{i + 1}
              </button>
            </li>
          );
        }
      }
    }
    setIndex(currentIndex + 1);
  }

  for (let i = renderStartPoint; i < Math.ceil(count / size); i++) {
    if (i > renderBreakPoint) break;
    renderPageCountButton.push(
      <li key={i} className={`paginate_button page-item ${i + 1 === index ? "active" : ""}`}>
        <button onClick={() => setIndex(i + 1)} className="page-link">{i + 1}
        </button>
      </li>
    );
  }


  return (
    <Fragment>
      {loading && <OpacityLoader top="30%"/>}
      {(data && data.length > 0) &&
        <div className="row mt-4 mx-1">
          <div className="col-sm-12 col-md-5">
            <div className="dataTables_info mt-2" role="status" aria-live="polite">
              Showing results {((index - 1) * size) + 1} - {paginationSize()} of {count}
              {count > 1 ? " entries." : " entry."}
            </div>
          </div>
          <div className="col-sm-12 col-md-7 col-md-push-6">
            <div className="dataTables_paginate float-end mb-4">
              <ul className="pagination">
                <li className={`paginate_button page-item previous ${canPreviousPage ? "" : "disabled"}`}>
                  <button onClick={goToPreviousPage} disabled={!canPreviousPage} className="page-link">Previous
                  </button>
                </li>
                {canPageOne &&
                  <li className={`paginate_button page-item`}>
                    <button onClick={goToPageOne} className="page-link pointer-event">
                      <i className="zmdi zmdi-long-arrow-left"/>
                    </button>
                  </li>
                }
                {renderPageCountButton}
                <li className={`paginate_button page-item next ${canNextPage ? "" : "disabled"}`}>
                  <button onClick={goToNextPage} disabled={!canNextPage} className="page-link"> Next
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default Pagination;
