import {Helmet} from "react-helmet";
import Favicon from "../../../assets/images/pngs/favicon.png";

const Meta = ({title, description, seo, logo, url}) => (
  <Helmet>
    <title>{title}</title>

    <meta charSet="utf-8"/>
    <meta name="viewport" content="width=device-width, initial-scale=1"/>
    <meta name="og:title" property="og:title" content={title}/>
    <meta name="description" property="og:description" content={description}/>
    <meta name="keywords" content={seo}/>
    <meta name="twitter:card" content={description}/>
    <meta property="og:type" content="article"/>
    <meta property="og:locale" content="en"/>
    <meta property="og:url" content={`https://mytruq.com/blog/content${url}`}/>
    <meta property="og:site_name" content="truQ"/>

    <link rel="canonical" href="https://mytruq.com/"/>
    <link rel="icon" href={logo || Favicon}/>

  </Helmet>
);

export default Meta;
