import {Fragment} from "react";
import {useSelector} from "react-redux";
import AppSphereLoader from "../loaders/AppSphereLoader";
import SettingsMenu from "./sideMenu/SettingsMenu";
import ProfileMenu from "./sideMenu/ProfileMenu";

const InnerMenuLayout = ({active, type, children}) => {
  const component = useSelector(({componentReducer}) => componentReducer);
  return (
    <Fragment>
      {component.appLoader ? <AppSphereLoader/> :
        <div className="main p-sm-3 p-1 mt-3 mt-sm-0 d-flex">
          {type === 'PROFILE' ? <ProfileMenu active={active}/> : <SettingsMenu active={active}/>}
          {children}
        </div>
      }
    </Fragment>
  )
}

export default InnerMenuLayout;
