import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import PageLayout from "../../../../components/layouts/PageLayout";
import {getTrips} from "../../../../store/actions/booking.action";
import {Loading, menuType} from "../../../../utils/helpers/constants";

const Trips = () => {

  const dispatch = useDispatch();
  const {booking, profile} = useSelector(({bookingReducer, profileReducer}) => ({
    booking: bookingReducer,
    profile: profileReducer
  }));
  const loadState = booking.loading !== Loading.SUCCESS;

  useEffect(() => {
    dispatch(getTrips("", profile.domState.type));
  }, [profile]);

  return (
    <PageLayout active={menuType.TRIP}>
      {loadState ? <SphereLoader/> : <Body trips={booking.trips} count={booking.tripsCount} domState={profile.domState} />}
    </PageLayout>
  );
}

export default Trips;
