import Body from "./Body";
import InnerPageLayout from "../../../components/layouts/InnerPageLayout";

const OnboardBusiness = () => {

  return (
    <InnerPageLayout>
      <Body/>
    </InnerPageLayout>
  );
}

export default OnboardBusiness;
