import EncryptHelper from "./encryptHelper";
import Cookies from "js-cookie";

const cookieHelper = {

  get: key => {
    const dataEncrypted = Cookies.get(key);
    return dataEncrypted !== 'null' ? EncryptHelper.decrypt(Cookies.get(key)) : '';
  },

  set: (key, data, ex) => {
    const dataEncrypted = EncryptHelper.encrypt(data);
    Cookies.set(key, dataEncrypted, { expires: ex });
  },

  remove: key => {
    Cookies.remove(key);
  }

}

export default cookieHelper;


