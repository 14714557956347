import {Fragment, useState, useMemo} from "react";
import toast from "react-hot-toast";
import {Accordion} from "react-bootstrap";
import TransitSvg from "../../../../../assets/images/svg/transit.svg";
import BookingService from "../../../../../utils/services/booking.service";
import {formatAmountToString} from "../../../../../utils/helpers/logicHelper";
import ImageUploadModal from "../../../../../components/modals/ImageUploadModal";
import {DomStateType, ImageOptions, Loading, TripStatus} from "../../../../../utils/helpers/constants";


const TripReconciliation = ({trip, picklist, isPickUpReconciled, handleSuccessResponse, domState, loadingImageUpload, setLoadingImageUpload, imageUploadRender, setImageUploadRender, stopNumber, setStopNumber}) => {
	const [stopOvers, setStopOvers] = useState(picklist);
	const [loadingIndex, setLoadingIndex] = useState(null);
	const [merchant, setMerchant] = useState("");
	const [stopIndex, setStopIndex] = useState("");
	const [activeKey, setActiveKey] = useState("collapse-0");
	const [loadingReconciliation, setLoadingReconciliation] = useState(false);

	useMemo(() => {
		const storedKey = sessionStorage.getItem('activeAccordionKey');
		if(storedKey) setActiveKey(storedKey);

		if(trip['system_status'] !== "DROP-OFF") sessionStorage.setItem('activeAccordionKey', "collapse-0");
	}, []);

	const handleSelectAccordion = (key) => setActiveKey(key);

	const handleUploadSuccess = (index) => {
		const nextKey = `collapse-${index + 1}`;
		sessionStorage.setItem('activeAccordionKey', nextKey);
		setActiveKey(nextKey);
	};

	const determineIfReconciliationRequired = (productIndex, itemIndex, item, product) => {
		let returnValue = <input type="text" value={product.reconciled} name="amount" className="form-control"
														 onChange={e => handleChange(e, productIndex, itemIndex, item, product)}/>;
		if(item['reconciliation']) {
			let ifExist = item['reconciliation'].find(each => each.product === product.name);
			if(ifExist && ifExist['quantity_delivered'] === product['quantity']) {
				returnValue = <i className="zmdi zmdi-check-circle text-success"/>
			}
		}
		return returnValue;
	};

	const canReconcile = (item, index) => {
		const isDropOff = item.type === "DROPOFF";

		if(trip.system_status === TripStatus["ORDER-CONFIRMED"]) return toast.error("You cannot reconcile trip that has not been assigned to a driver.");
		else if(trip.system_status === TripStatus["ASSIGNED-TO-DRIVER"]) return toast.error(`You cannot reconcile right now as you are yet to arrive at the pickup location.`);
		else if(trip.system_status === TripStatus["DRIVER-AWAITING-CONFIRMATION"]) return toast.error(`You cannot reconcile right now as you are yet to confirm arrival at the pickup location.`);
		else if(isDropOff && (trip["system_status"] === TripStatus["TRIP-STARTED"] || isPickUpReconciled)) return toast.error(`You cannot reconcile dropoff right now. Kindly reconcile all pick ups and update trip status`);
		else confirmDropOff(item, index).catch(err => console.log(err));
	}

	const handleChange = (event, productIndex, itemIndex, item, product) => {
		const updatedStopOvers = [...stopOvers];
		const newQuantity = (Number(event.target.value) > Number(product.quantity)) ? product.quantity : Number(event.target.value);

		updatedStopOvers[itemIndex] = {
			...updatedStopOvers[itemIndex],
			products: updatedStopOvers[itemIndex].products.map((iterationProduct, iterationIndex) =>
				iterationIndex === productIndex ? {...iterationProduct, reconciled: newQuantity} : iterationProduct),
		};
		setStopOvers(updatedStopOvers);
	};

	const confirmDropOff = async(item, index) => {
		setLoadingReconciliation(true);
		setLoadingIndex(index);
		let orders = []

		for(let product of item['products']) {
			let order = {reconciled: String(product.reconciled), id: product.id};
			orders.push(order);
		}
		const payload = {orderset: item['orderset'], orders};
		const {data: responseData} = await BookingService.reconcileOrderSetDropOff(trip.reference, payload);

		if(responseData && responseData.status === Loading.SUCCESS) {
			handleSuccessResponse();
			handleUploadSuccess(index);
		} else {
			const message = (responseData && responseData.data) ? responseData.message : "You cannot update trip status at the moment, please try again later";
			toast.error(message);
		}
		setLoadingReconciliation(false);
		setLoadingIndex(null);
	};

	const determineStopNumber = (label) => {
		const stop = label.toString();
		setStopNumber(Number(stop[stop.length - 1]))
	}

	const uploadProofOfPayment = async(formattedFile) => {
		setLoadingImageUpload(true);

		const payload = {
			stop_number: stopNumber,
			drop_type: stopIndex.includes('Pickup') ? "pickup" : "dropoff",
			documents: [{
				type: stopIndex.includes('Pickup') ? "WAYBILL" : "PROOF_OF_DELIVERY",
				base64File: formattedFile
			}]
		};

		const {data: responseData} = await BookingService.uploadProofOfPayment(trip.id, payload);
		if(responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
		else {
			toast.success(responseData.message);
			handleSuccessResponse();
		}
		setLoadingImageUpload(false);
	}

	const imageUploadRenderStatus = () => setImageUploadRender(false);

	const imageUpload = imageUploadRender ?
		<ImageUploadModal onchange={imageUploadRenderStatus}
											type={stopIndex.includes('Pickup') ? ImageOptions.WAYBILL : ImageOptions.PROOF_OF_DELIVERY}
											props={{aspectRatio: 1}}
											message={`You are about to upload ${stopIndex.includes('Pickup') ? "waybill for" : "proof of delivery to"}`}
											name={`${stopIndex}: ${merchant}`}
											onSuccessfulUpload={uploadProofOfPayment}/> : null;

	return (
		<Fragment>
			{imageUpload}
			{(trip['is_ims'] && stopOvers && stopOvers.length > 0) &&
				<div className="main-table py-4 px-md-2 px-0 d-flex flex-column mt-4">
					<div className="container-fluid order-md-2 order-1">
						<div className="row">
							<div className="d-flex flex-column main-card p-3 pb-4 h-100">
								<div className="main-card-icon d-flex justify-content-center cost-bg"><img src={TransitSvg} alt=""/>
								</div>

								<Accordion activeKey={activeKey} onSelect={handleSelectAccordion}>
									{stopOvers.map((item, index) =>
										<Accordion.Item key={index} eventKey={`collapse-${index}`} className="mt-2rem">
											<Accordion.Header>
												{item.status === 'RECONCILED' && <i className="zmdi zmdi-shield-check text-success me-2"/>}
												{item.status === 'PARTIAL' && <i className="zmdi zmdi-shield-check text-warning me-2"/>}
												{item.label}
											</Accordion.Header>
											<Accordion.Body>
												<div className="d-flex flex-column flex-md-row justify-content-between">
													<div className="mb-4 order-md-1 order-2">
														<div>Store Name:
															{(item['merchant'] && item['merchant']['extras'] && item['merchant']['extras'])
																? (item['merchant']['extras']['outlet_name'] ? item['merchant']['extras']['outlet_name']
																	: item['merchant']['extras']['name'] ? item['merchant']['extras']['name'] : '') : "-"
															}
														</div>
														<div>Phone
															Number: {item['merchant'] && item['merchant']['phone'] ? item['merchant']['phone'] : ""}</div>
													</div>
													{(domState.type === DomStateType.DRIVER && item.status === 'TRIP-ASSIGNED') &&
														<button type="button"
																		className="btn btn-sm btn-primary mb-4 me-2 px-3 order-md-2 order-1 ms-auto fw-bold"
																		onClick={() => canReconcile(item, index)}>
															<i className="zmdi zmdi-time-restore-setting me-2"/>
															Reconcile {item.label}
															{loadingReconciliation && loadingIndex === index ?
																<i className="zmdi zmdi-spinner ms-2 zmdi-hc-spin"/> : ""}
														</button>
													}

													{(item.status === "RECONCILED" || item.status === "PARTIAL") &&
														<button type="button"
																		className="btn btn-sm secondary-btn px-2 fw-bold order-md-2 order-1 mb-4 ms-auto"
																		onClick={() => {
																			setImageUploadRender(true);
																			setStopIndex(item.label);
																			setMerchant(item.merchant.extras.name);
																			determineStopNumber(item.label);
																		}}>
															Upload document
															{loadingImageUpload ?
																<i className="zmdi zmdi-spinner mg-2 zmdi-hc-spin"/> : ""}
														</button>}
												</div>

												<div className="table-responsive">
													<table className="table main-table-data bg-white table-hover pointer-event">
														<thead>
														<tr>
															<th>ID</th>
															<th>Product</th>
															<th>Unit Price</th>
															<th>Quantity</th>
															<th>Total Cost</th>
															{domState.type === 'DRIVER' && <th>Returned</th>}
															{domState.type === 'DRIVER' && <th>Reconcilable</th>}
														</tr>
														</thead>
														<tbody>
														{item['products'].map((product, productIndex) =>
															<tr key={productIndex}>
																<td>{productIndex + 1}</td>
																<td>{product.name}</td>
																<td>{product.price}</td>
																<td>{product.quantity}</td>
																<td>{formatAmountToString(product.quantity * Number(product.price))}</td>
																{domState.type === 'DRIVER' && <td>{product.returned}</td>}
																{domState.type === 'DRIVER' &&
																	<td className="text-sm-center">
																		{determineIfReconciliationRequired(productIndex, index, item, product)}
																	</td>
																}
															</tr>
														)}
														</tbody>
													</table>
												</div>
											</Accordion.Body>
										</Accordion.Item>
									)}

								</Accordion>

							</div>
						</div>
					</div>
				</div>
			}
		</Fragment>
	)
}

export default TripReconciliation
