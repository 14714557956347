import {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import Body from "./Body";
import PageLayout from "../../../../../components/layouts/PageLayout";
import InnerMenuLayout from "../../../../../components/layouts/InnerMenuLayout";
import {menuType} from "../../../../../utils/helpers/constants";
import {useSelector} from "react-redux";

const WarehouseInfo = () => {
	const location = useLocation(), navigate = useNavigate();
	const profile = useSelector(({profileReducer}) => (profileReducer));

	useEffect(() => {
		if(location.state === null) navigate('/profile/warehouse');
	}, []);


	return (
		<PageLayout active={menuType.PROFILE}>
			<InnerMenuLayout active={menuType.WAREHOUSE} type="PROFILE">
				<Body domState={profile.domState} warehouse={location.state}/>
			</InnerMenuLayout>
		</PageLayout>
	)
}
export default WarehouseInfo;
