import { useState } from "react";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import { Loading } from "../../utils/helpers/constants";
import BookingService from "../../utils/services/booking.service";
import { formatAmountToString } from "../../utils/helpers/logicHelper";


const AdvancePaymentModal = ({ onchange, props, onSuccessResponse }) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const walletConfig = (props && props.wallet && props.wallet.extras);
  const percentagePayable = Number(100 - Number(walletConfig.threshold_rate));
  const totalCharge = Number((1 + (walletConfig.organisation_rate / 100)) * props.trip.quote.charge, 2);
  const balanceAmount = (percentagePayable === 0) ? totalCharge : Number((percentagePayable / 100) * props.trip.quote.charge, 2);
  const InitialDeposit = Number(totalCharge - balanceAmount);
  const interest = Number(totalCharge - props.trip.quote.charge);

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const completeAction = (responseMessage) => {
    toast.success(responseMessage);
    handleClose()
    onSuccessResponse(true);
  }

  const handleTripInstallmentPayment = async () => {
    setLoading(true);
    let responseData;

    if (props && props.trip['business_tripset'] && Object.entries(props.trip['business_tripset']).length > 0) {
      responseData = await BookingService.advancePaymentBusinessCheckout(props.trip['business_tripset']['tripset_id'], props.trip['business_tripset']['business']);
    } else responseData = await BookingService.advancePaymentCheckout(props.trip.id);

    if (responseData && responseData.data && responseData.data.status && responseData.data.status === Loading.SUCCESS) completeAction(responseData.data.message);
    else {
      const message = (responseData && responseData.data && responseData.data.message && typeof responseData.data.message === 'string') ? responseData.data.message : "You cannot proceed with trip installment payment at the moment. Please contact support or try again later.";
      toast.error(message);
    }
    setLoading(false);
  };
  
  
  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="proceed">
            <span id="close-modal-btn1" onClick={handleClose} className="close-modal text-danger mt-3">X</span>
            <div className="withdraw-field">
              <h5 className="text-align-center mb-4"><u>Trip Advance Payment Breakdown</u></h5>
              <div className="booking-confirm-info px-3">
                <div className="info-group">
                  <div>
                    <span>Percentage Off</span>
                    <span>{walletConfig.threshold_rate} %</span>
                  </div>
                  <div>
                    <span>Max Amount Payable</span>
                    <span>{props?.trip?.quote?.currency} {formatAmountToString(Number(walletConfig.outstanding_cap))} </span>
                  </div>
                </div>
                <div className="info-group">
                  <div>
                    <span>Trip Charge</span>
                    <span>{props?.trip?.quote?.currency} {formatAmountToString(props.trip.quote.charge)}</span>
                  </div>
                  <div>
                    <span>Interest</span>
                    <span>{props?.trip?.quote?.currency} {formatAmountToString(interest)}</span>
                  </div>
                </div>

                <div className="info-group">
                  <div>
                    <span>Initial Deposit</span>
                    <span>{props?.trip?.quote?.currency} {formatAmountToString(InitialDeposit)}</span>
                  </div>
                  <div>
                    <span>Balance Charge</span>
                    <span>{props?.trip?.quote?.currency} {formatAmountToString(Number(balanceAmount))}</span>
                  </div>
                </div>

                <div className="d-flex justify-content-between charge-group border-bottom py-3 my-3">
                  <span>Total Charge</span>
                  <span>{props?.trip?.quote?.currency} {formatAmountToString(totalCharge)}</span>
                </div>
              </div>

              <div className="d-flex justify-content-center w-75">
                <button className="main-btn w-100 mt-5" onClick={handleTripInstallmentPayment}>
                  Proceed to pay
                  {loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin" /></>}
                </button>
              </div>


            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AdvancePaymentModal;