import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Body from "./Body";
import {Loading} from "../../../utils/helpers/constants";
import {fetchAuthPolicies} from "../../../store/actions/auth.action";
import AppSphereLoader from "../../../components/loaders/AppSphereLoader";

const ForgotPassword = () => {
	const dispatch = useDispatch();
	const auth = useSelector(({authReducer}) => (authReducer));
	const loadState = auth.loadingPolicies !== Loading.SUCCESS;

	useEffect(() => {
		if(auth.loadingPolicies !== Loading.SUCCESS && auth.organisation) dispatch(fetchAuthPolicies());
	}, [auth.organisation]);

	return loadState ? <AppSphereLoader/> : <Body properties={auth.properties}/>
}

export default ForgotPassword;
