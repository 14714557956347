import {NavLink, useNavigate} from "react-router-dom";
import PlusSvg from "../../../../assets/images/svg/plus.svg";
import {DomStateType} from "../../../../utils/helpers/constants";

const Body = ({domState, warehouses}) => {
	const navigate = useNavigate();

	const warehouseInfo = (warehouse) => navigate(`/profile/warehouse/${warehouse['reference']}`, {state: warehouse});

	return (
		<div className="settings-main p-4">
			{(domState.type === DomStateType.BUSINESS && domState.business && domState.business.type.toLowerCase() === 'owner') &&
				<NavLink to="/register-warehouse" className="main-btn btn d-flex w-50 justify-content-center">
					<img className="profile-main-plus me-2" src={PlusSvg} alt="register"/> Add New Warehouse
				</NavLink>
			}
			<div className="mt-5">
				<div className="w-100 table-responsive table-bod overflow-x-auto">
					<table className="table profile-table">
						<thead>
						<tr>
							<th>Business</th>
							<th>{domState.type === DomStateType.BUSINESS ? 'Warehouse' : 'Branch'}</th>
							<th>Address</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{warehouses.length > 0 ? warehouses.map(item =>
							<tr key={item.id}>
								<td>{item['administrative']['name']}</td>
								<td>{item['name']}</td>
								<td>{item['location']['address']}</td>
								<td>
									<button onClick={() => warehouseInfo(item)}
													className="main-btn btn btn-sm d-flex justify-content-center">View More
									</button>
								</td>
							</tr>
						) : <tr>
								<td colSpan={4}>
										<h6 className="mt-3 text-center">No data found, create a warehouse with the button above</h6>
								</td>
						</tr>
						}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
}
export default Body;
