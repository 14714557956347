export const getYear = () => {
  const date = new Date();
  return date.getFullYear();
};

export const yearArray = (start, end) => {
  let startYear = start ? start : 1990;
  let endYear = end ? end : getYear();
  let yearData = [];
  for (let i = startYear; i <= endYear; i++) {
    yearData[i] = {};
    yearData[i].label = i.toString(10);
    yearData[i].value = i.toString(10);
  }
  return yearData.reverse();
};

export const generateString = length => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  const charactersLength = characters.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
}

export const toTitleCase = str => {
  if (!str || str.length === 0) return "";
  str = (/[_&\\/#, +()$~%.'":*?<>{}-]/g).test(str) ? str.replaceAll(/[_&\\/#, +()$~%.'":*?<>{}-]/g, ' ') : str;
  return str.trim().split(' ').map(word => (
    word[0] ? word[0].toUpperCase() + word.substring(1).toLowerCase() : word + word.substring(1).toLowerCase()
  )).join(' ')
}

export const formatWebsite = website => {
  if (/(http(s?)):\/\//i.test(website)) return website;
  else return `https://${website}`;
}

export const formatNumber = value => {
  const numFormat = Intl.NumberFormat('en-US');
  numFormat.format(value);
  return numFormat.format(value);
};

export const checkSubdomainExists = url => {
  const regex = new RegExp(/(?:http[s]*:\/\/)*(.*?)\.(?=[^/]*\..{2,5})/i);
  return !!url.match(regex);
};

export const handleDynamicAuth = (fields, keys) => {
  let dynamicAuth = [];
  let emailIndex = keys.findIndex(each => each === 'email');
  if (emailIndex > -1) keys.splice(emailIndex, 1);
  for (let each of keys) {
    let findKeyElement = fields.find(eachKey => eachKey.key === each);
    if (findKeyElement) dynamicAuth.push(findKeyElement);
  }
  return dynamicAuth;
};

export const formatAmountToNumber = data => {
  const newData = data.replace(/[^0-9]/g, "");
  return Number(newData);
};

export const formatService = payloadData => {
  let newObj = {};
  for (let service of payloadData) {
    newObj = {...newObj, [service.reference]: true};
  }
  return newObj
};

export const formatAmountToString = data => {
  const format = !data ? "0.00" : data;
  if (format.toString() === "0.00") return format.toLocaleString();
  else if (format.toString().includes(",")) {
    const newFormat = format.toString().replace(/,/g, "");
    const formattedNumber = parseFloat(newFormat);
    return formattedNumber.toLocaleString();
  } else if (format.toString().includes(".")) {
    const formatValue = truncateDecimalValue(format);
    return formatValue.toLocaleString();
  } else return `${format.toLocaleString()}`;
};

export const truncateDecimalValue = value => {
  value = value.toString();
  const numberValue = value.split(".")[0];
  const suffixedNumber = value.split(".")[1].substring(0, 2);
  const decimalCount = value.split(".")[1].length;
  const prefixedNumber = Number(numberValue).toLocaleString();
  if (decimalCount > 2) value = `${prefixedNumber}.${suffixedNumber}`;
  else if (decimalCount < 2) value = `${prefixedNumber}.${suffixedNumber}0`;
  else value = suffixedNumber === "00" ? prefixedNumber : `${prefixedNumber}.${suffixedNumber}`;
  return value;
};

export const fileToBase64 = event => {
  let files;
  return new Promise(resolve => {
    if(event.dataTransfer) files = event.dataTransfer.files;
    else if(event.target) files = event.target.files;
    const reader = new FileReader();
    reader.onload = () => resolve(reader['result']);
    reader.readAsDataURL(files[0]);
  });
};

export const copyToClipboard = async (text) => {
  return await navigator.clipboard.writeText(text);
}

export const maskString = str => {  if (!str || str.length === 0) return "";  return str.replace(/\w/g, ".")}