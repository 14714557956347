import {useEffect} from "react";
import {batch, useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import PageLayout from "../../../../components/layouts/PageLayout";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import {getProfile} from "../../../../store/actions/profile.action";
import {getDynamicFields} from "../../../../store/actions/component.action";
import {DomStateType, Loading, menuType, ServiceType} from "../../../../utils/helpers/constants";

const DriverInfo = () => {
  const dispatch = useDispatch();
  const {profile, component} = useSelector(({profileReducer, componentReducer}) => ({
    profile: profileReducer,
    component: componentReducer
  }))
  const loadState = !component.dynamicFields[ServiceType.DRM] || profile.domState.driverProfile === null
    || component.loadingDynamicFields !== Loading.SUCCESS;

  useEffect(() => {
    batch(() => {
      if (!component.dynamicFields[ServiceType.DRM]) dispatch(getDynamicFields(ServiceType.DRM));
      if (profile.domState.type === DomStateType.DRIVER && profile.domState.driverProfile === null) dispatch(getProfile());
    });
  }, []);

  return (
    <PageLayout active={menuType.PROFILE}>
      <InnerMenuLayout active={menuType.DRIVER_INFO} type="PROFILE">
        {loadState ? <SphereLoader/> :
          <Body profile={profile.driver} dynamicFields={component.dynamicFields[ServiceType.DRM]}/>}
      </InnerMenuLayout>
    </PageLayout>
  )
}

export default DriverInfo;