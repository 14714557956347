import {useEffect} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import { useLocation } from "react-router-dom";

import Body from "./Body";
import SphereLoader from "../../../components/loaders/SphereLoader";
import InnerPageLayout from "../../../components/layouts/InnerPageLayout";
import { getProfile } from "../../../store/actions/profile.action";
import {DomStateType, Loading} from "../../../utils/helpers/constants";
import {getExtras, getTripTypes} from "../../../store/actions/booking.action";

const BookTrip = () => {

  const dispatch = useDispatch(), location = useLocation();
  const {booking, profile} = useSelector((
    {bookingReducer, profileReducer}) => ({
    booking: bookingReducer, profile: profileReducer,
  }));

  const loadState = profile.loading !== Loading.SUCCESS || booking.loadingTripType !== Loading.SUCCESS || booking.loadingExtras !== Loading.SUCCESS;

  useEffect(() => {
    batch(() => {
      if(profile.loading !== Loading.SUCCESS) dispatch(getProfile());
      if (profile.domState.type === DomStateType.CUSTOMER) {
        dispatch(getExtras());
        dispatch(getTripTypes());
      }
    });
  }, []);


  return (
    <InnerPageLayout>
      {(loadState && profile.domState.type === DomStateType.CUSTOMER) ? <SphereLoader/>
        : <Body organisation={profile.organisation} domState={profile.domState} wallet={location.state}
         extras={booking.extras} initTripTypes={booking.tripTypes}/>
      }
    </InnerPageLayout>
  );
}

export default BookTrip;
