import {Fragment, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import { useForm } from "react-hook-form";
import {useNavigate} from "react-router-dom";
import Select from "react-select";
import toast from "react-hot-toast";

import ArrowBackSvg from "../../../assets/images/svg/arrow-back.svg";
import DynamicFields from "../../../components/dynamicFields/DynamicFields";
import {getFleet} from "../../../store/actions/fleet.action";
import {InputType, Loading} from "../../../utils/helpers/constants";
import FleetService from "../../../utils/services/fleet.service";

const Body = ({categories, dynamicFields}) => {

  const dispatch = useDispatch(), navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [pages, setPages] = useState(dynamicFields.length > 0 ? 2 : 1);
  const [category, setCategory] = useState("");
  const [loading, setLoading] = useState(false);
  
  const goBack = () => navigate(-1);
 
  const useFormHook = useForm({
    defaultValues: {},
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
  });

useEffect(() => {
    setPages(dynamicFields.length > 0 ? 2 : 1);
  }, [dynamicFields]);

  const registerVehicle = async () => {
    if (pages > step) setStep(step + 1);
    else if (pages === step) {
      setLoading(true);
      let payload = { dynamic_fields: {...useFormHook.getValues()} };
      payload.category_size = category.id;
      const {data: responseData} = await FleetService.createFleet(payload);
      if (responseData.status !== Loading.SUCCESS){ 
        const message = (responseData && responseData.message) ? responseData.message : "You cannot add your vehicle at the moment, please try again later";
        toast.error(message);
      }
      else {
        await dispatch(getFleet());
        toast.success(responseData.message);
        navigate('/profile/registered-vehicle');
      }
      setLoading(false);
    }
  }

  const renderDynamicFields = dynamicFields.map((eachField, index) => (
    <Fragment key={index}>
      {(eachField.type !== InputType.FILE && !eachField['hidden_field']) &&
        <DynamicFields divClass="custom position-relative w-100 mg-t-15 mb-3" 
        formControl={eachField} formHook={useFormHook} />
      }
    </Fragment>
  ));

  return (
    <div className="booking py-4 bg-white d-flex flex-column">
      <p className="mb-0 booking-direct fs-18 pointer-event" onClick={goBack}>
        <img src={ArrowBackSvg} alt="Arrow-back"/> &nbsp; Go Back
      </p>
      <div className="py-5 mt-3">
        <div className="booking-main d-flex flex-column">
          <h3 className="booking-title pt-0">Register a new vehicle</h3>
          <form className="booking-form d-flex mt-4 flex-column align-items-start">
            {step === 1 ?
              <>
                <div className="position-relative w-100 mg-t-15">
                  <label>Vehicle category</label>
                  <Select options={categories}
                          getOptionLabel={each => `${each.category} - ${each.tonnage} tonnes`}
                          isClearable={false}
                          isSearchable={true}
                          maxMenuHeight={250}
                          menuPlacement="bottom"
                          name={category}
                          onChange={option => setCategory(option)}/>
                </div>
              </> : ""
            }
            {step === pages && renderDynamicFields}
            <button className="btn main-btn mt-4" onClick={useFormHook.handleSubmit(registerVehicle)} type="button" 
              disabled={!category}>
              {step === pages ? 'Add Vehicle' : 'Next'}
              {(step === pages && loading) && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
            </button>
          </form>
        </div>
      </div>
    </div>
  );

}

export default Body;
