import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router-dom";

import Body from "./Body";
import AppSphereLoader from "../../../components/loaders/AppSphereLoader";
import {fetchAuthPolicies} from "../../../store/actions/auth.action";
import TokenHelper from "../../../utils/helpers/tokenHelper";
import {Loading} from "../../../utils/helpers/constants";


const Login = () => {
  const dispatch = useDispatch(), isLoggedIn = TokenHelper.checkIfLoggedIn();
  const auth = useSelector(({authReducer}) => authReducer);
  const loadState = auth.loadingPolicies !== Loading.SUCCESS;

  useEffect(() => {
    if (auth.loadingPolicies !== Loading.SUCCESS && auth.organisation) dispatch(fetchAuthPolicies());
  }, [auth.organisation]);

  if (isLoggedIn) return <Navigate to={{pathname: "/"}}/>;
  return loadState ? <AppSphereLoader/> :
    <Body authPolicy={auth.authenticationPolicy} passwordPolicy={auth.passwordPolicy}
          dynamicFields={auth.dynamicFields} properties={auth.properties}/>
}

export default Login;
