import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import BookCustomerTrip from "./atom/BookCustomerTrip";
import BookBusinessTrip from "./atom/BookBusinessTrip.js";
import SphereLoader from "../../../components/loaders/SphereLoader";
import {DomStateType, Loading} from "../../../utils/helpers/constants";
import {getBusinessVehicleCategories} from "../../../store/actions/fleet.action";

const Body = ({organisation, initTripTypes, domState, wallet, extras}) => {

  const dispatch = useDispatch(), orgWalletConfig = organisation && organisation["wallet_configs"];
  const fleet = useSelector(({fleetReducer}) => fleetReducer);
  const [tripTypes, setTripTypes] = useState(initTripTypes ?? []);

  const loadState = fleet.loadingCategories !== Loading.SUCCESS && domState.type === DomStateType.BUSINESS;

  useEffect(() => {
    if (domState.type === DomStateType.BUSINESS) dispatch(getBusinessVehicleCategories(domState.business.id, domState.business.config.config));
  }, [domState]);

  useEffect(() => {
    if (domState.type === DomStateType.CUSTOMER) {
      let types = initTripTypes.filter(each => each['system_name'] === 'FIXED' || each['system_name'] === 'VARIABLE');
      setTripTypes(types);
    }
  }, [domState]);


  return (
    <Fragment>
      {loadState ? <SphereLoader/> :
        <div className="booking py-4 bg-white d-flex flex-column">
          {domState.type === DomStateType.CUSTOMER ?
            <BookCustomerTrip tripTypes={tripTypes} extraServices={extras} orgWalletConfig={orgWalletConfig}/>
            : <BookBusinessTrip domState={domState} orgWalletConfig={orgWalletConfig} wallet={wallet} categories={fleet.categories}/>
          }
        </div>
      }
    </Fragment>
  );
}

export default Body;
