import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

import {menuType} from "../../../utils/helpers/constants";
import KeySvg from "../../../assets/images/svg/key.svg";
import LockSvg from "../../../assets/images/svg/lock.svg";
import SettingsSvg from "../../../assets/images/svg/settings.svg";
import BankSvg from "../../../assets/images/svg/bank.svg";
import TrashSvg from "../../../assets/images/svg/trash.svg";
import NotificationSvg from "../../../assets/images/svg/notification.svg";
import KeySelectedSvg from "../../../assets/images/svg/key-selected.svg";
import LockSelectedSvg from "../../../assets/images/svg/lock-selected.svg";
import SettingsSelectedSvg from "../../../assets/images/svg/settings-selected.svg";
import BankSelectedSvg from "../../../assets/images/svg/bank-selected.svg";
import TrashSelectedSvg from "../../../assets/images/svg/trash-selected.svg";
import NotificationSelectedSvg from "../../../assets/images/svg/notification-selected.svg";



const SettingsMenu = ({active}) => {
  const profile = useSelector(({profileReducer}) => profileReducer);
  return (
    <div className="settings-menu settings-sidebar">
      <div className="sidebar-menu d-flex flex-column pt-2">
        <div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.WITHDRAWAL ? 'active' : ''}`}>
          <NavLink className="d-flex align-items-center" to="/settings/wallet-pin">
            <img src={active === menuType.WITHDRAWAL ? LockSelectedSvg : LockSvg} alt="WITHDRAWAL"/>
            <span className="ms-md-2 ms-0" id="settings-item">Wallet pin</span>
          </NavLink>
        </div>
        <div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.CHANGE_PASSWORD ? 'active' : ''}`}>
          <NavLink className="d-flex align-items-center" to="/settings/change-password">
            <img src={active === menuType.CHANGE_PASSWORD ? KeySelectedSvg : KeySvg} alt="CHANGE_PASSWORD"/>
            <span className="ms-md-2 ms-0" id="settings-item">Change password</span>
          </NavLink>
        </div>
        {profile.domState.type === "BUSINESS" ?
          <div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.API_INTEGRATION ? 'active' : ''}`}>
            <NavLink className="d-flex align-items-center" to="/settings/api-integration">
              <img src={active === menuType.API_INTEGRATION ? SettingsSelectedSvg : SettingsSvg} alt="API_INTEGRATION"/>
              <span className="ms-md-2 ms-0" id="settings-item">Api Integration</span>
            </NavLink>
          </div>
          : ''}
        <div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.BANK_ACCOUNT ? 'active' : ''}`}>
          <NavLink className="d-flex align-items-center" to="/settings/bank-account">
            <img src={active === menuType.BANK_ACCOUNT ? BankSelectedSvg : BankSvg} alt="BANK_ACCOUNT"/>
            <span className="ms-md-2 ms-0" id="settings-item">Add Bank Detail</span>
          </NavLink>
        </div>
        <div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.NOTIFICATION ? 'active' : ''}`}>
          <NavLink className="d-flex align-items-center" to="/settings/notification-preference">
            <img src={active === menuType.NOTIFICATION ? NotificationSelectedSvg : NotificationSvg} alt="NOTIFICATION"/>
            <span className="ms-sm-0 ms-md-2 ms-0" id="settings-item">Notifications</span>
          </NavLink>
        </div>
        <div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.DELETE_ACCOUNT ? 'active' : ''}`}>
          <NavLink className="d-flex align-items-center" to="/settings/delete-account">
            <img src={active === menuType.DELETE_ACCOUNT ? TrashSelectedSvg : TrashSvg} alt="DELETE_ACCOUNT"/>
            <span className="ms-md-2 ms-0" id="settings-item">Delete Account</span>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default SettingsMenu;
