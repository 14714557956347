import {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import ArrowBackSvg from "../../../assets/images/svg/arrow-back.svg";
import InnerPageLayout from "../../../components/layouts/InnerPageLayout";
import MapsSelectHook from "../../../components/maps/MapsSelectHook";
import {Loading} from "../../../utils/helpers/constants";
import TokenHelper from "../../../utils/helpers/tokenHelper";
import ConfigsService from "../../../utils/services/configs.service";
import {getWarehouses} from "../../../store/actions/configs.action";

const OnboardWarehouse = () => {
	const dispatch = useDispatch(), navigate = useNavigate();
	const [name, setName] = useState("");
	const [loading, setLoading] = useState(false);
	const [warehouseLocation, setWarehouseLocation] = useState({});
	const isInvalid = !name || Object.entries(warehouseLocation).length === 0;

	const goBack = () => navigate(-1);

	const closePage = async() => navigate(-1);

	const mapsError = (error) => console.error(error);

	const handleWarehouseLocation = data => {
		const location = {
			state: data.state,
			city: data.city,
			address: data.address,
			latitude: data.latitude,
			longitude: data.longitude
		}
		setWarehouseLocation(location)
	}

	const onboardWarehouse = async() => {
		setLoading(true);
		let payload = {business: TokenHelper.getSelectedBusiness(), name, location: warehouseLocation};
		const {data: responseData} = await ConfigsService.createWarehouse(payload);
		if(responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
		else {
			dispatch(getWarehouses());
			toast.success(responseData.message);
			navigate('/profile/warehouse');
		}
		setLoading(false);
	}


	return (
		<InnerPageLayout>
			<div className="booking py-4 bg-white d-flex flex-column gap-3">
				<p className="mb-0 me-4 booking-direct fs-18 pointer-event" onClick={goBack}>
					<img src={ArrowBackSvg} alt="Arrow-back"/> &nbsp; Go Back
				</p>

				<div className="pb-5">
					<div className="booking-main d-flex flex-column ">
						<h3 className="booking-title">Set up your business warehouses</h3>
						<form className="booking-form d-flex mt-5 flex-column align-items-start">
							<div className="position-relative w-100 mg-t-15 text-wrap">
								<label>Warehouse Name</label>
								<input type="text" value={name} onChange={e => setName(e.target.value)}/>
							</div>
							<div className="position-relative w-100 mt-5">
								<MapsSelectHook label="Warehouse Address" placeholder="Type to search for warehouse location"
																setLocation={handleWarehouseLocation} setError={mapsError}/>
							</div>
							<div className="position-relative w-100 mg-t-15">
								<button type="button" className="btn main-btn mt-4"
												disabled={isInvalid} onClick={onboardWarehouse}>
									Create Warehouse{loading && <i className="zmdi zmdi-spinner mg-l-5 zmdi-hc-spin"/>}
								</button>
								<button className="btn main-btn bg-danger mt-4 mg-l-3" onClick={closePage} type="button">Close</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</InnerPageLayout>
	);
}

export default OnboardWarehouse;
