import * as ProfileConstants from "../constants/profile.constants";
import {Configs, DomStateType, Loading} from "../../utils/helpers/constants";
import CookieHelper from "../../utils/helpers/cookieHelper";

const initialState = {
  userId: "",
  driverProfileId: "",
  loading: "",
  loadingOverview: "",
  domState: {type: 'CUSTOMER', id: ""},
  userData: {},
  overview: {},
  organisation: {},
  businesses: []
};

const setDomSwitcher = (userData, driverProfile, businesses) => {
  let cookieState = CookieHelper.get(Configs.USER_DOM);
  if (cookieState) return cookieState;
  else {
    let domState = {type: DomStateType.CUSTOMER, id: userData.id};
    if (userData['is_business'] && businesses.length > 0) {
      domState = {type: DomStateType.BUSINESS, id: businesses[0].id, business: businesses[0]};
    } else if (userData['is_driver'] && driverProfile) {
      domState = {type: DomStateType.DRIVER, id: driverProfile.id, driverProfile};
    }
    CookieHelper.set(Configs.USER_DOM, domState, Configs.COOKIE_EXPIRY_PERIOD);
    return domState;
  }
};

const profileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ProfileConstants.FETCH_PROFILE_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case ProfileConstants.FETCH_PROFILE_SUCCESS:
      const domState = setDomSwitcher(action.payload.profile, action.payload.driver, action.payload.businesses);
      return {
        ...state, domState,
        userData: action.payload.profile,
        userId: action.payload.profile.id,
        driverProfileId: action.payload.driver ? action.payload.driver.id : '',
        driver: action.payload.driver,
        organisation: action.payload.organisation,
        businesses: action.payload.businesses,
        loading: Loading.SUCCESS
      }

    case ProfileConstants.SET_DOM_PROFILE:
      CookieHelper.set(Configs.USER_DOM, action.payload, Configs.COOKIE_EXPIRY_PERIOD);
      return {...state, domState: action.payload}

    case ProfileConstants.FETCH_PROFILE_FAILURE:
      return {...state, loading: Loading.ERROR}

    case ProfileConstants.FETCH_OVERVIEW_REQUEST:
      return {...state, loadingOverview: Loading.FETCHING};

    case ProfileConstants.FETCH_OVERVIEW_SUCCESS:
      return {...state, loadingOverview: Loading.SUCCESS, overview: action.payload};

    case ProfileConstants.FETCH_OVERVIEW_FAILURE:
      return {...state, loadingOverview: Loading.ERROR};

    default:
      return state;
  }
};

export default profileReducer;
