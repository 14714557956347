export const FETCH_TRIPS_REQUEST = "FETCH_TRIPS_REQUEST";
export const FETCH_TRIPS_SUCCESS = "FETCH_TRIPS_SUCCESS";
export const FETCH_TRIPS_FAILURE = "FETCH_TRIPS_FAILURE";

export const FETCH_DRIVER_TRIPS_REQUEST = "FETCH_DRIVER_TRIPS_REQUEST";
export const FETCH_DRIVER_TRIPS_SUCCESS = "FETCH_DRIVER_TRIPS_SUCCESS";
export const FETCH_DRIVER_TRIPS_FAILURE = "FETCH_DRIVER_TRIPS_FAILURE";

export const FETCH_TRIP_DETAILS_REQUEST = "FETCH_TRIP_DETAILS_REQUEST";
export const FETCH_TRIP_DETAILS_SUCCESS = "FETCH_TRIP_DETAILS_SUCCESS";
export const FETCH_TRIP_DETAILS_FAILURE = "FETCH_TRIP_DETAILS_FAILURE";

export const FETCH_PICKLIST_REQUEST = "FETCH_PICKLIST_REQUEST";
export const FETCH_PICKLIST_SUCCESS = "FETCH_PICKLIST_SUCCESS";
export const FETCH_PICKLIST_FAILURE = "FETCH_PICKLIST_FAILURE";

export const FETCH_TRIP_TYPES_REQUEST = "FETCH_TRIP_TYPES_REQUEST";
export const FETCH_TRIP_TYPES_SUCCESS = "FETCH_TRIP_TYPES_SUCCESS";
export const FETCH_TRIP_TYPES_FAILURE = "FETCH_TRIP_TYPES_FAILURE";

export const FETCH_EXTRAS_REQUEST = "FETCH_EXTRAS_REQUEST";
export const FETCH_EXTRAS_SUCCESS = "FETCH_EXTRAS_SUCCESS";
export const FETCH_EXTRAS_FAILURE = "FETCH_EXTRAS_FAILURE";