import {useNavigate} from "react-router-dom";
import ArrowBackSvg from "../../../assets/images/svg/arrow-back.svg";
import BookBusinessManifest from "./atom/BookBusinessManifest.js";
import {DomStateType} from "../../../utils/helpers/constants";

const Body = ({domState, wallet}) => {

  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <div className="booking py-4 bg-white d-flex flex-column">
      <p className="mt-4 booking-direct fs-18 pointer-event" onClick={goBack}>
        <img src={ArrowBackSvg} alt="Arrow-back"/> &nbsp; Dashboard
      </p>
      {domState.type === DomStateType.BUSINESS ? <BookBusinessManifest domState={domState} wallet={wallet}/> : ""}
    </div>
  );
}

export default Body;
