import React, { useMemo, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { toTitleCase } from "../../../utils/helpers/logicHelper";

const EMAIL = ({ divClass, inputClass, formControl, formHook, initialValue }) => {
  const [validatorSchema, setValidatorSchema] = useState({});
  const errors = formHook.formState.errors || formHook.formState.errors[formControl["key"]];
  useMemo(() => {
    for (const [key, value] of Object.entries(formControl)) {
      switch (key) {
        case 'required':
          if (value) setValidatorSchema(prevState => ({ ...prevState, required: `${formControl["label"]} is required.` }));
          break;
        default:
          break;
      }
    }

    const pattern = {
      value: /\S+@\S+\.\S+/,
      message: `${formControl["label"]} is invalid.`
    };
    setValidatorSchema(prevState => ({ ...prevState, pattern }));
  }, []);


  return (
    <div className={divClass}>
      <label htmlFor={formControl["key"]}>
        {toTitleCase(formControl["label"])} {formControl["required"] && <span className="text-danger">*</span>}
      </label>
      <input className={`${inputClass}`}
        type={formControl["type"]}
        name={formControl["key"]}
        placeholder={formControl["placeholder"]}
        defaultValue={initialValue ?? formControl["default_value"]}
        {...formHook.register(formControl["key"], {...validatorSchema})}
      />
      <ErrorMessage
        errors={errors}
        name={formControl["key"]}
        render={(error) =>
          error &&
          Object.entries(error).map(([type, message]) => (
            <small key={type} className="text-danger">{message}</small>
          ))
        }
      />
    </div>
  )
}

export default EMAIL;
