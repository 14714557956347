import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";

import {getProfile} from "../../../../store/actions/profile.action";
import PageLayout from "../../../../components/layouts/PageLayout";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import ActionPromptModal from "../../../../components/modals/ActionPromptModal";
import CacheHelper from "../../../../utils/helpers/cacheHelper";
import CookieHelper from "../../../../utils/helpers/cookieHelper";
import ProfileService from "../../../../utils/services/profile.service";
import {ActionPromptOption, Configs, Loading, menuType} from "../../../../utils/helpers/constants";

const DeleteAccount = () => {

  const dispatch = useDispatch(), navigate = useNavigate();
  const profile = useSelector(({profileReducer}) => profileReducer);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [deleteDriver, setDeleteDriver] = useState(false);
  const [deleteBusiness, setDeleteBusiness] = useState(false);
  const [transferVehicle, setTransferVehicle] = useState(false);
  const [transferBusiness, setTransferBusiness] = useState(false);
  const ownedBusinesses = profile.businesses.filter(each => each.type === 'owner');
  const disabledState = email !== profile.userData.email || profile.userData['is_driver']
    || profile.userData['is_vehicle_owner'] || profile.userData['is_business'];

  const deleteAccount = async () => {
    setLoading(true);
    const {data: responseData} = await ProfileService.deleteUser(profile.userData.id);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      toast.success(responseData.message);
      CookieHelper.remove(Configs.KEY);
      CookieHelper.remove(Configs.USER_DOM);
      CacheHelper.clear();
      await navigate('/auth/login');
    }
    setLoading(false);
  }


  const goToVehiclePage = () => navigate('/profile/registered-vehicle');
  const onDeleteSuccess = () => dispatch(getProfile());
  const changeDeleteDriverRender = () => setDeleteDriver(false);
  const changeDeleteBusinessRender = () => setDeleteBusiness(false);
  const changeTransferBusinessRender = () => setTransferBusiness(false);
  const changeTransferVehicleRender = () => setTransferVehicle(false);

  const deleteBusinessModal = deleteBusiness &&
    <ActionPromptModal onchange={changeDeleteBusinessRender} type={ActionPromptOption.DELETE_BUSINESS}
                       props={ownedBusinesses[0]} initValue={ownedBusinesses[0]['reference']}
                       setDeleteSuccess={onDeleteSuccess}/>;

  const transferBusinessModal = transferBusiness &&
    <ActionPromptModal onchange={changeTransferBusinessRender} type={ActionPromptOption.TRANSFER_BUSINESS}
                       props={profile.userData} initValue={profile.userData.email} findUser={true}
                       setDeleteSuccess={onDeleteSuccess}/>;

  const deleteDriverModal = deleteDriver &&
    <ActionPromptModal onchange={changeDeleteDriverRender} type={ActionPromptOption.DELETE_DRIVER}
                       props={profile.driver} initValue={profile.driver['user']['email']}
                       setDeleteSuccess={onDeleteSuccess}/>;

  const transferVehicleModal = transferVehicle &&
    <ActionPromptModal onchange={changeTransferVehicleRender} type={ActionPromptOption.TRANSFER_VEHICLE}
                       props={profile.userData} initValue={profile.userData.email} findUser={true}
                       setDeleteSuccess={onDeleteSuccess}/>;


  return (
    <PageLayout active={menuType.SETTINGS}>
      <InnerMenuLayout active={menuType.DELETE_ACCOUNT} type="SETTINGS">
        {deleteDriverModal}
        {deleteBusinessModal}
        {transferBusinessModal}
        {transferVehicleModal}
        <div className="settings-main">
          <div className="p-4">
            <h3 className="mb-4">Delete account</h3>
            <div className="disable-group">
              <p className="mb-4">Are you sure you want to delete your account with email
                <span className="email-text tx-semibold tx-italic"> {profile.userData.email}</span>?
              </p>
              {ownedBusinesses.length > 0 &&
                <p className="mb-4">You currently own {ownedBusinesses.length}
                  {ownedBusinesses.length === 1 ? ' business' : ' businesses'} attached to your profile.
                  {ownedBusinesses.length === 1 ? ' This' : ' These'} {ownedBusinesses.length === 1 ? ' business ' : ' businesses '}
                  would need to be <a className="text-primary" onClick={() => setTransferBusiness(true)}>transferred to
                    another user</a>.
                  You can choose to also <a className="text-primary" onClick={() => setDeleteBusiness(true)}>
                    delete the {ownedBusinesses.length === 1 ? ' business ' : ' businesses '}</a>.
                </p>
              }
              {profile.userData['is_driver'] &&
                <p className="mb-4">You currently own a driver account attached to your profile. You can choose to
                  <a className="text-primary" onClick={() => setDeleteDriver(true)}> delete driver profile</a>.
                </p>
              }
              {profile.userData['is_vehicle_owner'] &&
                <p className="mb-4">You currently own vehicle(s) account attached to your profile.
                  <a className="text-primary" onClick={() => setTransferVehicle(true)}> transfer your fleet assets</a>,
                  {" "}if there are any attached, or,
                  <a className="text-primary" onClick={goToVehiclePage}> delete your vehicle(s)</a>.
                </p>
              }
              <p className="mb-4">You can send in a support request about deleting your account with our
                <a className="text-decoration-none" href="mailto:contact@mytruq.com"> support team</a>
                , and may choose not to continue with this action.
              </p>
              <p className="warn-text">This action is irreversible and all your data will be permanently removed.</p>
            </div>
            <form className="mt-4 disable-form">
              <div className="mb-4">
                <label className="mb-2">Enter your email to confirm</label>
                <div><input type="text" value={email} onChange={e => setEmail(e.target.value)}/></div>
              </div>
              <button className="btn primary-btn" onClick={deleteAccount} disabled={disabledState} type="button">
                Delete account {loading && <i className="zmdi zmdi-spinner mg-2 zmdi-hc-spin ms-2"/>}
              </button>
            </form>
          </div>
        </div>
      </InnerMenuLayout>
    </PageLayout>
  )
}

export default DeleteAccount;
