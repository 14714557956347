import {Fragment, useState} from "react";
import {useDispatch} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import moment from "moment";
import Blank from "../../../../../assets/images/jpg/blank.jpg";
import {getFleet} from "../../../../../store/actions/fleet.action";
import {toTitleCase} from "../../../../../utils/helpers/logicHelper";
import FleetService from "../../../../../utils/services/fleet.service";
import EditVehicleModal from "../../../../../components/modals/EditVehicleModal";
import ActionPromptModal from "../../../../../components/modals/ActionPromptModal";
import DynamicFileUploadModal from "../../../../../components/modals/DynamicFileUploadModal";
import {ActionPromptOption, DynamicFileUploadOption, Loading} from "../../../../../utils/helpers/constants";

const Body = ({vehicle, dynamicFields}) => {
  const dispatch = useDispatch(), navigate = useNavigate();
  const [vehicleDocument, setVehicleDocument] = useState(null);
  const [editVehicleRender, setEditVehicleRender] = useState(false);
  const [deleteVehicleRender, setDeleteVehicleRender] = useState(false);
  const [uploadVehicleDocumentRender, setUploadVehicleDocumentRender] = useState(false);
  const documentUpload = dynamicFields.filter(each => (each.type === 'file' && !each['hidden_field']));


  const deletedVehicleResponse = async () => {
    await dispatch(getFleet());
    navigate("/profile/registered-vehicle");
  }

  const onSuccessfulUpload = async () => {
    const {data: response} = await FleetService.getVehicle(vehicle.id);
    if (response.status !== Loading.SUCCESS) navigate('/profile/registered-vehicle');
    else navigate(`/profile/registered-vehicle/${response.data['dynamic_fields']['registration_number']}`, {state: response.data});
  }

  const openUploadVehicleDocumentRender = file => {
    setVehicleDocument(file);
    setUploadVehicleDocumentRender(true);
  }

  const changeEditVehicleRender = () => setEditVehicleRender(false);
  const changeDeleteVehicleRender = () => setDeleteVehicleRender(false);
  const changeUploadVehicleDocumentRender = () => setUploadVehicleDocumentRender(false);


  const editVehicleModal = editVehicleRender &&
    <EditVehicleModal onchange={changeEditVehicleRender} props={vehicle} dynamicFields={dynamicFields}/>;

  const deleteVehicleModal = deleteVehicleRender &&
    <ActionPromptModal onchange={changeDeleteVehicleRender} type={ActionPromptOption.DELETE_VEHICLE}
                       props={vehicle} initValue={vehicle['owner']['email']}
                       setDeleteSuccess={deletedVehicleResponse}/>;

  const uploadVehicleDocumentModal = uploadVehicleDocumentRender &&
    <DynamicFileUploadModal onchange={changeUploadVehicleDocumentRender} type={DynamicFileUploadOption.VEHICLE}
                            props={vehicle} dynamicFields={vehicleDocument} onSuccessResponse={onSuccessfulUpload}/>;


  return (
    <div className="settings-main p-4">
      {editVehicleModal}
      {deleteVehicleModal}
      {uploadVehicleDocumentModal}
      <div className="d-flex align-items-center">
        <NavLink to="/profile/registered-vehicle" className="text-dark" role="button">
          <i className="zmdi zmdi-chevron-left fs-3 mt-1"/>
        </NavLink>
        <p className="mb-0 ms-3 fs-5">Vehicle Information</p>
      </div>
      <div className="d-flex justify-content-end mt-4">
        <button className="btn primary-btn text-decoration-none mg-r-3 mb-2"
                onClick={() => setEditVehicleRender(true)}>Edit Vehicle
        </button>
        <button className="btn bg-danger text-white text-decoration-none mb-2"
                onClick={() => setDeleteVehicleRender(true)}>Delete Vehicle
        </button>
      </div>
      <div className="mt-4 container-fluid px-0">
        <div className="row">
          <div className="col-lg-12 col-xl-6 mb-3">
            <div className="main-card profile h-100">
              <div className="profile-header p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="fs-20 my-auto">
                      {vehicle['dynamic_fields']['registration_number']}
                    </p>
                  </div>
                  {vehicle['is_active']
                    ? <span
                      className="badge bg-success font-weight-light tx-15 my-auto py-1 px-2">Approved</span>
                    : <span className="badge bg-warning font-weight-light tx-15 my-auto py-1 px-2">Pending approval</span>
                  }
                </div>
              </div>
              <div className="profile-body p-3">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <p className="mb-0 profile-info-name text-truncate">Category</p>
                    <span className="profile-info-deet text-truncate">
											{vehicle.category_size ? vehicle.category_size.category : "-"}
										</span>
                  </div>
                  <div className="col-md-6 mb-3">
                    <p className="mb-0 profile-info-name text-truncate">Size</p>
                    <span className="profile-info-deet text-truncate">
											{vehicle.category_size ? vehicle.category_size['tonnage'] : "-"} tonne
										</span>
                  </div>
                </div>
                <div className="row">
                  {dynamicFields.map(item => (
                    <Fragment key={item.key}>
                      {(item.key in vehicle['dynamic_fields'] && item.type !== 'file') &&
                        <div className="col-md-6 mb-3">
                          <p className="mb-0 profile-info-name text-truncate">{toTitleCase(item.key)}</p>
                          <span className="profile-info-deet text-truncate">
                             {(item.type !== 'date' && vehicle['dynamic_fields'][item.key]) ? vehicle['dynamic_fields'][item.key]
                               : (item.type === 'date' && moment(vehicle['dynamic_fields'][item.key]).isValid()) ? moment(vehicle['dynamic_fields'][item.key]).format('DD-MMMM-YYYY')
                               : "-"
                              }
                          </span>
                        </div>
                      }
                    </Fragment>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-xl-6 mb-3">
            <div className="main-card profile h-100">
              <div className="profile-header p-3">
                <p className="fs-20 mb-0">Assigned Driver Info</p>
                <p
                  className="profile-info-deet text-muted mb-0">{vehicle['driver'] ? 'Assigned Driver Info' : 'No Driver Assigned'}</p>
              </div>
              <div className="profile-body p-3">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <p className="mb-0 profile-info-name text-truncate">First name</p>
                    <span className="profile-info-deet text-truncate">
											{(vehicle['driver'] && vehicle['driver']['user']) ? vehicle['driver']['user']['first_name'] : "-"}
										</span>
                  </div>
                  <div className="col-md-6 mb-3">
                    <p className="mb-0 profile-info-name text-truncate">Last name</p>
                    <span className="profile-info-deet text-truncate">
											{(vehicle['driver'] && vehicle['driver']['user']) ? vehicle['driver']['user']['last_name'] : "-"}
										</span>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <p className="mb-0 profile-info-name text-truncate">Email</p>
                    <span className="profile-info-deet text-truncate">
											{(vehicle['driver'] && vehicle['driver']['user']) ?
                        <a
                          href={`mailto:${vehicle['driver']['user']['email']}`}>{vehicle['driver']['user']['email']}</a> : "-"}
										</span>
                  </div>
                  <div className="col-md-6 mb-3">
                    <p className="mb-0 profile-info-name text-truncate">Phone</p>
                    <span className="profile-info-deet text-truncate">
											{(vehicle['driver'] && vehicle['driver']['user']) ?
                        <a href={`tel:${vehicle['driver']['user']['phone']}`}>{vehicle['driver']['user']['phone']}</a>
                        : "-"
                      }
										</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {(documentUpload && documentUpload.length > 0) &&
        <div className="main-card profile mt-5">
          <div className="profile-header p-3"><h5>Vehicle images and Documents</h5></div>
          <div className="container-fluid px-0">
            <div className="row p-3">
              {documentUpload.map(item => (
                <div key={item.key} className="col-xl-6 mb-3">
                  <div className="profile-vehicle-image overflow-hidden">
                    <div className="card-body bg-primary py-2 mb-0">
                      <div className="d-flex justify-content-between">
                        <span className="card-text">{toTitleCase(item.key)}</span>
                        <i className="zmdi zmdi-plus-circle fs-3 text-white"
                           onClick={() => openUploadVehicleDocumentRender(item)}/>
                      </div>
                    </div>
                    <img src={vehicle['dynamic_fields'][item.key] ?? Blank} alt={toTitleCase(item.key)}
                         className="mx-auto d-block w-100"/>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default Body;
