const SphereLoader = () => (
<div className="main-content pt-0 d-flex justify-content-center align-items-center">
  <div className="dimmer active">
    <div className="spinner1">
      <div className="double-bounce1"/>
      <div className="double-bounce2"/>
    </div>
  </div>
</div>
);

export default SphereLoader;
