import {Fragment, useState} from "react";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";

import AuthLayout from "../../../components/layouts/AuthLayout";
import EncryptHelper from "../../../utils/helpers/encryptHelper";
import ActionCreator from "../../../utils/helpers/actionCreator";
import { setLoggedInUser } from "../../../store/actions/auth.action";
import ProfileService from "../../../utils/services/profile.service";
import * as AuthConstants from "../../../store/constants/auth.constants";
import {InputValidator, Loading} from "../../../utils/helpers/constants";
import DynamicFields from "../../../components/dynamicFields/DynamicFields";
import {emailValidator, phoneValidator} from "../../../utils/services/validator.service";


const Body = ({passwordPolicy, dynamicFields, dynamicRender, properties}) => {

  const dispatch = useDispatch(), navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [passwordType, updatePasswordType] = useState("password");
  const disabledState = !firstName || !lastName || !email || emailError || !phone || phoneError || !password;

  const useFormHook = useForm({
    defaultValues: {},
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
  });

  const togglePasswordField = type => {
    updatePasswordType(type);
    document.getElementById('password').type = type;
  }

  const handleFieldValidation = (data, type) => {
    if (type === 'EMAIL') {
      const validatedEmail = emailValidator(data);
      setEmail(validatedEmail.validateEmail);
      validatedEmail.validState === InputValidator.VALID ? setEmailError(false) : setEmailError(true);
    } else if (type === 'PHONE') {
      data = data.replace(/[^0-9/+]/gi, "");
      const validatedPhone = phoneValidator(data);
      setPhone(data);
      validatedPhone === InputValidator.VALID ? setPhoneError(false) : setPhoneError(true);
    }
  }

  const onSubmitClick = async () => {
    if (step === 1 && dynamicRender > 1) setStep(step + 1);
    else if (step === dynamicRender) {
      setLoadingStatus(true);
      let payload = {
        password,
        phone,
        first_name: firstName,
        last_name: lastName,
        email: email.toLowerCase(),
      };
      payload.extras = { ...useFormHook.getValues()};
      const {data: responseData} = await ProfileService.createUser(payload);
      if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message)
      else {
        toast.success(responseData.message);
        const user = EncryptHelper.jwtDecode(responseData.data.access);
        await dispatch(setLoggedInUser(responseData.data.access, user));
        await dispatch(ActionCreator(AuthConstants.LOGIN_SUCCESS, true));
        navigate('/');
      }
      setLoadingStatus(false);
    }
  }

  const renderDynamicFields = dynamicFields.map((eachField, index) => (
    <DynamicFields divClass="form-group text-start pos-relative" inputClass="form-control" key={index}
      formControl={eachField} formHook={useFormHook} />
  ));

  return (
    <Fragment>
      <AuthLayout favicon={properties['favicon']} logo={properties['logo']}>
        <form className="login-form signup-page">
          {dynamicRender < 2 ? "" :
            <>
              <label>Step {step} of {dynamicRender}</label>
              <div
                className={step === dynamicRender ? 'signup-page-step-loader2' : 'signup-page-step-loader'}></div>
            </>
          }
          <h5 className="text-start text-center">Create your free account</h5>
          {step === 1 ?
            <>
              <div className="form-group text-start">
                <label>First name</label>
                <input className="form-control" value={firstName} placeholder="Enter first name"
                       type="text"
                       onChange={e => setFirstName(e.target.value)}/>
              </div>
              <div className="form-group text-start">
                <label>Last name</label>
                <input className="form-control" value={lastName} placeholder="Enter last name" type="text"
                       onChange={e => setLastName(e.target.value)}/>
              </div>
              <div className="form-group text-start">
                <label>Email </label>
                <input className={`form-control ${emailError && 'is-invalid'}`} value={email}
                       placeholder="Enter email address"
                       type="email"
                       onChange={event => handleFieldValidation(event.target.value, 'EMAIL')}/>
              </div>
              <div className="form-group text-start">
                <label>Phone number</label>
                <input className={`form-control ${phoneError && 'is-invalid'}`} type="text" value={phone}
                       placeholder="Enter phone number"
                       onChange={event => handleFieldValidation(event.target.value, 'PHONE')}/>
              </div>
              <div className="form-group text-start pos-relative">
                <label>Password</label>
                <input className="form-control" placeholder="Enter Password" value={password}
                       type="password"
                       minLength={Number(passwordPolicy['min_length'])}
                       maxLength={Number(passwordPolicy['max_length'])}
                       id="password" onChange={e => setPassword(e.target.value)}/>
                <div className="eye-right">
                  {passwordType === "password" ?
                    <i onClick={() => togglePasswordField("text")} className="zmdi zmdi-eye"/> :
                    <i onClick={() => togglePasswordField("password")} className="zmdi zmdi-eye-off"/>
                  }
                </div>
              </div>
            </> : step === 2 ? renderDynamicFields : ""
          }
          {step === dynamicRender &&
            <div className="form-group text-start terms-condition">
              <input type="checkbox" id="terms" defaultChecked={terms}
                     onChange={e => setTerms(e.target.checked)}/>
              <p>By activating your account, you agree to our Terms and Conditions.</p>
            </div>
          }
          <button className="log-in" disabled={step !== dynamicRender ? !terms : (disabledState || (!terms))}
                  onClick={onSubmitClick} type="button">
            {step === dynamicRender ? 'Submit' : 'Next'}
            {loadingStatus && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
          </button>
        </form>
      </AuthLayout>
      <div className="my-3 text-end">
        <div className="text-center mb-3">Already Have an account?
          <NavLink className="signup" to="/auth/login"> Login</NavLink>.
        </div>
      </div>
    </Fragment>
  )
}

export default Body;
