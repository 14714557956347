import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";

import PageLayout from "../../../../components/layouts/PageLayout";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import {removeLoggedInUser} from "../../../../store/actions/auth.action";
import {Loading, menuType} from "../../../../utils/helpers/constants";
import ProfileService from "../../../../utils/services/profile.service";

const DisableAccount = () => {

  const dispatch = useDispatch();
  const profile = useSelector(({profileReducer}) => profileReducer);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const disabledState = email !== profile.userData.email;

  const disableAccount = async () => {
    setLoading(true);
    const {data: responseData} = await ProfileService.disableCustomUser({});
    if (responseData.status !== Loading.SUCCESS) {
      const message = (responseData && responseData.message) ? responseData.message : "An Error occured while disabling your account with us. Please try again later";
      toast.error(message);
    }
    else dispatch(removeLoggedInUser());
    setLoading(false);
  }

  return (
    <PageLayout active={menuType.PROFILE}>
      <InnerMenuLayout active={menuType.DISABLE_ACCOUNT} type="PROFILE">
        <div className="settings-main">
          <div className="p-4">
            <h3 className="mb-4">Disable account</h3>
            <div className="disable-group">
              <p className="mb-4">Are you sure you want to disable your account with email &nbsp;
                <span className="email-text">{profile.userData.email}</span> ?
              </p>
              <p className="mb-4">You can send in a support request about account suspension information with our
                <a className="text-decoration-none" href="mailto:contact@mytruq.com"> support team</a>
                , and may choose not to continue with this action.
              </p>
              <p className="warn-text">This action is irreversible</p>
            </div>
            <form className="mt-4 disable-form">
              <div className="mb-4">
                <label className="mb-2">Enter your email to confirm</label>
                <div><input type="text" value={email} onChange={e => setEmail(e.target.value)}/></div>
              </div>
              <button className="btn primary-btn" onClick={disableAccount} disabled={disabledState} type="button">
                Yes
                {loading ? <i className="zmdi zmdi-spinner mg-2 zmdi-hc-spin"/> : ""}
              </button>
            </form>
          </div>
        </div>
      </InnerMenuLayout>
    </PageLayout>
  )
}

export default DisableAccount;
