import {dodgeInstance as Axios} from './axios.service';

const FleetService = {

  getVehicleCategories() {
    return Axios({
      method: 'GET',
      url: `asset-service/fleet/fleet-organisation-category-size/`
    });
  },

  getBookingVehicleCategories(tripType) {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-organisation-profile/get_booking_category_sizes/?trip_type=${tripType}`
    });
  },

  getBusinessVehicleCategories(id, tripType) {
    return Axios({
      method: 'GET',
      url: `entity-service/business/business-profile/${id}/get_business_category_sizes/?trip_type=${tripType}`
    });
  },

  getFleet() {
    return Axios({
      method: 'GET',
      url: `asset-service/fleet/fleet-organisation-vehicle/`
    });
  },

  getVehicle(id) {
    return Axios({
      method: 'GET',
      url: `asset-service/fleet/fleet-organisation-vehicle/${id}/`
    });
  },

  createFleet(payload) {
    return Axios({
      method: 'POST',
      url: `asset-service/fleet/fleet-organisation-vehicle/`,
      data: payload
    });
  },

  updateVehicle(id, payload) {
    return Axios({
      method: 'PATCH',
      url: `asset-service/fleet/fleet-organisation-vehicle/${id}/`,
      data: payload
    });
  },

  uploadFleetDocument(driverId, payload) {
    return Axios({
      method: 'POST',
      url: `asset-service/fleet/fleet-organisation-vehicle/${driverId}/upload_documents/`,
      data: payload,
      headers: {'Content-Type': 'multipart/form-data'}
    });
  },

  transferVehicle(payload) {
    return Axios({
      method: 'POST',
      url: `asset-service/fleet/fleet-organisation-vehicle/transfer_all_vehicles/`,
      data: payload
    });
  },

  deleteVehicle(id) {
    return Axios({
      method: 'DELETE',
      url: `asset-service/fleet/fleet-organisation-vehicle/${id}/`,
    });
  },

};

export default FleetService;
