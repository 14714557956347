import { useMemo, useState } from "react";

import NigeriaFlag from "../../../assets/images/pngs/nigeria.png";
import AddSvg from "../../../assets/images/svg/add.svg";
import WithdrawSvg from "../../../assets/images/svg/withdraw.svg";
import TransferSvg from "../../../assets/images/svg/transfer.svg";
import WalletTopupModal from "../../../components/modals/WalletTopupModal";
import WalletWithdrawalModal from "../../../components/modals/WalletWithdrawalModal";
import { formatAmountToString } from "../../../utils/helpers/logicHelper";
import PaginationTable from "../../../components/dataTable/PaginationTable";
import { paginationSize } from "../../../utils/helpers/objectHelpers";
import { entriesTableConfig } from "../../../utils/helpers/dataTableConfigs";
import { Loading } from "../../../utils/helpers/constants";
import WalletService from "../../../utils/services/wallet.service";

const Body = ({ wallet, accounts, entries, count, orgainsation }) => {

  const orgWalletConfig = orgainsation && orgainsation["wallet_configs"];
  const [pageData, setPageData] = useState(entries);
  const [page, setPage] = useState(1);
  const [filterInput, setFilterInput] = useState("");
  const [topUpRender, setTopUpRender] = useState(false);
  const [fundWithdrawRender, setFundWithdrawRender] = useState(false);
  const [pageSize, setPageSize] = useState(paginationSize[1]);
  const [loadingPagination, setLoadingPagination] = useState(false);

  const changeTopUpRenderStatus = () => setTopUpRender(false);
  const changeWithdrawRenderStatus = () => setFundWithdrawRender(false);

  const tableObject = [...entriesTableConfig];
  const columns = useMemo(() => tableObject, []);

  const walletTopUpModal = topUpRender && <WalletTopupModal onchange={changeTopUpRenderStatus} />;

  const walletWithdrawalModal = fundWithdrawRender &&
    <WalletWithdrawalModal accounts={accounts} onchange={changeWithdrawRenderStatus} wallet={wallet} />;

  const fetchPagination = async (option, value) => {
    setLoadingPagination(true);
    setPageSize(option);
    if (count < option.value) setPage(Math.ceil(value - 1));
    else setPage(value);
    const filter = `?size=${option.value}&page=${count < option.value ? value - 1 : value}`;
    const { data: responseData } = await WalletService.getWalletEntries(filter);
    if (responseData.status === Loading.SUCCESS) setPageData(responseData.data);
    else setPageData(entries);
    setLoadingPagination(false);
  }


  return (
    <div className="main-table py-4 px-md-2 px-0 d-flex flex-column wallet-bg">
      {walletTopUpModal}
      {walletWithdrawalModal}
      <div className="d-flex flex-column align-items-md-start align-items-center wallet-action ">
        <div className="container-fluid   order-md-2 order-1">
          <div className="row">
            <div className="col-sm-6">
              <div className="d-flex flex-column p-3 pb-4">
                <div>
                  <img src={NigeriaFlag} alt="" />
                </div>
                <p className="mt-4 mb-1">Wallet ledger balance</p>
                <p className=" mb-1 balance">{wallet['currency']} {formatAmountToString(wallet['ledger_balance'])}</p>
                <p className="mt-4 mb-1">
                  Available balance: <b>{wallet['currency']} {formatAmountToString(wallet['available_balance'])}</b>
                </p>
              </div>
            </div>
            <div className="col-sm-4 d-flex align-items-center process">
              <div className="d-flex justify-content-between align-items-center w-100">
                <div>
                  <div onClick={() => setTopUpRender(true)} className="opt-bg">
                    <img src={AddSvg} id="open-modal-btn" alt="" />
                  </div>
                  <p>Add Funds</p>
                </div>
                {orgWalletConfig && orgWalletConfig.payout_organisation_wallet &&
                  <div>
                    <div onClick={() => setFundWithdrawRender(true)} className="opt-bg">
                      <img src={WithdrawSvg} id="open-modal-btn2" alt="" />
                    </div>
                    <p>Withdraw</p>
                  </div>
                }
                {orgWalletConfig && orgWalletConfig.enable_funds_transfer &&
                  <div className="d-none">
                    <div className="opt-bg">
                      <img src={TransferSvg} id="open-modal-btn3" alt="" />
                    </div>
                    <p>Transfer</p>
                  </div>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-2">
        <div className="input-group mb-2 wd-40p">
          <input className="form-control" value={filterInput} onChange={e => setFilterInput(e.target.value)} type="text"
            placeholder="Search transactions..." />
        </div>
        <div className="w-100 table-md-bod overflow-x-auto">
          <PaginationTable columns={columns} filterData={filterInput} size={pageSize.value} count={count}
            loading={loadingPagination} index={page} data={pageData}
            setPage={value => fetchPagination(pageSize, value)} handleClick={() => false} />
        </div>
      </div>
    </div>
  );
}

export default Body;
