import {Fragment, useState} from "react";
import {useDispatch} from "react-redux";
import { useForm } from "react-hook-form";
import {NavLink, useNavigate} from "react-router-dom";
import {useGoogleLogin} from "@react-oauth/google";
import toast from "react-hot-toast";


import GoogleSVG from "../../../assets/images/svg/google.svg";
import * as AuthConstants from "../../../store/constants/auth.constants";
import AuthLayout from "../../../components/layouts/AuthLayout";
import ActionCreator from "../../../utils/helpers/actionCreator";
import DynamicFields from "../../../components/dynamicFields/DynamicFields";
import AuthService from "../../../utils/services/auth.service";
import EncryptHelper from "../../../utils/helpers/encryptHelper";
import TokenHelper from "../../../utils/helpers/tokenHelper";
import {InputValidator, Loading, UserType} from "../../../utils/helpers/constants";
import {setLoggedInUser} from "../../../store/actions/auth.action";
import {emailValidator} from "../../../utils/services/validator.service";


const Body = ({authPolicy, passwordPolicy, dynamicFields, properties}) => {

  const dispatch = useDispatch(), navigate = useNavigate(), isLoggedIn = TokenHelper.checkIfLoggedIn();
  const ifEmail = authPolicy['dynamicAuthOptions'].some(each => each === 'email');
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingSocialAuth, setLoadingSocialAuth] = useState(false);
  const [passwordType, updatePasswordType] = useState("password");
  const ifEmailAndInvalid = !email || !password || emailError;


  const useFormHook = useForm({
    defaultValues: {},
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
  });

  const setPasswordValue = value => {
    let pattern = '', newValue = value.slice(-1), paramsValue = '';
    if (passwordPolicy['allowUppercase']) pattern = pattern + '(?=.*?[A-Z])';
    if (passwordPolicy['allowLowercase']) pattern = pattern + '(?=.*?[a-z])';
    if (passwordPolicy['allowNumeric']) pattern = pattern + '(?=.*?[0-9])';
    if (passwordPolicy['allowSymbols']) pattern = pattern + `(?=.*?[${passwordPolicy['symbolsMatch']}])`;
    if (passwordPolicy['allowSymbols']) pattern = pattern + `.{${passwordPolicy['min_length']},${passwordPolicy['max_length']}}`;


    let patternRegex = new RegExp(pattern, 'g');
    let symbolsRegex = new RegExp(passwordPolicy['symbolsMatch'], 'g');

    if (passwordPolicy['allowSymbols'] && symbolsRegex.test(newValue)) paramsValue = value;
    if (isNaN(newValue) && passwordPolicy['allowNumeric']) paramsValue = value;
    if (passwordPolicy['allowUppercase'] && newValue === newValue.toUpperCase()) paramsValue = value;
    else if (passwordPolicy['allowLowercase'] && newValue === newValue.toLowerCase()) paramsValue = value;

    setPassword(value);

  }

  const togglePasswordField = type => {
    updatePasswordType(type);
    document.getElementById('password').type = type;
  }
  const handleFieldValidation = (data) => {
    const validatedEmail = emailValidator(data);
    setEmail(validatedEmail.validateEmail);
    validatedEmail.validState === InputValidator.VALID ? setEmailError(false) : setEmailError(true);
  }

  const renderDynamicFields = dynamicFields.map((eachField, index) => (
    <DynamicFields divClass="form-group text-start pos-relative" inputClass="form-control" key={index}
      formControl={eachField} formHook={useFormHook}/>
  ));

  const handleLogin = async () => {
    setLoadingStatus(true);
    let payload = { email: email.toLowerCase(), password, dynamicFields: { ...useFormHook.getValues()}};
    const {data: responseData} = await AuthService.login(payload, 'CUSTOM_USER');
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      const user = EncryptHelper.jwtDecode(responseData.data.access);
      dispatch(ActionCreator(AuthConstants.LOGIN_SUCCESS, !isLoggedIn));
      await dispatch(setLoggedInUser(responseData.data.access, user));
      navigate('/');
    }
    setLoadingStatus(false);
  };

  const googleCallbackResponse = useGoogleLogin({
    onSuccess: response => handleGoogleAuth({token: response['access_token'], platform: 'GOOGLE'}),
  });

  const handleGoogleAuth = async (payload) => {
    setLoadingSocialAuth(true);
    const {data: responseData} = await AuthService.validateOAuth(payload, UserType.CUSTOM_USER);
    await setLoadingSocialAuth(false);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      const user = EncryptHelper.jwtDecode(responseData.data.access);
      dispatch(ActionCreator(AuthConstants.LOGIN_SUCCESS, !isLoggedIn));
      await dispatch(setLoggedInUser(responseData.data.access, user));
      navigate('/');
    }
    setLoadingSocialAuth(false)
  }

  return (
    <Fragment>
      <AuthLayout favicon={properties['favicon']} logo={properties['logo']}>
        <form className="login-form">
          <h5 className="text-start text-center">Log in to Your Account</h5>
          {ifEmail &&
            <div className="form-group text-start">
              <label>Email </label> <span style={{color: "red"}}>*</span>
              <input className={`form-control ${emailError && 'is-invalid'}`} value={email}
                     placeholder="Enter your email"
                     type="text" onChange={event => handleFieldValidation(event.target.value)}/>
            </div>
          }
          <div className="form-group text-start pos-relative">
            <label>Password</label>
            <input className="form-control" value={password} placeholder="Enter your password"
                   minLength={Number(passwordPolicy['min_length'])}
                   maxLength={Number(passwordPolicy['max_length'])}
                   type="password" id="password" onChange={e => setPasswordValue(e.target.value)}/>
            <div className="eye-right">
              {passwordType === "password" ?
                <i onClick={() => togglePasswordField("text")} className="zmdi zmdi-eye"/> :
                <i onClick={() => togglePasswordField("password")} className="zmdi zmdi-eye-off"/>
              }
            </div>
          </div>
          {renderDynamicFields}
          <button className="log-in mb-1" type="button" disabled={ifEmail ? ifEmailAndInvalid : !password}
            onClick={useFormHook.handleSubmit(handleLogin)}>
            Log In
            {loadingStatus && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
          </button>

          {authPolicy['socialAuth'] &&
            <Fragment>
              {authPolicy['socialAuthOptions']['googleAuth'] &&
                <>
                  <p id="or" className="mt-3">Or</p>
                  <button className="log-in-google" type="button"
                          onClick={() => googleCallbackResponse()}>
                    <span><img src={GoogleSVG} alt="google-icon"/><p>Log in with google</p></span>
                    {loadingSocialAuth && <>&nbsp;<i
                      className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin ms-2"/></>}
                  </button>
                </>
              }
            </Fragment>
          }
        </form>
        <div className="mt-4 text-end">
          <NavLink to="/auth/forgot-password">Forgot password?</NavLink>
          <div className="text-center mt-4">Don't have an account?
            <NavLink className="signup" to="/auth/register"> Sign up</NavLink>.
          </div>
        </div>
      </AuthLayout>
    </Fragment>
  )
}

export default Body;
