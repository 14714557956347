import {NavLink, useNavigate} from "react-router-dom";
import PlusSvg from "../../../../assets/images/svg/plus.svg";


const Body = ({fleet}) => {

	const navigate = useNavigate();
	const vehicleInfo = (vehicle) => navigate(`/profile/registered-vehicle/${vehicle['dynamic_fields']['registration_number']}`, {state: vehicle});


	return (
		<div className="settings-main p-4">
			<NavLink to="/register-vehicle" className="main-btn btn d-flex w-50 justify-content-center">
				<img className="profile-main-plus me-2" src={PlusSvg} alt="register"/> Register new vehicle
			</NavLink>
			<div className="mt-5">
				<div className="w-100 table-responsive table-bod overflow-x-auto">
					<table className="table profile-table">
						<thead>
						<tr>
							<th>Reg No</th>
							<th>Category Size</th>
							<th>Tonnage</th>
							<th>Status</th>
							<th>Action</th>
						</tr>
						</thead>
						<tbody>
						{fleet.map((item, index) =>
							<tr key={index}>
								<td>{item['dynamic_fields']['registration_number']}</td>
								<td>{item['category_size']['category']}</td>
								<td>{item['category_size']['tonnage']} tonnes</td>
								<td>
									{item['is_active'] ? <span className="badge bg-success mg-r-2">Approved</span> :
										<span className="badge bg-warning mg-r-2">Pending approval</span>
									}
									{item['driver'] ? <span className="badge bg-success mg-r-2">Assigned Driver</span> :
										<span className="badge bg-warning mg-r-2">No Driver assigned</span>
									}
								</td>
								<td>
									<button onClick={() => vehicleInfo(item)}
													className="main-btn btn btn-sm d-flex justify-content-center">View More
									</button>
								</td>
							</tr>
						)}
						</tbody>
					</table>
				</div>
			</div>
		</div>
	)
};

export default Body;
