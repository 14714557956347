import GoogleMapReact from "google-map-react";
import MapSvg from "../../assets/images/svg/map.svg";

const MapHook = ({source, destination}) => {

  const defaultProps = {center: {lat: 0, lng: 0}, zoom: 12};
  const createMapOptions = () => {
    return {
      panControl: false,
      streetViewControl: true
    }
  }

  const handleApiLoaded = (map, maps) => {
    let start = {lat: source.latitude, lng: source.longitude};
    let end = {lat: destination.latitude, lng: destination.longitude};
    let waypoints = [];
    if(destination && destination.stops && destination.stops.stopovers){
      for (let stopover of destination.stops.stopovers) {
        let location = new maps.LatLng(stopover.address.latitude, stopover.address.longitude);
        let point = {location, stopover: true};
        waypoints.push(point);
      }
    }


    let routeMode = {travelMode: 'DRIVING', origin: start, destination: end, waypoints};
    let directionsService = new maps.DirectionsService();
    let directionsRenderer = new maps.DirectionsRenderer({map: map, suppressMarkers: true});
    directionsRenderer.setMap(map);
    directionsService.route(routeMode, (DirectionsResult, DirectionsStatus) => {

      if (DirectionsStatus === 'OK') {
        let route = DirectionsResult.routes[0].legs[0];
        createMarker(maps, map, route.start_location, 'Pick Up');
        createMarker(maps, map, route.end_location, 'Drop off');
        /*for (let [index, stopover] of destination.stops.stopovers.entries()) {
          let text = `Drop off ${index + 1}`;
          createMarker(maps, map, {lat: stopover.address.latitude, lng: stopover.address.longitude}, text);
        }*/
        directionsRenderer.setDirections(DirectionsResult);
      }
    });
  }

  const createMarker = (maps, map, position, text) => {
    let label = {text, position: 'absolute', top: '30px', textAlign: 'center', fontSize: "12px", fontWeight: "500"};
    return new maps.Marker({position: position, map: map, icon: MapSvg, label});
  }


  return (
    <GoogleMapReact bootstrapURLKeys={{key: "AIzaSyCN3zZS2207lNVlL8YvPqPr2JrVSEEUx_A"}}
                    yesIWantToUseGoogleMapApiInternals
                    options={createMapOptions}
                    defaultCenter={defaultProps.center}
                    layerTypes={['TrafficLayer', 'TransitLayer']}
                    defaultZoom={defaultProps.zoom}
                    onGoogleApiLoaded={({map, maps}) => handleApiLoaded(map, maps)}
    >
      {/*<MapsMarkerHook lat={source.latitude} lng={source.longitude} text="Pickup"/>*/}
      {/*<MapsMarkerHook lat={destination.latitude} lng={destination.longitude} text="DropOff"/>*/}
    </GoogleMapReact>
  );
}

export default MapHook;
