import {Fragment, useState} from "react";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import {useNavigate} from "react-router-dom";

import ArrowBackSvg from "../../../assets/images/svg/arrow-back.svg";
import * as ProfileConstants from "../../../store/constants/profile.constants";
import ActionCreator from "../../../utils/helpers/actionCreator";
import DynamicFields from "../../../components/dynamicFields/DynamicFields";
import {getProfile} from "../../../store/actions/profile.action";
import ProfileService from "../../../utils/services/profile.service";
import {DomStateType, InputType, Loading} from "../../../utils/helpers/constants";

const Body = ({dynamicFields}) => {

  const dispatch = useDispatch(), navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const goBack = () => navigate(-1);
 
  const useFormHook = useForm({
    defaultValues: {},
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
  });

  const setDriverProfile = driverProfile => {
    let newState = {type: DomStateType.DRIVER, id: driverProfile.id, driverProfile};
    dispatch(ActionCreator(ProfileConstants.SET_DOM_PROFILE, newState));
  }

  const onboardDriver = async () => {
    setLoading(true);
    const payload = { ...useFormHook.getValues() };
    const { data: responseData } = await ProfileService.createDriverProfile({ dynamic_fields: payload });
    if (responseData.status !== Loading.SUCCESS) {
      const message = (responseData && responseData.message) ? responseData.message : "You cannot onboard your driver profile at the moment, please try again later";
      toast.error(message);
    }
    else {
      await dispatch(getProfile());
      await setDriverProfile(responseData.data);
      toast.success(responseData.message);
      await navigate('/register-vehicle');
    }
    setLoading(false);
  }


  const renderDynamicFields = dynamicFields.map((eachField, index) => (
    <Fragment key={index}>
      {(eachField.type !== InputType.FILE && !eachField['hidden_field']) &&
        <DynamicFields divClass="custom position-relative w-100 mg-t-15 mb-3" key={index}
          formControl={eachField} formHook={useFormHook}/>
      }
    </Fragment>
  ));

  return (
    <div className="booking py-4 bg-white d-flex flex-column">
      <p className="mb-0 booking-direct fs-18 pointer-event" onClick={goBack}>
        <img src={ArrowBackSvg} alt="Arrow-back"/> &nbsp; Go Back
      </p>
      <div className="pb-5 pt-3">
        <div className="booking-main d-flex flex-column">
          <h3 className="booking-title pt-0">Set up your driver profile</h3>
          <form className="booking-form d-flex mt-4 flex-column align-items-start">
            {renderDynamicFields}
            <button className="btn main-btn mt-4" onClick={useFormHook.handleSubmit(onboardDriver)} type="button">
              Set up profile
              {loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
            </button>
          </form>
        </div>
      </div>
    </div>
  );

}

export default Body;
