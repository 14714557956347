import {Fragment, useState} from "react";
import {useDispatch} from "react-redux";
import moment from "moment/moment";

import Blank from "../../../../assets/images/jpg/blank.jpg";
import {toTitleCase} from "../../../../utils/helpers/logicHelper";
import EditDriverProfileModal from "../../../../components/modals/EditDriverProfileModal";
import DynamicFileUploadModal from "../../../../components/modals/DynamicFileUploadModal";
import {getProfile} from "../../../../store/actions/profile.action";
import {DynamicFileUploadOption, InputType} from "../../../../utils/helpers/constants";

const Body = ({profile, dynamicFields}) => {
  const dispatch = useDispatch();
  const [driverDocument, setDriverDocument] = useState(null);
  const [editDriverRender, setEditDriverRender] = useState(false);
  const [uploadDriverDocumentRender, setUploadDriverDocumentRender] = useState(false);

  const documentUpload = dynamicFields.filter(each => (each.type === 'file' && !each['hidden_field']));

  const onSuccessfulUpload = async () => dispatch(getProfile());
  const openUploadDriverDocumentRender = file => {
    setDriverDocument(file);
    setUploadDriverDocumentRender(true);
  }
  const changeEditDriverProfileRender = () => setEditDriverRender(false);
  const changeUploadDriverDocumentRender = () => setUploadDriverDocumentRender(false);


  const editDriverProfileModal = editDriverRender &&
    <EditDriverProfileModal onchange={changeEditDriverProfileRender} props={profile} dynamicFields={dynamicFields}/>;

  const uploadDriverDocumentModal = uploadDriverDocumentRender &&
    <DynamicFileUploadModal onchange={changeUploadDriverDocumentRender} type={DynamicFileUploadOption.DRIVER}
                            props={profile} dynamicFields={driverDocument} onSuccessResponse={onSuccessfulUpload}/>;


  return (
    <div className="settings-main p-4">
      {editDriverProfileModal}
      {uploadDriverDocumentModal}
      <div className="d-flex justify-content-end">
        <button className="btn primary-btn text-decoration-none mg-r-3 mb-2"
                onClick={() => setEditDriverRender(true)}>Edit Driver Profile
        </button>
      </div>

      <div className="row mt-4">
        <div className="col-12 mb-3">
          <div className="main-card profile h-100">
            <div className="profile-header p-3">
              <div className="d-flex justify-content-between">
                <div>
                  <p className="fs-20 my-auto">
                    {profile['dynamic_fields']['license_number']}
                  </p>
                </div>
                {profile['is_active']
                  ? <span
                    className="badge bg-success font-weight-light tx-15 my-auto py-1 px-0 wd-40p-f wd-sm-30p-f">Approved</span>
                  : <span className="badge bg-warning font-weight-light tx-15 my-auto py-1 px-0 wd-40p-f wd-sm-40p-f">Pending approval</span>
                }
              </div>
            </div>
            <div className="profile-body p-3">
              <div className="row">
                {dynamicFields && dynamicFields.map(item => (
                  <Fragment key={item.key}>
                    {(item.key in profile['dynamic_fields'] && item.type !== InputType.FILE) &&
                      <div className="col-md-6 mb-3">
                        <p className="mb-0 profile-info-name text-truncate">{toTitleCase(item.key)}</p>
                        <span className="profile-info-deet text-truncate">
                          {(item.type !== 'date' && profile['dynamic_fields'][item.key]) ? profile['dynamic_fields'][item.key]
                            : (item.type === 'date' && moment(profile['dynamic_fields'][item.key]).isValid()) ? moment(profile['dynamic_fields'][item.key]).format('DD-MMMM-YYYY')
                              : "-"
                          }
                        </span>
                      </div>
                    }
                  </Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>

      {(documentUpload && documentUpload.length > 0) &&
        <div className="main-card profile mt-5">
          <div className="profile-header p-3"><h5>Driver Documents</h5></div>
          <div className="container-fluid px-0">
            <div className="row p-3">
              {documentUpload.map(item => (
                <div key={item.key} className="col-xl-6 mb-3">
                  <div className="profile-vehicle-image overflow-hidden h-100">
                    <div className="card-body bg-primary py-2 mb-0">
                      <div className="d-flex justify-content-between">
                        <span className="card-text">{toTitleCase(item.key)}</span>
                        <i className="zmdi zmdi-plus-circle fs-3 text-white"
                           onClick={() => openUploadDriverDocumentRender(item)}/>
                      </div>
                    </div>
                    <img src={profile['dynamic_fields'][item.key] ?? Blank} alt={toTitleCase(item.key)}
                         className="mx-auto d-block w-100"/>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      }

    </div>
  )
}

export default Body;
