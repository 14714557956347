import ActionCreator from "../../utils/helpers/actionCreator";
import * as WalletConstants from "../constants/wallet.constants";
import WalletService from "../../utils/services/wallet.service";

export const getUserWallet = () => async dispatch => {
  try {
    dispatch(ActionCreator(WalletConstants.FETCH_WALLET_REQUEST));
    const {data: responseData} = await WalletService.getWallet();
    dispatch(ActionCreator(WalletConstants.FETCH_WALLET_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(WalletConstants.FETCH_WALLET_FAILURE));
  }
};

export const getUserWalletEntries = filter => async dispatch => {
  try {
    dispatch(ActionCreator(WalletConstants.FETCH_WALLET_ENTRIES_REQUEST));
    const {data: responseData} = await WalletService.getWalletEntries(filter);
    dispatch(ActionCreator(WalletConstants.FETCH_WALLET_ENTRIES_SUCCESS, responseData));
  } catch (error) {
    dispatch(ActionCreator(WalletConstants.FETCH_WALLET_ENTRIES_FAILURE));
  }
};

export const getBanks = () => async dispatch => {
  try {
    dispatch(ActionCreator(WalletConstants.FETCH_BANKS_REQUEST));
    const {data: responseData} = await WalletService.getBanks();
    dispatch(ActionCreator(WalletConstants.FETCH_BANKS_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(WalletConstants.FETCH_BANKS_FAILURE));
  }
};

export const getBankAccounts = () => async dispatch => {
  try {
    dispatch(ActionCreator(WalletConstants.FETCH_BANK_ACCOUNT_REQUEST));
    const {data: responseData} = await WalletService.getBankAccount();
    dispatch(ActionCreator(WalletConstants.FETCH_BANK_ACCOUNT_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(WalletConstants.FETCH_BANK_ACCOUNT_FAILURE));
  }
};