export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const FETCH_AUTH_POLICY_REQUEST = "FETCH_AUTH_POLICY_REQUEST";
export const FETCH_AUTH_POLICY_SUCCESS = "FETCH_AUTH_POLICY_SUCCESS";
export const FETCH_AUTH_POLICY_FAILURE = "FETCH_AUTH_POLICY_FAILURE";

export const SET_ORGANISATION_HEADER = "SET_ORGANISATION_HEADER";
export const SET_LOGGED_IN_USER = "SET_LOGGED_IN_USER";
export const LOG_OUT = "LOG_OUT";
