import {Fragment, useState} from "react";
import {Navigate, NavLink} from "react-router-dom";
import toast from "react-hot-toast";

import AuthLayout from "../../../components/layouts/AuthLayout";
import TokenHelper from "../../../utils/helpers/tokenHelper";
import AuthService from "../../../utils/services/auth.service";
import {emailValidator} from "../../../utils/services/validator.service";
import {InputValidator, Loading, UserType} from "../../../utils/helpers/constants";

const Body = ({properties}) => {
	const isLoggedIn = TokenHelper.checkIfLoggedIn();
	const [email, setEmail] = useState("");
	const [loading, setLoading] = useState(false);
	const [emailError, setEmailError] = useState(false);

	const handleFieldValidation = (data) => {
		const validatedEmail = emailValidator(data);
		setEmail(validatedEmail.validateEmail);
		validatedEmail.validState === InputValidator.VALID ? setEmailError(false) : setEmailError(true);
	}

	const handleForgetPassword = async() => {
		setLoading(true);
		const payload = {email: email.toLowerCase(), callbackUrl: `${window.location.origin}/auth/reset-password`}
		const {data: responseData} = await AuthService.forgotPassword(payload, UserType.CUSTOM_USER);
		if(responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
		else toast.success(responseData.message);
		setLoading(false);
	}

	if(isLoggedIn) return <Navigate to={{pathname: "/"}}/>;

	return (
		<Fragment>
			<AuthLayout favicon={properties['favicon']} logo={properties['logo']}>
			<form className="login-form">
				<h5 className="text-start mb-2">Forgot Password</h5>
				<p className="mb-4 text-muted tx-13 ms-0 text-start">
					Enter your email to validate that your access exist.
				</p>

				<div className="form-group text-start">
					<label>Email </label> <span className="text-danger">*</span>
					<input className={`form-control ${emailError && 'is-invalid'}`} value={email}
								 placeholder="Enter your email"
								 type="text" onChange={event => handleFieldValidation(event.target.value)}/>
				</div>

				<button className="log-in" type="button" disabled={!email}
								onClick={handleForgetPassword}>Request Reset Link
						{loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
				</button>
			</form>
			<div className="my-3 text-end">
				<div className="text-center mb-3">Already Have an account?
					<NavLink className="signup" to="/auth/login"> Login</NavLink>.
				</div>
			</div>
			</AuthLayout>
		</Fragment>
	)
}
export default Body;
