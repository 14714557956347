import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import PageLayout from "../../../../components/layouts/PageLayout";
import {getProfile} from "../../../../store/actions/profile.action";
import {Loading, menuType} from "../../../../utils/helpers/constants";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";

const EditProfile = () => {
  const dispatch = useDispatch();
  const {profile, configs} = useSelector(({profileReducer, configsReducer}) => ({
    profile: profileReducer,
    configs: configsReducer
  }))
  const loadState = profile.loading !== Loading.SUCCESS;

  useEffect(() => {
    if (profile.loading !== Loading.SUCCESS) dispatch(getProfile());
  }, []);
  

  return (
    <PageLayout active={menuType.PROFILE}>
      <InnerMenuLayout active={menuType.EDIT_PROFILE} type="PROFILE">
        {loadState ? <SphereLoader/> :
          <Body profile={profile.userData} domState={profile.domState} services={configs.organisationServices} business={profile.domState?.business}/>}
      </InnerMenuLayout>
    </PageLayout>
  )
}

export default EditProfile
