import {useState} from "react";
import {useDispatch} from "react-redux";
import {NavLink, useNavigate} from "react-router-dom";
import {ActionPromptOption, DomStateType} from "../../../../../utils/helpers/constants";
import EditWarehouseModal from "../../../../../components/modals/EditWarehouseModal";
import ActionPromptModal from "../../../../../components/modals/ActionPromptModal";
import {getWarehouses} from "../../../../../store/actions/configs.action";

const Body = ({domState, warehouse}) => {
  const dispatch = useDispatch(), navigate = useNavigate();
  const [editWarehouseRender, setEditWarehouseRender] = useState(false);
  const [deleteWarehouseRender, setDeleteWarehouseRender] = useState(false);


  const changeEditWarehouseRenderStatus = () => setEditWarehouseRender(false);
  const changeDeleteWarehouseRender = () => setDeleteWarehouseRender(false);
  const deletedWarehouseResponse = async () => {
    dispatch(getWarehouses())
    navigate("/profile/warehouse");
  }


  const editWarehouseModal = editWarehouseRender &&
    <EditWarehouseModal warehouse={warehouse} onchange={changeEditWarehouseRenderStatus}/>;

  const deleteWarehouseModal = deleteWarehouseRender &&
    <ActionPromptModal onchange={changeDeleteWarehouseRender} type={ActionPromptOption.DELETE_WAREHOUSE}
                       props={warehouse} initValue={warehouse.reference} name={`${warehouse.name}`}
                       setDeleteSuccess={deletedWarehouseResponse}/>;


  return (
    <div className="settings-main p-4">
      {editWarehouseModal}
      {deleteWarehouseModal}
      <div className="d-flex align-items-center">
        <NavLink to="/profile/warehouse" className="text-dark" role="button">
          <i className="zmdi zmdi-chevron-left fs-3 mt-1"/>
        </NavLink>
        <p className="mb-0 ms-3 fs-5">Warehouse Information</p>
      </div>
      {(domState.type === DomStateType.BUSINESS && domState.business && domState.business.type.toLowerCase() === 'owner') &&
        <div className="d-flex justify-content-end">
          <button className="btn primary-btn trip-btn text-decoration-none mg-r-2 mb-2"
                  onClick={() => setEditWarehouseRender(true)}>Edit Warehouse
          </button>
          <button className="btn bg-danger trip-btn text-white text-decoration-none mg-r-2 mb-2"
                  onClick={() => setDeleteWarehouseRender(true)}>
            Delete Warehouse
          </button>
        </div>
      }
      <div className="mt-4 container-fluid px-0">
        <div className="row">
          <div className="col-12 mb-5">
            <div className="main-card profile h-100">
              <div className="profile-header p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="fs-20 my-auto">Business</p>
                    <p className="profile-info-deet text-muted mb-0">{warehouse['administrative']['name']}</p>
                  </div>
                </div>
              </div>
              <div className="profile-body p-3">
                <div className="d-flex justify-content-between">
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">Business Name</p>
                    <span className="profile-info-deet text-truncate">{warehouse['administrative']['name']}</span>
                  </div>
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">Business Reference</p>
                    <span className="profile-info-deet text-truncate">{warehouse['administrative']['business']}</span>
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">Business Owner</p>
                    <span className="profile-info-deet text-truncate">
											{(warehouse['administrative'] && warehouse['administrative']['created_by']) ?
                        warehouse['administrative']['created_by']
                        : "-"
                      }
										</span>
                  </div>
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">Business Website</p>
                    <span className="profile-info-deet text-truncate">
											{(warehouse['administrative'] && warehouse['administrative']['url']) ?
                        <a href={warehouse['administrative']['url']} target="_blank" rel="noreferrer">
                          {warehouse['administrative']['url']}
                        </a>
                        : "-"
                      }
										</span>
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">Business Email</p>
                    <span className="profile-info-deet text-truncate">
											{(warehouse['administrative'] && warehouse['administrative']['created_by_email']) ?
                        <a href={`mailto:${warehouse['administrative']['created_by_email']}`}>
                          {warehouse['administrative']['created_by_email']}
                        </a> : "-"
                      }
										</span>
                  </div>
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">Business State</p>
                    <span className="profile-info-deet text-truncate">
											{(warehouse['administrative'] && warehouse['administrative']['state']) ?
                        warehouse['administrative']['state'] : "-"
                      }
										</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <div className="main-card profile h-100">
              <div className="profile-header p-3">
                <div className="d-flex justify-content-between">
                  <div>
                    <p className="fs-20 my-auto">Warehouse</p>
                    <p className="profile-info-deet text-muted mb-0">{warehouse['name']}</p>
                  </div>
                </div>
              </div>
              <div className="profile-body p-3">
                <div className="d-flex justify-content-between">
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">Warehouse Name</p>
                    <span className="profile-info-deet text-truncate">{warehouse['name']}</span>
                  </div>
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">Warehouse Reference</p>
                    <span className="profile-info-deet text-truncate">{warehouse['reference']}</span>
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">Address</p>
                    <span className="profile-info-deet text-truncate">{warehouse['location']['address']}</span>
                  </div>
                </div>
                <div className="mt-3 d-flex justify-content-between">
                  <div className="wd-40p-f wd-sm-30p-f">
                    <p className="mb-0 profile-info-name text-truncate">State</p>
                    <span className="profile-info-deet text-truncate">{warehouse['location']['state']}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}

export default Body;
