import ActionCreator from "../../utils/helpers/actionCreator";
import * as ProfileConstants from "../constants/profile.constants";
import * as ConfigsConstants from "../constants/configs.constants";
import ProfileService from "../../utils/services/profile.service";
import ConfigsService from "../../utils/services/configs.service";
import TokenHelper from "../../utils/helpers/tokenHelper";
import {UserType} from "../../utils/helpers/constants";
import {loadingApp} from "./component.action";

export const getProfile = () => async dispatch => {
  try {
    dispatch(loadingApp(true));
    dispatch(ActionCreator(ProfileConstants.FETCH_PROFILE_REQUEST));
    let organisation = TokenHelper.getSelectedOrg();
    const getResources = [
      ProfileService.getCustomUserProfile(), ProfileService.getOrganisation(organisation),
      ProfileService.getCustomUserBusinesses(), ConfigsService.getOrgService(organisation),
      ConfigsService.getNotifications(UserType.CUSTOM_USER, "")
    ];
    await Promise.all(getResources).then(async response => {
      let driverResponse, driver, businesses = [];
      let profile = response[0]['data']['data'];
      let organisation = response[1]['data']['data'];
      if (profile['is_business'] || profile['is_account_manager']) businesses = await response[2]['data']['data'];
      if ((profile['is_business'] || profile['is_account_manager']) && response[2]['data']['data'].length > 0) await TokenHelper.saveSelectedBusiness(businesses[0]['reference']);
      if (profile['is_driver']) driverResponse = await ProfileService.getCustomDriverProfile();
      if (profile['is_driver']) driver = driverResponse.data.data;
      let responseData = {profile, organisation, driver, businesses};
      dispatch(ActionCreator(ProfileConstants.FETCH_PROFILE_SUCCESS, responseData));
      dispatch(ActionCreator(ConfigsConstants.FETCH_ORGANISATION_SERVICES_SUCCESS, response[3]['data']['data']));
      dispatch(ActionCreator(ConfigsConstants.FETCH_NOTIFICATIONS_SUCCESS, response[4]['data']));
    });
    dispatch(loadingApp(false));
  } catch (error) {
    dispatch(ActionCreator(ProfileConstants.FETCH_PROFILE_FAILURE));
  }
};

export const getOverview = () => async dispatch => {
  try {
    dispatch(ActionCreator(ProfileConstants.FETCH_OVERVIEW_REQUEST));
    const {data: responseData} = await ProfileService.getOverview();
    await dispatch(ActionCreator(ProfileConstants.FETCH_OVERVIEW_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(ProfileConstants.FETCH_OVERVIEW_FAILURE));
  }
}
