export const FETCH_NOTIFICATIONS_REQUEST = "FETCH_NOTIFICATIONS_REQUEST";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_FAILURE = "FETCH_NOTIFICATIONS_FAILURE";

export const FETCH_ALL_NOTIFICATION_MATRICES_REQUEST = "FETCH_ALL_NOTIFICATION_MATRICES_REQUEST";
export const FETCH_ALL_NOTIFICATION_MATRICES_SUCCESS = "FETCH_ALL_NOTIFICATION_MATRICES_SUCCESS";
export const FETCH_ALL_NOTIFICATION_MATRICES_FAILURE = "FETCH_ALL_NOTIFICATION_MATRICES_FAILURE";

export const FETCH_ORGANISATION_SERVICES_REQUEST = "FETCH_ORGANISATION_SERVICES_REQUEST";
export const FETCH_ORGANISATION_SERVICES_SUCCESS = "FETCH_ORGANISATION_SERVICES_SUCCESS";
export const FETCH_ORGANISATION_SERVICES_FAILURE = "FETCH_ORGANISATION_SERVICES_FAILURE";

export const FETCH_WAREHOUSES_REQUEST = "FETCH_WAREHOUSES_REQUEST";
export const FETCH_WAREHOUSES_SUCCESS = "FETCH_WAREHOUSES_SUCCESS";
export const FETCH_WAREHOUSES_FAILURE = "FETCH_WAREHOUSES_FAILURE";

export let FETCH_INTEGRATION_KEYS_REQUEST = "FETCH_INTEGRATION_KEYS_REQUEST";
export let FETCH_INTEGRATION_KEYS_SUCCESS = "FETCH_INTEGRATION_KEYS_SUCCESS";
export let FETCH_INTEGRATION_KEYS_FAILURE = "FETCH_INTEGRATION_KEYS_FAILURE";

