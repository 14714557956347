import * as AuthConstants from "../constants/auth.constants";
import {Loading} from "../../utils/helpers/constants";
import {handleDynamicAuth} from "../../utils/helpers/logicHelper";

const initialState = {
  userId: "",
  isAuthenticated: false,
  loadingPolicies: "",
  organisation: "",
  dynamicFields: [],
  passwordPolicy: {},
  authenticationPolicy: {},
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case AuthConstants.LOGIN_REQUEST:
      return {...state, loginStatus: Loading.FETCHING};

    case AuthConstants.LOGIN_SUCCESS:
      return {...state, userId: action.payload.id};

    case AuthConstants.LOGIN_FAILURE:
      return {...state, loginStatus: Loading.ERROR};

    case AuthConstants.FETCH_AUTH_POLICY_REQUEST:
      return {...state, loadingPolicies: Loading.FETCHING};

    case AuthConstants.FETCH_AUTH_POLICY_SUCCESS:
      let properties = action.payload['properties'];
      let passwordPolicy = action.payload['password_policy'][0];
      let authenticationPolicy = action.payload['authentication_policy'][0];
      let dynamicKeys = [...action.payload['authentication_policy'][0]['dynamicAuthOptions']];
      let dynamicFields = handleDynamicAuth(action.payload['dynamic_fields'], dynamicKeys);
      return {
        ...state,
        loadingPolicies: Loading.SUCCESS,
        passwordPolicy,
        dynamicFields,
        authenticationPolicy,
        properties
      };

    case AuthConstants.FETCH_AUTH_POLICY_FAILURE:
      return {...state, loadingPolicies: Loading.ERROR};

    case AuthConstants.SET_ORGANISATION_HEADER:
      return {...state, organisation: action.payload};

    case AuthConstants.SET_LOGGED_IN_USER:
      return {...state, isAuthenticated: true};

    case 'RESET_STATE':
      return initialState;

    default:
      return state;
  }
};

export default authReducer;
