import {useEffect} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import {Loading, menuType} from "../../../../utils/helpers/constants";
import Body from "./Body";
import PageLayout from "../../../../components/layouts/PageLayout";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import {getAllNotificationMatrices} from "../../../../store/actions/configs.action";


const NotificationPreference = () => {
	const dispatch = useDispatch();
	const {configs, profile} = useSelector(({configsReducer, profileReducer}) => ({
		configs: configsReducer,
		profile: profileReducer
	}));
	const loadState = configs.loadingMatrices !== Loading.SUCCESS;

	useEffect(() => {
		batch(() => {
			if(configs.loadingMatrices !== Loading.SUCCESS) dispatch(getAllNotificationMatrices());
		});
	}, []);


	return (
		<PageLayout active={menuType.NOTIFICATION}>
			<InnerMenuLayout active={menuType.NOTIFICATION} type="NOTIFICATION">
				{loadState ? <SphereLoader/> :
					<Body matrices={configs.matrices} userMatrices={profile.userData['notification_matrix']}/>
				}
			</InnerMenuLayout>
		</PageLayout>
	)
}

export default NotificationPreference;
