import {useState, Fragment, useEffect} from "react";
import toast from "react-hot-toast";
import {useDispatch, useSelector} from "react-redux";
import PageLayout from "../../../../components/layouts/PageLayout";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import {copyToClipboard, toTitleCase} from "../../../../utils/helpers/logicHelper";
import {getProfile} from "../../../../store/actions/profile.action";
import ConfigsService from "../../../../utils/services/configs.service";
import {Loading, menuType} from "../../../../utils/helpers/constants";


const ApiIntegration = () => {
  const dispatch = useDispatch();
  const [integrationKeys, setIntegrationKeys] = useState([])
  const [loading, setLoading] = useState(false);
  const [publicKeyVisibility, updatePublicKeyVisibility] = useState("password");
  const [secretKeyVisibility, updateSecretKeyVisibility] = useState("password");
  const profile = useSelector(({profileReducer}) => profileReducer);

  useEffect(() => {
    (!profile.userData.key) ? setIntegrationKeys([]) : setIntegrationKeys(profile.userData.key)
  }, [profile]);

  const toggleKeyVisibilityField = (type, field) => {
    if (field === "SECRET_KEY") {
      updateSecretKeyVisibility(type);
      document.getElementById('secret_key').type = type;
    }
    if (field === "PUBLIC_KEY") {
      updatePublicKeyVisibility(type);
      document.getElementById('public_key').type = type;
    }
  }

  const handleCopy = async data => {
    await copyToClipboard(data.key);
    toast.success(`Successfully copied ${toTitleCase(data.domain)} Key!`);
  }

  const handleGeneratePublicKey = async () => {
    setLoading(true);
    const {data: responseData} = await ConfigsService.generatePublicKey();
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      await dispatch(getProfile());
      toast.success(responseData.message);
    }
    setLoading(false);
  }


  return (
    <PageLayout active={menuType.SETTINGS}>
      <InnerMenuLayout active={menuType.API_INTEGRATION} type="SETTINGS">
        <div className="settings-main">
          <div className="p-4">

            <div className="d-flex justify-content-between">
              <h2 className="main-content-title tx-22 mg-b-5 ">API Integration</h2>
              <div className="">
                <p className="text-secondary text-end fs-6 pointer-event" onClick={handleGeneratePublicKey}>
                  Generate new Encryption Key
                  {loading && <>&nbsp;<i className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin"/></>}
                </p>
              </div>
            </div>
            <span className="mt-4">In order to integrate with our API from your own application, you should first have generated a PUBLIC and SECRET KEY on the truQ dashboard.</span>

            <div className=" h-100 mt-4">
              <div className=" mb-3">
                {integrationKeys.map((item, index) =>
                  <Fragment key={index}>
                    {item.domain === 'public' &&
                      <div className="row row-xs align-items-center mg-b-20 mt-2">
                        <div className="col-md-3">
                          <label className="mg-b-0 fs-6 tx-bold text-black-50">{toTitleCase(item.domain)} key</label>
                        </div>
                        <div className="col-md-9 mg-md-t-0">
                          <div className="input-group has-validation mb-1">
                            <input type="password" id="public_key" className="form-control" value={item.key}
                                   placeholder="Set placeholder for dynamic fields" readOnly/>

                            <span className="input-group-text">
                              {publicKeyVisibility === "password" ?
                                <i onClick={() => toggleKeyVisibilityField("text", "PUBLIC_KEY")}
                                   className="zmdi zmdi-eye fs-5"/> :
                                <i
                                  onClick={() => toggleKeyVisibilityField("password", "PUBLIC_KEY")}
                                  className="zmdi zmdi-eye-off fs-5"
                                />
                              }
                            </span>
                            <span className="input-group-text">
                              <i className="zmdi zmdi-copy fs-5" onClick={() => handleCopy(item)}/>
                            </span>
                          </div>
                        </div>
                      </div>
                    }

                    {item.domain === 'secret' &&
                      <div className="row row-xs align-items-center ">
                        <div className="col-md-3">
                          <label className="mg-b-0 fs-6 tx-bold text-black-50">{toTitleCase(item.domain)} Key</label>
                        </div>
                        <div className="col-md-9 mg-md-t-0">
                          <div className="input-group has-validation mb-1">
                            <input type="password" id="secret_key" className="form-control" value={item.key}
                                   placeholder="Set placeholder for dynamic fields" readOnly/>

                            <span className="input-group-text">
                              {secretKeyVisibility === "password" ?
                                <i onClick={() => toggleKeyVisibilityField("text", "SECRET_KEY")}
                                   className="zmdi zmdi-eye fs-5"/> :
                                <i onClick={() => toggleKeyVisibilityField("password", "SECRET_KEY")}
                                   className="zmdi zmdi-eye-off fs-5"
                                />
                              }
                            </span>
                            <span className="input-group-text">
                              <i className="zmdi zmdi-copy fs-5" onClick={() => handleCopy(item)}/>
                            </span>
                          </div>
                        </div>
                      </div>
                    }
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </InnerMenuLayout>
    </PageLayout>

  )
}

export default ApiIntegration





