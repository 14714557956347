import {useEffect} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";
import Body from "./Body";
import PageLayout from "../../../../../components/layouts/PageLayout";
import SphereLoader from "../../../../../components/loaders/SphereLoader";
import InnerMenuLayout from "../../../../../components/layouts/InnerMenuLayout";
import {getDynamicFields} from "../../../../../store/actions/component.action";
import {Loading, menuType, ServiceType} from "../../../../../utils/helpers/constants";


const VehicleInfo = () => {
  const dispatch = useDispatch(), location = useLocation(), navigate = useNavigate();
  const component = useSelector(({componentReducer}) => (componentReducer));
  const loadState = !component.dynamicFields[ServiceType.FLT] || component.loadingDynamicFields !== Loading.SUCCESS;

  useEffect(() => {
    batch(() => {
      if (!component.dynamicFields[ServiceType.FLT]) dispatch(getDynamicFields(ServiceType.FLT));
      if (location.state === null) navigate('/profile/registered-vehicle');
    });
  }, []);


  return (
    <PageLayout active={menuType.PROFILE}>
      <InnerMenuLayout active={menuType.REGISTERED_VEHICLE} type="PROFILE">
        {loadState ? <SphereLoader/> :
          <Body vehicle={location.state} dynamicFields={component.dynamicFields[ServiceType.FLT]}/>}
      </InnerMenuLayout>
    </PageLayout>
  )
}

export default VehicleInfo;
