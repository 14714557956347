import {Fragment, useState} from "react";
import {Button} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

import TokenHelper from "../../utils/helpers/tokenHelper";
import AuthService from "../../utils/services/auth.service";
import FleetService from "../../utils/services/fleet.service";
import ProfileService from "../../utils/services/profile.service";
import BookingService from "../../utils/services/booking.service";
import ConfigsService from "../../utils/services/configs.service";
import {ActionPromptMap} from "../../utils/helpers/mappers";
import {ActionPromptOption, InputValidator, Loading, UserType} from "../../utils/helpers/constants";
import {emailValidator} from "../../utils/services/validator.service";

const ActionPromptModal = ({onchange, type, props, initValue, setDeleteSuccess, name, findUser}) => {
	const actionProps = ActionPromptMap[type];
	const [value] = useState(initValue);
	const [show, setShow] = useState(true);
	const [user, setUser] = useState({});
	const [searchInput, setSearchInput] = useState("");
	const [errorMsg, setErrorMsg] = useState("");
	const [successMsg, setSuccessMsg] = useState("");
	const [validateInput, setValidateInput] = useState("");
	const [passwordType, updatePasswordType] = useState("password");

	const [loading, setLoading] = useState(false);
	const [errorStatus, setErrorStatus] = useState(false);
	const [successStatus, setSuccessStatus] = useState(false);
	const [passwordValidated, setPasswordValidated] = useState(false);

	const disabledState = validateInput.length === 0;
	const disabledFindUser = !searchInput || emailValidator(searchInput).validState === InputValidator.INVALID;

	const handleClose = () => {
		setShow(false);
		onchange();
	};

	const completeAction = response => {
		resetMessageState("HARD_RESET");
		handleClose();
		setDeleteSuccess(response);
	}

	const togglePasswordField = type => {
		updatePasswordType(type);
		document.getElementById('password').type = type;
	}

	const resetMessageState = () => {
		setTimeout(() => {
			setErrorStatus(false);
			setErrorMsg("");

			setSuccessStatus(false);
			setSuccessMsg("");
		}, 7000)
	}

	const handleValidatePassword = async() => {
		setLoading(true);
		resetMessageState("HARD_RESET");
		const {data: responseData} = await AuthService.validatePassword({password: validateInput}, UserType.CUSTOM_USER);

		if(responseData.status !== Loading.SUCCESS) {
			setErrorStatus(true);
			setErrorMsg("Error, password provided is invalid!");
			resetMessageState();
		} else {
			if(findUser && !passwordValidated) await findUserByEmail();
			else {
				await handleDelete();
				setPasswordValidated(true);
			}

		}

		setLoading(false);
	}

	const findUserByEmail = async() => {
		setLoading(true);
		resetMessageState("HARD_RESET");

		const {data: responseData} = await ProfileService.findCustomUser(TokenHelper.getSelectedOrg(), {email: searchInput});
		if(responseData.status !== Loading.SUCCESS || responseData.data.length === 0) {
			setErrorStatus(true);
			setErrorMsg('User with the email does not exist');
		} else {
			setPasswordValidated(true);
			setUser(responseData.data[0]);
			resetMessageState("TIMED_RESET");
		}

		setLoading(false);
	}

	const handleDelete = async() => {
		setLoading(true);
		resetMessageState("HARD_RESET");

		let responseData;
		if(type === ActionPromptOption.CANCEL_TRIP) responseData = await BookingService.cancelTrip(props.id);
		if(type === ActionPromptOption.DELETE_VEHICLE) responseData = await FleetService.deleteVehicle(props.id);
		if(type === ActionPromptOption.DELETE_DRIVER) responseData = await ProfileService.deleteDriverProfile(props.id);
		if(type === ActionPromptOption.DELETE_WAREHOUSE) responseData = await ConfigsService.deleteWarehouse(props.reference);
		if(type === ActionPromptOption.DELETE_BUSINESS) responseData = await ProfileService.deleteCustomUserBusinesses(props.id);
		if(type === ActionPromptOption.TRANSFER_VEHICLE) responseData = await FleetService.transferVehicle({
			sender_email: props.email, receiver_email: user.email
		});
		if(type === ActionPromptOption.TRANSFER_BUSINESS) responseData = await ProfileService.transferCustomUserBusinesses({
			sender_email: props.email, receiver_email: user.email
		});

		if(responseData.data.status === Loading.FAILED) {
			setErrorStatus(true);
			setErrorMsg(responseData.data.message ? responseData.data.message : "An error occurred while trying to perform this action, Please try again later action.");
			resetMessageState("TIMED_RESET");
		} else if(responseData.status === Loading.NO_CONTENT) completeAction("COMPLETED");
		else completeAction(responseData.data.data);

		setLoading(false);
	};


	return (
		<>
			<Modal show={show} backdrop="static" keyboard={false}>
				<div className="modal-header">
					<h6 className="modal-title">
						{(findUser && passwordValidated) &&
							<span className="me-2 text-primary" onClick={() => setPasswordValidated(false)}>
								<i className="zmdi zmdi-arrow-back me-2"/>
							</span>
						}
						{actionProps.title}
					</h6>
					<span id="close-modal-btn1" onClick={handleClose} className="close-modal mt-2">&times;</span>
				</div>
				<Modal.Body>
					<div className="proceed">
						<div>
							{errorStatus && <div className="alert alert-danger shake text-center mb-4" role="alert">{errorMsg}</div>}
							{successStatus &&
								<div className="alert alert-success shake text-center mb-4" role="alert">{successMsg}</div>
							}

							{name
								? <div className="mb-4">{actionProps.message} - {name} - <strong>{value}</strong> ?</div>
								: <div className="mb-4">{actionProps.message} - <strong>{value}</strong> ?</div>
							}

							<div className="mb-4">
								You can send in a support request about this information, and may choose not to continue with this
								action.
							</div>
							<div className="mb-2 text-danger"><strong>This action cannot be undone</strong></div>

							{passwordValidated ?
								<Fragment>
									{Object.entries(user).length === 0 ? "" :
										<div className="alert alert-success mt-3" role="alert">
											<h4 className="alert-heading text-center">User Found</h4>
											<p className="mt-3 text-center">Found User
												<strong>
													&nbsp;{user.user ? user.user["first_name"] : user["first_name"]}
													&nbsp;{user.user ? user.user["last_name"] : user["last_name"]}
												</strong>,
												<br/>
												<strong>&nbsp;{user.user ? user.user["email"] : user["email"]}</strong>,
												<strong>&nbsp;{user.user ? user.user["phone"] : user["phone"]}</strong>
											</p>
											<hr/>
											<p className="mb-0 text-center">{actionProps.button}</p>
										</div>
									}
								</Fragment>
								:
								<Fragment>
									{findUser &&
										<div className="form-group mb-0 mt-4">
											<label className="text-muted mb-1">Email <span className="text-danger">*</span></label>
											<input type="text" className="form-control"
														 placeholder="Search for user by email..." value={searchInput}
														 onChange={event => setSearchInput(event.target.value)}/>
										</div>
									}

									<div className="form-group text-start pos-relative mt-2">
										<label className="text-muted mb-1">Validate Password <span className="text-danger">*</span></label>
										<input type="password" id="password" className="form-control" placeholder={actionProps.placeholder}
													 value={validateInput} onChange={event => setValidateInput(event.target.value)}/>
										<div className="eye-right">
											{passwordType === "password" ?
												<i onClick={() => togglePasswordField("text")} className="zmdi zmdi-eye"/> :
												<i onClick={() => togglePasswordField("password")} className="zmdi zmdi-eye-off"/>
											}
										</div>
									</div>
								</Fragment>
							}

						</div>
						<div className="d-flex justify-content-end mt-5">
							<Button variant="info" className="btn secondary-btn trip-btn text-decoration-none mb-2 me-2"
											onClick={handleClose}>Close
							</Button>

							{(findUser && !passwordValidated)
								? <Button disabled={disabledFindUser}
													className={`btn primary-btn trip-btn text-white text-decoration-none mb-2`}
													onClick={handleValidatePassword}>Find User
									{loading && <>&nbsp;<i
										className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin ms-2"/></>}
								</Button>
								: <Fragment>
									<Button disabled={disabledState} variant="danger"
													className="btn bg-danger trip-btn text-white text-decoration-none mb-2"
													onClick={findUser ? handleDelete : handleValidatePassword}>{actionProps.button}
										{loading && <>&nbsp;<i
											className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin ms-2"/></>}
									</Button>
								</Fragment>
							}
						</div>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default ActionPromptModal;
