import {NavLink, useNavigate} from "react-router-dom";
import moment from "moment";

import CostSvg from "../../../assets/images/svg/cost.svg";
import TickSvg from "../../../assets/images/svg/tick.svg";
import TruckSvg from "../../../assets/images/svg/truck.svg";
import TransitSvg from "../../../assets/images/svg/transit.svg";
import EmptyTableSvg from "../../../assets/images/svg/empty_field.svg";
import {TripStatusMap} from "../../../utils/helpers/mappers";
import {DomStateType} from "../../../utils/helpers/constants";
import {formatAmountToString} from "../../../utils/helpers/logicHelper";


const Body = ({domState, insights, trips, tripRequests, wallet}) => {

  const navigate = useNavigate(), initBusiness = {completed_trips: 0, total_revenue: '0.00'}
  const business = (domState.type === DomStateType.BUSINESS && domState.business.type === 'owner') ?
    insights.business.find(each => each.id === domState.id) : initBusiness;
  const findTrip = trip => navigate(`/trips/${trip.reference}`);

  return (
    <div className="main pt-5 p-sm-5 p-3">
      <div className="d-flex flex-column align-items-md-start align-items-center">
        {(domState.type === DomStateType.BUSINESS || domState.type === DomStateType.CUSTOMER) &&
          <div className="order-md-1 order-2 btns-sm mb-4">
            <NavLink className="btn primary-btn text-decoration-none my-1 mg-r-5" to="/book-trip" state={wallet} 
            role="button">
              Book A Vehicle
            </NavLink>
            {domState.type === DomStateType.BUSINESS &&
              <NavLink className="btn secondary-btn text-decoration-none my-1" to="/book-manifest" state={wallet}
                       role="button">
                Book Multiple Vehicles
              </NavLink>
            }
          </div>
        }

        <div className="container-fluid p-0 mt-md-5 mt-0  order-md-2 order-1">
          <div className="row">
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4">
              <div className="d-flex flex-column main-card p-3 pb-4">
                <div className="main-card-icon d-flex justify-content-center cost-bg">
                  <img src={CostSvg} alt=""/>
                </div>
                <p className="mt-4 mb-1">Wallet Balance</p>
                <p className="text-lg mb-1">
                  {wallet['currency']} {formatAmountToString(wallet['available_balance'])}
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4">
              <div className="d-flex flex-column main-card p-3 pb-4">
                <div className="main-card-icon d-flex justify-content-center truck-bg">
                  <img src={TruckSvg} alt=""/>
                </div>
                <p className="mt-4 mb-1">Scheduled trip</p>
                <p className="text-lg mb-1">{insights['customer']['awaiting_driver']}</p>
                {/*<span className="text-neg">-30%</span>*/}
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4">
              <div className="d-flex flex-column main-card p-3 pb-4">
                <div className="main-card-icon d-flex justify-content-center transit-bg">
                  <img src={TransitSvg} alt=""/>
                </div>
                <p className="mt-4 mb-1">In-transit trip</p>
                <p className="text-lg mb-1">{insights['customer']['is_transit']}</p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 mb-4">
              <div className="d-flex flex-column main-card p-3 pb-4">
                <div className="main-card-icon d-flex justify-content-center tick-bg">
                  <img src={TickSvg} alt=""/>
                </div>
                <p className="mt-4 mb-1">Completed trip</p>
                <p className="text-lg mb-1">
                  {domState.type === DomStateType.DRIVER ? insights['driver']['completed_trips'] : domState.type === DomStateType.BUSINESS ?
                    business['completed_trips'] : insights['customer']['completed_trips']}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="table-title my-3">{domState.type === DomStateType.DRIVER ? 'Trip Requests' : 'Recent trips'}</p>
      <div className="main-table pb-4 pt-1 px-md-2 px-0 d-flex flex-column mx-height-1220 overflow-scroll">
        <div className="mt-3">
          <div className="w-100 table-responsive table-bod overflow-x-auto">
            {domState.type === DomStateType.DRIVER ?
              <table className="table main-table-data bg-white table-hover pointer-event">
                <thead>
                <tr>
                  <th>Drop Off Address</th>
                  <th>Price</th>
                  <th>Type</th>
                  <th>Stops</th>
                  <th>Status</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
                </thead>
                <tbody>
                {tripRequests.map((item, index) =>
                  <tr key={index} onClick={() => findTrip(item)}>
                    <td>{item.destination.address}</td>
                    <td>{item['vehicle_payment'].currency} {formatAmountToString(item['vehicle_payment']['vehicle_payment'])}</td>
                    <td>{item.trip_type}</td>
                    <td className="text-date text-center">{item.destination.stops.number}</td>
                    <td className={`${item.status ? TripStatusMap[item.status].fill : 'text-info'}`}>
                      {item['system_status'] ? TripStatusMap[item['system_status']].label.toUpperCase() : 'OTHER'}
                    </td>
                    <td className="text-date">{moment(item.scheduled_date).format('lll')}</td>
                    <td className="wd-8rem">
                      <span className="text-center pointer">
                        <button className="btn btn-sm mg-x-2 btn-secondary"
                                onClick={() => navigate(`/trips/${item['reference']}`, {state: item})}>
                          View Trip
                        </button>
                      </span>
                    </td>
                  </tr>
                )}
                </tbody>
              </table> :
              <table className="table main-table-data bg-white table-hover pointer-event">
                <thead>
                <tr>
                  <th>Address</th>
                  <th>Amount</th>
                  <th>Vehicle</th>
                  <th>Status</th>
                  <th>Date Created</th>
                </tr>
                </thead>
                <tbody>
                {trips.slice(0, 5).map((item, index) =>
                  <tr key={index} onClick={() => findTrip(item)}>
                    <td>{item.destination.address}</td>
                    <td>{item.quote.currency} {formatAmountToString(item.quote['charge'])}</td>
                    <td>{item.category_size.category} - ({item.category_size['tonnage']} tonne)</td>
                    <td className={`${item['system_status'] ? TripStatusMap[item['system_status']].fill : 'text-info'}`}>
                      {item['status']}
                    </td>
                    <td className="text-date">{moment(item.scheduled_date).format('lll')}</td>
                  </tr>
                )}
                </tbody>
              </table>
            }
            {domState.type === DomStateType.DRIVER && tripRequests.length === 0 &&
              <div className="row">
                <div className="text-center">
                  <img src={EmptyTableSvg} className="mg-t-10" id="open-modal-btn3" alt=""/>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
