import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Navigate} from "react-router-dom";

import Body from "./Body";
import AppSphereLoader from "../../../components/loaders/AppSphereLoader";
import {fetchAuthPolicies} from "../../../store/actions/auth.action";
import TokenHelper from "../../../utils/helpers/tokenHelper";
import {Loading} from "../../../utils/helpers/constants";


const Register = () => {
  const dispatch = useDispatch(), isLoggedIn = TokenHelper.checkIfLoggedIn();
  const auth = useSelector(({authReducer}) => authReducer);
  const loadState = auth.loadingPolicies !== Loading.SUCCESS;

  useEffect(() => {
    if (auth.organisation && auth.loadingPolicies !== Loading.SUCCESS) dispatch(fetchAuthPolicies());
  }, [auth.organisation]);

  if (isLoggedIn) return <Navigate to={{pathname: "/"}}/>;
  let count = auth.dynamicFields.length > 6 ? 3 : auth.dynamicFields.length ? 2 : 1;
  return loadState ? <AppSphereLoader/> :
    <Body passwordPolicy={auth.passwordPolicy} dynamicFields={auth.dynamicFields} dynamicRender={count}
          properties={auth.properties}/>
}

export default Register;
