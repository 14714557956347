import { toTitleCase } from "../../../utils/helpers/logicHelper";

const CHECKBOX = ({ divClass, formControl, formHook, initialValue }) => {
  return (
    <div className={`mt-4 ${divClass}`}>
      <div className="form-group">
        <label className="custom-switch d-flex justify-content-between">
          <span className="custom-switch-description tx-16 me-2">
            {toTitleCase(formControl["label"])} {formControl.required && <span style={{ color: "red" }}>*</span>}
          </span>
          <input type="checkbox" className="custom-switch-input"
            name={formControl["key"]}
            defaultChecked={initialValue ?? formControl["default_value"]}
            checked={initialValue ?? formControl["default_value"]}
            onChange={event => formHook.setValue(formControl["key"], event.target.checked)} />
          <span
            className="custom-switch-indicator custom-switch-indicator-md pointer-event"></span>
        </label>
      </div>
    </div>
  );
}
export default CHECKBOX;