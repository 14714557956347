import {Fragment} from "react";
import {useSelector} from "react-redux";
import SideMenu from "./sideMenu/SideMenu";
import Header from "./header/Header";
import AppSphereLoader from "../loaders/AppSphereLoader";
import Meta from "./header/Meta";

const PageLayout = ({active, children}) => {

  const {component, profile} = useSelector(({componentReducer, profileReducer}) => ({
    component: componentReducer,
    profile: profileReducer,
  }));

  return (
    <Fragment>
      <Meta title="Dashboard" logo={profile.organisation['favicon']}/>
      {component.appLoader ? <AppSphereLoader/> :
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <Header/>
            <div className="app-wrapper d-flex flex-column">
              <SideMenu active={active} navStatus={component.sideNavMode}/>
              {children}
            </div>
          </div>
        </div>
      }
    </Fragment>
  )
}

export default PageLayout;
