import {useGlobalFilter, usePagination, useSortBy, useTable} from 'react-table';
import Pagination from "./Pagination";
import EmptyTableSvg from "../../assets/images/svg/empty_field.svg";

const PaginationTable = ({columns, data, handleClick, filterData, size, index, count, setPage, loading}) => {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setGlobalFilter,
    setPageSize,
  } = useTable({
    columns,
    data,
    initialState: {pageIndex: 0, pageSize: size}
  }, useGlobalFilter, useSortBy, usePagination);


  return (
    <>
      <table {...getTableProps()} className="table main-table-data w-100 bg-white table-hover">
        <thead>
        {headerGroups.length > 0 && headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps(column.getSortByToggleProps())} className="fw-bold">
                {column.render("Header")}
                <span>{column.isSorted ? (column.isSortedDesc ? <>&nbsp;<i className="zmdi zmdi-long-arrow-down"/></> :
                  <>&nbsp;<i className="zmdi zmdi-long-arrow-up"/></>) : <>&nbsp;<i
                  className="zmdi zmdi-swap-vertical"/></>}</span>
              </th>
            ))}
          </tr>
        ))}
        </thead>
        <tbody {...getTableBodyProps()} className="pointer-event">
        {page.map(row => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()} onClick={() => handleClick(cell.row.original)}>
                  {cell.render('Cell')}
                </td>
              })}
            </tr>
          )
        })}
        </tbody>
      </table>
      {data.length > 0 ?
        <Pagination filterData={filterData} size={size} count={count} setGlobalFilter={setGlobalFilter}
                    setPageSize={setPageSize} loading={loading} index={index} data={data} setPage={setPage}/>
        : <div className="row">
          <div className="text-center">
            <img src={EmptyTableSvg} className="mg-t-10" id="open-modal-btn3" alt=""/>
          </div>
        </div>
      }
    </>
  )
}

export default PaginationTable;
