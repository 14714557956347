import {useSelector} from "react-redux";
import HeaderNav from "./HeaderNav";

const Header = () => {
  const {component, profile, configs} = useSelector(({componentReducer, profileReducer, configsReducer}) => ({
    component: componentReducer,
    profile: profileReducer,
    configs: configsReducer,
  }));

  return <HeaderNav component={component} domState={profile.domState} businesses={profile.businesses}
                    profile={profile.userData} driverProfile={profile.driver} organisation={profile.organisation}
                    notifications={configs.notifications} unreadNotificationCount={configs.unreadNotificationCount}
  />;
}

export default Header;
