import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import InnerPageLayout from "../../../components/layouts/InnerPageLayout";
import SphereLoader from "../../../components/loaders/SphereLoader";
import {getDynamicFields} from "../../../store/actions/component.action";
import {Loading} from "../../../utils/helpers/constants";
import { useLocation } from "react-router-dom";

const BookManifest = () => {

  const dispatch = useDispatch(), location = useLocation();
  const {component, profile} = useSelector(({componentReducer, profileReducer}) => ({
    component: componentReducer,
    profile: profileReducer,
  }));

  const loadState = component.loadingDynamicFields !== Loading.SUCCESS;

  useEffect(() => {
    dispatch(getDynamicFields('BOK'));
  }, []);


  return (
    <InnerPageLayout>
      {loadState ? <SphereLoader/> :
        <Body dynamicFields={component.dynamicFields['FLT']} wallet={location.state} domState={profile.domState}/>
      }
    </InnerPageLayout>
  );
}

export default BookManifest;
