import * as BookingConstants from "../constants/booking.constants";
import {Loading} from "../../utils/helpers/constants";

const initialState = {
  loading: "",
  loadingRequests: "",
  loadingInfo: "",
  loadingPicklist: "",
  loadingTripType: "",
  loadingExtras: "",
  tripTypes: [],
  extras: [],
  trips: [],
  tripRequests: [],
  trip: {},
  picklist: {},
  tripsCount: "",
};

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case BookingConstants.FETCH_TRIPS_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case BookingConstants.FETCH_TRIPS_SUCCESS:
      return {...state, loading: Loading.SUCCESS, trips: action.payload.data, tripsCount: action.payload.count};

    case BookingConstants.FETCH_TRIPS_FAILURE:
      return {...state, loading: Loading.ERROR}

    case BookingConstants.FETCH_DRIVER_TRIPS_REQUEST:
      return {...state, loadingRequests: Loading.FETCHING};

    case BookingConstants.FETCH_DRIVER_TRIPS_SUCCESS:
      return {...state, loadingRequests: Loading.SUCCESS, tripRequests: action.payload};

    case BookingConstants.FETCH_DRIVER_TRIPS_FAILURE:
      return {...state, loadingRequests: Loading.ERROR}

    case BookingConstants.FETCH_TRIP_DETAILS_REQUEST:
      return {...state, loadingInfo: Loading.FETCHING};

    case BookingConstants.FETCH_TRIP_DETAILS_SUCCESS:
      let trip = (Array.isArray(action.payload) && action.payload.length > 0) ? action.payload[0] : Object.entries(action.payload).length > 0 ? action.payload : {};
      return {...state, loadingInfo: Loading.SUCCESS, trip};

    case BookingConstants.FETCH_TRIP_DETAILS_FAILURE:
      return {...state, loadingInfo: Loading.ERROR}

    case BookingConstants.FETCH_PICKLIST_REQUEST:
      return {...state, loadingPicklist: Loading.FETCHING};

    case BookingConstants.FETCH_PICKLIST_SUCCESS:
      return {...state, loadingPicklist: Loading.SUCCESS, picklist: action.payload};

    case BookingConstants.FETCH_PICKLIST_FAILURE:
      return {...state, loadingPicklist: Loading.ERROR}

    case BookingConstants.FETCH_TRIP_TYPES_REQUEST:
      return {...state, loadingTripType: Loading.FETCHING};

    case BookingConstants.FETCH_TRIP_TYPES_SUCCESS:
      return {...state, loadingTripType: Loading.SUCCESS, tripTypes: action.payload};

    case BookingConstants.FETCH_TRIP_TYPES_FAILURE:
      return {...state, loadingTripType: Loading.ERROR};

    case BookingConstants.FETCH_EXTRAS_REQUEST:
      return {...state, loadingExtras: Loading.FETCHING};

    case BookingConstants.FETCH_EXTRAS_SUCCESS:
      return {...state, loadingExtras: Loading.SUCCESS, extras: action.payload};

    case BookingConstants.FETCH_EXTRAS_FAILURE:
      return {...state, loadingExtras: Loading.ERROR};

    default:
      return state;
  }
};

export default bookingReducer;
