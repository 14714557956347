import { useMemo, useState } from "react";
import { ErrorMessage } from "@hookform/error-message";
import { toTitleCase } from "../../../utils/helpers/logicHelper";

const RADIO = ({ divClass, formControl, formHook, initialValue }) => {
  const [validatorSchema, setValidatorSchema] = useState({});
  const errors = formHook.formState.errors || formHook.formState.errors[formControl["key"]];

  useMemo(() => {
    for (const [key, value] of Object.entries(formControl)) {
      switch (key) {
        case 'required':
          if (value) setValidatorSchema(prevState => ({ ...prevState, required: `${formControl["label"]} is required.` }));
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <div className={divClass}>
      <label htmlFor={formControl["key"]}>
        {toTitleCase(formControl["label"])} {formControl["required"] && <span className="text-danger">*</span>}
      </label>

      {(formControl && formControl['options']) && formControl['options'].map((option, index) => (
        <div key={index} className="form-group mb-0 d-flex justify-content-between">
          <label className="form-label">{option.label}</label>
          <label className="custom-control custom-checkbox custom-control-md">
            <input type="radio"
              className={`custom-control-input`}
              name={formControl.name} value={option.value}
              defaultChecked={initialValue ? initialValue : formControl["default_value"]}
              {...formHook.register(formControl["key"], { ...validatorSchema })}
              onChange={event => formHook.setValue(formControl["key"], event.target.value)} />
            <span className="custom-control-label custom-control-label-md  tx-16"></span>
          </label>
        </div>
      ))}

      <ErrorMessage
        errors={errors}
        name={formControl["key"]}
        render={(error) =>
          error &&
          Object.entries(error).map(([type, message]) => (
            <small key={type} className="text-danger mt-1">{message}</small>
          ))
        }
      />
    </div>
  );
}

export default RADIO;