import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import InnerPageLayout from "../../../components/layouts/InnerPageLayout";
import SphereLoader from "../../../components/loaders/SphereLoader";
import {getDynamicFields} from "../../../store/actions/component.action";
import {Loading} from "../../../utils/helpers/constants";

const OnboardDriver = () => {

  const dispatch = useDispatch();
  const component = useSelector(({componentReducer}) => componentReducer);
  const loadState = component.loadingDynamicFields !== Loading.SUCCESS;

  useEffect(() => {
    dispatch(getDynamicFields('DRM'));
  }, []);

  return (
    <InnerPageLayout>
      {loadState ? <SphereLoader/> : <Body dynamicFields={component.dynamicFields['DRM']}/>}
    </InnerPageLayout>
  );
}

export default OnboardDriver;
