import {Configs, UserType} from './constants';
import cookieHelper from "./cookieHelper";

const TokenHelper = {

	checkIfLoggedIn() {
		const TOKEN = cookieHelper.get(Configs.KEY);
		return !!TOKEN;
	},

	saveToken(token) {
		cookieHelper.set(Configs.KEY, token, Configs.COOKIE_EXPIRY_PERIOD);
		localStorage.setItem(Configs.KEY, token);
	},

	getToken() {
		return cookieHelper.get(Configs.KEY);
	},

	saveUserType(data) {
		let type = data.toString().toUpperCase() !== UserType.ADMIN ? UserType.TENANT : UserType.ADMIN;
		cookieHelper.set(Configs.USER_TYPE, type, Configs.COOKIE_EXPIRY_PERIOD);
	},

	getUserType() {
		return cookieHelper.get(Configs.USER_TYPE);
	},

	saveUserId(userId) {
		cookieHelper.set(Configs.USER_ID, userId, Configs.COOKIE_EXPIRY_PERIOD);
	},

	getUserId() {
		return cookieHelper.get(Configs.USER_ID);
	},

	saveSelectedOrg(data) {
		cookieHelper.set(Configs.USER_ORG, data, Configs.COOKIE_EXPIRY_PERIOD);
	},

	getSelectedOrg() {
		return cookieHelper.get(Configs.USER_ORG);
	},

	saveSelectedBusiness(reference) {
		cookieHelper.set(Configs.USER_BUSINESS, reference, Configs.COOKIE_EXPIRY_PERIOD);
	},

	getSelectedBusiness() {
		return cookieHelper.get(Configs.USER_BUSINESS);
	},

}

export default TokenHelper;
