import ActionCreator from "../../utils/helpers/actionCreator";
import * as BookingConstants from "../constants/booking.constants";
import BookingService from "../../utils/services/booking.service";

export const getTripTypes = () => async dispatch => {
  try {
    dispatch(ActionCreator(BookingConstants.FETCH_TRIP_TYPES_REQUEST));
    const {data: responseData} = await BookingService.getOrganisationTripTypes();
    dispatch(ActionCreator(BookingConstants.FETCH_TRIP_TYPES_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(BookingConstants.FETCH_TRIP_TYPES_FAILURE));
  }
};

export const getExtras = () => async dispatch => {
  try {
    dispatch(ActionCreator(BookingConstants.FETCH_EXTRAS_REQUEST));
    const {data: responseData} = await BookingService.getOrganisationExtras();
    dispatch(ActionCreator(BookingConstants.FETCH_EXTRAS_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(BookingConstants.FETCH_EXTRAS_FAILURE));
  }
};

export const getTrips = (filter, entity) => async dispatch => {
  try {
    dispatch(ActionCreator(BookingConstants.FETCH_TRIPS_REQUEST));
    const {data: responseData} = await BookingService.fetchTrips(filter, entity);
    dispatch(ActionCreator(BookingConstants.FETCH_TRIPS_SUCCESS, responseData));
  } catch (error) {
    dispatch(ActionCreator(BookingConstants.FETCH_TRIPS_FAILURE));
  }
};

export const getDriverTripRequests = () => async dispatch => {
  try {
    dispatch(ActionCreator(BookingConstants.FETCH_DRIVER_TRIPS_REQUEST));
    let tripData, data = await BookingService.fetchDriverTripRequests();
    if (data.status === 400) tripData = [];
    else if (data.status === 200) tripData = data.data.data;
    dispatch(ActionCreator(BookingConstants.FETCH_DRIVER_TRIPS_SUCCESS, tripData));
  } catch (error) {
    dispatch(ActionCreator(BookingConstants.FETCH_DRIVER_TRIPS_FAILURE));
  }
};

export const getTripInfo = (payload, domType) => async dispatch => {
  try {
    dispatch(ActionCreator(BookingConstants.FETCH_TRIP_DETAILS_REQUEST));
    const { data: responseData } = await BookingService.fetchTripInfo(payload, domType);
    await dispatch(ActionCreator(BookingConstants.FETCH_TRIP_DETAILS_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(BookingConstants.FETCH_TRIP_DETAILS_FAILURE));
  }
};

export const getPickList = (reference, busRef) => async dispatch => {
  try {
    dispatch(ActionCreator(BookingConstants.FETCH_PICKLIST_REQUEST));
    const {data: responseData} = await BookingService.fetchPicklist(reference, busRef);
    await dispatch(ActionCreator(BookingConstants.FETCH_PICKLIST_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(BookingConstants.FETCH_PICKLIST_FAILURE));
  }
};
