import { Gallery, Item } from "react-photoswipe-gallery";
import Gravatar from "../../assets/images/pngs/gravatar.png";

const Documents = ({ documents }) => (
	<>
		{(documents && Array.isArray(documents) && documents.length > 0) && documents.map((item, index) =>
			<div key={index} className="d-flex flex-column justify-items-center flex-wrap my-3" style={{ width: "215px"}}>
				{item.stop && <p className="d-block text-center fw-bold mb-1 fs-6">{item.drop_type ? item.drop_type : "Stop"} {item.stop}</p>}
				{(item && item.ext === "application/pdf")
					? <div className="clickable-iframe">
						<iframe src={item.url ?? item.base64File} />
						<div title="View" onClick={() => window.open(item.url ?? item.base64File, "_blank")} />
					</div>
					: <Gallery>
						<Item original={item.url ?? item.base64File} thumbnail={item.url ?? item.base64File} width="1024" height="1024">
							{({ ref, open }) => (
								<img ref={ref} onClick={open} src={item.url ?? item.base64File} alt="DOCUMENT" width="200"
									className="img-thumbnail rounded pointer-event"
									onError={(e) => e.target.src = Gravatar}
								/>
							)}
						</Item>
					</Gallery>
				}
			</div>
		)}
	</>
);
export default Documents;