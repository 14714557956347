import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

import SiennaSvg from "../../../../assets/images/svg/sienna.svg";
import ArrowBackSvg from "../../../../assets/images/svg/arrow-back.svg";
import {Loading} from "../../../../utils/helpers/constants";
import {BookTripFlowMap} from "../../../../utils/helpers/mappers";
import FleetService from "../../../../utils/services/fleet.service";
import BookingService from "../../../../utils/services/booking.service";
import IndividualConfirmation from "./IndividualConfirmation";
import DatePickerHook from "../../../../components/hooks/DatePickerHook";
import {formatAmountToNumber, toTitleCase} from "../../../../utils/helpers/logicHelper";
import {validateIfFloat, validateIfInteger} from "../../../../utils/services/validator.service";
import MapsSelectHook from "../../../../components/maps/MapsSelectHook";


const BookCustomerTrip = ({tripTypes, extraServices}) => {

	const navigate = useNavigate();
	const [step, setStep] = useState(1);
	const [date, setDate] = useState("");
	const [coupon, setCoupon] = useState("");
	const [estimate, setEstimate] = useState("");
	const [type, setType] = useState({});
	const [extras, setExtras] = useState({});
	const [category, setCategory] = useState({});
	const [categories, setCategories] = useState([]);
	const [sourceObject, setSourceObject] = useState({});
	const [confirmationData, setConfirmationData] = useState({});
	const [destinationObject, setDestinationObject] = useState({});
	const [confirmedVehicleData, setConfirmedVehicleData] = useState({});
	const [loading, setLoading] = useState(false);
	const [isExtras, setIsExtras] = useState(false);
	const [confirmationState, setConfirmationState] = useState(false);
	const [updateBookingState, setUpdateBookingState] = useState(false);

	const tripContext = BookTripFlowMap[step];


	const goToDashboard = () => navigate(-1);
	const goToTripBooking = () => setConfirmationState(false);

	const updateLocation = (placeData, type) => {
		let placeObj = {
			city: placeData.city,
			state: placeData.state,
			address: placeData.address,
			latitude: placeData['latitude'],
			longitude: placeData['longitude']
		};
		if (type === "SOURCE") setSourceObject(prevState => ({ ...prevState, ...placeObj }))
			if (type === "DESTINATION") setDestinationObject(prevState => ({ ...prevState, ...placeObj }))
	}


	const isFormValid = () => {
		if(step === 1 && Object.entries(type).length > 0) return true;
		else if(step === 2 && Object.entries(sourceObject).length > 0) return true;
		else if(step === 3 && Object.entries(destinationObject).length > 0) return true;
		else if(step === 4 && estimate) return true;
		else if(step === 5 && date) return true;
		else if(step === 6 && Object.entries(category).length > 0) return true;
		else if(step === 7 || step === 8) return true;
		return false;
	}

	const canPrevious = () => step !== 1;

	const decreaseStep = () => {
		if(step === 2 || step === 3);
		if(canPrevious()) setStep(step - 1);
	}

	const updateExtras = (value, key) => setExtras({...extras, [key]: parseInt(value)});

	const bookHandler = async() => {
		setLoading(true);
		let responseData;

		if(step === 1) {
			const {data: responseData} = await FleetService.getBookingVehicleCategories(type['customized_name']);
			if(responseData.status !== Loading.SUCCESS) console.error(responseData.message);
			else setCategories(responseData.data);
			setStep(step + 1);
		} else if(step > 1 && step < 8) {
			setStep(step + 1);
		} else {
			let payload = {
				extras,
				source: sourceObject,
				category_size: category.id,
				destination: {...destinationObject, stops: {number: 0, stopovers: []}},
			};

			if(coupon && coupon.trim().length > 0) payload.coupon = coupon;
			payload.trip_type = type['customized_name'];
			payload.scheduled_date = moment(date).format();
			payload.estimated_value = formatAmountToNumber(estimate);

			if(!updateBookingState) responseData = await BookingService.bookTrip(payload);
			else responseData = await BookingService.updateBookTrip(confirmationData.id, payload);

			if(responseData.data.status !== Loading.SUCCESS) {
				const message = (responseData && responseData.data && responseData.data.message) ? responseData.data.message : "You cannot book this trip at the moment, please try again later";
				toast.error(message);
			}
			else {
				setConfirmationState(true);
				setUpdateBookingState(true);
				setConfirmationData(responseData.data.data);
				setConfirmedVehicleData(category);
			}
		}
		setLoading(false);
	}


	return (
		<Fragment>
			<p className="mt-4 booking-direct fs-18 pointer-event"
				 onClick={(updateBookingState && confirmationState) ? goToTripBooking : goToDashboard}>
				<img src={ArrowBackSvg} alt="Arrow-back"/>
				&nbsp; {(updateBookingState && confirmationState) ? 'Edit Trip Details' : 'Dashboard'}
			</p>
			<div className="py-md-5 pt-5 pb-2 ms-5">
				{confirmationState ?
					<IndividualConfirmation booking={confirmationData} vehicle={confirmedVehicleData}/> :
					<div className="booking-main d-flex flex-column">
						<h3 className="booking-title">Book a trip</h3>
						<form className="booking-form d-flex mt-4 flex-column align-items-start">
							<label>{tripContext.title}</label>
							<div className="position-relative w-100 mg-t-15">
								{step === 1 ?
									<>
										<div className="grid-group">
											{tripTypes.map((item, index) =>
												<div key={index}
														 className={`d-flex flex-column text-center wd-100p raise-transition ${item['customized_name'] === type['customized_name'] && 'selected-option'}`}
														 onClick={() => setType(item)}>
													<span>{item['customized_name']}</span>
												</div>
											)}
										</div>
										{Object.entries(type).length > 0 &&
											<div
												className="description-box mt-5 text-muted fw-semibold">Description: {type.description ?? ""}</div>
										}
									</> : ""
								}
								{(step === 2) &&
									<MapsSelectHook
										setInitialValue={ sourceObject.address}
										setLocation={value => updateLocation(value, "SOURCE")} />
								}
								{(step === 3) &&
									<MapsSelectHook
										setInitialValue={ destinationObject.address}
										setLocation={value => updateLocation(value, "DESTINATION")} />
								}
								{step === 4 ? <input type="number" value={estimate} onKeyPress={validateIfFloat}
																		 onChange={e => setEstimate(e.target.value)}/> :
									step === 5 ? <DatePickerHook placeholder="Select scheduled date" selected={date} getDate={setDate}
																							 timeIntervals={30} dateFormat="MMMM d, yyyy h:mm aa" filterTime={true}
																							 showYearDropdown selectDropdownMode withPortal showTimeSelect
																							 setMinDate={new Date()}/>
										: ""
								}
								{step === 6 ?
									<>
										<div className="grid-group">
											{categories.map((item, index) =>
												<div key={index}
														 className={`d-flex flex-column raise-transition text-center ${item['tonnage'] === category['tonnage'] && 'selected-option'}`}
														 onClick={() => setCategory(item)}>
													<img className="mb-0 d-block mx-auto" src={item.image ?? SiennaSvg} alt="" width={75}
															 height={75}/>
													<span>{item.category} - {item['tonnage']} tonne</span>
												</div>
											)}
										</div>
										{Object.entries(category).length > 0 &&
											<div
												className="description-box mt-5 text-muted fw-semibold">Description: {category.description ?? ""}</div>
										}
									</> : ""
								}
								{step === 7 &&
									<input type="text" className="mt-2rem"
												 value={coupon} onChange={e => setCoupon(e.target.value.toUpperCase())}/>
								}
								{step === 8 ?
									<>
										<div className="d-flex align-items-center radio-group mt-4">
											{isExtras ? <i className="zmdi zmdi-check-square"></i> :
												<i onClick={() => setIsExtras(!isExtras)} className="zmdi zmdi-square-o"></i>}
											<span className="ms-3">Yes</span>
										</div>
										<div className="d-flex align-items-center radio-group mb-4rem">
											{isExtras ? <i onClick={() => setIsExtras(!isExtras)} className="zmdi zmdi-square-o"></i> :
												<i className="zmdi zmdi-check-square"></i>}
											<span className="ms-3">No</span>
										</div>
										{isExtras &&
											<Fragment>
												{extraServices.length > 0 ? extraServices.map(item =>
													<Fragment key={item['key']}>
														{!item['key'].toLowerCase().includes('delivery') &&
															<div className="position-relative w-100 mg-t-15">
																<label>
																	Number of {item.name ? toTitleCase(item.name) : toTitleCase(item['key'])} ?
																</label>
																<input type="number" onKeyPress={validateIfInteger}
																			 value={(extras && extras[item['key']]) ? extras[item['key']] : ""}
																			 onChange={event => updateExtras(event.target.value, item['key'])}/>
															</div>
														}
													</Fragment>
												) : <label className="d-block tx-italic text-warning mb-4">We Currently do not have any added
													service, Kindly proceed to pay for this trip.</label>
												}
											</Fragment>
										}
									</> : ""
								}
							</div>
							<div className="booking-btn-group wd-md-85p-f wd-lg-70p-f">
								{step !== 1 ?
									<button className="btn bg-danger text-white mt-4 me-4" onClick={decreaseStep} type="button">
										{tripContext.prev_button}
									</button> : ''}
								<button className="btn main-btn mt-4" disabled={!isFormValid()} onClick={bookHandler} type="button">
									{tripContext.next_button}
									{loading ? <i className="zmdi zmdi-spinner mg-l-5 zmdi-hc-spin"/> : ""}
								</button>
							</div>
						</form>
						<div className="mt-auto">
							<div className="d-flex justify-content-between align-items-center py-3 me-5">
								<div className="booking-step flex-grow-1">
									<p className="mb-1">{step} of 8</p>
									<div className="booking-progress">
										<div className={`booking-progress-bar ${tripContext.progress}`}></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				}
			</div>
		</Fragment>
	);
}

export default BookCustomerTrip;
