import * as FleetConstants from "../constants/fleet.constants";
import {Loading} from "../../utils/helpers/constants";

const initialState = {
  loadingCategories: "",
  fleet: [],
  categories: []
};

const fleetReducer = (state = initialState, action) => {
  switch (action.type) {
    case FleetConstants.FETCH_FLEET_CATEGORY_REQUEST:
      return {...state, loadingCategories: Loading.FETCHING};

    case FleetConstants.FETCH_FLEET_CATEGORY_SUCCESS:
      return {...state, loadingCategories: Loading.SUCCESS, categories: action.payload};

    case FleetConstants.FETCH_FLEET_CATEGORY_FAILURE:
      return {...state, loadingCategories: Loading.ERROR}

    case FleetConstants.FETCH_FLEET_REQUEST:
      return {...state, loading: Loading.FETCHING};

    case FleetConstants.FETCH_FLEET_SUCCESS:
      return {...state, loading: Loading.SUCCESS, fleet: action.payload};

    case FleetConstants.FETCH_FLEET_FAILURE:
      return {...state, loading: Loading.ERROR}

    default:
      return state;
  }
};

export default fleetReducer;
