import ActionCreator from "../../utils/helpers/actionCreator";
import * as FleetConstants from "../constants/fleet.constants";
import FleetService from "../../utils/services/fleet.service";

export const getVehicleCategories = () => async dispatch => {
  try {
    dispatch(ActionCreator(FleetConstants.FETCH_FLEET_CATEGORY_REQUEST));
    const {data: responseData} = await FleetService.getVehicleCategories();
    dispatch(ActionCreator(FleetConstants.FETCH_FLEET_CATEGORY_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(FleetConstants.FETCH_FLEET_CATEGORY_FAILURE));
  }
};

export const getBusinessVehicleCategories = (id, tripType) => async dispatch => {
  try {
    dispatch(ActionCreator(FleetConstants.FETCH_FLEET_CATEGORY_REQUEST));
    const {data: responseData} = await FleetService.getBusinessVehicleCategories(id, tripType);
    dispatch(ActionCreator(FleetConstants.FETCH_FLEET_CATEGORY_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(FleetConstants.FETCH_FLEET_CATEGORY_FAILURE));
  }
};

export const getFleet = () => async dispatch => {
  try {
    dispatch(ActionCreator(FleetConstants.FETCH_FLEET_REQUEST));
    const {data: responseData} = await FleetService.getFleet();
    dispatch(ActionCreator(FleetConstants.FETCH_FLEET_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(FleetConstants.FETCH_FLEET_FAILURE));
  }
};