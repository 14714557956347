import {useEffect} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import Body from "./Body";
import PageLayout from "../../../../components/layouts/PageLayout";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import {getNotifications} from "../../../../store/actions/configs.action";
import {Loading, menuType} from "../../../../utils/helpers/constants";

const Notifications = () => {
  const dispatch = useDispatch();
  const configs = useSelector(({configsReducer}) => (configsReducer));
  const loadState = configs.loadingNotifications !== Loading.SUCCESS;

  useEffect(() => {
    batch(() => {
      if (configs.loadingNotifications !== Loading.SUCCESS) dispatch(getNotifications());
    });
  }, []);

  return (
    <PageLayout active={menuType.NOTIFICATION}>
      <InnerMenuLayout active={menuType.NOTIFICATION} type="NOTIFICATION">
        {loadState ? <SphereLoader/> :
          <Body notifications={configs.notifications} notificationCount={configs.notificationCount}/>
        }
      </InnerMenuLayout>
    </PageLayout>
  )
}

export default Notifications;