import {useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";
import {DomStateType, Loading} from "../../../../utils/helpers/constants";
import {paginationSize} from "../../../../utils/helpers/objectHelpers";
import {tripsTableConfig} from "../../../../utils/helpers/dataTableConfigs";
import BookingService from "../../../../utils/services/booking.service";
import PaginationTable from "../../../../components/dataTable/PaginationTable";
import QuoteModal from "../../../../components/modals/QuoteModal";

const Body = ({trips, count, domState}) => {

  const navigate = useNavigate();
  const [pageData, setPageData] = useState(trips);
  const [filterInput] = useState("");
  const [page, setPage] = useState(1);
  const [loadingPagination, setLoadingPagination] = useState(false);
  const [pageSize, setPageSize] = useState(paginationSize[1]);
  const [quoteRender, setQuoteRender] = useState(false);


  const tableObject = [...tripsTableConfig];
  const columns = useMemo(() => tableObject, []);
  const findEachItem = value => navigate(`/trips/${value.reference}`);

  const fetchPagination = async (option, value) => {
    setLoadingPagination(true);
    setPageSize(option);
    if (count < option.value) setPage(Math.ceil(value - 1));
    else setPage(value);
    const filter = `?size=${option.value}&page=${count < option.value ? value - 1 : value}`;
    const {data: responseData} = await BookingService.fetchTrips(filter, domState.type);
    if (responseData.status === Loading.SUCCESS) setPageData(responseData.data);
    else setPageData(trips);
    setLoadingPagination(false);
  }

  const changeQuoteRender = () => setQuoteRender(false);

  const quoteModal = quoteRender &&
  <QuoteModal onchange={changeQuoteRender} props={domState.business}/>;

  return (
    <div className="main p-sm-4">
      {quoteModal}
      <div className="d-flex justify-content-between mt-5 mt-md-0 mx-3">
        <p className="table-title my-3">My trips</p>
        { domState.type === DomStateType.BUSINESS && domState.business && domState.business.is_merchant &&
          <button className="btn primary-btn px-3 py-0" onClick={() => setQuoteRender(true)}>Initiate Quote</button>
        }
      </div>
      <div className="main-table py-4 px-md-2 px-0 d-flex flex-column">
        <div className="mt-3">
          <div className="w-100 table-responsive table-md-bod overflow-x-auto">
            <PaginationTable columns={columns} filterData={filterInput} size={pageSize.value} count={count}
                             loading={loadingPagination} index={page}
                             setPage={value => fetchPagination(pageSize, value)} data={pageData}
                             handleClick={findEachItem}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body;
