import * as Constants from "../constants/configs.constants";
import {Loading} from "../../utils/helpers/constants";
import {formatService} from "../../utils/helpers/logicHelper";

const initialState = {
  matrices: [],
  notifications: [],
  warehouses: [],
  organisationServices: [],
  notificationCount: "",
  unreadNotificationCount: "",
  loadingMatrices: "",
  loadingWarehouses: "",
  loadingNotifications: "",
  loadingOrganisationServices: "",
};


const configsReducer = (state = initialState, action) => {
  switch (action.type) {

    case Constants.FETCH_NOTIFICATIONS_REQUEST:
      return {...state, loadingNotifications: Loading.FETCHING};

    case Constants.FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loadingNotifications: Loading.SUCCESS,
        notifications: action.payload.data,
        notificationCount: action.payload.count,
        unreadNotificationCount: action.payload['unread']
      };

    case Constants.FETCH_NOTIFICATIONS_FAILURE:
      return {...state, loadingNotifications: Loading.ERROR};

    case Constants.FETCH_ALL_NOTIFICATION_MATRICES_REQUEST:
      return {...state, loadingMatrices: Loading.FETCHING};

    case Constants.FETCH_ALL_NOTIFICATION_MATRICES_SUCCESS:
      return {...state, loadingMatrices: Loading.SUCCESS, matrices: action.payload};

    case Constants.FETCH_ALL_NOTIFICATION_MATRICES_FAILURE:
      return {...state, loadingMatrices: Loading.ERROR};

    case Constants.FETCH_WAREHOUSES_REQUEST:
      return {...state, loadingWarehouses: Loading.FETCHING};

    case Constants.FETCH_WAREHOUSES_SUCCESS:
      return {...state, loadingWarehouses: Loading.SUCCESS, warehouses: action.payload};

    case Constants.FETCH_WAREHOUSES_FAILURE:
      return {...state, loadingWarehouses: Loading.ERROR};

    case Constants.FETCH_ORGANISATION_SERVICES_REQUEST:
      return {...state, loadingOrganisationServices: Loading.FETCHING};

    case Constants.FETCH_ORGANISATION_SERVICES_SUCCESS:
      const organisationServices = formatService(action.payload)
      return {...state, loadingOrganisationServices: Loading.SUCCESS, services: action.payload, organisationServices};

    case Constants.FETCH_ORGANISATION_SERVICES_FAILURE:
      return {...state, loadingOrganisationServices: Loading.ERROR};


    default:
      return state;
  }
}

export default configsReducer;
