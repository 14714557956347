export const FETCH_PROFILE_REQUEST = "FETCH_PROFILE_REQUEST";
export const FETCH_PROFILE_SUCCESS = "FETCH_PROFILE_SUCCESS";
export const FETCH_PROFILE_FAILURE = "FETCH_PROFILE_FAILURE";

export const FETCH_OVERVIEW_REQUEST = "FETCH_OVERVIEW_REQUEST";
export const FETCH_OVERVIEW_SUCCESS = "FETCH_OVERVIEW_SUCCESS";
export const FETCH_OVERVIEW_FAILURE = "FETCH_OVERVIEW_FAILURE";

export const SET_DOM_PROFILE = "SET_DOM_PROFILE";

