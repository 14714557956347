import {Fragment, useState} from "react";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

import {formatAmountToString, toTitleCase} from "../../../../utils/helpers/logicHelper";
import BookingService from "../../../../utils/services/booking.service";
import {Loading} from "../../../../utils/helpers/constants";


const IndividualConfirmation = ({booking, vehicle}) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [loadingWallet, setLoadingWallet] = useState(false);


	const makePayment = async() => {
		setLoading(true);

		if(booking.quote['checkout_url']) window.open(booking.quote['checkout_url'], '_self')?.focus();
		else {
			const callbackUrl = `${window.location.origin}/trips/${booking.reference}/`;
			const {data: responseData} = await BookingService.checkout(booking.id, callbackUrl);
			if(responseData.status !== Loading.SUCCESS) {
				const message = (responseData && responseData.message) ? responseData.message : "You cannot make payment for this trip at the moment, please try again later";
				toast.error(message);
			}
			else window.open(responseData.data['checkout_url'], '_self')?.focus();
		}
		setLoading(false);
	}

	const payWithWallet = async() => {
		setLoadingWallet(true);
		const {data: responseData} = await BookingService.walletCheckout(booking.id);
		if(responseData.status !== Loading.SUCCESS) {
			const message = (responseData && responseData.message) ? responseData.message : "You cannot make payment for this trip at the moment, please try again later";
			toast.error(message);
		}
		else {
			toast.success(responseData.message);
			navigate(`/trips/${booking.reference}`);
		}
		setLoadingWallet(false);
	}
 
	return (
		<div className="booking-confirm">
			<div className="d-flex flex-column align-items-center">
				<h3 className="booking-confirm-title">Booking confirmation</h3>
				<div className="booking-confirm-info">
					<div className="info-group">
						<div>
							<span>Pickup location</span>
							<span className="text-right">
                {booking.source.address.length > 45 ? `${booking.source.address.replace(/<[^>]*>?/gm, ' ').slice(0, 45)}...` : booking.source.address}
              </span>
						</div>
						<div>
							<span>Drop-off location</span>
							<span className="text-right">
                {booking.destination.address.length > 45 ? `${booking.destination.address.replace(/<[^>]*>?/gm, ' ').slice(0, 45)}...` : booking.destination.address}
              </span>
						</div>
						<div>
							<span>Distance</span>
							<span>{Math.ceil(booking['distance'])}km</span>
						</div>
						<div>
							<span>Vehicle category</span>
							<span>{vehicle['category']} - {vehicle['tonnage']} tonne</span>
						</div>
						<div>
							<span>Schedule date</span>
							<span>{moment(booking.scheduled_date).format('Do MMMM YYYY')}</span>
						</div>
					</div>
					<div className="info-group">
						<div>
							<span>Base fare</span>
							<span>{booking.quote.currency} {formatAmountToString(booking.quote['base_fare'])}</span>
						</div>
						{booking.system_trip_type !== "VARIABLE" ? "" :
							<div>
								<span>Cost per Km</span>
								<span>{booking.quote.currency} {formatAmountToString(booking.quote['cost_per_kilometer'])}</span>
							</div>}
						<div>
							<span>Estimated insurance</span>
							<span>{booking.quote.currency} {formatAmountToString(booking.quote['estimated_insurance'])}</span>
						</div>
						{(Object.keys(booking.extras).length > 0 && Object.keys(booking.extras).map((key, index) =>
							<Fragment key={index}>
								{key in booking.quote &&
									<div>
										<span>{toTitleCase(key)} Cost</span>
										<span>{booking.quote.currency} {formatAmountToString(booking.quote[key])}</span>
									</div>
								}
							</Fragment>
						))}
						<div>
							<span>VAT</span>
							<span>{booking.quote.currency} {formatAmountToString(booking.quote['vat_value'])}</span>
						</div>
					</div>
					{booking.quote['discount_amount'] &&
							<div className="d-flex justify-content-between my-1">
								<span className="text-primary">Discount {Math.round(booking.quote['discount_amount'] * 100/ booking.quote['total_charge'])}%</span>
								<span className="text-primary">{booking.quote.currency} {formatAmountToString(booking.quote['discount_amount'])}</span>
							</div>
						}
					<div className="d-flex justify-content-between charge-group my-3">
						<span>Total charge</span>
						<span>{booking.quote.currency} {formatAmountToString(booking.quote['charge'])}</span>
					</div>
				</div>
				<div className="d-flex justify-content-between charge-group my-3">
					<button className="btn main-btn mt-4 me-2" onClick={makePayment} type="button">
						Pay with Card
						{loading ? <i className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin ms-2"/> : ""}
					</button>
					<button className="btn secondary-btn mt-4" onClick={payWithWallet} type="button">
						Pay with Wallet
						{loadingWallet ? <i className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin ms-2"/> : ""}
					</button>
				</div>
			</div>
		</div>
	);
}

export default IndividualConfirmation;
