import {Fragment, useState} from 'react';
import Modal from 'react-bootstrap/Modal';

const NetworkModal = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <Fragment>
      <Modal show={show} backdrop="static" centered keyboard={false}>
        <Modal.Body>
          <span id="close-modal-btn1" onClick={handleClose} className="close-modal fs-1 mt-2">&times;
					</span>
          <div className="proceed">
            <div className="modal-body text-center p-3">
              <i className="zmdi zmdi-network-wifi-off fs-1 text-danger mb-4 d-inline-block"/>
              <h4 className="text-danger mb-20">Seems like your internet may be down!</h4>
              <p className="mx-4">No network connection. Check your connection..</p>
              <div className="d-flex justify-content-center mt-5">
                <button aria-label="Close" onClick={handleClose}
                        className="btn bg-danger trip-btn text-white text-decoration-none">Continue Anyway
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </Fragment>
  );
}
export default NetworkModal;
