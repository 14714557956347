import {InputValidator} from "../helpers/constants";

export const emailValidator = email => {
	const pattern = /\S+@\S+\.\S+/, validateEmail = email.toLowerCase().replace(/ /g, "");
	const validState = pattern.test(validateEmail) ? InputValidator.VALID : InputValidator.INVALID;
	return {validState, validateEmail}
};

export const phoneValidator = phone => {
	const pattern = /^[+]?[(]?[0-9]{3}[)]?[-\]?[0-9]{3}[-\s]?[0-9]{4,7}$/;
	return pattern.test(phone) ? InputValidator.VALID : InputValidator.INVALID
}

export const passwordMatchValidator = (password, confirmPassword) => {
	return password === confirmPassword ? InputValidator.VALID : InputValidator.INVALID;
}

export const validateIfFloat = event => {
	if(event.charCode === 101 || event.charCode === 45) event.preventDefault();
}

export const validateIfInteger = event => {
	if(event.charCode === 101 || (event.charCode === 46 || event.charCode === 45)) event.preventDefault();
}
