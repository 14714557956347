//SOURCE: DYNAMIC FORM VALIDATION WITH REACT BY NWAKAEME CYNTHIA - https://stackblitz.com/edit/stackblitz-starters-arhkmg
import TEXT from "./atom/TEXT";
import DATE from "./atom/DATE";
import FILE from "./atom/FILE";
import EMAIL from "./atom/EMAIL";
import RADIO from "./atom/RADIO";
import NUMBER from "./atom/NUMBER";
import SELECT from "./atom/SELECT";
import CHECKBOX from "./atom/CHECKBOX";
import { InputType } from "../../utils/helpers/constants";


const DynamicFields = ({ divClass, inputClass, formControl, formHook, initialValue }) => {

  switch (formControl["type"]) {
    case InputType.TEXT:
      return TEXT({ divClass, inputClass, formControl, formHook, initialValue: initialValue && initialValue[formControl["key"]] });
    case InputType.EMAIL:
      return EMAIL({ divClass, inputClass, formControl, formHook, initialValue: initialValue && initialValue[formControl["key"]] });
    case InputType.NUMBER:
      return NUMBER({ divClass, inputClass, formControl, formHook, initialValue: initialValue && initialValue[formControl["key"]] });
    case InputType.BOOLEAN:
      return CHECKBOX({ divClass, formControl, formHook, initialValue: initialValue && initialValue[formControl["key"]] });
    case InputType.RADIO:
      return RADIO({ divClass, formControl, formHook, initialValue: initialValue && initialValue[formControl["key"]] });
    case InputType.OPTIONS:
      return SELECT({ divClass, formControl, formHook, initialValue: initialValue && initialValue[formControl["key"]] });
    case InputType.DATE:
      return DATE({ divClass, formControl, formHook, initialValue: initialValue && initialValue[formControl["key"]] });
    case InputType.FILE:
      return FILE({ divClass, inputClass, formControl, formHook });

    default:
      return null
  }
}
export default DynamicFields;