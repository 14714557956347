import {useState} from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";

import DynamicFields from "../dynamicFields/DynamicFields";
import FleetService from "../../utils/services/fleet.service";
import ProfileService from "../../utils/services/profile.service";
import {DynamicFileUploadMap} from "../../utils/helpers/mappers";
import {DynamicFileUploadOption, Loading} from "../../utils/helpers/constants";


const DynamicFileUploadModal = ({onchange, type, props, dynamicFields, onSuccessResponse}) => {
  const actionProps = DynamicFileUploadMap[type];
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const completeAction = response => {
    onSuccessResponse(response);
    handleClose()
  }

  const useFormHook = useForm({
    defaultValues: {},
    mode: "all",
    reValidateMode: "onChange",
    criteriaMode: "firstError",
  });

  const processFileUpload = async () => {
    setLoading(true);
    let payload = { ...useFormHook.getValues()};

    if (payload && Object.entries(payload).length > 0) {
      const formData = new FormData();
      Object.keys(payload).map(async (key) => {
        await formData.append("document_type", key);
        await formData.append("document", payload[key]);
      });

      await handleFileUpload(formData);
    } else {
      setLoading(false);
      toast.error('Document Upload failed. Please, try again later.');
    }
  }


  const handleFileUpload = async (payload) => {
    let responseData;
    if (type === DynamicFileUploadOption.DRIVER) responseData = await ProfileService.uploadDriverDocument(props.id, payload);
    if (type === DynamicFileUploadOption.VEHICLE) responseData = await FleetService.uploadFleetDocument(props.id, payload);

    if (responseData.data.status !== Loading.SUCCESS) {
      const message = (responseData && responseData.data && responseData.data.message) ? responseData.data.message : "You cannot upload your file(s) at the moment. Please try again later.";
      toast.error(message);
    }
    else {
      completeAction(responseData.data);
      toast.success(responseData.data.message);
    }
    setLoading(false);
  }


  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered scrollable>
        <Modal.Body>
          <span id="close-modal-btn1" onClick={handleClose} className="close-modal mt-2 fs-1 text-danger">&times;
          </span>
          <div className="proceed">
            <div className="mt-2 mb-5">
              <h5>{actionProps.title}</h5>
            </div>
            <div className="mt-4">
              <div className="row">        
                <DynamicFields divClass="col-12 form-group mb-3" inputClass="input-group file-browser mb-0"
                  formControl={dynamicFields} formHook={useFormHook} initialValue={props['dynamic_fields']} />
              </div>
            </div>
            <div className="d-flex justify-content-end mt-5">
              <button onClick={useFormHook.handleSubmit(processFileUpload)} className="btn main-btn w-auto" disabled={!useFormHook.formState.isValid}>
                {actionProps.button}
                {loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default DynamicFileUploadModal;