import * as ComponentConstants from '../constants/component.constants';
import {Loading} from "../../utils/helpers/constants";

const initialState = {
  appLoader: false,
  sideNavMode: false,
  iconNavMode: false,
  iconNavOnHoverMode: false,
  mobileDropdownMode: false,
  themeMode: "",
  dynamicFields: {},
  loadingDynamicFields: "",
};

const componentReducer = (state = initialState, action) => {
  switch (action.type) {

    case ComponentConstants.LOADING_APP:
      return {...state, appLoader: action.payload};

    case ComponentConstants.TOGGLE_MOBILE_NAV:
      return {...state, sideNavMode: action.payload};

    case ComponentConstants.TOGGLE_ICON_NAV:
      return {...state, iconNavMode: action.payload};

    case ComponentConstants.TOGGLE_ICON_NAV_HOVER:
      return {...state, iconNavOnHoverMode: action.payload};

    case ComponentConstants.TOGGLE_THEME_STATE:
      return {...state, themeMode: action.payload};

    case ComponentConstants.TOGGLE_MOBILE_DROPDOWN:
      return {...state, mobileDropdownMode: action.payload};

    case ComponentConstants.FETCH_DYNAMIC_FIELDS_REQUEST:
      return {...state, loadingDynamicFields: Loading.FETCHING};

    case ComponentConstants.FETCH_DYNAMIC_FIELDS_SUCCESS:
      let dynamicFields = {...state.dynamicFields, [action.payload.type]: action.payload.data};
      return {...state, loadingDynamicFields: Loading.SUCCESS, dynamicFields};

    case ComponentConstants.FETCH_DYNAMIC_FIELDS_FAILURE:
      return {...state, loadingDynamicFields: Loading.FAILED};

    case 'RESET_STATE':
      return initialState;

    default:
      return state;
  }
};

export default componentReducer;
