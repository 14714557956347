export const Cities = [
  /************************* ABUJA *************************/
  { city: "Abaji", state: "Abuja", longitude: 6.9333255, latitude: 8.4692081 },
  { city: "Abuja", state: "Abuja", longitude: 7.4708467, latitude: 9.0845647 },
  { city: "Wuse", state: "Abuja", longitude: 7.4701862, latitude: 9.078749 },
  { city: "Abuja Municipal Area Council", state: "Abuja", longitude: 7.4708467, latitude: 9.0845647 },
  { city: "Bwari", state: "Abuja", longitude: 7.4900776, latitude: 9.035318 },
  { city: "Gwagwalada", state: "Abuja", longitude: 7.083234399999999, latitude: 8.9548203 },
  { city: "Kuje", state: "Abuja", longitude: 7.483784, latitude: 9.0398821 },
  { city: "Kwali", state: "Abuja", longitude: 7.176668899999999, latitude: 8.828685499999999 },
  { city: "Keffi", state: "Abuja", longitude: 7.7081354, latitude: 8.915748899999999 },
  /************************* LAGOS *************************/
  { city: "Ajeromi-Ifelodun", state: "Lagos", longitude: 3.3338547, latitude: 6.455477699999999 },
  { city: "Ajeromi", state: "Lagos", longitude: 3.3339, latitude: 6.4555 },
  { city: "Apapa Iganmu", state: "Lagos", longitude: 3.3515, latitude: 6.4846 },
  { city: "Alimosho", state: "Lagos", longitude: 3.2569872, latitude: 6.5744462 },
  { city: "Agbado/Oke-Odo", state: "Lagos", longitude: 3.2682, latitude: 6.6475 },
  { city: "Egbe-Idimu", state: "Lagos", longitude: 3.2825, latitude: 6.5739 },
  { city: "Eti-Osa East", state: "Lagos", longitude: 3.590445, latitude: 6.476686 },
  { city: "Eredo", state: "Lagos", longitude: 3.9987, latitude: 6.6244 },
  { city: "Eti-Osa West", state: "Lagos", longitude: 3.6015207, latitude: 6.4589849 },
  { city: "Mosan Okunola", state: "Lagos", longitude: 3.2893, latitude: 6.6117 },
  { city: "Orile-Agege", state: "Lagos", longitude: 3.3112, latitude: 6.6288 },
  { city: "Oriade", state: "Lagos", longitude: 4.8728, latitude: 7.5194 },
  { city: "Badagry West", state: "Lagos", longitude: 2.8901, latitude: 6.4183 },
  { city: "Olorunda", state: "Lagos", longitude: 4.5728, latitude: 7.8583 },
  { city: "Ifelodun", state: "Lagos", longitude: 5.1432, latitude: 8.5381 },
  { city: "Ikosi Ejinrin", state: "Lagos", longitude: 3.7141, latitude: 6.6492 },
  { city: "Iru Victoria Island", state: "Lagos", longitude: 3.4403, latitude: 6.4336 },
  { city: "Ojokoro", state: "Lagos", longitude: 3.2835, latitude: 6.6797 },
  { city: "Onigbongbo", state: "Lagos", longitude: 3.3641, latitude: 6.5831 },
  { city: "Imota", state: "Lagos", longitude: 3.6737, latitude: 6.6729 },
  { city: "Ikosi Isheri", state: "Lagos", longitude: 3.4216, latitude: 6.6287 },
  { city: "Yaba", state: "Lagos", longitude: 3.3711, latitude: 6.5095 },
  { city: "Odi-Olowo", state: "Lagos", longitude: 3.3606, latitude: 6.5572 },
  { city: "Oto Awori", state: "Lagos", longitude: 3.4151, latitude: 6.4421 },
  { city: "Ejigbo", state: "Lagos", longitude: 4.3052, latitude: 7.9045 },
  { city: "Coker Aguda", state: "Lagos", longitude: 3.3380, latitude: 6.4880 },
  { city: "Itire-Ikate", state: "Lagos", longitude: 3.3451, latitude: 6.5072 },
  { city: "Okota", state: "Lagos", longitude: 3.3137, latitude: 6.5088 },
  { city: "Igbo Efon", state: "Lagos", longitude: 3.5219, latitude: 6.4385 },
  { city: "Osapa London", state: "Lagos", longitude: 3.5147, latitude: 6.4428 },
  { city: "Victoria garden City", state: "Lagos", longitude: 3.5446, latitude: 6.4660 },
  { city: "Igbo Efon", state: "Lagos", longitude: 3.5219, latitude: 6.4385 },
  { city: "Igbo Efon", state: "Lagos", longitude: 3.5219, latitude: 6.4385 },
  { city: "Amuwo Odofin", state: "Lagos", longitude: 3.2885455, latitude: 6.4291734 },
  { city: "Badagry", state: "Lagos", longitude: 2.8901388, latitude: 6.4182673 },
  { city: "Epe", state: "Lagos", longitude: 3.9699874, latitude: 6.586663199999999 },
  { city: "Eti Osa", state: "Lagos", longitude: 3.6015207, latitude: 6.4589849 },
  { city: "Ifako-Ijaiye", state: "Lagos", longitude: 3.2885455, latitude: 6.6849677 },
  { city: "Kosofe", state: "Lagos", longitude: 3.4094629, latitude: 6.6201123 },
  { city: "Lagos Island", state: "Lagos", longitude: 3.4245984, latitude: 6.454879099999999 },
  { city: "Lagos Mainland", state: "Lagos", longitude: 3.3780722, latitude: 6.5059002 },
  { city: "Ojo", state: "Lagos", longitude: 3.1579655, latitude: 6.4579097 },
  { city: "Shomolu", state: "Lagos", longitude: 3.3841676, latitude: 6.539173 },
  { city: "Lagos", state: "Lagos", longitude: 3.391531071, latitude: 6.443261653 },
  { city: "Anthony", state: "Lagos", longitude: 3.3716443, latitude: 6.564386799999999 },
  { city: "Bariga", state: "Lagos", longitude: 3.3849441, latitude: 6.5391037 },
  { city: "Gbagada", state: "Lagos", longitude: 3.3914817, latitude: 6.558351699999999 },
  { city: "Maryland", state: "Lagos", longitude: 3.365344, latitude: 6.576421 },
  { city: "Ketu", state: "Lagos", longitude: 3.3904469, latitude: 6.597303699999999 },
  { city: "Agboyi", state: "Lagos", longitude: 3.4037093, latitude: 6.585401399999999 },
  { city: "Ojota", state: "Lagos", longitude: 3.3785711, latitude: 6.5873161 },
  { city: "Cement", state: "Lagos", longitude: 3.3187935, latitude: 6.6067085 },
  { city: "Dopemu", state: "Lagos", longitude: 3.3139943, latitude: 6.612897999999999 },
  { city: "Ile Epo", state: "Lagos", longitude: 3.2961968, latitude: 6.639290499999999 },
  { city: "Abule Egba", state: "Lagos", longitude: 3.2991753, latitude: 6.6483728 },
  { city: "Alakuko", state: "Lagos", longitude: 3.2646492, latitude: 6.681121099999999 },
  { city: "Toll gate", state: "Lagos", longitude: 3.2618331, latitude: 6.688155099999999 },
  { city: "Iju", state: "Lagos", longitude: 3.3233339, latitude: 6.650004500000001 },
  { city: "Ikeja", state: "Lagos", longitude: 3.3514863, latitude: 6.601838 },
  { city: "Agege", state: "Lagos", longitude: 3.3208916, latitude: 6.617973099999999 },
  { city: "Omole", state: "Lagos", longitude: 3.3557854, latitude: 6.6375968 },
  { city: "Iju-Ishaga", state: "Lagos", longitude: 3.3235571, latitude: 6.6409144 },
  { city: "Ojodu", state: "Lagos", longitude: 3.3741647, latitude: 6.6470273 },
  { city: "Magodo", state: "Lagos", longitude: 3.3859966, latitude: 6.635437599999999 },
  { city: "Ogba", state: "Lagos", longitude: 3.3441475, latitude: 6.625188199999999 },
  { city: "Ayobo", state: "Lagos", longitude: 3.2438129, latitude: 6.605353300000001 },
  { city: "Iyana Ipaja", state: "Lagos", longitude: 3.2873135, latitude: 6.6096226 },
  { city: "Gowon", state: "Lagos", longitude: 3.2847716, latitude: 6.603612999999999 },
  { city: "Ipaja", state: "Lagos", longitude: 3.2659066, latitude: 6.613069899999999 },
  { city: "Ikola", state: "Lagos", longitude: 3.2459992, latitude: 6.637779999999999 },
  { city: "Command", state: "Lagos", longitude: 3.2572151, latitude: 6.622042 },
  { city: "Alagbole", state: "Lagos", longitude: 3.354280499999999, latitude: 6.6572404 },
  { city: "Sango Ota", state: "Lagos", longitude: 3.2560028, latitude: 6.707727999999999 },
  { city: "Oju Ore", state: "Lagos", longitude: 3.2265151, latitude: 6.6888288 },
  { city: "Idiroko Road", state: "Lagos", longitude: 3.1922085, latitude: 6.6822458 },
  { city: "Iyana Iyesi", state: "Lagos", longitude: 3.1702494, latitude: 6.6689235 },
  { city: "Igando", state: "Lagos", longitude: 3.239370999999999, latitude: 6.551004 },
  { city: "Ikotun", state: "Lagos", longitude: 3.2505651, latitude: 6.5630684 },
  { city: "Jakande-Lekki", state: "Lagos", longitude: 3.5077018, latitude: 6.435825299999999 },
  { city: "Jakande-Isolo", state: "Lagos", longitude: 3.3032085, latitude: 6.530067000000001 },
  { city: "Egbeda", state: "Lagos", longitude: 3.2910616, latitude: 6.5915737 },
  { city: "Isheri", state: "Lagos", longitude: 3.3603044, latitude: 6.6351156 },
  { city: "Akowonjo", state: "Lagos", longitude: 3.3021204, latitude: 6.602068 },
  { city: "Oshodi", state: "Lagos", longitude: 3.3509833, latitude: 6.554668899999999 },
  { city: "Isolo", state: "Lagos", longitude: 3.322734, latitude: 6.537182500000001 },
  { city: "Ilasa", state: "Lagos", longitude: 3.2838282, latitude: 6.4149122 },
  { city: "Mushin", state: "Lagos", longitude: 3.3489671, latitude: 6.535233000000001 },
  { city: "Ijesha", state: "Lagos", longitude: 3.331049, latitude: 6.5028036 },
  { city: "Ago Palace", state: "Lagos", longitude: 3.3027681, latitude: 6.500195799999999 },
  { city: "FESTAC", state: "Lagos", longitude: 3.2818048, latitude: 6.4702531 },
  { city: "Apapa", state: "Lagos", longitude: 3.3691241, latitude: 6.428651899999999 },
  { city: "Surulere", state: "Lagos", longitude: 3.348572, latitude: 6.498292999999999, },
  { city: "Ebute Meta", state: "Lagos", longitude: 3.3769472, latitude: 6.4817446 },
  { city: "idi Araba", state: "Lagos", longitude: 3.3477076, latitude: 6.5152684 },
  { city: "Akoka", state: "Lagos", longitude: 3.3921481, latitude: 6.5260973 },
  { city: "Obalende", state: "Lagos", longitude: 3.410724, latitude: 6.4483221 },
  { city: "Ikoyi", state: "Lagos", longitude: 3.433333, latitude: 6.449999999999998 },
  { city: "Victoria Island - VI", state: "Lagos", longitude: 3.4238032, latitude: 6.429098199999999 },
  { city: "Lekki Phase 1", state: "Lagos", longitude: 3.4723495, latitude: 6.447809299999999 },
  { city: "Agungi", state: "Lagos", longitude: 3.5180065, latitude: 6.447133000000001 },
  { city: "Chevron", state: "Lagos", longitude: 3.5306693, latitude: 6.449815699999999 },
  { city: "Oniru", state: "Lagos", longitude: 3.4462735, latitude: 6.4225368 },
  { city: "Ajah", state: "Lagos", longitude: 3.5725244, latitude: 6.464587400000001 },
  { city: "Sangotedo", state: "Lagos", longitude: 3.6268464, latitude: 6.4534872 },
  { city: "Ibeju Lekki", state: "Lagos", longitude: 3.8858955, latitude: 6.4569054 },
  { city: "Mile 12", state: "Lagos", longitude: 3.3968535, latitude: 6.601532499999999 },
  { city: "Mile 2", state: "Lagos", longitude: 3.315932, latitude: 6.462391999999999 },
  { city: "Ikorodu", state: "Lagos", longitude: 3.5104537, latitude: 6.6194131 },
  { city: "Ikorodu North", state: "Lagos", longitude: 3.5133391, latitude: 6.634880799999999 },
  { city: "Ikorodu West", state: "Lagos", longitude: 3.4808421, latitude: 6.622987500000001 },
  { city: "Ogijo", state: "Lagos", longitude: 3.5155086, latitude: 6.699425499999999 },
  { city: "Ijede", state: "Lagos", longitude: 3.596457, latitude: 6.5708251 },
  { city: "Baiyeku", state: "Lagos", longitude: 3.536804, latitude: 6.560365099999999 },
  { city: "Igbogbo", state: "Lagos", longitude: 3.525617, latitude: 6.582353199999999 },
  { city: "Jakande gate", state: "Lagos", longitude: 3.3084109, latitude: 6.530052999999999 },
  { city: "LASU", state: "Lagos", longitude: 3.2008727, latitude: 6.4696511 },
  { city: "Okomaiko", state: "Lagos", longitude: 3.1905192, latitude: 6.4750169 },
  { city: "Ajangbadi", state: "Lagos", longitude: 3.1702641, latitude: 6.4635316 },
  { city: "Agbara", state: "Lagos", longitude: 3.1114879, latitude: 6.5114449 },
  { city: "Badagary", state: "Lagos", longitude: 2.8901388, latitude: 6.4182673 },
  { city: "Trade Fair", state: "Lagos", longitude: 3.2533339, latitude: 6.4666377 },
  { city: "Alaba", state: "Lagos", longitude: 3.193621, latitude: 6.4601837 },
  { city: "Iyana Ishashi", state: "Lagos", longitude: 3.1713845, latitude: 6.4962089 },
  { city: "Berger", state: "Lagos", longitude: 3.3710171, latitude: 6.6395189 },
  { city: "Osasa", state: "Lagos", longitude: 3.609692999999999, latitude: 6.468457799999999 },
  { city: "Iganmu", state: "Lagos", longitude: 3.3514863, latitude: 6.4846306 },
  { city: "Ikeja NBC", state: "Lagos", longitude: 3.337941, latitude: 6.5966065 },
  { city: "Ajao Estate", state: "Lagos", longitude: 3.3277003, latitude: 6.5496257 },
  { city: "Ikorodu Kimberly", state: "Lagos", longitude: 3.5276681, latitude: 6.680885399999999 },
  { city: "Ilupeju", state: "Lagos", longitude: 3.3566738, latitude: 6.553648399999999 },
  { city: "Eric Moore", state: "Lagos", longitude: 3.356833, latitude: 6.484505299999999 },
  { city: "Lekki", state: "Lagos", longitude: 3.5851718, latitude: 6.4698419 },
  { city: "Five Star", state: "Lagos", longitude: 6.540452699999999, latitude: 6.540452699999999 },
  { city: "Mafoluku", state: "Lagos", longitude: 3.3323378, latitude: 6.555401 },
  { city: "Ladipo", state: "Lagos", longitude: 3.3465227, latitude: 6.536976500000001 },
  { city: "Bolade", state: "Lagos", longitude: 3.3263003, latitude: 6.5593588 },
  { city: "Charity", state: "Lagos", longitude: 3.3235421, latitude: 6.490263700000001 },
  { city: "Ojuelegba", state: "Lagos", longitude: 3.3630196, latitude: 6.510709400000001 },
  { city: "Onipanu", state: "Lagos", longitude: 3.3604924, latitude: 6.5375833 },
  { city: "Western Avenue", state: "Lagos", longitude: 3.3625136, latitude: 6.499891100000001 },
  { city: "Obanikoro", state: "Lagos", longitude: 3.3703842, latitude: 6.5524054 },
  { city: "Local Airport", state: "Lagos", longitude: 3.3327257, latitude: 6.5891856 },
  { city: "Idumota", state: "Lagos", longitude: 3.3841554, latitude: 6.457160599999999 },
  { city: "Oyingbo", state: "Lagos", longitude: 3.3918106, latitude: 6.4790418 },
  { city: "Oworonshoki", state: "Lagos", longitude: 3.4006205, latitude: 6.5536278 },
  { city: "Ikosi Ketu", state: "Lagos", longitude: 3.3842473, latitude: 6.6149501 },
  { city: "Ogudu", state: "Lagos", longitude: 3.3917493, latitude: 6.5749418 },
  { city: "Shangisha", state: "Lagos", longitude: 3.3740841, latitude: 6.615769299999998 },
  { city: "Ijora", state: "Lagos", longitude: 3.3716443, latitude: 6.468532 },
  { city: "Costain", state: "Lagos", longitude: 3.3689501, latitude: 6.4829752 },
  { city: "Volks", state: "Lagos", longitude: 3.212061, latitude: 6.459673 },
  { city: "Ajegunle", state: "Lagos", longitude: 3.3552653, latitude: 6.443368599999999 },
  { city: "Meran", state: "Lagos", longitude: 3.2697358, latitude: 6.6391027 },
  { city: "Alagbado", state: "Lagos", longitude: 3.2633918, latitude: 6.6584403 },
  { city: "Ipaye", state: "Lagos", longitude: 3.1953108, latitude: 6.4932041 },
  { city: "Tin Can,", state: "Lagos", longitude: 3.3435673, latitude: 6.4310151 },
  { city: "Abule Oshun", state: "Lagos", longitude: 3.2441548, latitude: 6.448544399999999 },
  { city: "Alakija", state: "Lagos", longitude: 3.2718382, latitude: 6.4559938 },
  { city: "Ijaye", state: "Lagos", longitude: 3.3313365, latitude: 6.6233064 },
  { city: "Aboru", state: "Lagos", longitude: 3.275304, latitude: 6.6174194 },
  { city: "Isheri Osun", state: "Lagos", longitude: 3.2935778, latitude: 6.5302694 },
  { city: "Oke Arin", state: "Lagos", longitude: 3.3847327, latitude: 6.460078299999999 },
  { city: "CMS - Marina", state: "Lagos", longitude: 3.390248, latitude: 6.450277300000001 },
  { city: "Kirikiri", state: "Lagos", longitude: 3.3080485, latitude: 6.44434 },
  { city: "Marina", state: "Lagos", longitude: 3.390155, latitude: 6.4505453 },
  { city: "Tafawa Balewa Square - TBS", state: "Lagos", longitude: 3.399661, latitude: 6.4470251 },
  { city: "Onikan", state: "Lagos", longitude: 3.4018969, latitude: 6.4417319 },
  { city: "Dolphin Estate", state: "Lagos", longitude: 3.4151382, latitude: 6.4582672 },
  { city: "Iba", state: "Lagos", longitude: 3.9065872, latitude: 6.4876633 },
  { city: "Akodo", state: "Lagos", longitude: 3.9298355, latitude: 6.4386265 },
  { city: "Badore", state: "Lagos", longitude: 3.6014641, latitude: 6.5037588 },
  { city: "Ikota", state: "Lagos", longitude: 3.5501862, latitude: 6.4481755 },
  { city: "Ikate", state: "Lagos", longitude: 3.3451358, latitude: 6.507242499999999 },
  { city: "Awoyaya", state: "Lagos", longitude: 3.7051201, latitude: 6.4652268 },
  { city: "Bogije", state: "Lagos", longitude: 3.766204600000001, latitude: 6.480825499999999 },
  { city: "Lakowe", state: "Lagos", longitude: 3.7416047, latitude: 6.4785927 },
  { city: "Iyana Oba", state: "Lagos", longitude: 3.204947, latitude: 6.460369 },
  { city: "Eleko", state: "Lagos", longitude: 3.8496256, latitude: 6.463162199999999 },
  /************************* ABIA *************************/
  { city: "Aba", state: "Abia", longitude: 7.3732655, latitude: 5.1215877 },
  { city: "Aba North", state: "Abia", longitude: 7.3653299, latitude: 5.1279828 },
  { city: "Aba South", state: "Abia", longitude: 7.3408535, latitude: 5.0702604 },
  { city: "Isiala Ngwa North", state: "Abia", longitude: 7.4165053, latitude: 5.286818600000001 },
  { city: "Isiala Ngwa South", state: "Abia", longitude: 7.55228247689065, latitude: 5.475771247016576 },
  { city: "Ukwa West", state: "Abia", longitude: 7.382290000000002, latitude: 5.397842600000001 },
  { city: "Ukwa East", state: "Abia", longitude: 7.3571366, latitude: 4.889571699999999 },
  { city: "Obingwa", state: "Abia", longitude: 7.386832399999998, latitude: 5.184496499999999 },
  { city: "Ikwuano", state: "Abia", longitude: 7.589737200000001, latitude: 5.409292100000001 },
  { city: "Bende", state: "Abia", longitude: 7.6304012, latitude: 5.557042600000001 },
  { city: "Arochukwu", state: "Abia", longitude: 7.886744599999999, latitude: 5.3844563 },
  { city: "Ohafia", state: "Abia", longitude: 7.839855099999999, latitude: 5.6432006 },
  { city: "Isuikwuato", state: "Abia", longitude: 7.502777899999999, latitude: 5.7351561 },
  { city: "Umuahia North", state: "Abia", longitude: 7.493371499999999, latitude: 5.529347899999999 },
  { city: "Umuahia South", state: "Abia", longitude: 7.436955500000001, latitude: 5.4746608 },
  { city: "Ugwunagbo", state: "Abia", longitude: 7.4211307, latitude: 5.9295449 },
  { city: "Osisioma", state: "Abia", longitude: 7.330053, latitude: 5.204903499999999 },
  /************************* ADAMAWA *************************/
  { city: "Yola", state: "Adamawa", longitude: 12.4953899, latitude: 9.203496299999999 },
  /************************* ANAMBRA *************************/
  { city: "Aguata", state: "Anambra", longitude: 7.100948799999999, latitude: 6.0085842 },
  { city: "Awka North", state: "Anambra", longitude: 6.987968100000001, latitude: 6.336758499999999 },
  { city: "Awka South", state: "Anambra", longitude: 7.060143299999999, latitude: 6.1954143 },
  { city: "Anambra East", state: "Anambra", longitude: 6.862203099999999, latitude: 6.3353125 },
  { city: "Anambra West", state: "Anambra", longitude: 6.726655500000001, latitude: 6.4526262 },
  { city: "Anaocha", state: "Anambra", longitude: 7.002834, latitude: 6.0872117 },
  { city: "Ayamelum", state: "Anambra", longitude: 6.9638624, latitude: 6.4878428 },
  { city: "Dunukofia", state: "Anambra", longitude: 6.9786326, latitude: 6.2009693 },
  { city: "Ekwusigo", state: "Anambra", longitude: 6.8511814, latitude: 5.9565942 },
  { city: "Idemili North", state: "Anambra", longitude: 6.947753199999999, latitude: 6.1236562 },
  { city: "Idemili South", state: "Anambra", longitude: 6.898363799999999, latitude: 6.100352499999999 },
  { city: "Ihiala", state: "Anambra", longitude: 6.8511814, latitude: 5.8516439 },
  { city: "Njikoka", state: "Anambra", longitude: 6.988033499999999, latitude: 6.178444 },
  { city: "Nnewi North", state: "Anambra", longitude: 6.910180599999999, latitude: 6.0136533 },
  { city: "Nnewi South", state: "Anambra", longitude: 6.985347399999999, latitude: 5.960165 },
  { city: "Ogbaru", state: "Anambra", longitude: 6.7279918, latitude: 5.9213455 },
  { city: "Onitsha", state: "Anambra", longitude: 6.7923994, latitude: 6.1329419 },
  { city: "Onitsha North", state: "Anambra", longitude: 6.8029489, latitude: 6.162390200000001 },
  { city: "Onitsha South", state: "Anambra", longitude: 6.7761686, latitude: 6.1363826 },
  { city: "Orumba North", state: "Anambra", longitude: 7.2194466, latitude: 6.0543288 },
  { city: "Orumba South", state: "Anambra", longitude: 7.200580599999999, latitude: 5.999403999999999 },
  { city: "Oyi", state: "Anambra", longitude: 6.8887202, latitude: 6.2245579 },
  /************************* AKWA IBOM *************************/
  { city: "Eket", state: "Akwa Ibom", longitude: 8.06667, latitude: 4.66056 },
  { city: "Abak", state: "Akwa Ibom", longitude: 7.77427, latitude: 5.003286 },
  { city: "Eastern Obolo", state: "Akwa Ibom", longitude: 7.6657, latitude: 4.5116 },
  { city: "Esit Eket", state: "Akwa Ibom", longitude: 8.06667, latitude: 4.66056 },
  { city: "Essien Udim", state: "Akwa Ibom", longitude: 7.176668899999999, latitude: 8.828685499999999 },
  { city: "Etim Ekpo", state: "Akwa Ibom", longitude: 7.68333, latitude: 5.13333 },
  { city: "Etinan", state: "Akwa Ibom", longitude: 7.83333, latitude: 4.85 },
  { city: "Ibeno", state: "Akwa Ibom", longitude: 8.0725, latitude: 4.565 },
  { city: "Ibesikpo Asutan", state: "Akwa Ibom", longitude: 7.95, latitude: 4.76667 },
  { city: "Ibiono-Ibom", state: "Akwa Ibom", longitude: 7.89306, latitude: 5.20694 },
  { city: "Ika", state: "Akwa Ibom", longitude: 7.53333, latitude: 5.01667 },
  { city: "Ikono", state: "Akwa Ibom", longitude: 7.79389, latitude: 5.21028 },
  { city: "Ikot Abasi", state: "Akwa Ibom", longitude: 7.65, latitude: 4.63333 },
  { city: "Ikot Ekpene", state: "Akwa Ibom", longitude: 7.71472, latitude: 5.18194 },
  { city: "Ini", state: "Akwa Ibom", longitude: 7.74889, latitude: 5.40417 },
  { city: "Itu", state: "Akwa Ibom", longitude: 7.98333, latitude: 5.16667 },
  { city: "Mbo", state: "Akwa Ibom", longitude: 8.23333, latitude: 4.65 },
  { city: "Mkpat-Enin", state: "Akwa Ibom", longitude: 7.74861, latitude: 4.73361 },
  { city: "Nsit-Atai", state: "Akwa Ibom", longitude: 8.01667, latitude: 4.85 },
  { city: "Nsit-Ibom", state: "Akwa Ibom", longitude: 7.89306, latitude: 4.90083 },
  { city: "Nsit-Ubium", state: "Akwa Ibom", longitude: 7.93333, latitude: 4.76667 },
  { city: "Obot Akara", state: "Akwa Ibom", longitude: 7.6, latitude: 5.26667 },
  { city: "Okobo", state: "Akwa Ibom", longitude: 8.13194, latitude: 4.82722 },
  { city: "Onna", state: "Akwa Ibom", longitude: 7.86667, latitude: 4.65 },
  { city: "Oron", state: "Akwa Ibom", longitude: 8.23389, latitude: 4.82583 },
  { city: "Oruk Anam", state: "Akwa Ibom", longitude: 7.65, latitude: 4.81667 },
  { city: "Udung-Uko", state: "Akwa Ibom", longitude: 8.26528, latitude: 4.75222 },
  { city: "Ukanafun", state: "Akwa Ibom", longitude: 7.6, latitude: 4.9 },
  { city: "Uruan", state: "Akwa Ibom", longitude: 8.05, latitude: 5.03333 },
  { city: "Urue-Offong/Oruko", state: "Akwa Ibom", longitude: 8.16944, latitude: 4.71694 },
  { city: "Uyo", state: "Akwa Ibom", longitude: 7.90306, latitude: 5.01861 },
  /************************* BAUCHI *************************/
  { city: "Bauchi", state: "Bauchi", longitude: 9.999194299999999, latitude: 10.7760624 },
  /************************* BENUE *************************/
  { city: "Otukpo", state: "Benue", longitude: 8.1393179, latitude: 7.1982133 },
  { city: "Markurdi", state: "Benue", longitude: 8.539144, latitude: 7.7321516 },
  { city: "Yagba", state: "Benue", longitude: 8.2028444, latitude: 7.7555799 },
  { city: "Oju", state: "Benue", longitude: 8.414995599999999, latitude: 6.8398127 },
  { city: "Gboko", state: "Benue", longitude: 9.001842399999999, latitude: 7.336842400000001 },
  /************************* BORNO *************************/
  { city: "Yauri", state: "Borno", longitude: 13.2154951, latitude: 11.7649844 },
  /************************* DELTA *************************/
  { city: "Asaba", state: "Delta", longitude: 6.6958939, latitude: 6.2059295 },
  { city: "Ethiope East", state: "Delta", longitude: 5.9621106, latitude: 5.6782458 },
  { city: "Ethiope West", state: "Delta", longitude: 5.751047499999999, latitude: 5.932316 },
  { city: "Okpe", state: "Delta", longitude: 5.9475763, latitude: 5.4341328 },
  { city: "Udu", state: "Delta", longitude: 5.8353837, latitude: 5.4704358 },
  { city: "Uvwie", state: "Delta", longitude: 5.7826597, latitude: 5.5649555 },
  { city: "Aniocha North", state: "Delta", longitude: 6.471658, latitude: 6.346079700000001 },
  { city: "Aniocha South", state: "Delta", longitude: 6.4503434, latitude: 6.156216400000001 },
  { city: "Ika North East", state: "Delta", longitude: 6.3013432, latitude: 6.2213208 },
  { city: "Ika South", state: "Delta", longitude: 6.173911599999999, latitude: 6.2650871 },
  { city: "Ndokwa East", state: "Delta", longitude: 6.5356449, latitude: 5.6511495 },
  { city: "Ndokwa", state: "Delta", longitude: 6.4413118, latitude: 5.7108535 },
  { city: "Oshimili North", state: "Delta", longitude: 6.621061099999999, latitude: 6.4076815 },
  { city: "Oshimili South", state: "Delta", longitude: 6.621061099999999, latitude: 6.0698466 },
  { city: "Ukwuani", state: "Delta", longitude: 6.195132, latitude: 5.822521 },
  { city: "Bomadi", state: "Delta", longitude: 5.9297436, latitude: 5.1696539 },
  { city: "Burutu", state: "Delta", longitude: 5.5066242, latitude: 5.3509707 },
  { city: "Isoko North", state: "Delta", longitude: 6.2163597, latitude: 5.5919041 },
  { city: "Isoko South", state: "Delta", longitude: 6.195132, latitude: 5.4042968 },
  { city: "Patani", state: "Delta", longitude: 6.1911526, latitude: 5.2287989 },
  { city: "Warri", state: "Delta", longitude: 5.7668515, latitude: 5.549430399999999 },
  { city: "Warri North", state: "Delta", longitude: 5.1432068, latitude: 5.959341299999999 },
  { city: "Warri South", state: "Delta", longitude: 5.6037465, latitude: 5.6608683 },
  { city: "Warri South-West", state: "Delta", longitude: 5.435853, latitude: 5.578705100000001 },
  { city: "Agbor", state: "Delta", longitude: 6.2057449, latitude: 6.2541441 },
  { city: "Sapele", state: "Delta", longitude: 5.6931356, latitude: 5.8750769 },
  { city: "Ughelli", state: "Delta", longitude: 5.993833899999999, latitude: 5.5001866 },
  { city: "Ughelli North", state: "Delta", longitude: 6.1102943, latitude: 5.65577 },
  { city: "Ughelli South", state: "Delta", longitude: 5.940970999999999, latitude: 5.2403052 },
  { city: "Obiariku", state: "Delta", longitude: 6.1580011, latitude: 5.8386689 },
  /************************* EDO *************************/
  { city: "Benin City", state: "Edo", longitude: 5.6037465, latitude: 6.334986 },
  { city: "Ekpoma", state: "Edo", longitude: 6.0885528, latitude: 6.743968199999999 },
  { city: "Auchi", state: "Edo", longitude: 6.274773400000001, latitude: 7.066864499999999 },
  { city: "Akoko-Edo", state: "Edo", longitude: 6.1102943, latitude: 7.353331499999999 },
  { city: "Egor", state: "Edo", longitude: 5.572229699999999, latitude: 6.3671083 },
  { city: "Esan Central", state: "Edo", longitude: 6.2163597, latitude: 6.6887913 },
  { city: "Esan North-East", state: "Edo", longitude: 6.3438785, latitude: 6.729735 },
  { city: "Esan West", state: "Edo", longitude: 6.131492799999999, latitude: 6.6898534 },
  { city: "Esan South-East", state: "Edo", longitude: 6.492979699999999, latitude: 6.6214492 },
  { city: "Etsako Central", state: "Edo", longitude: 6.4503434, latitude: 7.0056607 },
  { city: "Etsako East", state: "Edo", longitude: 6.4503434, latitude: 7.262687100000001 },
  { city: "Etsako West", state: "Edo", longitude: 6.2800864, latitude: 7.0079603 },
  { city: "Igueben", state: "Edo", longitude: 6.2389221, latitude: 6.5888315 },
  { city: "Ikpoba-Okha", state: "Edo", longitude: 5.6878736, latitude: 6.164866099999999 },
  { city: "Oredo", state: "Edo", longitude: 5.5407299, latitude: 6.2298249 },
  { city: "Orhionmwon", state: "Edo", longitude: 5.983257699999999, latitude: 6.1194361 },
  { city: "Ovia North-East", state: "Edo", longitude: 5.6037465, latitude: 6.5046794 },
  { city: "Ovia South-West", state: "Edo", longitude: 5.3102505, latitude: 6.4653494 },
  { city: "Owan East", state: "Edo", longitude: 6.0255738, latitude: 7.0969157 },
  { city: "Owan West", state: "Edo", longitude: 5.8564864, latitude: 6.927869299999999 },
  { city: "Uhunmwonde", state: "Edo", longitude: 5.983257699999999, latitude: 6.457908199999999 },
  /************************* EKITI *************************/
  { city: "Ado Ekiti", state: "Ekiti", longitude: 5.2486703, latitude: 7.621857800000001 },
  { city: "Efon", state: "Ekiti", longitude: 4.914323899999999, latitude: 7.691906500000001 },
  { city: "Ekiti West", state: "Ekiti", longitude: 5.0390536, latitude: 7.690466 },
  { city: "Ekiti East", state: "Ekiti", longitude: 5.6668306, latitude: 7.7259207 },
  { city: "Ekiti South-West", state: "Ekiti", longitude: 5.0390536, latitude: 7.517556399999999 },
  { city: "Ikole", state: "Ekiti", longitude: 5.514493000000001, latitude: 7.798266099999998 },
  { city: "Ise/Orun", state: "Ekiti", longitude: 5.414900299999999, latitude: 7.426855400000001 },
  { city: "Ikere", state: "Ekiti", longitude: 5.231887899999999, latitude: 7.499125500000001 },
  { city: "Emure", state: "Ekiti", longitude: 5.462054500000001, latitude: 7.4316665 },
  { city: "Ilejemeje", state: "Ekiti", longitude: 5.237108699999999, latitude: 7.959051799999999 },
  { city: "Moba", state: "Ekiti", longitude: 5.1223608, latitude: 7.9930673 },
  { city: "Oye", state: "Ekiti", longitude: 5.3285505, latitude: 7.7979306 },
  { city: "Ido-Osi,", state: "Ekiti", longitude: 5.205790899999999, latitude: 7.861778999999999 },
  { city: "Irepodun/Ifelodun", state: "Ekiti", longitude: 5.2475518, latitude: 7.731258499999999 },
  { city: "Ijero", state: "Ekiti", longitude: 5.0676766, latitude: 7.811959300000001 },
  /************************* GOMBE *************************/
  { city: "Gombe", state: "Gombe", longitude: 11.1927587, latitude: 10.3637795 },
  /************************* JIGAWA *************************/
  { city: "Jigawa", state: "Jigawa", longitude: 9.561586699999999, latitude: 12.228012 },
  /************************* KADUNA *************************/
  { city: "Birni – Gwari", state: "Kaduna", longitude: 6.5383124, latitude: 10.6613339 },
  { city: "Chikun", state: "Kaduna", longitude: 7.110136499999999, latitude: 10.2697436 },
  { city: "Giwa", state: "Kaduna", longitude: 7.417856999999999, latitude: 11.2801638 },
  { city: "Igabi", state: "Kaduna", longitude: 7.7151939, latitude: 10.8059521 },
  { city: "Ikara", state: "Kaduna", longitude: 8.2267665, latitude: 11.178802 },
  { city: "Jaba", state: "Kaduna", longitude: 8.024700500000002, latitude: 9.4753712 },
  { city: "Jema’a", state: "Kaduna", longitude: 8.388272299999999, latitude: 9.4641073 },
  { city: "Kachia", state: "Kaduna", longitude: 7.955199899999999, latitude: 9.873441800000002 },
  { city: "Kaduna", state: "Kaduna", longitude: 7.433739500000001, latitude: 10.503644 },
  { city: "Kaduna North", state: "Kaduna", longitude: 7.4489531, latitude: 10.5432199 },
  { city: "Kaduna South", state: "Kaduna", longitude: 7.413801999999999, latitude: 10.4630656 },
  { city: "Kagarko", state: "Kaduna", longitude: 7.6955129, latitude: 9.491027599999999 },
  { city: "Kajuru", state: "Kaduna", longitude: 7.683299799999999, latitude: 10.3242857 },
  { city: "Kaura", state: "Kaduna", longitude: 8.503456099999999, latitude: 9.6321773 },
  { city: "Kauru", state: "Kaduna", longitude: 8.148877499999999, latitude: 10.57481 },
  { city: "Kubau", state: "Kaduna", longitude: 8.157072399999999, latitude: 10.8009074 },
  { city: "Kudan", state: "Kaduna", longitude: 7.7320724, latitude: 11.3039523 },
  { city: "Lere", state: "Kaduna", longitude: 8.573475199999999, latitude: 10.3853189 },
  { city: "Makarfi", state: "Kaduna", longitude: 7.4559686, latitude: 9.590698999999999 },
  { city: "Sabon – Gari", state: "Kaduna", longitude: 7.7375963, latitude: 11.1173874 },
  { city: "Sanga", state: "Kaduna", longitude: 8.461613, latitude: 9.313134699999999 },
  { city: "Soba", state: "Kaduna", longitude: 8.060157999999999, latitude: 10.9840928 },
  { city: "Zangon – Kataf", state: "Kaduna", longitude: 8.2212979, latitude: 9.8905849 },
  { city: "Zaria", state: "Kaduna", longitude: 7.725376, latitude: 11.1247243 },
  /************************* KANO *************************/
  { city: "Ajingi", state: "Kano", longitude: 9.037761200000002, latitude: 11.9686426 },
  { city: "Albasu", state: "Kano", longitude: 9.1400807, latitude: 11.6691801 },
  { city: "Bagwai", state: "Kano", longitude: 8.1352213, latitude: 12.1564071 },
  { city: "Bebeji", state: "Kano", longitude: 8.2623222, latitude: 11.6647762 },
  { city: "Bichi", state: "Kano", longitude: 8.2404397, latitude: 12.2312766 },
  { city: "Bunkure", state: "Kano", longitude: 8.541889899999999, latitude: 11.696086 },
  { city: "Dala", state: "Kano", longitude: 8.500711599999999, latitude: 12.0052643 },
  { city: "Dambatta", state: "Kano", longitude: 8.514435199999999, latitude: 12.432918 },
  { city: "Dawakin Kudu", state: "Kano", longitude: 11.841376, latitude: 11.841376 },
  { city: "Dawakin Tofa", state: "Kano", longitude: 12.1304024, latitude: 12.1304024 },
  { city: "Doguwa", state: "Kano", longitude: 8.704075, latitude: 10.8125373 },
  { city: "Fagge", state: "Kano", longitude: 8.521298, latitude: 12.031519 },
  { city: "Gabasawa", state: "Kano", longitude: 8.912116899999999, latitude: 12.1805847 },
  { city: "Garko", state: "Kano", longitude: 8.8059596, latitude: 11.6486333 },
  { city: "Garum Mallam", state: "Kano", longitude: 8.3718319, latitude: 11.684176 },
  { city: "Gaya", state: "Kano", longitude: 8.9990801, latitude: 11.862577 },
  { city: "Gezawa", state: "Kano", longitude: 8.7536237, latitude: 12.0993046 },
  { city: "Gwale", state: "Kano", longitude: 8.5199254, latitude: 11.9848764 },
  { city: "Gwarzo", state: "Kano", longitude: 7.9347713, latitude: 11.9171312 },
  { city: "Kabo", state: "Kano", longitude: 8.230184599999999, latitude: 11.9065366 },
  { city: "Kano Municipal", state: "Kano", longitude: 8.500711599999999, latitude: 11.9600036 },
  { city: "Karaye", state: "Kano", longitude: 8.0110677, latitude: 11.7848816 },
  { city: "Kibiya", state: "Kano", longitude: 8.6573084, latitude: 11.5291917 },
  { city: "Kiru", state: "Kano", longitude: 8.1365868, latitude: 11.7022386 },
  { city: "Kumbotso", state: "Kano", longitude: 8.514435199999999, latitude: 11.9284828 },
  { city: "Kunchi", state: "Kano", longitude: 8.271213899999999, latitude: 12.5022964 },
  { city: "Kura", state: "Kano", longitude: 8.4239055, latitude: 11.7701438 },
  { city: "Madobi", state: "Kano", longitude: 8.286812300000001, latitude: 11.7747991 },
  { city: "Makoda", state: "Kano", longitude: 8.430759999999999, latitude: 12.4199145 },
  { city: "Minjibir", state: "Kano", longitude: 8.628437399999999, latitude: 12.1924477 },
  { city: "Nasarawa", state: "Kano", longitude: 8.5539045, latitude: 11.2282192 },
  { city: "Rano", state: "Kano", longitude: 8.581716999999999, latitude: 11.5610083 },
  { city: "Rimin Gado", state: "Kano", longitude: 8.247150999999999, latitude: 11.9673401 },
  { city: "Rogo", state: "Kano", longitude: 7.823198199999999, latitude: 11.5500501 },
  { city: "Shanono", state: "Kano", longitude: 7.989260499999999, latitude: 12.0515162 },
  { city: "Sumaila", state: "Kano", longitude: 11.531421, latitude: 11.531421 },
  { city: "Takai", state: "Kano", longitude: 9.1096477, latitude: 11.5716154 },
  { city: "Tarauni", state: "Kano", longitude: 8.8990118, latitude: 12.1123634 },
  { city: "Tofa", state: "Kano", longitude: 12.0584525, latitude: 12.0584525 },
  { city: "Tsanyawa", state: "Kano", longitude: 7.989260499999999, latitude: 12.2954782 },
  { city: "Tudun Wada", state: "Kano", longitude: 11.2496719, latitude: 11.2496719 },
  { city: "Ungogo", state: "Kano", longitude: 8.496335499999999, latitude: 12.0915753 },
  { city: "Warawa", state: "Kano", longitude: 8.7322863, latitude: 11.8871934 },
  { city: "Wudil", state: "Kano", longitude: 8.839032099999999, latitude: 11.7942415 },

  /************************* KATSINA *************************/
  { city: "Katsina", state: "Katsina", longitude: 7.6305748, latitude: 12.3796707 },

  /************************* KEBBI *************************/
  { city: "Lokoja", state: "Kebbi", longitude: 4.2077723, latitude: 12.4375938 },

  /************************* KOGI *************************/
  { city: "Kebbi", state: "Kogi", longitude: 6.733343, latitude: 7.8023204 },
  { city: "Okene", state: "Kogi", longitude: 6.2429045, latitude: 7.561418400000001 },
  { city: "Kabba", state: "Kogi", longitude: 6.0732146, latitude: 7.8231145 },
  { city: "Ankpa", state: "Kogi", longitude: 7.622266499999999, latitude: 7.4053197 },
  { city: "Anyigba", state: "Kogi", longitude: 7.1736385, latitude: 7.493366399999999 },
  { city: "Idah", state: "Kogi", longitude: 6.744046699999999, latitude: 7.113811900000001 },

  /************************* KWARA *************************/
  { city: "Asa", state: "Kwara", longitude: 4.5795315, latitude: 8.5660249 },
  { city: "Baruten", state: "Kwara", longitude: 3.5812692, latitude: 9.3493393 },
  { city: "Edu", state: "Kwara", longitude: 5.1432068, latitude: 8.889176899999999 },
  { city: "Ekiti", state: "Kwara", longitude: 5.3311652, latitude: 8.120899099999999 },
  { city: "Ifelodun", state: "Kwara", longitude: 5.1432068, latitude: 8.5380979 },
  { city: "Ilorin", state: "Kwara", longitude: 4.5443923, latitude: 8.5373356 },
  { city: "Ilorin East", state: "Kwara", longitude: 4.789873099999999, latitude: 8.6083453 },
  { city: "Ilorin South", state: "Kwara", longitude: 4.6657027, latitude: 8.434660899999999 },
  { city: "Ilorin West", state: "Kwara", longitude: 4.5108862, latitude: 8.4911827 },
  { city: "Irepodun", state: "Kwara", longitude: 8.148652999999999, latitude: 8.148652999999999 },
  { city: "Isin", state: "Kwara", longitude: 5.018246, latitude: 8.2338123 },
  { city: "Kaiama", state: "Kwara", longitude: 3.9419415, latitude: 9.6062133 },
  { city: "Moro", state: "Kwara", longitude: 4.5091148, latitude: 8.5536656 },
  { city: "Offa", state: "Kwara", longitude: 4.7174062, latitude: 8.139340799999999 },
  { city: "Oke Ero", state: "Kwara", longitude: 5.3520876, latitude: 8.2295195 },
  { city: "Oyun", state: "Kwara", longitude: 4.5722761, latitude: 8.5943665 },
  { city: "Pategi", state: "Kwara", longitude: 5.7563151, latitude: 8.721124 },

  /************************* NASARAWA *************************/
  { city: "Masaka", state: "Nasarawa", longitude: 7.681943, latitude: 9.0045661 },
  { city: "Lafia", state: "Nasarawa", longitude: 8.5226706, latitude: 8.5060115 },
  { city: "Kainji", state: "Nasarawa", longitude: 7.4627556, latitude: 10.5422474 },

  /************************* NIGER *************************/
  { city: "Minna", state: "Niger", longitude: 6.5463156, latitude: 9.583554599999999 },
  { city: "Bida", state: "Niger", longitude: 6.009701799999999, latitude: 9.0796794 },
  { city: "Suleja", state: "Niger", longitude: 7.186097900000001, latitude: 9.1701391 },
  { city: "Lapai", state: "Niger", longitude: 6.579673300000001, latitude: 9.0492688 },
  { city: "Shiroro", state: "Niger", longitude: 6.8093109, latitude: 9.9822296 },
  { city: "Kontagora", state: "Niger", longitude: 5.4699173, latitude: 10.407146 },
  { city: "New Bussa", state: "Niger", longitude: 4.5108862, latitude: 9.882884499999999 },
  { city: "Mokwa", state: "Niger", longitude: 5.0546643, latitude: 9.2928248 },

  /************************* OGUN *************************/
  { city: "Ogijo / Likosi", state: "Ogun", longitude: 3.5155086, latitude: 6.699425499999999 },
  { city: "Arepo", state: "Ogun", longitude: 3.4253559, latitude: 6.6966956 },
  { city: "Akute", state: "Ogun", longitude: 3.3595429, latitude: 6.677955499999999 },
  { city: "Ibafo", state: "Ogun", longitude: 3.4220755, latitude: 6.740146999999999 },
  { city: "Mowe", state: "Ogun", longitude: 3.4369233, latitude: 6.806994800000001 },
  { city: "Shagamu", state: "Ogun", longitude: 3.6319131, latitude: 6.8322014 },
  { city: "Osasa", state: "Ogun", longitude: 3.856623, latitude: 6.7970425 },
  { city: "Abeokuta", state: "Ogun", longitude: 3.3619356, latitude: 7.147502 },
  { city: "Abẹ́òkúta", state: "Ogun", longitude: 3.3619356, latitude: 7.147502 },
  { city: "Ijebu East", state: "Ogun", longitude: 4.315422, latitude: 6.8159044 },
  { city: "Ijebu North", state: "Ogun", longitude: 3.9905534, latitude: 6.962381799999999 },
  { city: "Ijebu North East", state: "Ogun", longitude: 4.0082563, latitude: 6.8827355 },
  { city: "Ijebu Ode", state: "Ogun", longitude: 3.9164585, latitude: 6.8299846 },
  { city: "Abeokuta South", state: "Ogun", longitude: 3.3489671, latitude: 7.1561368 },
  { city: "Ado-Odo/Ota", state: "Ogun", longitude: 3.08626, latitude: 6.62366 },
  { city: "Ota", state: "Ogun", longitude: 3.2364854, latitude: 6.692744599999999 },
  { city: "Egbado North", state: "Ogun", longitude: 2.8577105, latitude: 7.1701914 },
  { city: "Egbado South", state: "Ogun", longitude: 2.9775558, latitude: 6.7831512 },
  { city: "Ewekoro", state: "Ogun", longitude: 3.19444, latitude: 6.96111 },
  { city: "Ifo", state: "Ogun", longitude: 3.25194, latitude: 6.78194 },
  { city: "Ijebu East", state: "Ogun", longitude: 4.258, latitude: 6.805 },
  { city: "Ijebu North", state: "Ogun", longitude: 3.97083, latitude: 6.99556 },
  { city: "Ijebu North East", state: "Ogun", longitude: 4.0225, latitude: 6.88806 },
  { city: "Ijebu Ode", state: "Ogun", longitude: 3.92083, latitude: 6.82083 },
  { city: "Ikenne", state: "Ogun", longitude: 3.676, latitude: 6.919 },
  { city: "Imeko Afon", state: "Ogun", longitude: 2.86667, latitude: 7.63333 },
  { city: "Ipokia", state: "Ogun", longitude: 2.81056, latitude: 6.61806 },
  { city: "Obafemi Owode", state: "Ogun", longitude: 3.5, latitude: 6.95 },
  { city: "Owode", state: "Ogun", longitude: 3.5079264, latitude: 6.948262 },
  { city: "Odeda", state: "Ogun", longitude: 3.51667, latitude: 7.21667 },
  { city: "Odogbolu", state: "Ogun", longitude: 3.8, latitude: 6.76667 },
  { city: "Ogun Waterside", state: "Ogun", longitude: 4.4, latitude: 6.48333 },
  { city: "Remo North", state: "Ogun", longitude: 3.71667, latitude: 7.0000000000 },
  /************************* ONDO *************************/
  { city: "Ikare-Akoko / Ikare", state: "Ondo", longitude: 5.7668515, latitude: 7.524806300000001 },
  { city: "Akure", state: "Ondo", longitude: 5.141617, latitude: 7.2910063 },
  { city: "Ore", state: "Ondo", longitude: 4.877996899999999, latitude: 6.7518524 },
  { city: "Ondo", state: "Ondo", longitude: 4.8416936, latitude: 7.1000051 },
  { city: "Old Owo Road / Okeodo", state: "Ondo", longitude: 5.385504699999999, latitude: 7.265539899999999 },
  { city: "Owo", state: "Ondo", longitude: 5.616703999999999, latitude: 7.166703999999999 },
  { city: "Akure North", state: "Ondo", longitude: 5.2985862, latitude: 7.2483748 },
  { city: "Akure South", state: "Ondo", longitude: 5.1640605, latitude: 7.214619099999998 },
  { city: "Osijogun", state: "Ondo", longitude: 5.5672247, latitude: 7.1434189 },
  /************************* OSUN *************************/
  { city: "Ilé-Ifẹ̀ / Ile-Ife", state: "Osun", longitude: 4.5521274, latitude: 7.490462 },
  { city: "Ilesa", state: "Osun", longitude: 4.709647599999999, latitude: 7.6103457 },
  { city: "Osogbo", state: "Osun", longitude: 4.5418141, latitude: 7.7826709 },
  /************************* OYO *************************/
  { city: "Akinyele", state: "Oyo", longitude: 3.9470396, latitude: 7.5503161 },
  { city: "Atiba", state: "Oyo", longitude: 3.9148048, latitude: 7.851637800000001 },
  { city: "Atisbo", state: "Oyo", longitude: 3.3388917, latitude: 8.3824436 },
  { city: "Egbeda", state: "Oyo", longitude: 3.9674675, latitude: 7.379596299999999 },
  { city: "Ibadan", state: "Oyo", longitude: 3.9470396, latitude: 7.3775355 },
  { city: "Ibadan North", state: "Oyo", longitude: 3.9164585, latitude: 7.410167499999999 },
  { city: "Ibadan North East", state: "Oyo", longitude: 3.9317468, latitude: 7.361493500000001 },
  { city: "Ibadan North West", state: "Oyo", longitude: 3.8757119, latitude: 7.3889435 },
  { city: "Ibadan South East", state: "Oyo", longitude: 3.9113634, latitude: 7.329329599999999 },
  { city: "Ibadan South West", state: "Oyo", longitude: 3.8757119, latitude: 7.345806100000001 },
  { city: "Ibarapa Central", state: "Oyo", longitude: 3.238251, latitude: 7.404794699999999 },
  { city: "Ibarapa East", state: "Oyo", longitude: 3.4599326, latitude: 7.641023100000001 },
  { city: "Ibarapa North", state: "Oyo", longitude: 3.1779659, latitude: 7.6865302 },
  { city: "Ido", state: "Oyo", longitude: 3.7193936, latitude: 7.5077051 },
  { city: "Irepo", state: "Oyo", longitude: 3.8655303, latitude: 9.036844499999999 },
  { city: "Iseyin", state: "Oyo", longitude: 3.5913939, latitude: 7.9764931 },
  { city: "Itesiwaju", state: "Oyo", longitude: 3.5407909, latitude: 8.1621641 },
  { city: "Iwajowa", state: "Oyo", longitude: 3.0175712, latitude: 8.035507299999999 },
  { city: "Kajola", state: "Oyo", longitude: 3.346448, latitude: 8.0901864 },
  { city: "Lagelu", state: "Oyo", longitude: 4.0491077, latitude: 7.484551 },
  { city: "Ogbomosho", state: "Oyo", longitude: 4.2435893, latitude: 8.122749599999999 },
  { city: "Ogbomosho North", state: "Oyo", longitude: 4.2538452, latitude: 8.1335403 },
  { city: "Ogbomosho South", state: "Oyo", longitude: 4.2230836, latitude: 8.079387499999999 },
  { city: "Ogo Oluwa", state: "Oyo", longitude: 4.2128337, latitude: 7.9597967 },
  { city: "Odo Ona", state: "Oyo", longitude: 3.8373097, latitude: 7.387914199999999 },
  { city: "Olorunsogo", state: "Oyo", longitude: 3.9342953, latitude: 7.3695588 },
  { city: "Oluyole", state: "Oyo", longitude: 3.8655303, latitude: 7.205884800000001 },
  { city: "Ona Ara", state: "Oyo", longitude: 4.0491077, latitude: 7.268874 },
  { city: "Orelope", state: "Oyo", longitude: 3.7841503, latitude: 8.861338699999999 },
  { city: "Ori Ire", state: "Oyo", longitude: 3.9161563, latitude: 7.3507606 },
  { city: "Oyo", state: "Oyo", longitude: 3.9368439, latitude: 7.842957900000001 },
  { city: "New Gra / Oyo Town", state: "Oyo", longitude: 3.8735247, latitude: 7.3786482 },
  { city: "Oyo East", state: "Oyo", longitude: 4.0491077, latitude: 7.874475100000001 },
  { city: "Saki East", state: "Oyo", longitude: 3.6217802, latitude: 8.6869566 },
  { city: "Saki West", state: "Oyo", longitude: 3.1378173, latitude: 8.603343299999999 },
  { city: "Surulere", state: "Oyo", longitude: 4.43879, latitude: 8.066227999999999 },
  { city: "Shaki", state: "Oyo", longitude: 3.394332, latitude: 8.672557099999999 },
  /************************* PLATEAU *************************/
  { city: "Jos", state: "Plateau", longitude: 8.8583309, latitude: 9.896527299999999 },
  /************************* RIVERS *************************/
  { city: "Abua-Odual", state: "Rivers", longitude: 6.83333, latitude: 4.75 },
  { city: "Ahoada East", state: "Rivers", longitude: 6.64302, latitude: 5.07027 },
  { city: "Asari-Toru", state: "Rivers", longitude: 6.83493, latitude: 4.74883 },
  { city: "Bonny", state: "Rivers", longitude: 7.16667, latitude: 4.43333 },
  { city: "Etche", state: "Rivers", longitude: 7.05444, latitude: 4.99083 },
  { city: "Gokana", state: "Rivers", longitude: 7.2711, latitude: 4.63225 },
  { city: "Ikwerre", state: "Rivers", longitude: 6.92242, latitude: 5.05514 },
  { city: "Degema", state: "Rivers", longitude: 6.93806, latitude: 4.57417 },
  { city: "Ahoada West", state: "Rivers", longitude: 6.47089, latitude: 5.0858 },
  { city: "Akuku-Toru", state: "Rivers", longitude: 6.78088, latitude: 4.72211 },
  { city: "Andoni", state: "Rivers", longitude: 7.44639, latitude: 4.54917 },
  { city: "Eleme", state: "Rivers", longitude: 6.65, latitude: 5.08333 },
  { city: "Emohua", state: "Rivers", longitude: 6.7475, latitude: 5.00667 },
  { city: "Oyigbo", state: "Rivers", longitude: 7.12833, latitude: 4.87806 },
  { city: "Khana", state: "Rivers", longitude: 7.35, latitude: 4.7 },
  { city: "Omuma", state: "Rivers", longitude: 7.02082, latitude: 4.96942 },
  { city: "Opobo-Nkoro", state: "Rivers", longitude: 7.55278, latitude: 4.56806 },
  { city: "Portharcourt", state: "Rivers", longitude: 7.02165, latitude: 4.77763 },
  { city: "Tai", state: "Rivers", longitude: 7.3, latitude: 4.71667 },
  { city: "Obio-Akpor", state: "Rivers", longitude: 7.0283, latitude: 4.8776 },
  { city: "Ogba-Egbema-Ndoni", state: "Rivers", longitude: 6.65556, latitude: 5.34167 },
  { city: "Ogu-Bolo", state: "Rivers", longitude: 7.19992, latitude: 4.72172 },
  { city: "Okrika", state: "Rivers", longitude: 7.083, latitude: 4.74 },
  { city: "Port Harcourt", state: "Rivers", longitude: 6.974603999999999, latitude: 4.847222599999999 },
  /************************* SOKOTO **********************/
  { city: "Sokoto", state: "Sokoto", longitude: 5.2475518, latitude: 13.0058731 },
  /************************* TARABA **********************/
  { city: "Taraba", state: "Taraba", longitude: 10.7739863, latitude: 7.9993616 },
  /************************* YOBE **********************/
  { city: "Yobe", state: "Yobe", longitude: 11.4390411, latitude: 12.293876 },
  /************************* ZAMFARA *************************/
  { city: "Zamfara", state: "Zamfara", longitude: 6.2235819, latitude: 12.1221805 },
];