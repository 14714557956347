import {useRef, useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import toast from "react-hot-toast";
import Gravatar from "../../../../assets/images/pngs/gravatar.png";
import EditPicture from "../../../../assets/images/svg/edit-profile-white.svg";
import ProfileService from "../../../../utils/services/profile.service";
import {DomStateType, InputValidator, Loading} from "../../../../utils/helpers/constants";
import {getProfile} from "../../../../store/actions/profile.action";
import {emailValidator, phoneValidator} from "../../../../utils/services/validator.service";

const Body = ({profile, domState, business}) => {
  const dispatch = useDispatch(), navigate = useNavigate(), hiddenFileInput = useRef();
  const [firstName, setFirstName] = useState(profile.first_name ?? "");
  const [lastName, setLastName] = useState(profile.last_name ?? "");
  const [email, setEmail] = useState(profile.email ?? "");
  const [phone, setPhone] = useState(profile.phone ?? "");
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingPicStatus, setLoadingPicStatus] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const createDriverProfile = () => navigate('/onboard-driver');
  const createBusinessProfile = (action) => navigate('/onboard-business', {state: {actionType: action, business}});
  const createVehicle = () => navigate('/register-vehicle');


  const handleFieldValidation = (data, type) => {
    if (type === 'EMAIL') {
      const validatedEmail = emailValidator(data);
      setEmail(validatedEmail.validateEmail);
      validatedEmail.validState === InputValidator.VALID ? setEmailError(false) : setEmailError(true);
    } else if (type === 'PHONE') {
      data = data.replace(/[^0-9/+]/gi, "");
      const validatedPhone = phoneValidator(data);
      setPhone(data);
      validatedPhone === InputValidator.VALID ? setPhoneError(false) : setPhoneError(true);
    }
  }

  const uploadProfilePicture = async (event) => {
    setLoadingPicStatus(true);
    const file = event.target.files[0];

    if (typeof file === 'object') {
      const formData = new FormData();
      await formData.append('picture', file);

      const {data: responseData} = await ProfileService.changeProfilePicture(profile.id, formData);
      if (responseData.status !== Loading.SUCCESS){ 
        const message = (responseData && responseData.message) ? responseData.message : "An Error occured while uploading your. Please try again later";
        toast.error(message);
      }
      else {
        await dispatch(getProfile());
        toast.success(responseData.message);
      }
    }
    setLoadingPicStatus(false);
  };

  const updateMyProfile = async event => {
    event.preventDefault();
    setLoadingStatus(true);
    const payload = {first_name: firstName, last_name: lastName, address: {}};
    const {data: responseData} = await ProfileService.updateProfile(profile.id, payload);
    if (responseData.status !== Loading.SUCCESS) {
      const message = (responseData && responseData.message) ? responseData.message : "An Error occured while updating your profile. Please try again later";
      toast.error(message);
    }
    else {
      await dispatch(getProfile());
      toast.success(responseData.message);
    }
    setLoadingStatus(false);
  }


  return (
    <div className="profile-main p-4 bg-white w-100">
      <div className="d-flex justify-content-end mb-4">
        {((!profile['is_business'] && !profile['is_driver'] && !profile['is_vehicle_owner'] && !profile['is_account_manager'])
            || (profile['is_vehicle_owner'] && domState.type === DomStateType.VEHICLE_OWNER)
            || (profile['is_driver'] && domState.type === DomStateType.DRIVER)) &&
          <button className="btn primary-btn me-1" onClick={createVehicle}>Register Vehicle</button>
        }
        {((!profile['is_business'] && !profile['is_driver'] && !profile['is_vehicle_owner'] && !profile['is_account_manager'])
            || (profile['is_vehicle_owner'] && !profile['is_driver'])) &&
          <button className="btn secondary-btn me-1" onClick={createDriverProfile}>Create Driver Profile</button>
        }
        {((!profile['is_business'] && !profile['is_driver'] && !profile['is_vehicle_owner'] && !profile['is_account_manager'])
            || (profile['is_business'] && domState.type === DomStateType.BUSINESS)) &&
          <div className="order-md-1 order-2 btns-sm mb-4">  
          <button className="btn primary-btn  mg-r-5" onClick={()=> {createBusinessProfile("CREATE");}}>Onboard business</button>
          <button className="btn secondary-btn" onClick={()=> {createBusinessProfile("UPDATE")}}>Update business</button>
          </div>
        }
      </div>
      <div className="d-flex justify-content-center">
        <div className="media">
          <input type="file" accept="image/*" ref={hiddenFileInput} onChange={uploadProfilePicture}
                 style={{display: 'none'}}/>
          <img alt="avatar" className="main-img rounded-circle bg-light" src={profile.picture ?? Gravatar}/>
          <button className="overlay_img rounded-circle" title="Upload Display Picture"
                  onClick={() => hiddenFileInput.current.click()}>
            {loadingPicStatus ?
              <i className="zmdi zmdi-spinner zmdi-hc-spin text-white fs-4"/> :
              <img src={EditPicture} alt="Profile" width={17} height={17}/>
            }
          </button>
        </div>
      </div>
      <form className="form pt-3">
        <div className="row">
          <div className="col-md-6 mt-4 form-group text-start">
            <label className="mb-2">First Name</label>
            <input className="form-control" placeholder="" type="text" value={firstName}
                   onChange={event => setFirstName(event.target.value)}/>
          </div>
          <div className="col-md-6 mt-4 form-group text-start">
            <label className="mb-2">Last Name</label>
            <input className="form-control" placeholder="" type="text" value={lastName}
                   onChange={event => setLastName(event.target.value)}/>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 mt-4 form-group text-start">
            <label className="mb-2">Email</label>
            <input className={`form-control ${emailError && 'is-invalid'}`} placeholder="Enter Email" type="text"
                   value={email} readOnly disabled
                   title="You are not allowed to update your Email Address"
                   onChange={event => handleFieldValidation(event.target.value, 'EMAIL')}/>
          </div>
          <div className="col-md-6 mt-4 form-group text-start">
            <label className="mb-2">Phone number</label>
            <input className={`form-control ${phoneError && 'is-invalid'}`} placeholder="Enter Phone number" type="text"
                   value={phone} readOnly disabled
                   title="You are not allowed to update your Phone"
                   onChange={event => handleFieldValidation(event.target.value, 'PHONE')}/>
          </div>
        </div>

        <div className="d-flex justify-content-start mt-5">
          <button className="btn primary-btn mg-r-2" onClick={updateMyProfile}>
            {profile ? 'Change' : 'Create'} profile
            {loadingStatus && <>&nbsp;<i
              className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin ms-2"/></>}
          </button>
        </div>
      </form>
    </div>
  )
}

export default Body;
