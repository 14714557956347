import ActionCreator from "../../utils/helpers/actionCreator";
import * as ComponentConstants from '../constants/component.constants';
import ProfileService from "../../utils/services/profile.service";

export const loadingApp = data => async dispatch => {
  dispatch(ActionCreator(ComponentConstants.LOADING_APP, data));
};

export const toggleNavMenu = data => async dispatch => {
  // const body = document.body;
  // if (body && data) body.style.overflow = "hidden";
  // else if (body && !data) body.classList.remove("visible");
  dispatch(ActionCreator(ComponentConstants.TOGGLE_MOBILE_NAV, data));
};

export const getDynamicFields = type => async dispatch => {
  try {
    dispatch(ActionCreator(ComponentConstants.FETCH_DYNAMIC_FIELDS_REQUEST));
    const {data: responseData} = await ProfileService.getDynamicFields(type);
    dispatch(ActionCreator(ComponentConstants.FETCH_DYNAMIC_FIELDS_SUCCESS, {data: responseData.data, type}));
  } catch (error) {
    dispatch(ActionCreator(ComponentConstants.FETCH_DYNAMIC_FIELDS_FAILURE));
  }
};

export const findOrganisationModalRender = () => async dispatch => {
  // const body = document.body;

};