import ActionCreator from "../../utils/helpers/actionCreator";
import * as ConfigsConstants from "../constants/configs.constants";
import ConfigsService from "../../utils/services/configs.service";
import {Loading} from "../../utils/helpers/constants";


export const getNotifications = (platform, filter) => async dispatch => {
	try {
		dispatch(ActionCreator(ConfigsConstants.FETCH_NOTIFICATIONS_REQUEST));
		const {data: responseData} = await ConfigsService.getNotifications(platform, filter);
		if (responseData.status !== Loading.SUCCESS) throw responseData;
		dispatch(ActionCreator(ConfigsConstants.FETCH_NOTIFICATIONS_SUCCESS, responseData));
	} catch(error) {
		dispatch(ActionCreator(ConfigsConstants.FETCH_NOTIFICATIONS_FAILURE));
	}
};

export const getAllNotificationMatrices = () => async dispatch => {
	try {
		dispatch(ActionCreator(ConfigsConstants.FETCH_ALL_NOTIFICATION_MATRICES_REQUEST));
		const {data: responseData} = await ConfigsService.getAllNotificationMatrices();
		if (responseData.status !== Loading.SUCCESS) throw responseData;
		dispatch(ActionCreator(ConfigsConstants.FETCH_ALL_NOTIFICATION_MATRICES_SUCCESS, responseData.data));
	} catch(error) {
		dispatch(ActionCreator(ConfigsConstants.FETCH_ALL_NOTIFICATION_MATRICES_FAILURE));
	}
};

export const getWarehouses = () => async dispatch => {
	try {
		dispatch(ActionCreator(ConfigsConstants.FETCH_WAREHOUSES_REQUEST));
		const {data: responseData} = await ConfigsService.getWarehouses();
		if (responseData.status !== Loading.SUCCESS) throw responseData;

		await dispatch(ActionCreator(ConfigsConstants.FETCH_WAREHOUSES_SUCCESS, responseData.data));
	} catch(error) {
		dispatch(ActionCreator(ConfigsConstants.FETCH_WAREHOUSES_FAILURE));
	}
};

export const getIntegrationKeys = () => async dispatch => {
	try {
	  dispatch(ActionCreator(ConfigsConstants.FETCH_INTEGRATION_KEYS_REQUEST));
	  const {data: responseData} = await ConfigsService.getIntegrationKeys();
	  await dispatch(ActionCreator(ConfigsConstants.FETCH_INTEGRATION_KEYS_SUCCESS, responseData.data));
	} catch (error) {
	  dispatch(ActionCreator(ConfigsConstants.FETCH_INTEGRATION_KEYS_FAILURE));
	}
  }
