import {useEffect, useState} from "react";
import {batch, useDispatch, useSelector} from "react-redux";
import Select from "react-select";
import toast from "react-hot-toast";

import SuccessSvg from "../../../../assets/images/svg/success-check.svg";
import PageLayout from "../../../../components/layouts/PageLayout";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import {getBankAccounts, getBanks} from "../../../../store/actions/wallet.action";
import {Loading, menuType} from "../../../../utils/helpers/constants";
import WalletService from "../../../../utils/services/wallet.service";
import TokenHelper from "../../../../utils/helpers/tokenHelper";

const BankAccount = () => {

  const dispatch = useDispatch();
  const [bank, setBank] = useState("");
  const [account, setAccount] = useState("");
  const [loading, setLoading] = useState(false);
  const [validatedUser, setValidatedUser] = useState(false);
  const [accountDetails, setAccountDetails] = useState({});
  const wallet = useSelector(({walletReducer}) => walletReducer);
  const loadState = wallet.loadingBanks !== Loading.SUCCESS || wallet.loadingBankAccounts !== Loading.SUCCESS;

  useEffect(() => {
    batch(() => {
      if (wallet.loadingBanks !== Loading.SUCCESS) dispatch(getBanks());
      if (wallet.loadingBankAccounts !== Loading.SUCCESS) dispatch(getBankAccounts());
    });
  }, []);

  const validateAccount = async value => {
    setAccount(value);
    setValidatedUser(false);
    if (value.length > 9) {
      const {data: responseData} = await WalletService.nameInquiry({account_number: value, bank_code: bank.code});
      if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
      else {
        delete responseData.data['bank_id'];
        setValidatedUser(true);
        setAccountDetails(responseData.data);
      }
    }
  }

  const addBankAccount = async () => {
    setLoading(true);
    let user_id = TokenHelper.getUserId();
    let payload = {
      ...accountDetails, user_id,
      bank: bank.name, slug: bank.slug, bank_code: bank.code, nibss_code: bank['nibss_bank_code']
    };
    const {data: responseData} = await WalletService.addBankAccount(payload);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      toast.success(responseData.message);
      dispatch(getBankAccounts());
    }
    setLoading(false)
  }

  return (
    <PageLayout active={menuType.SETTINGS}>
      <InnerMenuLayout active={menuType.BANK_ACCOUNT} type="SETTINGS">
        <div className="settings-main">
          {loadState ? <SphereLoader/> :
            <div>
              <form className="p-4">
                <div className="mb-3">
                  <label className="mb-2">Select bank</label>
                  <Select options={wallet.banks}
                          getOptionLabel={each => each.name}
                          isClearable={false}
                          isSearchable={true}
                          maxMenuHeight={250}
                          menuPlacement="bottom"
                          name={bank}
                          className="wd-60p"
                          onChange={option => setBank(option)}/>
                </div>
                <div className="mb-3">
                  <label className="mb-2">Enter account number</label>
                  <div><input type="text" value={account} onChange={e => validateAccount(e.target.value)}/></div>
                  {validatedUser &&
                    <div className="account-name-text d-flex align-items-center mt-2">
                      <img src={SuccessSvg} alt="success check"/>
                      <span className="ms-1">{accountDetails['account_name']}</span>
                    </div>
                  }
                </div>
                <button className="btn primary-btn" onClick={addBankAccount} disabled={!validatedUser} type="button">
                  Add bank details
                  {loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
                </button>
              </form>
              <div className="accounts-group mb-4">
                <h2 className="px-4">Existing bank account</h2>
                {wallet.bankAccounts.map((item, index) =>
                  <div key={index} className="accounts-group-item d-flex align-items-center py-3 px-4">
                    <img src={`https://storage.googleapis.com/dodge-staging-storage/banks/${item['slug']}.svg`} alt=""/>
                    <div className="ms-2">
                      <p className="mb-0 acc-name">{item['bank']}</p>
                      <span>{item['account_name']} • {item['account_number']}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          }
        </div>
      </InnerMenuLayout>
    </PageLayout>
  )
}

export default BankAccount;
