import {useState} from "react";
import toast from "react-hot-toast";
import {useDispatch} from "react-redux";
import {Loading} from "../../../../utils/helpers/constants";
import ConfigsService from "../../../../utils/services/configs.service";
import {getProfile} from "../../../../store/actions/profile.action";

const Body = ({matrices, userMatrices}) => {
	const dispatch = useDispatch()
	const [loading, setLoading] = useState(false);
	const [detectMatrixChange, setDetectMatrixChange] = useState(false);
	const [userNotificationMatrices, setUserNotificationMatrices] = useState(userMatrices);


	const updateNotificationMatrix = (value, index, key, item) => {
		setDetectMatrixChange(true);
		const newArray = [...userNotificationMatrices]
		item[key] = value;
		newArray[index] = item;
		setUserNotificationMatrices(newArray);
	}

	const saveNotificationMatrices = async () => {
		setLoading(true);
		const {data: responseData} = await ConfigsService.updateNotificationMatrices([...userNotificationMatrices]);
		if(responseData.status !== Loading.SUCCESS) {
			setLoading(false);
			toast.error(responseData.message);
		}
		else {
			toast.success(responseData.message);
			setLoading(false);
			setDetectMatrixChange(false);
			dispatch(getProfile());
		}
	}


	return (
		<div className="settings-main">
			<div className="mt-5">
				<div className="row mx-2">
					<div className="col-6">
						<h5 className="notification-header">Notifications</h5>
					</div>
					<div className="col-6">
						<div className="row">
							<h6 className="col-4 notification-header">InApp</h6>
							<h6 className="col-4 notification-header">SMS</h6>
							<h6 className="col-4 notification-header">Email</h6>
						</div>
					</div>
				</div>
				{userNotificationMatrices.map((item, index) => {
					let element = matrices.find((data) => (item.matrix === data.matrix) && data.active === 'user');

					if(element) {
						return (
							<div key={index} className="row border-top py-2 mx-1">
								<div className="col-6">
									<h5 className="notification-description">{element['description']}</h5>
								</div>
								<div className="col-6">
									<div className="row mt-2">
										<label className="col-4">
											<input type="checkbox" className="w-100" name="push" checked={item['push']}
														 onChange={event => updateNotificationMatrix(event.target.checked, index, 'push', item)}/>
										</label>
										<label className="col-4">
											<input type="checkbox" className="w-100" name="sms" checked={item['sms']}
														 onChange={event => updateNotificationMatrix(event.target.checked, index, 'sms', item)}/>
										</label>
										<label className="col-4">
											<input type="checkbox" className="w-100" name="email" checked={item['email']}
														 onChange={event => updateNotificationMatrix(event.target.checked, index, 'email', item)}/>
										</label>
									</div>
								</div>
							</div>
						)
					}
				})}
				<div className="d-flex justify-content-center border-top">
					<button className="btn primary-btn mt-5" type="button" disabled={!detectMatrixChange}
									onClick={() => saveNotificationMatrices()}>
						Save changes
						{loading && <i className="zmdi zmdi-spinner ms-2 zmdi-hc-spin"/>}
					</button>
				</div>
			</div>
		</div>

	)
};

export default Body;
