import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import Body from "./Body";
import PageLayout from "../../../../components/layouts/PageLayout";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import {getFleet} from "../../../../store/actions/fleet.action";
import {Loading, menuType} from "../../../../utils/helpers/constants";


const RegisteredVehicles = () => {

	const dispatch = useDispatch();
	const fleet = useSelector(({fleetReducer}) => fleetReducer);
	const loadState = fleet.loading !== Loading.SUCCESS;

	useEffect(() => {
		if(fleet.loading !== Loading.SUCCESS) dispatch(getFleet());
	}, []);


	return (
		<PageLayout active={menuType.PROFILE}>
			<InnerMenuLayout active={menuType.REGISTERED_VEHICLE} type="PROFILE">
				{loadState ? <SphereLoader/> : <Body fleet={fleet.fleet}/>}
			</InnerMenuLayout>
		</PageLayout>
	)
}

export default RegisteredVehicles
