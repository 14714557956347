import moment from "moment";
import { useMemo, useState } from "react";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import DatePickerHook from "../../hooks/DatePickerHook";
import { toTitleCase } from "../../../utils/helpers/logicHelper";

const DATE = ({ divClass, formControl, formHook, initialValue }) => {
  const initialDate = (initialValue && moment(initialValue).isValid()) ? new Date(initialValue) : "";
  const [date, setDate] = useState(initialDate);
  const [validatorSchema, setValidatorSchema] = useState({});
  const errors = formHook.formState.errors || formHook.formState.errors[formControl["key"]];
  const maxDate = (formControl["max_length"] && moment(formControl["max_length"]).isValid()) ? new Date(formControl["max_length"]) : new Date();
  const minDate = (formControl["min_length"] && moment(formControl["min_length"]).isValid()) ? new Date(formControl["min_length"]) : new Date("1960/01/01");

  useMemo(() => {
    for (const [key, value] of Object.entries(formControl)) {
      switch (key) {
        case 'required':
          if (value) setValidatorSchema(prevState => ({ ...prevState, required: `${formControl["label"]} is required.` }));
          break;
        case 'max_length':
          setValidatorSchema(prevState => ({
            ...prevState,
            max: { value, message: `Maximum ${(value && moment(value).isValid()) ? `of ${moment(value).format('LL')}` : key} is required.` }
          }));
          break;
        case 'min_length':
          if (value) setValidatorSchema(prevState => ({
            ...prevState,
            min: { value, message: `Minimum of ${(value && moment(value).isValid()) ? `of ${moment(value).format('LL')}` : key} is required.` }
          }));
          break;
        default:
          break;
      }
    }
  }, []);

  const setDateParams = value => {
    formHook.setValue(formControl["key"], moment(value).format());
    setDate(value);
  }

  return (
    <div className={divClass}>
      <label htmlFor={formControl["key"]}>
        {toTitleCase(formControl["label"])} {formControl["required"] && <span className="text-danger">*</span>}
      </label>
      <Controller
        name={formControl["key"]}
        control={formHook.control}
        isInvalid={errors}
        rules={{ ...validatorSchema, valueAsDate: true }}
        render={({ field }) => (
          <>
            <DatePickerHook selected={date}
              placeholder={formControl.placeholder}
              getDate={setDateParams}
              validatorField={{...field}}
              setMinDate={minDate}
              setMaxDate={maxDate}
              dateFormat="MMMM d, yyyy"
              showYearDropdown
              selectDropdownMode
            />
            <ErrorMessage
              errors={errors}
              name={formControl["key"]}
              render={(error) =>
                error &&
                Object.entries(error).map(([type, message]) => (
                  <small key={type} className="text-danger mt-1">{message}</small>
                ))
              }
            />
          </>
        )}
      />

    </div>
  );
}

export default DATE;