import {useState} from "react";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import TokenHelper from "../../utils/helpers/tokenHelper";
import WalletService from "../../utils/services/wallet.service";
import {Loading} from "../../utils/helpers/constants";

const WalletTopupModal = ({onchange}) => {
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const topUpWallet = async () => {
    setLoading(true);
    let callbackUrl = `${window.location.origin}/wallet`;
    let payload = {amount: amount.split(",").join(""), user_id: TokenHelper.getUserId(), callbackUrl};
    const {data: responseData} = await WalletService.topUpUserWallet(payload);
    if (responseData.status !== Loading.SUCCESS) {
      const message = (responseData && responseData.message) ? responseData.message : "You cannot topup your wallet at the moment. Please try again later.";
      toast.error(message);
    }
    else window.open(responseData.data['checkout_url'], '_self')?.focus();
    setLoading(false);
  };

  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="proceed">
            <span id="close-modal-btn1" onClick={handleClose} className="close-modal">&times;</span>
            <div className="withdraw-field">
              <p>Enter the amount you want to top up</p>
              <div className="d-flex w-75">
                <span className="input-group-text input-group-text-left">NGN</span>
                <input type="text" value={amount} className="form-control" onChange={e => setAmount(e.target.value)}
                       placeholder="Enter input here"/>
              </div>
              <button className="main-btn mt-5" onClick={topUpWallet}>
                Proceed to Top up
                {loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletTopupModal;
