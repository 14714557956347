import {useState} from 'react';
import DatePicker from "react-datepicker";

const DatePickerHook = (props) => {

  const [date, setDate] = useState("");
  const dropDownMode = props.selectDropdownMode ? "select" : "";

  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);
    return currentDate.getTime() < selectedDate.getTime();
  };

  const updateGetDate = date => {
    props.getDate(date);
    setDate(date);
  }

  /**
   * UNDERSTANDING HOW THIS DYNAMIC DATE PICKER WORKS.
   * Find library information from https://reactdatepicker.com
   * Log Props to see details of items sent to the datePicker hook. Defaults are used where necessary.
   * ------------------------------------------------------------------------------------------------------
   * */

  return (
    <DatePicker
                {...props.validatorField}
                selected={props.selected ? props.selected : date} onChange={date => updateGetDate(date)}
                className={props.className}
                placeholderText={props.placeholder ? props.placeholder : "Select date"}
                dateFormat={props.dateFormat ? props.dateFormat : "dd/MM/yyyy"}
                timeFormat="HH:mm"
                timeIntervals={props.timeIntervals ? props.timeIntervals : 15}
                showTimeSelect={props.showTimeSelect}
                showYearDropdown={props.showYearDropdown}
                dropdownMode={dropDownMode}
                minDate={props.setMinDate}
                maxDate={props.setMaxDate}
                withPortal={props.withPortal}
                filterTime={props.filterTime ? filterPassedTime : ""}
    />
  );
}

export default DatePickerHook;
