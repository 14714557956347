import moment from "moment";
import {formatAmountToString} from "./logicHelper";
import {WalletStatusMap} from "./mappers";

export const tripsTableConfig = [
  {
    Header: () => <span className="text-center">Address</span>,
    columnId: 0,
    accessor: "destination",
    Cell: ({row}) => {
      return <span className="pointer text-right">{row.original.destination.address}</span>
    }
  },
  {
    Header: () => <span className="text-center">Amount</span>,
    columnId: 1,
    accessor: "total_charge",
    Cell: ({row}) => {
      return <span className="pointer text-right">
        {row.original.quote.currency}{" "} 
        {(row.original.driver !== null && (row.original.vehicle_payment && row.original.vehicle_payment['vehicle_payment'])) ? formatAmountToString(row.original.vehicle_payment['vehicle_payment']) : formatAmountToString(row.original.quote['charge'])}
      </span>
    }
  },
  {
    Header: () => <span className="text-center">Status</span>,
    columnId: 2,
    accessor: "status",
    Cell: ({row}) => {
      return <div className="pointer">{row.original["status"]}</div>
    }
  },
  {
    Header: () => <span className="text-center">Date</span>,
    columnId: 3,
    accessor: "scheduled_date",
    Cell: ({row}) => {
      return <span className='pointer'>{moment(row.original.scheduled_date).format('Do MMMM YYYY')}</span>
    }
  }
];

export const entriesTableConfig = [
  {
    Header: () => <span className="text-center">Reference</span>,
    columnId: 0,
    accessor: "invoice_reference",
    Cell: ({row}) => {
      return <span className="pointer text-right">{row.original['invoice_reference']}</span>
    }
  },
  {
    Header: () => <span className="text-center">Amount</span>,
    columnId: 1,
    accessor: "amount",
    Cell: ({row}) => {
      return <span className="pointer text-right">
        {row.original.currency} {formatAmountToString(row.original.amount)}
      </span>
    }
  },
  {
    Header: () => <span className="text-center">Balance</span>,
    columnId: 1,
    accessor: "available_balance",
    Cell: ({row}) => {
      return <span className="pointer text-right">
        {row.original.currency} {formatAmountToString(row.original['available_balance'])}
      </span>
    }
  },
  {
    Header: () => <span className="text-center">Type</span>,
    columnId: 2,
    accessor: "type",
    Cell: ({row}) => {
      return <div className="pointer">{row.original["type"]}</div>
    }
  },
  {
    Header: () => <span className="text-center">Description</span>,
    columnId: 2,
    accessor: "description",
    Cell: ({row}) => {
      return <div>{row.original["description"]}</div>
    }
  },
  {
    Header: () => <span className="text-center">Status</span>,
    columnId: 3,
    accessor: "status",
    Cell: ({row}) => {
      return <div className={`pointer ${row.original.status ? WalletStatusMap[row.original.status.toUpperCase()].fill : "text-warning"}`}>
        {row.original.status ? WalletStatusMap[row.original.status.toUpperCase()].label : "OTHER"}
      </div>
    }
  },
  {
    Header: () => <span className="text-center">Date</span>,
    columnId: 4,
    accessor: "date",
    Cell: ({row}) => {
     return <div>
       {(row.original['created_at'] && moment(row.original['created_at']).isValid()) ? moment(row.original['created_at']).format('lll') : "-"
       }
     </div>
  }}
];

