import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import toast from "react-hot-toast";

import ResetWithdrawalPin from "./ResetWithdrawalPin";
import PageLayout from "../../../../components/layouts/PageLayout";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import WalletService from "../../../../utils/services/wallet.service";
import {Loading, menuType} from "../../../../utils/helpers/constants";
import {getUserWallet} from "../../../../store/actions/wallet.action";
import {validateIfInteger} from "../../../../utils/services/validator.service";


const WithdrawalPin = () => {

  const dispatch = useDispatch();
  const [pin, setPin] = useState("");
  const [oldPin, setOldPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinType, updatePinType] = useState("password");
  const [oldPinType, updateOldPinType] = useState("password");
  const [confirmPinType, updateConfirmPinType] = useState("password");
  const [loading, setLoading] = useState(false);
  const [resetPinState, setResetPinState] = useState(false);
  const [loadingForgotPin, setLoadingForgotPin] = useState(false);


  const wallet = useSelector(({walletReducer}) => walletReducer);
  const loadState = wallet.loading !== Loading.SUCCESS;
  const isValidated = !pin || (pin !== confirmPin);


  useEffect(() => {
    if (wallet.loading !== Loading.SUCCESS) dispatch(getUserWallet());
  }, []);


  const toggledPinField = (field, type) => {
    if (field === 'oldPin') {
      updateOldPinType(type);
      document.getElementById('oldPin').type = type;
    } else if (field === 'pin') {
      updatePinType(type);
      document.getElementById('pin').type = type;
    } else if (field === 'confirmPin') {
      updateConfirmPinType(type);
      document.getElementById('confirmPin').type = type;
    }
  }

  const handleForgotPin = async () => {
    setLoadingForgotPin(true);
    const {data: responseData} = await WalletService.forgotPin({user_id: wallet.wallet.id});

    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      setResetPinState(true);
      toast.success(responseData.message);
    }

    setLoadingForgotPin(false);
  }


  const createPin = async () => {
    setLoading(true);
    let payload = {pin};
    if (wallet.wallet['extras'] && wallet.wallet['extras']['pin_enabled']) payload['old_pin'] = oldPin;
    const {data: responseData} = await WalletService.changePin(payload, wallet.wallet['extras']['pin_enabled']);

    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      toast.success(responseData.message);
      setPin("");
      setOldPin("");
      setConfirmPin("");
      dispatch(getUserWallet());
    }
    setLoading(false);
  }


  return (
    <PageLayout active={menuType.SETTINGS}>
      <InnerMenuLayout active={menuType.WITHDRAWAL} type="SETTINGS">
        <div className="settings-main">
          {loadState ? <SphereLoader/> :
            <div className="p-4">
              {resetPinState ?
                <ResetWithdrawalPin wallet={wallet.wallet} updateResetState={value => setResetPinState(value)}/>
                :
                <form>
                  {(wallet.wallet['extras'] && wallet.wallet['extras']['pin_enabled']) &&
                    <div className=" mb-3">
                      <label className="mb-2">Enter old pin</label>
                      <div className="input-group">
                        <input type="password" value={oldPin} id="oldPin" onKeyPress={validateIfInteger}
                               onChange={e => setOldPin(e.target.value.replace(/[^0-9]/g, ""))}/>
                        <span className="input-group-text">
                        {oldPinType === "password" ?
                          <i onClick={() => toggledPinField("oldPin", "number")}
                             className="zmdi zmdi-eye fs-5"/> :
                          <i onClick={() => toggledPinField("oldPin", "password")}
                             className="zmdi zmdi-eye-off fs-5"/>
                        }
                      </span>
                      </div>
                    </div>
                  }
                  <div className="col-12 mb-3">
                    <label className="mb-2">Enter new pin</label>
                    <div className="input-group">
                      <input type="password" value={pin} id="pin" onKeyPress={validateIfInteger}
                             onChange={e => setPin(e.target.value.replace(/[^0-9]/g, ""))}
                      />
                      <span className="input-group-text">
                      {pinType === "password" ?
                        <i onClick={() => toggledPinField("pin", "number")} className="zmdi zmdi-eye fs-5"/> :
                        <i onClick={() => toggledPinField("pin", "password")}
                           className="zmdi zmdi-eye-off fs-5"/>
                      }
                    </span>
                    </div>
                  </div>
                  <div className=" col-12 mb-4">
                    <label className="mb-2">Confirm new pin</label>
                    <div className="input-group">
                      <input value={confirmPin} id="confirmPin" type="password" onKeyPress={validateIfInteger}
                             onChange={e => setConfirmPin(e.target.value.replace(/[^0-9]/g, ""))}
                      />
                      <span className="input-group-text">
                      {confirmPinType === "password" ?
                        <i onClick={() => toggledPinField("confirmPin", "number")}
                           className="zmdi zmdi-eye fs-5"/> :
                        <i onClick={() => toggledPinField("confirmPin", "password")}
                           className="zmdi zmdi-eye-off fs-5"/>
                      }
                    </span>
                    </div>
                  </div>
                  {loadState ? <SphereLoader/> :
                    <div className="col-12 mb-4">
                      <div className="mx-wd-65p-f wd-70p-f d-flex justify-content-end pointer-event">
                        <p className="text-primary" onClick={handleForgotPin}>Forgot Pin?
                          {" "}{loadingForgotPin && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
                        </p>
                      </div>
                    </div>
                  }
                  <button className="btn primary-btn" onClick={createPin} disabled={isValidated} type="button">
                    {(wallet.wallet['extras'] && wallet.wallet['extras']['pin_enabled']) ? 'Change Pin' : 'Create pin'}
                    {loading && <>&nbsp;<i className="zmdi zmdi-spinner mg-l-1 ms-2 zmdi-hc-spin"/></>}
                  </button>
                </form>
              }
            </div>
          }
        </div>
      </InnerMenuLayout>
    </PageLayout>
  )
}

export default WithdrawalPin;
