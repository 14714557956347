import { Fragment, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Cities } from "../../utils/helpers/cities";
import { DebounceHook } from "../hooks/DebounceHook";
import { Loading } from "../../utils/helpers/constants";
import BookingService from "../../utils/services/booking.service";

const MapsSelectHook = ({ label, placeholder, setInitialValue, setLocation, setError }) => {
	const [mapOptions, setMapOptions] = useState([]);
	const [debounceField, setDebounceField] = useState("");
	const [locationObject, setLocationObject] = useState({});
	const [debounceState, setDebounceState] = useState(false);
	const [mapOptionsState, setMapOptionsState] = useState(false);
	const debounceText = DebounceHook(debounceField, 350);

	useEffect(() => {
		if (Object.entries(locationObject).length > 0) callPlacesAPI(locationObject['place_id']).catch(err => console.trace(err));
	}, [locationObject]);

	useEffect(() => {
		if (debounceText && debounceState) callAutoCompleteAPI(debounceText).catch(err => console.trace(err));
	}, [debounceText]);
	const getDebounceField = () => {
		if (debounceField) return debounceField;
		else if (setInitialValue) return setInitialValue;
		else return debounceField;
	}

	const updateDebounceField = value => {
		setDebounceField(value);
		setDebounceState(true);
	}

	const selectMapOption = item => {
		setMapOptionsState(false);
		setDebounceField(item.name);
		setLocationObject(item);
	}

	const callAutoCompleteAPI = async data => {
		const { data: responseData } = await BookingService.autoCompleteMapsBySearch(data);
		if (responseData.status !== Loading.SUCCESS) setError(responseData.status);
		else {
			setDebounceState(false);
			setMapOptionsState(true);
			setMapOptions(responseData.data);
		}
	}

	const callPlacesAPI = async data => {
		if (data) {
			const { data: responseData } = await BookingService.placesApiMap(data);
			if (responseData.status !== Loading.SUCCESS) setError(responseData.status);
			else {
				let ifExist = [];
				for (let each of responseData.data['address_components']) {
					let found = Cities.filter(eachCity => each['long_name'].toLowerCase() === eachCity.city.toLowerCase());
					ifExist = ifExist.concat(found);
				}
				let city = ifExist.length > 0 ? ifExist[0]['city'].toUpperCase() : 'DEFAULT';
				let state = ifExist.length > 0 ? ifExist[0]['state'] : 'DEFAULT';
				let placeData = {
					city, state, address: responseData.data.address,
					latitude: responseData.data['geometry']['latitude'],
					longitude: responseData.data['geometry']['longitude']
				};
				setLocation(placeData)
			}
		}	else return toast.error("We are unable to resove this location, Please select another location that is approximately close to your choice of location");
	}


	return (
		<Fragment>
			{label && <label className="mb-3">{label}</label>}
			<input type="text" placeholder={placeholder} value={getDebounceField()}
				onChange={e => updateDebounceField(e.target.value)} />
			{mapOptionsState && <div className="booking-form-dropdown p-3">
				{mapOptions.slice(0, 5).map((item, index) =>
					<div key={index} onClick={() => selectMapOption(item)} className="item py-3 pointer-event">
						<span>{item.name}</span>
					</div>
				)}
			</div>
			}
		</Fragment>

	)
}

export default MapsSelectHook

