const AppSphereLoader = () => (
  <div className="">
    <div className="dimmer active">
      <div className="spinner1">
        <div className="double-bounce1"/>
        <div className="double-bounce2"/>
      </div>
    </div>
  </div>
);

export default AppSphereLoader;
