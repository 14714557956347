import { useMemo, useState } from "react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { toTitleCase } from "../../../utils/helpers/logicHelper";

const SELECT = ({ divClass, formControl, formHook, initialValue }) => {
  const [validatorSchema, setValidatorSchema] = useState({});
  const errors = formHook.formState.errors || formHook.formState.errors[formControl["key"]];
  const initial = (initialValue) ? formControl.options.filter(each => (each.value === initialValue)) : [];

  useMemo(() => {
    for (const [key, value] of Object.entries(formControl)) {
      switch (key) {
        case 'required':
          if (value) setValidatorSchema(prevState => ({ ...prevState, required: `${formControl["label"]} is required.` }));
          break;
        default:
          break;
      }
    }
  }, []);

  return (
    <div className={divClass}>

      <label className="tx-14 d-block" htmlFor={formControl["key"]}>
        {toTitleCase(formControl["label"])} {formControl["required"] && <span className="text-danger">*</span>}
      </label>
      <Controller 
        name={formControl["key"]}
        control={formHook.control}
        isInvalid={errors}
        rules={{...validatorSchema}}
        render={({ field }) => (
        <>
            <Select options={formControl.options ?? []}
              className={`pe-1`}
              maxMenuHeight={250}
              menuPlacement="bottom"
              isClearable={true}
              isSearchable={true}
              defaultValue={initial}
              name={formControl["key"]}
              {...field}
              inputRef={field.ref}
              onBlur={field.onBlur}
              onChange={event => formHook.setValue(formControl["key"], event.value)}
            />
            <ErrorMessage
              errors={errors}
              name={formControl["key"]}
              render={(error) =>
                error &&
                Object.entries(error).map(([type, message]) => (
                  <small key={type} className="text-danger mt-1">{message}</small>
                ))
              }
            /></>
      )}/>
    
    </div>);
}

export default SELECT;