
export const GeneralAppInfo = {
  APP_NAME: "Siju by truQ",
  DODGE_BASE_URL: 'https://dodge.truq.it',
  DODGE_STAGING_BASE_URL: 'https://dodge-apis-staging-c2btvqbhlq-uc.a.run.app',
  GOOGLE_AUTH_KEY: "846567812607-qr3ivqac95pvkcjebf3744q8tq2banre.apps.googleusercontent.com",
};

export const InputValidator = {
  INVALID: 'INVALID',
  VALID: 'VALID'
};

export const UserType = {
  ADMIN: "ADMIN",
  TENANT: "TENANT",
  CUSTOM_USER: "CUSTOM_USER"
};

export const DomStateType = {
  BUSINESS: 'BUSINESS',
  DRIVER: 'DRIVER',
  CUSTOMER: 'CUSTOMER',
  VEHICLE_OWNER: 'VEHICLE_OWNER',
}

export const Loading = {
  FETCHING: 'FETCHING',
  SUCCESS: 'SUCCESS',
  CREATED: 'CREATED',
  ERROR: 'ERROR',
  FAILED: 'FAILED',
  OK: 200,
  NO_CONTENT: 204
}

export const DynamicFileUploadOption = {
  DRIVER: "DRIVER",
  VEHICLE: "VEHICLE",
};

export const InputType = {
  TEXT: 'text',
  EMAIL: 'email',
  OPTIONS: 'options',
  RADIO: 'radio',
  BOOLEAN: 'boolean',
  FILE: 'file',
  NUMBER: 'number',
  DATE: 'date',
};

export const ActionPromptOption = {
  CANCEL_TRIP: "CANCEL_TRIP",
  DELETE_DRIVER: "DELETE_DRIVER",
  DELETE_VEHICLE: "DELETE_VEHICLE",
  DELETE_BUSINESS: "DELETE_BUSINESS",
  DELETE_WAREHOUSE: "DELETE_WAREHOUSE",
  TRANSFER_VEHICLE: "TRANSFER_VEHICLE",
  TRANSFER_BUSINESS: "TRANSFER_BUSINESS",
};

export const Configs = {
  KEY: 'T7uQd45hb04rd-0987Tt1V13wMT4-987to89nC0D30f1529CUlpCyvNmm4r5hyCR36Gp8P48SKAxfYa1k3C5JU',
  USER_KEY: 'T7uQd45hb04rd-0987Tt1V13wMT4-987to89D35h0f15ryP29C48SCR3UnC0lp6Gp8a1CyvNmm4k3KAxfC5JU',
  USER_TYPE: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tC0lpCyvNmm4r5o89n8a1hyPD30f1529CU4kS86GpCR33KAxfC5JU',
  USER_ID: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tC0lm4r5hyPDf1529G130CU486kSCR33KAx9n8ppo8NmaCyDvfC5JU',
  USER_ORG: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tC0lpCyvNmm4yPD30f486Gp152r5hUo9CCa1kSxfCR33KA89n85JU',
  USER_BUSINESS: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tC0lpCyvNmmbU51N355r343r3Nc35hUo9CCa1kC5JU',
  USER_DOM: 'T7uQmuL71Vi3w-0987Tt1V13wMT4-987tC0lpCyvNmm46kSxfGyPD30f48CR33KGp19CCa1A8952r5hUon85JU',
  COOKIE_EXPIRY_PERIOD: 1,
};

export const ServiceType = {
  USR: "USR",
  BOK: "BOK",
  DRM: "DRM",
  FLT: "FLT",
  IMS: "IMS",
  REC: "REC",
  TRP: "TRP",
  WHA: "WHA",
};

export const TripStatus = {
	INITIATED: "INITIATED",
	"AWAITING-PAYMENT": "AWAITING-PAYMENT",
	"ORDER-CONFIRMED": "ORDER-CONFIRMED",
	"ASSIGNED-TO-DRIVER": "ASSIGNED-TO-DRIVER",
	"DRIVER-AWAITING-CONFIRMATION": "DRIVER-AWAITING-CONFIRMATION",
	"DRIVER-ARRIVED": "DRIVER-ARRIVED",
	LOADING: "LOADING",
	"TRIP-STARTED": "TRIP-STARTED",
	"TRIP-STARTED-WITH-STOPS": "TRIP-STARTED-WITH-STOPS",
	"AWAITING-DELIVERY-CONFIRMATION": "AWAITING-DELIVERY-CONFIRMATION",
	"DROP-OFF": "DROP-OFF",
	"COMPLETED-OFFLINE": "COMPLETED-OFFLINE",
	COMPLETED: "COMPLETED",
	CANCELLED: "CANCELLED",
	RECONCILED: "RECONCILED",
	INVALID: "INVALID",
	OTHER: "OTHER",
};

export const menuType = {
  HOME: 'home',
  TRIP: 'trip',
  WALLET: 'wallet',
  BOOK_TRIP: 'book',
  PROFILE: 'profile',
  SETTINGS: 'settings',
  WAREHOUSE: 'warehouse',
  DRIVER_INFO: 'driverInfo',
  EDIT_PROFILE: 'editProfile',
  WITHDRAWAL: 'withdrawalPin',
  BANK_ACCOUNT: 'bankAccount',
  NOTIFICATION: 'notification',
  DELETE_ACCOUNT: 'deleteAccount',
  GUARANTOR_INFO: 'guarantorInfo',
  DISABLE_ACCOUNT: 'disableAccount',
  CHANGE_PASSWORD: 'changePassword',
  API_INTEGRATION: 'apiIntegration',
  REGISTERED_VEHICLE: 'registeredVehicle',
}

export const ImageOptions = {
	PROFILE: 'PROFILE',
	IMAGE: 'IMAGE',
	WAYBILL: 'WAYBILL',
	PROOF_OF_DELIVERY: 'PROOF_OF_DELIVERY',
};

export const ActionType = {
	CREATE: 'CREATE',
	UPDATE: 'UPDATE',
};

