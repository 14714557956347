import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Loading, menuType} from "../../../../utils/helpers/constants";
import Body from "./Body";
import PageLayout from "../../../../components/layouts/PageLayout";
import SphereLoader from "../../../../components/loaders/SphereLoader";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import {getWarehouses} from "../../../../store/actions/configs.action";

const Warehouse = () => {
	const dispatch = useDispatch();
	const {profile, configs} = useSelector(({profileReducer, configsReducer}) => ({
		profile: profileReducer,
		configs: configsReducer
	}));
	const loadState = configs.loadingWarehouses !== Loading.SUCCESS;

	useEffect(() => {
		if(configs.loadingWarehouses !== Loading.SUCCESS) dispatch(getWarehouses());
	}, []);


	return (
		<PageLayout active={menuType.PROFILE}>
			<InnerMenuLayout active={menuType.WAREHOUSE} type="PROFILE">
				{loadState ? <SphereLoader/> : <Body domState={profile.domState} warehouses={configs.warehouses}/>}
			</InnerMenuLayout>
		</PageLayout>
	)
}

export default Warehouse;
