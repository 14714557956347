import {dodgeInstance as Axios} from './axios.service';
import {DomStateType} from "../helpers/constants";
import TokenHelper from "../helpers/tokenHelper";

const BookingService = {

  autoCompleteMapsBySearch(key) {
    return Axios({
      method: 'GET',
      url: `booking-service/maps/autocomplete-search-by-text/?search_text=${key}`
    });
  },

  placesApiMap(key) {
    return Axios({
      method: 'GET',
      url: `booking-service/maps/search-place-by-id/?place_id=${key}`
    });
  },

  getOrganisationTripTypes() {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-organisation-profile/get_organisation_trip_type/`
    });
  },

  getOrganisationExtras() {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-organisation-profile/get_extras/`
    });
  },

  fetchTrips(filter, entity) {
    let headers = {entity: entity.toLowerCase()};
    if (entity === DomStateType.BUSINESS) headers = {...headers, business: TokenHelper.getSelectedBusiness()};
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-trip/${filter}`,
      headers: headers,
    });
  },

  fetchTripInfo(payload, domtype) {
    const headers = domtype === DomStateType.DRIVER ? {entity: domtype.toLowerCase()} : {};
    
    return Axios({
      method: 'POST',
      url: `booking-service/booking/booking-trip/search/`,
      data: payload,
      headers
    });
  },

  fetchPicklist(reference, businessRef) {
    const headers = !businessRef ? {} : {entity: businessRef};
    
    return Axios({
      method: 'GET',
      url: `warehouse-service/vehicle/picklist/${reference}`,
      headers
    });
  },

  fetchDriverTripRequests() {
    return Axios({
      method: 'GET',
      url: `asset-service/driver/driver-trip-requests/`
    });
  },

  initiateTripQuote(payload){
    return Axios({
      method: 'POST',
      url: `booking-service/booking/booking-trip/check_quote/`,
      data: payload
    });
  },

  bookTrip(payload) {
    return Axios({
      method: 'POST',
      url: `booking-service/booking/booking-trip/`,
      data: payload
    });
  },

  bookBusinessTrip(payload) {
    return Axios({
      method: 'POST',
      url: `entity-service/business/business-tripset/`,
      data: payload
    });
  },

  bookIMSTrip(payload) {
    return Axios({
      method: 'POST',
      url: `booking-service/ims/ims-user-manifest/`,
      data: payload
    });
  },

  updateBookTrip(id, payload) {
    return Axios({
      method: 'PATCH',
      url: `booking-service/booking/booking-trip/${id}/`,
      data: payload,
    })
  },

  checkout(id, callbackUrl) {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-trip/${id}/checkout/?callback=${callbackUrl}`
    });
  },

  checkoutBusiness(id, businessRef, callbackUrl) {
    return Axios({
      method: 'GET',
      url: `entity-service/business/business-tripset/${id}/checkout/?callback=${callbackUrl}`,
      headers: {entity: businessRef}
    });
  },

  walletCheckout(id) {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-trip/${id}/walletcheckout/`
    });
  },

  walletBusinessCheckout(id, businessRef) {
    return Axios({
      method: 'GET',
      url: `entity-service/business/business-tripset/${id}/walletcheckout/`,
      headers: {entity: businessRef}
    });
  },

  advancePaymentCheckout(id){
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-trip/${id}/advancedcheckout`
    });
  },

  advancePaymentBusinessCheckout(id, businessRef){
    return Axios({
      method: 'GET',
      url: `entity-service/business/business-tripset/${id}/advancedcheckout/`,
      headers: {entity: businessRef}
    });
  },

  cancelTrip(id) {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-trip/${id}/cancel/`,
    });
  },

  acceptTripRequest(payload) {
    return Axios({
      method: 'POST',
      url: `asset-service/driver/driver-accept-trip/`,
      data: payload,
      headers: { entity: 'driver' }
    });
  },

  validateTripArrival(id) {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-trip/${id}/driverarrival/`,
      headers: {entity: 'driver'}
    });
  },

  startTrip(id) {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-trip/${id}/starttrip/`,
      headers: {entity: 'driver'}
    });
  },

  confirmB2BArrival(id, payload) {
    return Axios({
      method: 'POST',
      url: `booking-service/booking/booking-trip/${id}/confirm_driver_arrival/`,
      data: payload,
      headers: {entity: 'driver'}
    });
  },

  confirmB2CDelivery(id, payload) {
    return Axios({
      method: 'POST',
      url: `/booking-service/booking/booking-trip/${id}/confirm_driver_delivery/`,
      data: payload,
      headers: {entity: 'driver'}
    });
  },

  dropOffDelivery(id, payload, ) {
    return Axios({
      method: 'POST',
      url: `booking-service/booking/booking-trip/${id}/dropofflocation/`,
      data: payload,
      headers: {entity: 'driver'}
    });
  },

  validateDelivery(id) {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-trip/${id}/driverdelivery/`,
      headers: {entity: 'driver'}
    });
  },

  reconcileDelivery(id) {
    return Axios({
      method: 'GET',
      url: `booking-service/booking/booking-trip/${id}/reconcile_business_trip/`,
      headers: { entity: 'driver' }
    });
  },

  reconcileIMSDropOff(id, payload) {
    return Axios({
      method: 'POST',
      url: `booking-service/booking/booking-trip/${id}/reconcile_ims_trip/`,
      data: payload,
      headers: {entity: 'driver'}
    });
  },

  reconcileOrderSetDropOff(id, payload) {
    return Axios({
      method: 'PATCH',
      url: `warehouse-service/inventory/orderset/reconcile/${id}`,
      data: payload,
      headers: {entity: 'driver'}
    });
  },

  uploadProofOfPayment(tripId, payload){
    return Axios({
      method: "POST",
      url: `booking-service/booking/booking-trip/${tripId}/upload_trip_documents/`,
      data: payload,
      headers: {entity: 'driver'}
    })
  },

};

export default BookingService;
