import { useSelector} from "react-redux";
import {NavLink} from "react-router-dom";

import BankSvg from "../../../assets/images/svg/bank.svg";
import VehicleSvg from "../../../assets/images/svg/vehicle.svg";
import DisableProfileSvg from "../../../assets/images/svg/driver.svg";
import DriverProfileSvg from "../../../assets/images/svg/profile.svg";
import EditProfileSvg from "../../../assets/images/svg/edit-profile.svg";
import BankSelectedSvg from "../../../assets/images/svg/bank-selected.svg";
import VehicleSelectedSvg from "../../../assets/images/svg/vehicle-selected.svg";
import DisableProfileSelectedSvg from "../../../assets/images/svg/driver-selected.svg";
import DriverProfileSelectedSvg from "../../../assets/images/svg/profile-selected.svg";
import EditProfileSelectedSvg from "../../../assets/images/svg/edit-profile-selected.svg";
import {DomStateType, menuType, ServiceType} from "../../../utils/helpers/constants";


const ProfileMenu = ({active}) => {
	const {profile, configs} = useSelector(({profileReducer, configsReducer}) => ({
		profile: profileReducer,
		configs: configsReducer
	}))


	return (
		<div className="settings-menu settings-sidebar">
			<div className="sidebar-menu d-flex flex-column pt-2">
				<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.EDIT_PROFILE ? 'active' : ''}`}>
					<NavLink className="d-flex align-items-center" to="/profile/edit-profile">
						<img src={active === menuType.EDIT_PROFILE ? EditProfileSelectedSvg : EditProfileSvg} alt="EDIT_PROFILE"/>
						<span className="ms-md-2 ms-0" id="settings-item">Edit Profile</span>
					</NavLink>
				</div>

				{(profile.domState.type === DomStateType.DRIVER && profile.domState.driverProfile) &&
					<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.DRIVER_INFO ? 'active' : ''}`}>
						<NavLink className="d-flex align-items-center" to="/profile/driver-information">
							<img src={active === menuType.DRIVER_INFO ? DriverProfileSelectedSvg : DriverProfileSvg} alt="DRIVER_INFO"/>
							<span className="ms-md-2 ms-0" id="settings-item">Driver Information</span>
						</NavLink>
					</div>
				}

				{(profile.domState.type === DomStateType.BUSINESS && ServiceType.WHA in configs.organisationServices) &&
					<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.WAREHOUSE ? 'active' : ''}`}>
						<NavLink className="d-flex align-items-center" to="/profile/warehouse">
							<img src={active === menuType.WAREHOUSE ? BankSelectedSvg : BankSvg} alt="WAREHOUSE"/>
							<span className="ms-md-2 ms-0" id="settings-item">Warehouse</span>
						</NavLink>
					</div>
				}

				{profile.userData['is_vehicle_owner'] && (profile.domState.type === DomStateType.VEHICLE_OWNER || profile.domState.type === DomStateType.DRIVER) &&
					<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.REGISTERED_VEHICLE ? 'active' : ''}`}>
						<NavLink className="d-flex align-items-center" to="/profile/registered-vehicle">
							<img src={active === menuType.REGISTERED_VEHICLE ? VehicleSelectedSvg : VehicleSvg} alt="REGISTERED_VEHICLE"/>
							<span className="ms-md-2 ms-0" id="settings-item">Registered Vehicle</span>
						</NavLink>
					</div>
				}

				<div className={`sidebar-menu-item d-flex mb-2 ${active === menuType.DISABLE_ACCOUNT ? 'active' : ''}`}>
					<NavLink className="d-flex align-items-center" to="/profile/disable-account">
						<img src={active === menuType.DISABLE_ACCOUNT ? DisableProfileSelectedSvg : DisableProfileSvg} alt="DISABLE_ACCOUNT"/>
						<span className="ms-md-2 ms-0" id="settings-item">Disable Account</span>
					</NavLink>
				</div>
			</div>
		</div>

	);
}

export default ProfileMenu;
