import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

import { Loading } from "../../../../utils/helpers/constants";
import { getTrips } from "../../../../store/actions/booking.action";
import BookingService from "../../../../utils/services/booking.service";
import { formatAmountToString } from "../../../../utils/helpers/logicHelper";
import AdvancePaymentModal from "../../../../components/modals/AdvancePaymentModal";


const BusinessConfirmation = ({ domState, booking, wallet, orgWalletConfig, vehicle }) => {

	const dispatch = useDispatch(), navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [loadingWallet, setLoadingWallet] = useState(false);
	const [advancePaymentRender, setAdvancePaymentRender] = useState(false);


	const handleSuccessResponse = () => {
		toast.success('Successfully paid for trip. Assigning to driver...');
		navigate(`/trips/${booking.trips[0].reference}`);
	}

	const makeCardPayment = async () => {
		setLoading(true);

		if (booking.quote['checkout_url']) window.open(booking.quote['checkout_url'], '_self')?.focus();
		else {
			const callbackUrl = `${window.location.origin}/trips/${booking.trips[0].reference}/`;
			const { data: responseData } = await BookingService.checkoutBusiness(booking.id, booking.business.reference, callbackUrl);
			if (responseData.status !== Loading.SUCCESS) {
				const message = (responseData && responseData.message) ? responseData.message : "You cannot make payment for this trip at the moment, please try again later";
				toast.error(message);
			}
			else window.open(responseData.data['checkout_url'], '_self')?.focus();
		}
		setLoading(false);
	}

	const makeWalletPayment = async () => {
		setLoadingWallet(true);
		const { data: responseData } = await BookingService.walletBusinessCheckout(booking.id, booking.business.reference);
		if (responseData.status !== Loading.SUCCESS) {
			const message = (responseData && responseData.message) ? responseData.message : "You cannot make payment for this trip at the moment, please try again later";
			toast.error(message);
		}
		else {
			await dispatch(getTrips("", domState.type));
			handleSuccessResponse();
		}
		setLoadingWallet(false);
	}

	const changeAdvancePaymentRender = () => setAdvancePaymentRender(false);

	const advancePaymentModal = advancePaymentRender ?
		<AdvancePaymentModal onchange={changeAdvancePaymentRender} props={{ trip: booking.trips[0], wallet }}
			onSuccessResponse={handleSuccessResponse} /> : null;


	return (
		<div className="booking-confirm mt-5">
			{advancePaymentModal}
			<div className="d-flex flex-column align-items-center">
				<h3 className="booking-confirm-title">Booking confirmation</h3>
				<div className="booking-confirm-info">
					<div className="info-group">
						<div>
							<span>Number of trips</span>
							<span>{booking.trips.length}</span>
						</div>
						{booking.trips.length > 0 &&
							<div>
								<span>Pickup location</span>
								<span className="text-right">
									{booking.trips[0].source.address.length > 45 ?
										`${booking.trips[0].source.address.replace(/<[^>]*>?/gm, ' ').slice(0, 45)}...` : booking.trips[0].source.address}
								</span>
							</div>
						}
						<div>
							<span>Vehicle Category</span>
							<span>{vehicle['category']} </span>
						</div>
						<div>
							<span>Vehicle Size</span>
							<span>{vehicle['tonnage']} tonne</span>
						</div>
						<div>
							<span>Date Initiated</span>
							<span>{moment(booking['created_on']).format('Do MMMM YYYY')}</span>
						</div>
					</div>
					<div className="info-group">
						<div>
							<span>Delivery fee</span>
							<span>{booking.quote.currency} {formatAmountToString(booking.quote['total_charge'])}</span>
						</div>
					</div>
					{booking.quote['discount_amount'] &&
						<div>
							<span className="text-primary">Discount {Math.round(booking.quote['discount_amount'] * 100 / booking.quote['charge'])}%</span>
							<span className="text-primary">{booking.quote.currency} {formatAmountToString(booking.quote['discount_amount'])}</span>
						</div>
					}
					<div className="d-flex justify-content-between charge-group my-3">
						<span>Total Charge</span>
						<span>{booking.quote.currency} {formatAmountToString(booking.quote['charge'])}</span>
					</div>
				</div>
				<div className="w-100 d-block d-sm-flex justify-content-center justify-content-sm-between gap-sm-1 charge-group my-3">
					<button className="btn main-btn mt-4" onClick={makeCardPayment} type="button">
						Pay with Card
						{loading ? <i className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin ms-2" /> : ""}
					</button>
					<button className="btn main-btn secondary-btn mt-4" onClick={makeWalletPayment} type="button">
						Pay with Wallet
						{loadingWallet ? <i className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin ms-2" /> : ""}
					</button>
					{(booking.quote && Object.entries(booking.quote).length > 0) &&  (wallet && wallet.extras && wallet.extras["advanced_payment_status"]) && (orgWalletConfig && orgWalletConfig["advance_payment_status"]) &&
						<button className="btn main-btn mt-4 me-1" onClick={() => setAdvancePaymentRender(true)} type="button">
							Part Payment
						</button>
					}
				</div>
			</div>
		</div>
	);
}

export default BusinessConfirmation;
