export const menuOptions = [
  {name: "Dashboard", link: "/dashboard", icon: "zmdi zmdi-view-dashboard"},
  {name: "Vehicle Management", link: "/vehicles", icon: "zmdi zmdi-truck"},
  {name: "Drivers Management", link: "/drivers", icon: "zmdi zmdi-accounts"},
  {name: "Businesses", link: "/business", icon: "zmdi zmdi-store"},
  {name: "Customers", link: "/customers", icon: "zmdi zmdi-accounts-list-alt"},
  {name: "Trips and Orders", link: "/trips", icon: "zmdi zmdi-google-maps"},
  {
    name: "Wallet Management",
    link: "/wallets",
    icon: "zmdi zmdi-balance-wallet",
    keywords: "wallet box top up fund wallet transfer"
  },
  {name: "Curacel and Insurance", link: "/insurance", icon: "zmdi zmdi-globe-lock"},
  {
    name: "Back Office",
    link: "/back-configuration",
    icon: "zmdi zmdi-settings",
    keywords: "base fare VAT insurance country supported country"
  },
];

export const DynamicFieldsTypes = [
  { label: "Text", value: "text" },
  { label: "Email", value: "email" },
  { label: "Select option", value: "options" },
  { label: "Radio button", value: "radio" },
  { label: "Check button", value: "boolean" },
  { label: "File upload button", value: "file" },
  { label: "Number", value: "number" },
  { label: "Date", value: "date" }
];

export const PaymentTransactionTypes = [
  {label: 'Credit', value: 'CREDIT'},
  {label: 'Debit', value: 'DEBIT'}
];

export const paginationSize = [
  {label: "Showing 10", value: 10},
  {label: "Showing 20", value: 20},
  {label: "Showing 30", value: 30},
  {label: "Showing 50", value: 50},
  {label: "Showing 100", value: 100},
];

export const financialOptions = [
  {label: "Credit Wallet", value: "CREDIT"},
  {label: "Debit Wallet", value: "DEBIT"},
];

export const quarterOptions = [
  {label: "Q1", value: 1},
  {label: "Q2", value: 2},
  {label: "Q3", value: 3},
  {label: "Q4", value: 4},
];

export const monthOptions = [
  {label: "January", value: "January"},
  {label: "February", value: "February"},
  {label: "March", value: "March"},
  {label: "April", value: "April"},
  {label: "May", value: "May"},
  {label: "June", value: "June"},
  {label: "July", value: "July"},
  {label: "August", value: "August"},
  {label: "September", value: "September"},
  {label: "October", value: "October"},
  {label: "November", value: "November"},
  {label: "December", value: "December"},
];
