import {useState} from "react";
import {useDispatch} from "react-redux";
import {NavLink} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";
import toast from "react-hot-toast";

import SuccessSvg from "../../assets/images/pngs/success.png";
import WalletService from "../../utils/services/wallet.service";
import {Loading} from "../../utils/helpers/constants";
import {validateIfFloat, validateIfInteger} from "../../utils/services/validator.service";
import {getUserWallet, getUserWalletEntries} from "../../store/actions/wallet.action";

const WalletWithdrawalModal = ({onchange, accounts, wallet}) => {

  const dispatch = useDispatch();
  const [show, setShow] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [pin, setPin] = useState("");
  const [account, setAccount] = useState("");
  const [amount, setValidateInput] = useState("");
  const [pinType, updatePinType] = useState("password");
  const isValidated = !wallet['withdraw_status'] || !pin || !amount || Object.entries(account).length === 0;

  const handleClose = () => {
    setShow(false);
    onchange();
  };

  const toggledPinField = type => {
    updatePinType(type);
    document.getElementById('pin').type = type;
  }

  const withdraw = async () => {
    setLoading(true);
    const payload = {amount: amount.split(",").join(""), bank_id: account.id, pin}
    const {data: responseData} = await WalletService.withdrawFunds(payload);
    if (responseData.status !== Loading.SUCCESS){
      const message = (responseData && responseData.message) ? responseData.message : "You cannot withdraw your funds at the moment. Please try again later.";
      toast.error(message);
    }
    else {
      dispatch(getUserWallet());
      dispatch(getUserWalletEntries(""));
      setShowSuccess(true);
    }
    setLoading(false);
  }


  return (
    <>
      <Modal show={show} backdrop="static" keyboard={false} centered>
        <Modal.Body>
          <div className="proceed">
            <span id="close-modal-btn1" onClick={handleClose} className="close-modal">&times;</span>
            {showSuccess ?
              <div className="success-withdraw">
                <div className="withdraw-field">
                  <div className="opt-bg"><img src={SuccessSvg} alt=""/></div>
                  <p>Successful!</p>
                  <p id="success">Your Withdrawal is successful</p>
                </div>
                <div className="go-home"><span id="close-modal-btn-success1">Go back to home</span></div>
              </div> :
              <div className="withdraw-field">
                <p>Withdraw Funds</p>
                {!wallet['extras'] || (wallet['extras'] && !wallet['extras']['pin_enabled']) ?
                  <div className="alert alert-warning text-center mb-4" role="alert">
                    <strong className="me-1">Warning!</strong>
                    You cannot withdraw at the moment as you are yet to create a withdrawal Pin. Click
                    <NavLink to="/settings/wallet-pin"> here </NavLink> to create transaction pin.
                  </div> : ""
                }
                {!wallet['withdraw_status'] &&
                  <div className="alert alert-danger text-center mb-4" role="alert">
                    <strong className="me-1">Warning!</strong>
                    You cannot withdraw at this moment as this feature is not enabled for you.
                  </div>
                }

                <div className="d-flex w-85">
                  <Select options={accounts}
                          getOptionLabel={each => `${each['bank']} / ${each.account_number}`}
                          isClearable={false}
                          isSearchable={true}
                          maxMenuHeight={250}
                          menuPlacement="bottom"
                          name={account}
                          placeholder="Select Bank Account"
                          className="wd-100p"
                          onChange={option => setAccount(option)}/>
                </div>
                <div className="d-flex w-85 mx-4 mt-3">
                  <span className="input-group-text-left">NGN</span>
                  <input type="number" className="form-control"
                   onKeyPress={validateIfFloat} value={amount}
                         onChange={e => setValidateInput(e.target.value)}
                         placeholder="Enter input here"/>
                </div>

                <div className="d-flex w-85 mt-2">
                  <span className="input-group-text-left">PIN</span>
                  <input type="password" placeholder="Enter pin" id="pin" className="form-control" value={pin}
                         onKeyPress={validateIfInteger}
                         onChange={e => setPin(e.target.value)}/>
                  <span className="input-group-text-right">
                      {pinType === "password" ?
                        <i onClick={() => toggledPinField("number")} className="zmdi zmdi-eye fs-5"/> :
                        <i onClick={() => toggledPinField("password")}
                           className="zmdi zmdi-eye-off fs-5"/>
                      }
                    </span>
                </div>

                <button onClick={withdraw} disabled={isValidated} className="btn main-btn mt-5 w-85">
                  Proceed to Withdraw
                  {loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
                </button>
              </div>
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WalletWithdrawalModal;
