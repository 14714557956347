import ActionCreator from "../../utils/helpers/actionCreator";
import * as AuthConstants from "../constants/auth.constants";
import {getProfile} from "./profile.action";
import {getOrganisationDomain, getOrganisationDomainMethod} from "../../utils/services/baseUrl.service";
import {findOrganisationModalRender} from "./component.action";
import AuthService from "../../utils/services/auth.service";
import TokenHelpers from "../../utils/helpers/tokenHelper";
import TokenHelper from "../../utils/helpers/tokenHelper";
import CookieHelper from "../../utils/helpers/cookieHelper";
import CacheHelper from "../../utils/helpers/cacheHelper";
import {Configs, Loading} from "../../utils/helpers/constants";
import * as ProfileConstants from "../constants/profile.constants";


export const fetchAuthPolicies = () => async dispatch => {
  try {
    dispatch(ActionCreator(AuthConstants.FETCH_AUTH_POLICY_REQUEST));
    const {data: responseData} = await AuthService.fetchAuthPolicy();
    await dispatch(ActionCreator(AuthConstants.FETCH_AUTH_POLICY_SUCCESS, responseData.data));
  } catch (error) {
    dispatch(ActionCreator(AuthConstants.FETCH_AUTH_POLICY_FAILURE));
  }
}

export const getStoreOnReload = store => {
  store.dispatch(ActionCreator(ProfileConstants.SET_DOM_PROFILE, CookieHelper.get(Configs.USER_DOM)));
  const tokenExists = TokenHelper.checkIfLoggedIn();
  if (tokenExists) {
    const token = TokenHelper.getToken();
    if (token) store.dispatch(getProfile());
  }
};

export const setOrganisationHeader = async store => {
  let userAuthenticated = TokenHelpers.checkIfLoggedIn();
  let organisationDomain = getOrganisationDomain(), organisationDomainMethod = getOrganisationDomainMethod();
  if (!userAuthenticated) {
    if (organisationDomain === 'findOrganisation') store.dispatch(findOrganisationModalRender());
    else if (organisationDomainMethod === 'cookieQuery') {
      store.dispatch(ActionCreator(AuthConstants.SET_ORGANISATION_HEADER, organisationDomain));
      TokenHelpers.saveSelectedOrg(organisationDomain);
    } else if (organisationDomainMethod === 'searchQuery') {
      store.dispatch(ActionCreator(AuthConstants.SET_ORGANISATION_HEADER, organisationDomain));
      TokenHelpers.saveSelectedOrg(organisationDomain);
    } else if (organisationDomainMethod === 'domainQuery') {
      const {data: responseData} = await AuthService.fetchOrganisationFromDomain(organisationDomain);
      if (responseData.status === Loading.SUCCESS) {
        store.dispatch(ActionCreator(AuthConstants.SET_ORGANISATION_HEADER, responseData.data.organisation));
        TokenHelpers.saveSelectedOrg(responseData.data.organisation);
      }
    }
  }
};

export const setLoggedInUser = (userToken, user) => async dispatch => {
  await TokenHelper.saveToken(userToken);
  await TokenHelper.saveUserId(user.id);
  await TokenHelper.saveUserType(user.platform);
  const token = TokenHelper.checkIfLoggedIn();
  if (token) await dispatch(getProfile());
};

export const removeLoggedInUser = () => async dispatch => {
  await AuthService.logout();
  dispatch(ActionCreator(AuthConstants.LOG_OUT));
  CookieHelper.remove(Configs.KEY);
  CookieHelper.remove(Configs.USER_DOM);
  CacheHelper.clear();
  window.location.href = "/auth/login";
};