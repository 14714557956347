import {useState} from "react";
import toast from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import {copyToClipboard} from "../../utils/helpers/logicHelper";
import BookingService from "../../utils/services/booking.service";
import {InputValidator, Loading} from "../../utils/helpers/constants";
import {emailValidator, phoneValidator} from "../../utils/services/validator.service";


const QuoteModal = ({onchange, props}) => {

	const [show, setShow] = useState(true);
	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [phoneError, setPhoneError] = useState(true);
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		setShow(false);
		onchange();
	};
	const validateInput = !email || emailValidator(email).validState === InputValidator.INVALID || emailError || phoneError;

	const handleFieldValidation = (data, type) => {
		if(type === 'EMAIL') {
			const validatedEmail = emailValidator(data);
			setEmail(validatedEmail.validateEmail);
			validatedEmail.validState === InputValidator.VALID ? setEmailError(false) : setEmailError(true);
		} else if(type === 'PHONE') {
			data = data.replace(/[^0-9/+]/gi, "");
			const validatedPhone = phoneValidator(data);
			setPhone(data);
			(validatedPhone === InputValidator.VALID || !data) ? setPhoneError(false) : setPhoneError(true);
		}
	};

	const getQuote = async() => {
		setLoading(true);
		let payload = {email, phone, business: props.reference};
		if(props && props.coupon) payload.coupon = props.coupon;

		const {data: responseData} = await BookingService.initiateTripQuote(payload);
		if(responseData.status !== Loading.SUCCESS) {
			const message = (responseData && responseData.message) ? responseData.message : "You cannot get trip quote at the moment. Please try again later.";
			toast.error(message);
		}
		else {
			const checkoutUrl = `${window.location.hostname === "localhost" || window.location.hostname === "dodge-super-app-beta.vercel.app" ? "dodge-checkout-beta.vercel.app" : "checkout.siju.it"}/${responseData.data.quote.reference}`;
			await copyToClipboard(checkoutUrl);
			toast.success("checkout url has been copied to clipboard!");
			handleClose();
		}
		setLoading(false);
	}


	return (
		<>
			<Modal show={show} backdrop="static" keyboard={false} size="lg" centered scrollable>
				<Modal.Body>
          <span id="close-modal-btn1" onClick={handleClose} className="close-modal mt-2 fs-1 text-danger">&times;
          </span>
					<div className="proceed">
						<div className="mt-2 mb-2">
							<h5>Initiate Quote</h5>
						</div>
						<form className="form pt-3">
							<div className="row">
								<div className="col-md-6 mt-3 form-group text-start">
									<label className="mb-1">Email</label>
									<input className={`form-control ${emailError && 'is-invalid'}`} placeholder="Enter Email" type="text"
												 value={email}
												 onChange={event => handleFieldValidation(event.target.value, 'EMAIL')}/>
								</div>
								<div className="col-md-6 mt-3 form-group text-start">
									<label className="mb-1">Phone number</label>
									<input className={`form-control ${phoneError && 'is-invalid'}`} placeholder="Enter Phone number"
												 type="text"
												 value={phone}
												 onChange={event => handleFieldValidation(event.target.value, 'PHONE')}/>
								</div>
							</div>
							<div className="d-flex justify-content-start mt-5">
								<button className="btn primary-btn mg-r-2"
												onClick={getQuote}
												type="button"
												disabled={validateInput}>
									Initiate Quote
									{loading && <>&nbsp;<i
										className="zmdi zmdi-spinner mg-l-1 zmdi-hc-spin ms-2"/></>}
								</button>
							</div>
						</form>
					</div>
				</Modal.Body>
			</Modal>
		</>
	)
}

export default QuoteModal
