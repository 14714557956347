import {dodgeInstance as Axios} from './axios.service';

const WalletService = {

  getWallet() {
    return Axios({
      method: 'GET',
      url: `locale-service/wallets/user/wallet`
    });
  },

  getWalletEntries(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/wallets/user/entries/${filter}`
    });
  },

  topUpUserWallet(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/wallets/user/top-up`,
      data: payload
    });
  },

  changePin(payload, changeStatus) {
    let urlPatch = changeStatus ? 'change-pin' : 'create-pin';
    return Axios({
      method: 'POST',
      url: `locale-service/payment/withdrawals/users/${urlPatch}`,
      data: payload
    });
  },

  forgotPin(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/payment/withdrawals/users/forgot-pin`,
      data: payload
    });
  },

  resetPin(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/payment/withdrawals/users/reset-pin`,
      data: payload
    });
  },

  getBanks() {
    return Axios({
      method: 'GET',
      url: `locale-service/payment/withdrawals/banks`
    });
  },

  getBankAccount() {
    return Axios({
      method: 'GET',
      url: `locale-service/payment/withdrawals/users/banks`
    });
  },

  nameInquiry(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/payment/withdrawals/banks/account-inquiry`,
      data: payload
    });
  },

  addBankAccount(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/payment/withdrawals/users/banks`,
      data: payload
    });
  },

  withdrawFunds(payload) {
    return Axios({
      method: 'POST',
      url: `locale-service/payment/withdrawals/users/withdraw`,
      data: payload
    });
  },

};

export default WalletService;
