import {Fragment, useState} from "react";
import {Navigate, useNavigate, useSearchParams} from "react-router-dom";
import toast from "react-hot-toast";

import AuthService from "../../../utils/services/auth.service";
import AuthLayout from "../../../components/layouts/AuthLayout";
import {passwordMatchValidator} from "../../../utils/services/validator.service";
import {InputValidator, Loading, UserType} from "../../../utils/helpers/constants";

const Body = ({properties, passwordPolicy}) => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordConfirmError, setConfirmPasswordError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordType, updatePasswordType] = useState("password");
  const [confirmPasswordType, updateConfirmPasswordType] = useState("password");

  const isInvalid = !password || !confirmPassword || passwordConfirmError || password !== confirmPassword;
  if (!searchParams.get('token')) return <Navigate to={{pathname: "/auth/login"}}/>;


  const togglePasswordField = (type, field) => {
    if (field === "password") {
      updatePasswordType(type);
      document.getElementById('password').type = type;
    } else if (field === "confirmPassword") {
      updateConfirmPasswordType(type);
      document.getElementById('confirmPassword').type = type;
    }
  };

  const handlePasswordValidator = (data, type) => {
    const validatedConfirmPassword = passwordMatchValidator(password, data);
    type === 'PASSWORD' ? setPassword(data) : setConfirmPassword(data);
    if (validatedConfirmPassword === InputValidator.VALID) setConfirmPasswordError(false);
    else setConfirmPasswordError(true);
  }

  const handleConfirmReset = async () => {
    setLoading(true);
    const payload = {
      password, confirm_password: confirmPassword,
      token: searchParams.get('token')
    }
    const {data: responseData} = await AuthService.resetPassword(payload, UserType.CUSTOM_USER);
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      navigate('/auth/login');
      toast.success(responseData.message);
    }
    setLoading(false);
  }

  return (
    <Fragment>
      <AuthLayout favicon={properties['favicon']} logo={properties['logo']}>
        <form className="login-form">
          <h5 className="text-start text-center">Retrieve Your Account</h5>
          <p className="mb-4 text-muted tx-13 ms-0 text-start">
            Set-Up new password to retrieve your Administrator access.
          </p>
          <div className="form-group text-start pos-relative">
            <label>Password</label>
            <input className="form-control" value={password} placeholder="Enter your password"
                   minLength={Number(passwordPolicy['min_length'])}
                   maxLength={Number(passwordPolicy['max_length'])}
                   type="password" id="password"
                   onChange={event => handlePasswordValidator(event.target.value, "PASSWORD")}/>
            <div className="eye-right">
              {passwordType === "password" ?
                <i onClick={() => togglePasswordField("text", "password")} className="zmdi zmdi-eye"/> :
                <i onClick={() => togglePasswordField("password", "password")}
                   className="zmdi zmdi-eye-off"/>
              }
            </div>
          </div>

          <div className="form-group text-start pos-relative">
            <label>Confirm Password</label>
            <input className={`form-control ${(passwordConfirmError || password !== confirmPassword) && 'is-invalid'}`}
                   value={confirmPassword} placeholder="Enter your password"
                   minLength={Number(passwordPolicy['min_length'])}
                   maxLength={Number(passwordPolicy['max_length'])}
                   type="password" id="confirmPassword"
                   onChange={event => handlePasswordValidator(event.target.value, "CONFIRM_PASSWORD")}/>
            <div className="eye-right">
              {confirmPasswordType === "password" ?
                <i onClick={() => togglePasswordField("text", "confirmPassword")}
                   className="zmdi zmdi-eye"/> :
                <i onClick={() => togglePasswordField("password", "confirmPassword")}
                   className="zmdi zmdi-eye-off"/>
              }
            </div>
          </div>
          {(passwordConfirmError || password !== confirmPassword) ?
            <div>
              <small className="text-danger my-0">Password and Confirm Password does not match</small>
            </div> : null
          }
          <button className="log-in" type="button" disabled={isInvalid}
                  onClick={handleConfirmReset}>Reset Password
            {loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
          </button>
        </form>
      </AuthLayout>
    </Fragment>
  )
}
export default Body;
