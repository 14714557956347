import {dodgeInstance as Axios} from "./axios.service";


const ConfigsService = {
	getOrgService(ref) {
		return Axios({
			method: 'GET',
			url: `/locale-service/configs/services/users/${ref}`
		});
	},

	getNotifications(platform, filter) {
		return Axios({
			method: 'GET',
			url: `locale-service/messaging/user/notifications/${filter}`,
			headers: {platform: platform}
		});
	},

	updateNotificationReadStatus(platform, id, payload) {
		return Axios({
			method: 'GET',
			url: `locale-service/messaging/user/notifications/${id}`,
			data: payload,
			headers: {platform: platform}
		});
	},

	getAllNotificationMatrices() {
		return Axios({
			method: 'GET',
			url: `/locale-service/messaging/matrices`
		});
	},

	updateNotificationMatrices(payload) {
		return Axios({
			method: 'PUT',
			url: `/locale-service/messaging/user/notification-matrix`,
			data: payload
		});
	},

	getWarehouses() {
		return Axios({
			method: 'GET',
			url: `/warehouse-service/warehouse`
		});
	},

	getWarehouse(branchRef) {
		return Axios({
			method: 'GET',
			url: `/warehouse-service/warehouse/${branchRef}`
		});
	},

	createWarehouse(payload) {
		return Axios({
			method: 'POST',
			url: `/warehouse-service/warehouse/create-branch`,
			data: payload
		});
	},

	updateWarehouse(branchRef, payload) {
		return Axios({
			method: 'PATCH',
			url: `/warehouse-service/warehouse/update/${branchRef}`,
			data: payload
		});
	},

	deleteWarehouse(branchRef) {
		return Axios({
			method: 'DELETE',
			url: `/warehouse-service/warehouse/delete/${branchRef}`,
		});
	},


	/********************* API KEYS AND WEBHOOKS **************************/
 getIntegrationKeys() {
    return Axios({
      method: 'GET',
      url: `/locale-service/configs/integration/users/retrieve-keys`,
    });
  },

  generatePublicKey() {
    return Axios({
      method: 'GET',
      url: `/locale-service/configs/integration/users/generate-keys`,
    });
  }

};

 

export default ConfigsService;
