import TokenHelpers from "../helpers/tokenHelper";

export const LOCALHOST_BASEURL = "localhost";
export const DEFAULT_BASEURL = "dodge-super-app";
export const BETA_BASEURL = "dodge-super-app-beta";

export const getOrganisationDomain = () => {
  let cookieQuery = TokenHelpers.getSelectedOrg();
  let domainQuery = window.location.hostname.split(".")[0];
  let searchQuery = window.location.search.split("?organisation=")[1];
  if (domainQuery === LOCALHOST_BASEURL || domainQuery === DEFAULT_BASEURL || domainQuery === BETA_BASEURL) domainQuery = '';
  return searchQuery ? searchQuery : cookieQuery ? cookieQuery : domainQuery ? domainQuery : 'findOrganisation';
};

export const getOrganisationDomainMethod = () => {
  let cookieQuery = TokenHelpers.getSelectedOrg();
  let domainQuery = window.location.hostname.split(".")[0];
  let searchQuery = window.location.search.split("?organisation=")[1];
  if (domainQuery === LOCALHOST_BASEURL || domainQuery === DEFAULT_BASEURL || domainQuery === BETA_BASEURL) domainQuery = '';
  return searchQuery ? 'searchQuery' : cookieQuery ? 'cookieQuery' : domainQuery ? 'domainQuery' : 'findOrganisation';
};
