import {useState} from "react";
import {useDispatch} from "react-redux";
import toast from "react-hot-toast";

import ArrowBackSvg from "../../../../assets/images/svg/arrow-back.svg";
import WalletService from "../../../../utils/services/wallet.service";
import {Loading} from "../../../../utils/helpers/constants";
import {validateIfInteger} from "../../../../utils/services/validator.service";
import {getUserWallet} from "../../../../store/actions/wallet.action";

const ResetWithdrawalPin = ({wallet, updateResetState}) => {
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [tokenType, updateTokenType] = useState("password");


  const goBack = () => updateResetState(false);

  const toggleTokenField = (type) => {
    updateTokenType(type);
    document.getElementById('token').type = type;
  }

  const handleResetPin = async () => {
    setLoading(true);
    const {data: responseData} = await WalletService.resetPin({token, user_id: wallet.id});

    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else {
      updateResetState(false);
      dispatch(getUserWallet());
      toast.success(responseData.message);
    }
    setLoading(false);
  }


  return (
    <form>
      <div className="col-12">
        <p className="mb-5 fs-16 ms-0 pointer-event" onClick={goBack}>
          <img src={ArrowBackSvg} alt="Arrow-back" width={30} height={30}/> &nbsp; Go Back
        </p>
      </div>

      <div className=" col-12 mb-4">
        <label className="mb-2">Provide Reset Code</label>
        <div className="input-group">
          <input value={token} id="token" type="password" onKeyPress={validateIfInteger}
                 onChange={e => setToken(e.target.value.replace(/[^0-9]/g, ""))}
          />
          <span className="input-group-text">
                      {tokenType === "password" ?
                        <i onClick={() => toggleTokenField("number")}
                           className="zmdi zmdi-eye fs-5"/> :
                        <i onClick={() => toggleTokenField("password")}
                           className="zmdi zmdi-eye-off fs-5"/>
                      }
                    </span>
        </div>
      </div>
      <button className="btn primary-btn" type="button"
              onClick={handleResetPin} disabled={!token || token.length < 5}>
        Reset Wallet Pin {loading && <>&nbsp;<i className="zmdi zmdi-spinner ms-2 mg-l-1 zmdi-hc-spin"/></>}
      </button>
    </form>
  )
}

export default ResetWithdrawalPin;