import {combineReducers} from "redux";

import authReducer from "./auth.reducer";
import bookingReducer from "./booking.reducer";
import componentReducer from "./component.reducer";
import configsReducer from "./configs.reducer";
import fleetReducer from "./fleet.reducer";
import profileReducer from "./profile.reducer";
import walletReducer from "./wallet.reducer";

const rootReducer = combineReducers({
  authReducer,
  bookingReducer,
  componentReducer,
  configsReducer,
  fleetReducer,
  profileReducer,
  walletReducer
});

export default rootReducer;
