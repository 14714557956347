import {dodgeInstance as Axios} from './axios.service';

const ProfileService = {

  createUser(payload) {
    return Axios({
      method: 'POST',
      url: `entity-service/user/user-profile/`,
      data: payload,
    });
  },

  findCustomUser(orgRef, payload) {
    return Axios({
      method: 'POST',
      url: `entity-service/user/user-profile/search/ `,
      data: payload,
      headers: {organisation: orgRef}
    })
  },

  deleteUser(id) {
    return Axios({
      method: 'DELETE',
      url: `entity-service/user/user-profile/${id}/`,
    });
  },

  updateProfile(userId, payload) {
    return Axios({
      method: 'PATCH',
      url: `entity-service/user/user-profile/${userId}/updateUser/`,
      data: payload,
    })
  },

  changeProfilePicture(userId, payload) {
    return Axios({
      method: 'POST',
      url: `entity-service/user/user-profile/${userId}/profilePicture/`,
      data: payload,
      headers: {'Content-Type': 'multipart/form-data'}
    });
  },


  changePassword(payload) {
    return Axios({
      method: 'PATCH',
      url: `locale-service/tenants/change-password`,
      data: payload
    })
  },

  createBusinessProfile(payload) {
    return Axios({
      method: 'POST',
      url: `entity-service/business/business-profile/`,
      data: payload,
    });
  },

  updateBusinessProfile(businessId, payload) {
    return Axios({
      method: 'PATCH',
      url:`entity-service/business/business-profile/${businessId}/update_business/`,
      data: payload,
    })
  },

  createDriverProfile(payload) {
    return Axios({
      method: 'POST',
      url: `asset-service/driver/driver-profile/`,
      data: payload,
    });
  },

  updateDriverProfile(driverId, payload) {
    return Axios({
      method: 'PATCH',
      url: `asset-service/driver/driver-profile/${driverId}/`,
      data: payload,
    });
  },

  getCustomUserProfile() {
    return Axios({
      method: 'GET',
      url: `locale-service/tenants/profile`
    });
  },

  getCustomDriverProfile() {
    return Axios({
      method: 'GET',
      url: `asset-service/driver/driver-profile/profile/`
    });
  },

  uploadDriverDocument(driverId, payload) {
    return Axios({
      method: 'POST',
      url: `asset-service/driver/driver-profile/${driverId}/upload_documents/`,
      data: payload,
      headers: {'Content-Type': 'multipart/form-data'}
    });
  },

  deleteDriverProfile(id) {
    return Axios({
      method: 'DELETE',
      url: `asset-service/driver/driver-profile/${id}/`,
    });
  },

  getCustomUserBusinesses() {
    return Axios({
      method: 'GET',
      url: `entity-service/user/user-profile/get_businesses/`
    });
  },

  deleteCustomUserBusinesses(id) {
    return Axios({
      method: 'DELETE',
      url: `entity-service/business/business-profile/${id}/`,
    });
  },

  transferCustomUserBusinesses(payload) {
    return Axios({
      method: 'POST',
      url: `entity-service/business/business-profile/transfer_all_businesses/`,
      data: payload,
    });
  },

  creatGuarantorProfile(driverId, payload) {
    return Axios({
      method: 'POST',
      url: `asset-service/driver/driver-profile/${driverId}/guarantor/`,
      data: payload,
    })
  },

  getOrganisation(filter) {
    return Axios({
      method: 'GET',
      url: `locale-service/tenants/organisation/${filter}`
    });
  },

  getDynamicFields(type) {
    return Axios({
      method: 'GET',
      url: `locale-service/tenants/organisation/dynamic-fields/${type}`
    });
  },

  getOverview() {
    return Axios({
      method: 'GET',
      url: `/locale-service/analytics/overview/user`,
    });
  },

  disableCustomUser(payload) {
    return Axios({
      method: 'PATCH',
      url: `/locale-service/tenants/disable-profile`,
      data: payload
    });
  },

};

export default ProfileService;
