import {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate, useLocation} from "react-router-dom";
import toast from "react-hot-toast";

import ArrowBackSvg from "../../../assets/images/svg/arrow-back.svg";
import * as ProfileConstants from "../../../store/constants/profile.constants";
import ActionCreator from "../../../utils/helpers/actionCreator";
import {formatWebsite} from "../../../utils/helpers/logicHelper";
import {getProfile} from "../../../store/actions/profile.action";
import TokenHelper from "../../../utils/helpers/tokenHelper";
import ProfileService from "../../../utils/services/profile.service";
import {DomStateType, Loading, ActionType} from "../../../utils/helpers/constants";
import MapsSelectHook from "../../../components/maps/MapsSelectHook";


const Body = () => {
	const dispatch = useDispatch(), navigate = useNavigate();
	const goBack = () => navigate(-1);
	const location = useLocation()
  	const type = location.state?.actionType
  	const business = location.state?.business
	

	const [name, setName] = useState(business && business.name ? business.name : "");
	const [website, setWebsite] = useState(business && business.url ? business.url : "");
	const [isMerchant, setIsMerchant] = useState(false);
	const [loading, setLoading] = useState(false);
	const [addressObject, setAddressObject] = useState(business && business.address ? business.address : {});

	const isInvalid = !name || !website || Object.keys(addressObject).length === 0

	const updateLocation = (placeData) => {
		let placeObj = {
			city: placeData.city,
			state: placeData.state,
			address: placeData.address,
			latitude: placeData['latitude'],
			longitude: placeData['longitude']
		};
		setAddressObject(placeObj);
	}

	const closePage = async() => navigate(-1);

	const setBusinessProfile = business => {
		let newState = {type: DomStateType.BUSINESS, id: business.id, business};
		TokenHelper.saveSelectedBusiness(business.reference);
		dispatch(ActionCreator(ProfileConstants.SET_DOM_PROFILE, newState));
	}

	const onboardBusiness = async() => {
		setLoading(true);
		let payload = {
			name, 
			url: formatWebsite(website), 
			address: addressObject, 
			...(type === ActionType.CREATE && { is_merchant: isMerchant })
		}
		const {data: responseData} = type === ActionType.CREATE ? await ProfileService.createBusinessProfile(payload) : await ProfileService.updateBusinessProfile(business.id, payload);
		if(responseData.status !== Loading.SUCCESS) {
			const message = (responseData && responseData.message) ? responseData.message : "You cannot onboard your business at the moment, please try again later";
			toast.error(message);
		}
		else {
			await dispatch(getProfile());
			await setBusinessProfile(responseData.data);
			toast.success(responseData.message);
			type === ActionType.CREATE ? navigate('/') : navigate(-1);
		}
		setLoading(false);
	}


	return (
		<div className="booking py-4 bg-white">
			<div className="pb-5">
				<p className="mb-0 booking-direct ms-0 ps-0 fs-18 pointer-event" onClick={goBack}>
					<img src={ArrowBackSvg} alt="Arrow-back"/> &nbsp; Go Back
				</p>
				<div className="booking-main d-flex flex-column ">
					<h3 className="booking-title">{type === ActionType.UPDATE ? "Update" : "Set up"} your business profile</h3>
					<form className="booking-form d-flex mt-5 flex-column align-items-start">
						<div className="position-relative w-100 mg-t-15 text-wrap">
							<label>Business Name</label>
							<div className="position-relative w-100 mg-t-15">
								<input type="text" value={name} onChange={e => setName(e.target.value)}/>
							</div>
						</div>
						<div className="position-relative w-100 mg-t-15">
							<label>Business Website</label>
							<div className="position-relative w-100 mg-t-15">
								<input type="text" value={website} onChange={e => setWebsite(e.target.value)}/>
							</div>
						</div>
						{(type === ActionType.CREATE || (business && business.address && Object.keys(business.address).length === 0)) &&
							<div className="position-relative w-100 mg-t-15">
								<MapsSelectHook label="Business Address" setLocation={value => updateLocation(value)} />
							</div>
						}
						{type === ActionType.CREATE &&
							<div className="position-relative w-100 mg-t-15">
								<label>Are you onboarding as a Merchant?</label>
								<div className="d-flex align-items-center radio-group mt-4">
									{isMerchant ? <i className="zmdi zmdi-check-square"></i> :
										<i onClick={() => setIsMerchant(true)} className="zmdi zmdi-square-o"></i>
									}
									<span className="ms-3">Yes</span>
								</div>
								<div className="d-flex align-items-center radio-group mb-4rem">
									{isMerchant ? <i onClick={() => setIsMerchant(false)} className="zmdi zmdi-square-o"></i> :
										<i className="zmdi zmdi-check-square"></i>
									}
									<span className="ms-3">No</span>
								</div>
							</div>
						}
						<div className="position-relative w-100 mg-t-15">
							<button type="button" className="btn main-btn mt-4" onClick={onboardBusiness} disabled={isInvalid}>
							{type === ActionType.UPDATE ? "Update" : "Onboard"} Business{loading && <i className="zmdi zmdi-spinner mg-l-5 zmdi-hc-spin"/>}
							</button>
							<button className="btn main-btn bg-danger mt-4 mg-l-3" onClick={closePage} type="button">Close</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);

}

export default Body;
