import {useState} from "react";
import toast from "react-hot-toast";

import PageLayout from "../../../../components/layouts/PageLayout";
import InnerMenuLayout from "../../../../components/layouts/InnerMenuLayout";
import ProfileService from "../../../../utils/services/profile.service";
import {Loading, menuType} from "../../../../utils/helpers/constants";


const ChangePassword = () => {

  const [loading, setLoading] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordType, updateOldPasswordType] = useState("password");
  const [passwordType, updatePasswordType] = useState("password");
  const [confirmPasswordType, updateConfirmPasswordType] = useState("password");
  const disabledState = !oldPassword || !password || !confirmPassword || password !== confirmPassword;

  const changePassword = async () => {
    setLoading(true);
    const {data: responseData} = await ProfileService.changePassword({oldPassword, password});
    if (responseData.status !== Loading.SUCCESS) toast.error(responseData.message);
    else toast.success(responseData.message);
    setLoading(false);
  }

  const togglePassword = (field, type) => {

    if (field === 'OLD_PASSWORD') {
      updateOldPasswordType(type);
      document.getElementById('oldPassword').type = type;
    } else if (field === 'PASSWORD') {
      updatePasswordType(type);
      document.getElementById('password').type = type;
    } else {
      updateConfirmPasswordType(type);
      document.getElementById('confirmPassword').type = type;
    }
  }


  return (
    <PageLayout active={menuType.SETTINGS}>
      <InnerMenuLayout active={menuType.CHANGE_PASSWORD} type="SETTINGS">
        <div className="settings-main">
          <div className="p-4">
            <form>
              <div className="mb-3">
                <label className="mb-2">Enter old password</label>
                <div className="input-group">
                  <input type="password" value={oldPassword} onChange={e => setOldPassword(e.target.value)}
                         id="oldPassword"/>
                  <span className="input-group-text">
                    {oldPasswordType === "password" ?
                      <i onClick={() => togglePassword("OLD_PASSWORD", "text")} className="zmdi zmdi-eye"/> :
                      <i onClick={() => togglePassword("OLD_PASSWORD", "password")} className="zmdi zmdi-eye-off"/>
                    }
                  </span>
                </div>
              </div>
              <div className="mb-3">
                <label className="mb-2">Enter new password</label>
                <div className="input-group">
                  <input type="password" value={password} onChange={e => setPassword(e.target.value)} id="password"/>
                  <span className="input-group-text">
                    {passwordType === "password" ?
                      <i onClick={() => togglePassword("PASSWORD", "text")} className="zmdi zmdi-eye"/> :
                      <i onClick={() => togglePassword("PASSWORD", "password")} className="zmdi zmdi-eye-off"/>
                    }
                  </span>
                </div>
              </div>
              <div className="mb-4">
                <label className="mb-2">Confirm new password</label>
                <div className="input-group">
                  <input type="password" value={confirmPassword} onChange={e => setConfirmPassword(e.target.value)}
                         id="confirmPassword"/>
                  <span className="input-group-text">
                    {confirmPasswordType === "password" ?
                      <i onClick={() => togglePassword("CONFIRM_PASSWORD", "text")} className="zmdi zmdi-eye"/> :
                      <i onClick={() => togglePassword("CONFIRM_PASSWORD", "password")} className="zmdi zmdi-eye-off"/>
                    }
                  </span>
                </div>
              </div>
              <button className="btn primary-btn" disabled={disabledState} onClick={changePassword} type="button">
                Change password
                {loading ? <i className="zmdi zmdi-spinner mg-2 zmdi-hc-spin"/> : ""}
              </button>
            </form>
          </div>
        </div>
      </InnerMenuLayout>
    </PageLayout>
  )
}

export default ChangePassword;
