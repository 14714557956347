import {useState} from "react";
import {useDispatch} from "react-redux";
import Select from "react-select";
import moment from "moment";

import NOTFOUND from "../../../../assets/images/svg/empty_field.svg";
import ConfigsService from "../../../../utils/services/configs.service";
import {Loading, UserType} from "../../../../utils/helpers/constants";
import {paginationSize} from "../../../../utils/helpers/objectHelpers";
import {getNotifications} from "../../../../store/actions/configs.action";
import Pagination from "../../../../components/dataTable/Pagination";

const Body = ({notifications, notificationCount}) => {
  const dispatch = useDispatch(), platform = UserType.CUSTOM_USER;
  const [page, setPage] = useState(1);
  const [filterInput] = useState("");
  const [pageData, setPageData] = useState(notifications ?? []);
  const [countData, setCountData] = useState(notificationCount);
  const [pageSize, setPageSize] = useState(paginationSize[1]);
  const [loadingPagination, setLoadingPagination] = useState(false);


  const fetchNotifications = () => dispatch(getNotifications(platform, ""));

  const fetchPagination = async (option, value) => {
    setLoadingPagination(true);
    setPageSize(option);
    if (notificationCount < option.value) setPage(Math.ceil(value - 1));
    else setPage(value);
    const filter = `?size=${option.value}&page=${notificationCount < option.value ? value - 1 : value}`;
    const {data: responseData} = await ConfigsService.getNotifications(platform, filter);
    const updatedCount = responseData.count ? parseInt(responseData.count) : responseData.data ? responseData.data.length : 0;

    if (responseData.status === Loading.SUCCESS || updatedCount > 0) {
      setPageData(responseData.data);
      setCountData(updatedCount);
    } else {
      setPageData(notifications);
      setCountData(notificationCount);
    }
    setLoadingPagination(false);
  }

  const handleMarkNotificationAsRead = async notification => {
    if (!notification['is_read']) {
      const {data: responseData} = await ConfigsService.updateNotificationReadStatus(platform, notification.id, {status: true});
      if(responseData.status !== Loading.SUCCESS) console.error(responseData.message)
      else await fetchNotifications();
    }
  }


  return (
    <div className="settings-main">
      <div className="mt-5">
        <div className="row mx-2">
          <div className="col-6">
            <h5 className="notification-header">Notifications</h5>
          </div>
        </div>

        <div className="row mt-4">
          <div className="col-12">
            <div className="d-flex justify-content-end me-3">
              <Select options={paginationSize}
                      aria-label="pagination"
                      isClearable={false}
                      isSearchable={false}
                      maxMenuHeight={250}
                      menuPlacement="bottom"
                      name="pageSize"
                      className="wd-md-200-f mb-3"
                      value={pageSize}
                      onChange={option => fetchPagination(option, page)}/>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col-12 px-4">
            <ul className="list-group">
              {(pageData && pageData.length > 0)
                ? pageData.map(notification =>
                  <li key={notification.id} onClick={() => handleMarkNotificationAsRead(notification)}
                      className="list-group-item list-group-item-action">
                    <div className="d-flex w-100 justify-content-between mb-2">
                      <h5 className="mb-1">{notification.title ? notification.title : "-"}</h5>
                      <small className="text-muted">
                        {notification['delivery_time'] ? moment(notification['delivery_time']).format('lll') : ''}
                      </small>
                    </div>
                    <p className="mb-1 text-muted">{notification.body ? notification.body : "-"}</p>
                    {notification['is_read'] &&
                      <p className="me-2 float-end">
                        <i className="text-success fs-15 zmdi zmdi-check"></i>
                      </p>
                    }
                  </li>)
                : <div className="my-5 py-5">
                  <h3 className="text-center">No Notification</h3>
                  <img src={NOTFOUND} width={300} height={300} alt="NO-NOTIFICATION" className="m-auto d-block"/>
                </div>
              }
            </ul>
          </div>
        </div>

        <div className="row row-sm">
          <div className="col-12 mt-5">
            <Pagination filterData={filterInput} size={pageSize.value} count={countData}
                        loading={loadingPagination} index={page} data={pageData}
                        setPage={value => fetchPagination(pageSize, value)}/>
          </div>
        </div>
      </div>
    </div>
  )
};

export default Body;