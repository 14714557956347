export const FETCH_WALLET_REQUEST = "FETCH_WALLET_REQUEST";
export const FETCH_WALLET_SUCCESS = "FETCH_WALLET_SUCCESS";
export const FETCH_WALLET_FAILURE = "FETCH_WALLET_FAILURE";

export const FETCH_WALLET_ENTRIES_REQUEST = "FETCH_WALLET_ENTRIES_REQUEST";
export const FETCH_WALLET_ENTRIES_SUCCESS = "FETCH_WALLET_ENTRIES_SUCCESS";
export const FETCH_WALLET_ENTRIES_FAILURE = "FETCH_WALLET_ENTRIES_FAILURE";

export const FETCH_BANKS_REQUEST = "FETCH_BANKS_REQUEST";
export const FETCH_BANKS_SUCCESS = "FETCH_BANKS_SUCCESS";
export const FETCH_BANKS_FAILURE = "FETCH_BANKS_FAILURE";

export const FETCH_BANK_ACCOUNT_REQUEST = "FETCH_BANK_ACCOUNT_REQUEST";
export const FETCH_BANK_ACCOUNT_SUCCESS = "FETCH_BANK_ACCOUNT_SUCCESS";
export const FETCH_BANK_ACCOUNT_FAILURE = "FETCH_BANK_ACCOUNT_FAILURE";