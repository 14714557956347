import {Fragment} from "react";
import {useSelector} from "react-redux";
import Header from "./header/Header";
import AppSphereLoader from "../loaders/AppSphereLoader";

const InnerPageLayout = ({children}) => {

  const component = useSelector(({componentReducer}) => componentReducer);
  return (
    <Fragment>
      {component.appLoader ? <AppSphereLoader/> :
        <div className="d-flex flex-column">
          <div className="d-flex flex-column">
            <Header/>
            {children}
          </div>
        </div>
      }
    </Fragment>
  )
}

export default InnerPageLayout;
